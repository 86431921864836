import { FunctionComponent, ReactNode } from "react";
import BasicButton from "@components/buttons/BasicButton";
import i18n from "i18next";

import { FileTextOutlined } from "@ant-design/icons";

interface FileDownloadProps {
  image?: string | ReactNode;
  title?: string;
  description?: string;
  onClick: () => void;
  className?: string;
  backgroundColor?: string;
  buttonVariant?: string;
  buttonLabel?: string;
  carded?: boolean;
  cardColor?: string;
  uploadLabel?: string;
  onUploadClick?: () => void;
  isInModal?: boolean;
}

const FileDownloadModuleCMS: FunctionComponent<FileDownloadProps> = ({
  image = <FileTextOutlined style={{ fontSize: "48pt" }} />,
  title = "",
  description = "",
  className = "",
  onClick,
  backgroundColor = "transparent",
  buttonVariant = undefined,
  buttonLabel = i18n.t<string>("common.file.download"),
  carded = false,
  cardColor = "white",
  uploadLabel,
  onUploadClick,
  isInModal = false,
}) => {
  return (
    <div
      className={`app-download ${className}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={`download-content content-contained ${
          carded ? "download-carded" : ""
        }`}
        style={{ backgroundColor: carded ? cardColor : "transparent" }}
      >
        <div className={"download-file-container"}>
          {typeof image === "string" ? (
            <img src={image} className={"download-img"} />
          ) : (
            image
          )}
          <div className={"download-file-text-container"}>
            <span className={"download-file-title"}>{title}</span>
            <span className={"download-file-description"}>{description}</span>
          </div>
        </div>
        <div className={`download-button-container ${isInModal ? "me-0" : ""}`}>
          {uploadLabel && onUploadClick && (
            <BasicButton
              text={uploadLabel}
              variant="primary-outline"
              onClick={() => onUploadClick()}
              className="ms-3"
            />
          )}
          <BasicButton
            text={buttonLabel}
            variant={buttonVariant}
            onClick={() => onClick()}
            className="ms-3"
          />
        </div>
      </div>
    </div>
  );
};

export default FileDownloadModuleCMS;
