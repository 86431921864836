import { FC } from "react";
import {
  BlockInput,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import { File } from "@type/resource-type-interface";
import FileDownloadModuleCMS from "@components/cms/FileDownloadModuleCMS";
import { downloadFile } from "@utils/Base64File";

interface Props {
  block: PageBlockInfoResponseDto;
}

const Files: FC<Props> = ({ block }) => {
  const onClick = (file: File) => {
    if (file.base64Data) {
      downloadFile(file.base64Data, file.type, file.name);
    }
  };

  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const files =
    (inputs[0].content &&
      (JSON.parse(inputs[0].content as string) as File[])) ||
    [];
  return (
    <div className="d-flex align-items-center flex-wrap">
      {files.map((file) => (
        <FileDownloadModuleCMS
          key={file.id}
          title={file.name}
          description={`${file.type} - ${file.size}`}
          onClick={() => onClick(file)}
        />
      ))}
    </div>
  );
};

export default Files;
