import i18n from "i18next";
import { Tooltip } from "antd";
import { tooltipDateNoTimeRender } from "@components/tooltips/CustomTooltip";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import TableButton from "@components/buttons/TableButton";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";

export const columns = (
  onRowClick: (
    record: OrganisationProfessionalHabitAPlusMailsResponseDto,
  ) => void,
  updateRead: (
    mail: OrganisationProfessionalHabitAPlusMailsResponseDto,
  ) => void,
  getPDFLink: (id: string) => string,
): ColumnsType<OrganisationProfessionalHabitAPlusMailsResponseDto> => [
  {
    title: i18n.t("organisationprofessional.mails.table.headers.createdAt"),
    dataIndex: "date",
    key: "date",
    className: "date",
    width: 150,
    render: tooltipDateNoTimeRender,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.mails.table.headers.title"),
    key: "title",
    className: "title",
    ellipsis: {
      showTitle: false,
    },
    render: (
      record: OrganisationProfessionalHabitAPlusMailsResponseDto,
    ): ReactNode => {
      return (
        <div className="d-flex align-items-center gap-3">
          <Tooltip placement="topLeft" title={record.title}>
            {record.title}
          </Tooltip>
          {!record.read && (
            <BasicTag
              label={i18n.t("organisationprofessional.mails.table.new")}
              variant="light-green"
            />
          )}
        </div>
      );
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.mails.table.headers.recipient"),
    key: "recipient",
    className: "recipient",
    width: 180,
    render: (
      record: OrganisationProfessionalHabitAPlusMailsResponseDto,
    ): ReactNode => {
      return <div>{record.recipient}</div>;
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.mails.table.headers.actions"),
    key: "id",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 105,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (
      record: OrganisationProfessionalHabitAPlusMailsResponseDto,
    ): JSX.Element => (
      <div className="d-inline-flex">
        <TableButton
          tooltipTitle={i18n.t(
            "organisationprofessional.mails.table.tooltip.details",
          )}
          icon={<EyeOutlined />}
          buttonClassName="btn-transparent w-auto"
          onClick={() => onRowClick(record)}
        />
        <TableButton
          tooltipTitle={i18n.t(
            "organisationprofessional.mails.table.tooltip.download",
          )}
          icon={<DownloadOutlined />}
          buttonClassName="btn-transparent w-auto ms-3"
          onClick={() => {
            window.open(getPDFLink(record.id), "_blank");
            updateRead(record);
          }}
        />
      </div>
    ),
  },
];
