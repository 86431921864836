import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicButton from "@components/buttons/BasicButton";
import BasicCard from "@components/cards/BasicCard";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { setMentionPacHybride } from "@state/auth/AuthEvents";
import { requestRenewalMention } from "@state/mention/pachybride/MentionPacHybrideEffects";
import { Col, Form, message, Modal, Row } from "antd";
import UploadFormField from "@components/inputs/UploadFormField";
import { File } from "@type/resource-type-interface";
import { mapResourceTofileType } from "@utils/upload-utils";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import RadioFormField from "@components/inputs/RadioFormField";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import DateFormat from "@utils/DateFormat";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import parse from "html-react-parser";
import { PacHybrideMentionPendinScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionPendinScreen";

const { confirm } = Modal;

export const PacHybrideMentionRenewalScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);
  const hasPacHybride = authentifiedContext.user
    ?.pacHybride as MentionResponseDto;
  const [isLoadingRGE, setIsLoadingRGE] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const isRgeRefused = hasPacHybride?.statusRge === "REFUSED";
  const isElearningRefused = hasPacHybride?.elearningStatus === "REFUSED";

  const [rgeId, setRgeId] = useState<string | undefined>(
    hasPacHybride?.rge?.id ?? undefined,
  );

  const [acceptedMentionTypes] = useState<string[]>([
    RESOURCE_TYPE.PDF.applicationType,
  ]);

  const [numberPacHybride, setNumberPacHybride] = useState<string>(
    isElearningRefused ? hasPacHybride?.numberPacHybride ?? "" : "",
  );
  const [technicalTraining, setTechnicalTraining] = useState<string>(
    isElearningRefused ? hasPacHybride?.technicalTraining ?? "" : "",
  );
  const [commercialTraining, setCommercialTraining] = useState<string>(
    isElearningRefused ? hasPacHybride?.commercialTraining ?? "" : "",
  );

  const [rgeFiles] = useState<File[]>(
    isRgeRefused
      ? hasPacHybride?.rge
        ? [mapResourceTofileType(hasPacHybride.rge)]
        : []
      : [],
  );
  const [renewalInstallationId, setRenewalInstallationId] = useState<
    string | undefined
  >(hasPacHybride?.renewalInstallation?.id ?? undefined);
  const [renewalTechnicalTrainingId, setRenewalTechnicalTrainingId] = useState<
    string | undefined
  >(hasPacHybride?.renewalTechnicalTraining?.id ?? undefined);
  const [technicalTrainingFiles] = useState<File[]>(
    isElearningRefused
      ? hasPacHybride?.renewalTechnicalTraining
        ? [mapResourceTofileType(hasPacHybride.renewalTechnicalTraining)]
        : []
      : [],
  );
  const [installationFiles] = useState<File[]>(
    isElearningRefused
      ? hasPacHybride?.renewalInstallation
        ? [mapResourceTofileType(hasPacHybride.renewalInstallation)]
        : []
      : [],
  );
  const isPending = hasPacHybride?.statusRge === "PENDING";
  const isRefused =
    hasPacHybride?.statusRge === "REFUSED" ||
    hasPacHybride?.elearningStatus === "REFUSED";
  const isExpired = hasPacHybride?.status === "EXPIRED";
  const timerMentionWillExpired = DateFormat.getNumberOfDaysBeforeTheDeadline(
    hasPacHybride.expiredAt ?? "00/00/0000",
  );
  const needToPassElearning =
    numberPacHybride === "no" &&
    technicalTraining === "no" &&
    commercialTraining === "no";

  const handleRenewalMention = useCallback(() => {
    if (hasPacHybride) {
      needToPassElearning &&
        rgeId &&
        navigate(ROUTES.professional.pacsHybrides.iframe.generate());
      void requestRenewalMention({
        id: hasPacHybride.id,
        dto: {
          rgeId: rgeId ?? undefined,
          renewalInstallationId:
            numberPacHybride === "yes" ? renewalInstallationId : undefined,
          renewalTechnicalTrainingId:
            technicalTraining === "yes"
              ? renewalTechnicalTrainingId
              : undefined,
          numberPacHybride: numberPacHybride,
          technicalTraining:
            numberPacHybride === "yes" ? undefined : technicalTraining,
          commercialTraining:
            numberPacHybride === "yes" || technicalTraining === "yes"
              ? undefined
              : commercialTraining,
        },
      });
      setIsLoadingRGE(true);
    }
  }, [
    hasPacHybride,
    commercialTraining,
    history,
    numberPacHybride,
    renewalInstallationId,
    renewalTechnicalTrainingId,
    rgeId,
    technicalTraining,
  ]);

  useEffect(() => {
    return requestRenewalMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
        void message.success(t("pro.mentions.pacHybride.form.messages.update"));
        navigate(ROUTES.professional.pacsHybrides.mention.generate());
      }
      setIsLoadingRGE(false);
    });
  });

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: parse(
          t<string>(
            `pro.mentions.pacHybride.renewal.modal.${
              needToPassElearning ? "elearning" : "save"
            }.title`,
          ),
        ),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        className: "app-modal professional",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const handleSubmit = () => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        handleRenewalMention();
      }
    });
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.mentions.pacHybride.detail.renewalMention.title")}
      titleCentered={false}
      returnRoute={
        isExpired ? "" : ROUTES.professional.pacsHybrides.mention.generate()
      }
      headerLeftContent={
        <>
          {!isRefused && !isPending && (
            <>
              <div className="mb-3">
                {isExpired
                  ? t(
                      "pro.mentions.pacHybride.detail.renewalMention.expiredsubtitle1",
                    )
                  : t(
                      "pro.mentions.pacHybride.detail.renewalMention.subtitle1",
                      { nbDays: timerMentionWillExpired },
                    )}
              </div>
              <div>
                {t("pro.mentions.pacHybride.detail.renewalMention.listTitle")}
                <ul>
                  <li>
                    {t("pro.mentions.pacHybride.detail.renewalMention.list1")}
                  </li>
                  <li>
                    {t("pro.mentions.pacHybride.detail.renewalMention.list2")}
                  </li>
                </ul>
              </div>
              <div className="mb-4">
                {t("pro.mentions.pacHybride.detail.renewalMention.subtitle2")}
              </div>
            </>
          )}
        </>
      }
    >
      {isPending ? (
        <PacHybrideMentionPendinScreen />
      ) : (
        <BasicCard>
          <Form onFinish={handleSubmit}>
            {(!isRefused || (isRgeRefused && isRefused)) && (
              <>
                <div className="text-primary font-weight-bold mb-20">
                  {t(
                    "pro.mentions.pacHybride.detail.renewalMention.card.titleRGE",
                  )}
                </div>
                <div>
                  {isRgeRefused ? (
                    <div className="mb-1" style={{ color: "red" }}>
                      {t(
                        "pro.mentions.pacHybride.detail.renewalMention.card.errors.rge",
                      )}
                    </div>
                  ) : (
                    <h4 className="worksite-form-title mb-4">
                      {t(
                        "pro.mentions.pacHybride.detail.renewalMention.card.rgeDetail",
                      )}
                    </h4>
                  )}
                  <div className="mb-4">
                    <UploadFormField
                      showUploadList
                      name="rge"
                      label={t(
                        "pro.mentions.pacHybride.detail.renewalMention.card.rge",
                      )}
                      domain="RGE_PAC_HYBRIDE"
                      saveFile
                      setFileId={setRgeId}
                      types={acceptedMentionTypes}
                      formatLabel="PDF"
                      setIsUploading={setIsUploading}
                      files={rgeFiles}
                    />
                  </div>
                </div>
              </>
            )}
            {(!isRefused || (isElearningRefused && isRefused)) && (
              <>
                <div className="text-primary font-weight-bold mb-20 mt-5">
                  {t(
                    "pro.mentions.pacHybride.detail.renewalMention.card.titleForm",
                  )}
                </div>
                {isElearningRefused && (
                  <div className="mb-1" style={{ color: "red" }}>
                    {t(
                      `pro.mentions.pacHybride.detail.renewalMention.card.errors.${
                        hasPacHybride.hasPassedACommercialTraining
                          ? "renewalInformationsCommercialTraining"
                          : "renewalInformations"
                      }`,
                    )}
                  </div>
                )}
                {needToPassElearning && (
                  <div className="mb-1" style={{ color: "red" }}>
                    {t(
                      "pro.mentions.pacHybride.detail.renewalMention.card.warnings.renewalInformations",
                    )}
                  </div>
                )}
                <h4 className="worksite-form-title mb-4">
                  {t(
                    "pro.mentions.pacHybride.detail.renewalMention.card.numberPacHybrideTitle",
                  )}
                </h4>
                <Row>
                  <Col xs={24} lg={12}>
                    <RadioFormField
                      initialValue={numberPacHybride}
                      required
                      field="numberPacHybride"
                      onChange={(value) =>
                        setNumberPacHybride(value.target.value)
                      }
                      options={[
                        {
                          value: "yes",
                          label: t("common.yes"),
                        },
                        {
                          value: "no",
                          label: t("common.no"),
                        },
                      ]}
                    />
                  </Col>
                  {numberPacHybride === "yes" && (
                    <Col xs={24} lg={12}>
                      <UploadFormField
                        showUploadList
                        name="numberPacHybrideCertif"
                        label={t(
                          "pro.mentions.pacHybride.detail.renewalMention.card.form.fields.numberPacHybrideCertif.label",
                        )}
                        domain="PAC_HYBRIDE_RENEWAL_INSTALLATION"
                        saveFile
                        setFileId={setRenewalInstallationId}
                        types={acceptedMentionTypes}
                        formatLabel="PDF"
                        setIsUploading={setIsUploading}
                        files={installationFiles}
                      />
                    </Col>
                  )}
                </Row>
                {numberPacHybride === "no" && (
                  <>
                    <h4 className="worksite-form-title mb-4">
                      {t(
                        "pro.mentions.pacHybride.detail.renewalMention.card.technicalTraining",
                      )}
                    </h4>
                    <Row>
                      <Col xs={24} lg={12}>
                        <RadioFormField
                          initialValue={technicalTraining}
                          required
                          field="technicalTraining"
                          onChange={(value) =>
                            setTechnicalTraining(value.target.value)
                          }
                          options={[
                            {
                              value: "yes",
                              label: t("common.yes"),
                            },
                            {
                              value: "no",
                              label: t("common.no"),
                            },
                          ]}
                        />
                      </Col>
                      {technicalTraining === "yes" && (
                        <Col xs={24} lg={12}>
                          <UploadFormField
                            name="technicalCertif"
                            label={t(
                              "pro.mentions.pacHybride.detail.renewalMention.card.form.fields.technicalCertif.label",
                            )}
                            domain="PAC_HYBRIDE_RENEWAL_TECHNICAL_TRAINING"
                            saveFile
                            setFileId={setRenewalTechnicalTrainingId}
                            filesId={
                              renewalTechnicalTrainingId
                                ? [renewalTechnicalTrainingId]
                                : []
                            }
                            types={acceptedMentionTypes}
                            formatLabel="PDF"
                            files={technicalTrainingFiles}
                            setIsUploading={setIsUploading}
                            showUploadList
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                )}
                {technicalTraining === "no" && numberPacHybride === "no" && (
                  <>
                    <h4 className="worksite-form-title mb-4">
                      {t(
                        "pro.mentions.pacHybride.detail.renewalMention.card.commercialTraining",
                      )}
                    </h4>
                    <Row>
                      <Col xs={24} lg={12}>
                        <RadioFormField
                          initialValue={commercialTraining}
                          required
                          onChange={(value) =>
                            setCommercialTraining(value.target.value)
                          }
                          field="commercialTraining"
                          options={[
                            {
                              value: "yes",
                              label: t("common.yes"),
                            },
                            {
                              value: "no",
                              label: t("common.no"),
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            <div className="d-flex justify-content-center mt-3">
              <BasicButton
                type="submit"
                text={t<string>(
                  "pro.mentions.pacHybride.detail.renewalMention.card.actions.submit",
                )}
                isLoading={isUploading || isLoadingRGE}
              />
            </div>
          </Form>
        </BasicCard>
      )}
    </PageLayout>
  );
};
