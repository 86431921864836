import { Store } from "rc-field-form/lib/interface";
import { MaintenanceCertificateSaveRequestDto } from "@state/maintenancecertificate/dto/maintenance.certificate.save.request.dto";
import { RefObject } from "react";
import ReactSignatureCanvas from "react-signature-canvas";

export const getPublishRequest = (
  values: Store,
  sigCanvas: RefObject<ReactSignatureCanvas>,
  sigTechicianCanvas: RefObject<ReactSignatureCanvas>,
) => {
  const urlCustumer =
    sigCanvas.current
      ?.getSignaturePad()
      .toDataURL("image/png")
      .split("base64,")[1] ?? "";
  const urlTechnician =
    sigTechicianCanvas.current
      ?.getSignaturePad()
      .toDataURL("image/png")
      .split("base64,")[1] ?? "";

  return {
    clientFirstname: values.section1clientFirstname as string,
    clientLastname: values.section1clientLastname as string,
    clientPhone: values.section1clientPhone as string,

    address: values.section1address as string,
    complementCity: values.section1complementCity as string,
    boilerRoom: values.section1boilerRoom as string,

    boilerBrand: values.section2boilerBrand as string,
    boilerCapacity: values.section2boilerCapacity as string,
    boilerEvacuationMode: values.section2boilerEvacuationMode as string,
    boilerCommissioningDate: values.section2boilerCommissioningDate as string,
    boilerSerialNumero: values.section2boilerSerialNumero as string,
    boilerLastMaintenanceDate:
      values.section2boilerLastMaintenanceDate as string,

    bruleurAirSouffleBrand: values.section2bruleurAirSouffleBrand as string,
    bruleurAirSouffleCapacity:
      values.section2bruleurAirSouffleCapacity as string,
    bruleurAirSouffleCommissioningDate:
      values.section2bruleurAirSouffleCommissioningDate as string,
    bruleurAirSouffleSerialNumero:
      values.section2bruleurAirSouffleSerialNumero as string,
    bruleurAirSouffleLastMaintenanceDate:
      values.section2bruleurAirSouffleLastMaintenanceDate as string,

    checkpoint: JSON.stringify({
      text1: values.section3checkpointText1 as string,
      text2: values.section3checkpointText2 as string,
      text3: values.section3checkpointText3 as string,
      text4: values.section3checkpointText4 as string,
      text5: values.section3checkpointText5 as string,
      text6: values.section3checkpointText6 as string,
      text7: values.section3checkpointText7 as string,
      text8: values.section3checkpointText8 as string,
      text9: values.section3checkpointText9 as string,
      text10: values.section3checkpointText10 as string,
      text11: values.section3checkpointText11 as string,
      text12: values.section3checkpointText12 as string,
      text13: values.section3checkpointText13 as string,
      text14: values.section3checkpointText14 as string,
      text15: values.section3checkpointText15 as string,
      text16: values.section3checkpointText16 as string,
      text17: values.section3checkpointText17 as string,
      text18: values.section3checkpointText18 as string,
      text19: values.section3checkpointText19 as string,
      text20: values.section3checkpointText20 as string,
      text21: values.section3checkpointText21 as string,
      text22: values.section3checkpointText22 as string,
      text23: values.section3checkpointText23 as string,
      text24: values.section3checkpointText24 as string,
    }),
    checkpointComment: values.section3checkpointComment as string,

    mandatoryMeasureOfBruleurAirSouffle: JSON.stringify({
      text1: values.section4Text1 as string,
      text2: values.section4Text2 as string,
      text3: values.section4Text3 as string,
      text4: values.section4Text4 as string,
      text5: values.section4Text5 as string,
      text6: values.section4Text6 as string,
      text7: values.section4Text7 as string,
      text8: values.section4Text8 as string,
      text9: values.section4Text9 as string,
      text10: values.section4Text10 as string,
      text11: values.section4Text11 as string,
      text12: values.section4Text12 as string,
    }),

    mandatoryMeasureOfBruleurAirSouffleComment:
      values.section4mandatoryMeasureOfBruleurAirSouffleComment as string,

    adviceInstallation: JSON.stringify({
      text1: values.section5adviceText1 as string,
      text2: values.section5adviceText2 as string,
      text3: values.section5adviceText3 as string,
      text4: values.section5adviceText4 as string,
      text5: values.section5adviceText5 as string,
      text6: values.section5adviceText6 as string,
      text7: values.section5adviceText7 as string,
      text8: values.section5adviceText8 as string,
      text9: values.section5adviceText9 as string,
      text10: values.section5adviceText10 as string,
      text11: values.section5adviceText11 as string,
      text12: values.section5adviceText12 as string,
      text13: values.section5adviceText13 as string,
      text14: values.section5adviceText14 as string,
      text15: values.section5adviceText15 as string,
      text16: values.section5adviceText16 as string,
      text17: values.section5adviceText17 as string,
      text18: values.section5adviceText18 as string,
      text19: values.section5adviceText19 as string,
    }),
    adviceInstallationComment:
      values.section5adviceInstallationComment as string,

    dateOfVisite: values.section6dateOfVisite as string,
    nameOfPersonPresent: values.section6nameOfPersonPresent as string,
    comment: values.section6comment as string,
    technicianName: values.section6technicianName as string,
    customerSignature: urlCustumer,
    technicianSignature: urlTechnician,
  } as MaintenanceCertificateSaveRequestDto;
};

export const getSaveRequest = (values: Store) => {
  return {
    clientFirstname: values.section1clientFirstname as string,
    clientLastname: values.section1clientLastname as string,
    clientPhone: values.section1clientPhone as string,

    address: values.section1address as string,
    complementCity: values.section1complementCity as string,
    boilerRoom: values.section1boilerRoom as string,

    boilerBrand: values.section2boilerBrand as string,
    boilerCapacity: values.section2boilerCapacity as string,
    boilerEvacuationMode: values.section2boilerEvacuationMode as string,
    boilerCommissioningDate: values.section2boilerCommissioningDate as string,
    boilerSerialNumero: values.section2boilerSerialNumero as string,
    boilerLastMaintenanceDate:
      values.section2boilerLastMaintenanceDate as string,

    bruleurAirSouffleBrand: values.section2bruleurAirSouffleBrand as string,
    bruleurAirSouffleCapacity:
      values.section2bruleurAirSouffleCapacity as string,
    bruleurAirSouffleCommissioningDate:
      values.section2bruleurAirSouffleCommissioningDate as string,
    bruleurAirSouffleSerialNumero:
      values.section2bruleurAirSouffleSerialNumero as string,
    bruleurAirSouffleLastMaintenanceDate:
      values.section2bruleurAirSouffleLastMaintenanceDate as string,

    checkpoint: JSON.stringify({
      text1: values.section3checkpointText1 as string,
      text2: values.section3checkpointText2 as string,
      text3: values.section3checkpointText3 as string,
      text4: values.section3checkpointText4 as string,
      text5: values.section3checkpointText5 as string,
      text6: values.section3checkpointText6 as string,
      text7: values.section3checkpointText7 as string,
      text8: values.section3checkpointText8 as string,
      text9: values.section3checkpointText9 as string,
      text10: values.section3checkpointText10 as string,
      text11: values.section3checkpointText11 as string,
      text12: values.section3checkpointText12 as string,
      text13: values.section3checkpointText13 as string,
      text14: values.section3checkpointText14 as string,
      text15: values.section3checkpointText15 as string,
      text16: values.section3checkpointText16 as string,
      text17: values.section3checkpointText17 as string,
      text18: values.section3checkpointText18 as string,
      text19: values.section3checkpointText19 as string,
      text20: values.section3checkpointText20 as string,
      text21: values.section3checkpointText21 as string,
      text22: values.section3checkpointText22 as string,
      text23: values.section3checkpointText23 as string,
      text24: values.section3checkpointText24 as string,
    }),
    checkpointComment: values.section3checkpointComment as string,

    mandatoryMeasureOfBruleurAirSouffle: JSON.stringify({
      text1: values.section4Text1 as string,
      text2: values.section4Text2 as string,
      text3: values.section4Text3 as string,
      text4: values.section4Text4 as string,
      text5: values.section4Text5 as string,
      text6: values.section4Text6 as string,
      text7: values.section4Text7 as string,
      text8: values.section4Text8 as string,
      text9: values.section4Text9 as string,
      text10: values.section4Text10 as string,
      text11: values.section4Text11 as string,
      text12: values.section4Text12 as string,
    }),

    mandatoryMeasureOfBruleurAirSouffleComment:
      values.section4mandatoryMeasureOfBruleurAirSouffleComment as string,

    adviceInstallation: JSON.stringify({
      text1: values.section5adviceText1 as string,
      text2: values.section5adviceText2 as string,
      text3: values.section5adviceText3 as string,
      text4: values.section5adviceText4 as string,
      text5: values.section5adviceText5 as string,
      text6: values.section5adviceText6 as string,
      text7: values.section5adviceText7 as string,
      text8: values.section5adviceText8 as string,
      text9: values.section5adviceText9 as string,
      text10: values.section5adviceText10 as string,
      text11: values.section5adviceText11 as string,
      text12: values.section5adviceText12 as string,
      text13: values.section5adviceText13 as string,
      text14: values.section5adviceText14 as string,
      text15: values.section5adviceText15 as string,
      text16: values.section5adviceText16 as string,
      text17: values.section5adviceText17 as string,
      text18: values.section5adviceText18 as string,
      text19: values.section5adviceText19 as string,
    }),
    adviceInstallationComment:
      values.section5adviceInstallationComment as string,

    dateOfVisite: values.section6dateOfVisite as string,
    nameOfPersonPresent: values.section6nameOfPersonPresent as string,
    comment: values.section6comment as string,
    technicianName: values.section6technicianName as string,
  } as MaintenanceCertificateSaveRequestDto;
};
