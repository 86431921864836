import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressFields } from "@components/inputs/AddressFields";
import { useNavigate } from "react-router-dom";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { requestGetAddressReverse } from "@state/address/AddressesEffects";
import { Spin } from "antd";
import TagManager from "react-gtm-module";
import parse from "html-react-parser";
import { ROUTES } from "@utils/Routes";
import config from "@utils/Config";

const HomeHeaderGazEvent: FunctionComponent = () => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const navigate = useNavigate();
  const [isCurrentLocationLoading, setCurrentLocationLoading] =
    useState<boolean>(false);

  const [selectedAddress, setSelectedAddress] = useState<
    AddressFieldsDto | undefined
  >();
  const [isSelectedAddressExists, setIsSelectedAddressExists] =
    useState<boolean>(false);

  const handleSearch = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "lancer_recherche_home_part",
      },
    });
    if (selectedAddress) {
      navigate(ROUTES.individual.gazProfessionals.search.generate(), {
        state: {
          parentQueryString: selectedAddress.properties.label,
          parentCurrentPosition: {
            lat: selectedAddress.geometry.coordinates[1],
            lon: selectedAddress.geometry.coordinates[0],
          },
        },
      });
    } else {
      navigate(ROUTES.individual.gazProfessionals.search.generate());
    }
  }, [navigate, selectedAddress]);

  return (
    <div
      className={"app-home-header px-3"}
      style={{ backgroundImage: "url('/img/home/header-rdc.png')" }}
    >
      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center my-3 my-lg-5 gap-5 content-contained">
        <div className="app-home-header-content flex-grow-1 p-0 mt-0">
          <div>
            <div className={"home-header-content mt-5"}>
              <span>
                {parse(
                  t<string>(`${gazEventType}.home.header-section.subtitle`),
                )}
              </span>
              <div
                className={
                  "content-header pb-4 flex-grow-1 d-flex align-items-center mt-2"
                }
              >
                <h1 className="it-title" style={{ color: "white" }}>
                  {parse(
                    t<string>(`${gazEventType}.home.header-section.title`),
                  )}
                </h1>
              </div>
              <h3 style={{ color: "white", fontWeight: "normal" }}>
                {parse(
                  t<string>(`${gazEventType}.home.header-section.description`),
                )}
              </h3>
            </div>
          </div>
          <div className="header-searchbar">
            <Spin
              spinning={isCurrentLocationLoading}
              style={{ height: "60px" }}
            >
              <div
                className={"searchbar-locate-btn"}
                onClick={() => {
                  setCurrentLocationLoading(true);
                  setIsSelectedAddressExists(true);
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      (position: GeolocationPosition): void => {
                        void requestGetAddressReverse(
                          `lon=${position.coords.longitude}&lat=${position.coords.latitude}`,
                        );
                      },
                    );
                  }
                }}
              >
                <img src={"/img/icons/target.svg"} alt={"geoLocationIcon"} />
              </div>
            </Spin>
            <AddressFields
              setSelectedAddress={(val) => {
                setSelectedAddress(val);
                setCurrentLocationLoading(false);
              }}
              onEnterPress={handleSearch}
              className={"searchbar-addressField"}
              setIsSelectedAddressExists={setIsSelectedAddressExists}
            />
            {isSelectedAddressExists && (
              <div
                className={"searchbar-submit-btn"}
                onClick={() => handleSearch()}
              >
                <img src={"/img/icons/search.svg"} alt={"searchIcon"} />
              </div>
            )}
          </div>
        </div>
        <div>
          <img
            className={"image-gazMonth"}
            src={
              gazEventType === "gazMonth"
                ? "/img/home/gazMonthEncart-v2.svg"
                : "/img/home/rentreeChauffageEncart.svg"
            }
            alt={
              gazEventType === "gazMonth"
                ? "Mois du Chauffage Encart"
                : "RentréeDuChauffageEncart"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default HomeHeaderGazEvent;
