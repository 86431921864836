export enum ContactEnum {
  CARTE = "carte",
  FICHE = "fiche",
}

export const SearchEngine: { [index: string]: SearchEngine } = {
  GOOGLE: {
    urlValue: "https://www.google.com/",
  },
  QWANT: {
    urlValue: "https://www.qwant.com/",
  },
  BING: {
    urlValue: "https://www.bing.com/",
  },
  DUCKDUCKGO: {
    urlValue: "https://duckduckgo.com/",
  },
  ECOSIA: {
    urlValue: "https://www.ecosia.org/",
  },
  BAIDU: {
    urlValue: "https://www.baidu.com/",
  },
  YANDEX: {
    urlValue: "https://yandex.com/",
  },
  LILO: {
    urlValue: "https://www.lilo.org/",
  },
  YAHOO: {
    urlValue: "https://fr.yahoo.com/",
  },
  GOOGLEFR: {
    urlValue: "https://www.google.fr/",
  },
  BAIDUFR: {
    urlValue: "https://www.baidu.fr/",
  },
  YANDEXFR: {
    urlValue: "https://yandex.fr/",
  },
};

export interface SearchEngine {
  urlValue: string;
}
