import { FunctionComponent } from "react";
import Section from "@components/section/Section";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import InputNumberFormField from "@components/inputs/InputNumberFormField";

const MapSection: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Section
        title={t<string>("personalInfo.sectionTitle.gps")}
        titleClassName="text-primary px-12 mb-10"
      >
        <>
          <div className="mb-20 px-12">
            {t<string>("personalInfo.sectionSubtitle.gps")}
          </div>
          <div className="px-12">
            <Row gutter={16}>
              <Col xs={0} lg={1} className="mx-0 px-0" />
              <Col xs={24} lg={23}>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <InputNumberFormField
                      showLabel
                      module="personalInfo"
                      field="latitude"
                      step={0.000001}
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <InputNumberFormField
                      showLabel
                      module="personalInfo"
                      field="longitude"
                      step={0.000001}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      </Section>
    </>
  );
};

export default MapSection;
