import React, { ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Select } from "antd";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";
import { Rule } from "antd/es/form";

export interface SelectFieldProps {
  module: string;
  field: string | InternalNamePath;
  options: {
    value: string;
    label: string;
    group?: string;
  }[];
  showSearch?: boolean;
  showLabel?: boolean;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  mode?: "multiple";
  onSelect?: (value: string) => void;
  onChange?: (value: string[] | string) => void;
  fieldPrefix?: string;
  className?: string;
  allowClear?: boolean;
  onClear?: () => void;
  notFoundContent?: string | ReactNode;
  disabled?: boolean;
  fieldClassName?: string;
  labelColSpan?: number;
  labelAlign?: "left" | "right";
  initialValue?: string | string[];
  rules?: Rule[];
  showArrow?: boolean;
  selectsOption?: string[];
  checkbox?: boolean;
}

interface Option {
  value: string;
  label: string;
}

interface SelectGroup {
  label: string;
  item: Option[];
}

const SelectFormField = ({
  module,
  field,
  options,
  showSearch,
  showLabel,
  placeholder,
  required,
  readOnly,
  mode,
  onSelect,
  onChange,
  fieldPrefix = "",
  className,
  allowClear,
  onClear,
  notFoundContent,
  disabled,
  fieldClassName = "",
  labelAlign,
  initialValue,
  rules = [],
  showArrow,
  checkbox = false,
  selectsOption,
}: SelectFieldProps): ReactElement => {
  const { t } = useTranslation();

  const [selectOption, setSelectOption] = useState<string[]>(
    selectsOption ?? [],
  );
  const [dirty, setDirty] = useState<boolean>(false);

  const fieldRules =
    !readOnly && required
      ? [
          ...rules,
          {
            required: true,
            message: t<string>("forms.errors.mandatory"),
          },
        ]
      : rules;

  const i18nField = formUtils.geti18nFieldFromField(field);

  const groups: SelectGroup[] = [];
  options.forEach((item) => {
    let isInclude = false;
    groups.forEach((groupItem) => {
      if (groupItem.label === item.group) {
        groupItem.item.push(item);
        isInclude = true;
      }
    });
    if (!isInclude) {
      groups.push({ label: item.group ?? item.label, item: [item] });
    }
  });

  const handleChange = (values: string[] | string) => {
    onChange?.(values);
    if (checkbox) {
      setSelectOption(typeof values === "string" ? [values] : values);
      setDirty(true);
    }
  };

  const handleCheckBox = (e: React.MouseEvent) => {
    setDirty(false);
    e.stopPropagation();
  };

  return (
    <Form.Item
      className={className}
      name={
        typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
      }
      labelAlign={labelAlign}
      key={`${fieldPrefix}${String(field)}`}
      label={
        showLabel ? (
          <span className={readOnly ? "label-readonly" : ""}>
            {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
          </span>
        ) : (
          ""
        )
      }
      rules={fieldRules}
      initialValue={initialValue}
    >
      <Select
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentElement ? triggerNode.parentElement : triggerNode
        }
        showSearch={showSearch}
        mode={mode}
        disabled={readOnly ?? disabled}
        id={`${fieldPrefix}${String(field)}`}
        placeholder={
          placeholder ??
          t<string>(`${module}.form.fields.${i18nField}.placeholder`)
        }
        onSelect={onSelect}
        onChange={handleChange}
        filterOption={(input, option) =>
          ((option?.value as string) ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        allowClear={allowClear}
        onClear={onClear}
        notFoundContent={notFoundContent}
        className={fieldClassName}
        defaultValue={initialValue}
        maxTagCount="responsive"
        showArrow={showArrow ?? undefined}
        onMouseDown={checkbox ? handleCheckBox : undefined}
      >
        {groups.map((groupItem, index) => {
          if (groupItem.item.length > 1) {
            return (
              <Select.OptGroup key={index.valueOf()} label={groupItem.label}>
                {groupItem.item.map((opt) => {
                  return (
                    <Select.Option key={opt.value} value={opt.value}>
                      {t<string>(opt.label)}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            );
          } else {
            return (
              <Select.Option
                key={groupItem.item[0].value}
                value={groupItem.item[0].value}
              >
                {checkbox ? (
                  <Checkbox
                    checked={selectOption?.includes(groupItem.item[0].value)}
                    onClick={(e) => {
                      if (dirty) {
                        e.stopPropagation();
                      }
                      setDirty(false);
                    }}
                  >
                    <span>{t<string>(groupItem.item[0].label)}</span>
                  </Checkbox>
                ) : (
                  <>{t<string>(groupItem.item[0].label)}</>
                )}
              </Select.Option>
            );
          }
        })}
      </Select>
    </Form.Item>
  );
};

export { SelectFormField };
