import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import IndividualGazMonthHeader from "@components/gazMonth/IndividualGazMonthHeader";
import IndividualGazMonthConfirmationSection from "@components/gazMonth/IndividualGazMonthConfirmationSection";
import IndividualGazMonthCommitments from "@components/gazMonth/IndividualGazMonthCommitments";

const IndividualGazMonthConfirmationScreen: FC = () => {
  return (
    <PageLayout noHeader>
      <>
        <IndividualGazMonthHeader />
        <IndividualGazMonthConfirmationSection />
        <IndividualGazMonthCommitments />
      </>
    </PageLayout>
  );
};

export default IndividualGazMonthConfirmationScreen;
