import i18n from "i18next";

export const CONTACT_GAZ_PROFESSIONAL_STATUS: {
  [index: string]: ContactGazprofessionalStatus;
} = {
  UNREAD: {
    value: "UNREAD",
    position: 1,
  },
  READ: {
    value: "READ",
    position: 2,
  },
  PROCESSED: {
    value: "PROCESSED",
    position: 3,
  },
  SENT: {
    value: "SENT",
    position: 4,
  },
  CONCLUDED: {
    value: "CONCLUDED",
    position: 5,
  },
  ABANDONED: {
    value: "ABANDONED",
    position: -1,
  },
};

export interface ContactGazprofessionalStatus {
  value: string;
  position: number;
}

export const ABANDON_REASONS: {
  [index: string]: AbandonReason;
} = {
  DUPLICATE: {
    value: "DUPLICATE",
    label: i18n.t<string>("pro.contact.modals.updateStatus.duplicate"),
  },
  UNREACHABLE: {
    value: "UNREACHABLE",
    label: i18n.t<string>(
      "pro.contact.modals.updateStatus.unreachableProspect",
    ),
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  REFUSED_QUOTE: {
    value: "REFUSED_QUOTE",
    label: i18n.t<string>("pro.contact.modals.updateStatus.refusedEstimate"),
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OUTSIDE_SECTOR_SITE: {
    value: "OUTSIDE_SECTOR_SITE",
    label: i18n.t<string>("pro.contact.modals.updateStatus.outsideSectorSite"),
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  COMPLETE_AGENDA: {
    value: "COMPLETE_AGENDA",
    label: i18n.t<string>(
      "pro.contact.modals.updateStatus.alreadyCompleteAgenda",
    ),
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_EMPOWERMENT: {
    value: "NO_EMPOWERMENT",
    label: i18n.t<string>(
      "pro.contact.modals.updateStatus.unauthorizedIntervention",
    ),
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  UNWANTED_PROSPECT: {
    value: "UNWANTED_PROSPECT",
    label: i18n.t<string>("pro.contact.modals.updateStatus.unwantedProspect"),
  },
};

export interface AbandonReason {
  value: string;
  label: string;
}
