import { FC, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import SummaryHeader from "@components/headers/SummaryHeader";
import SummaryArticle from "@components/articles/SummaryArticle";
import { useLocation } from "react-router-dom";
import {
  findStructureFromSeoFriendlyTitle,
  useStructures,
} from "@state/structure/Structures";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { requestGetPages } from "@state/page/PageEffects";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import moment from "moment";
import configuration from "@utils/Config";
import { SUMMARY_ROUTE } from "@utils/Routes";

const SummaryScreen: FC = () => {
  const [pages, setPages] = useState<PageInfoResponseDto[]>([]);
  const sortPages = [...pages].sort(
    (a: PageInfoResponseDto, b: PageInfoResponseDto) => {
      const aDate = a.publicationDate
        ? moment.utc(a.publicationDate).valueOf()
        : moment.utc(a.createdAt).valueOf();
      const bDate = b.publicationDate
        ? moment.utc(b.publicationDate).valueOf()
        : moment.utc(b.createdAt).valueOf();
      return bDate - aDate;
    },
  );

  const location = useLocation();

  const categorySeoFriendlyTitle =
    location.pathname.substring(0, location.pathname.indexOf(SUMMARY_ROUTE)) ||
    undefined;
  const authentifiedContext = useStore(authentifiedStore);

  const structure = useStructures();

  const category =
    (categorySeoFriendlyTitle &&
      findStructureFromSeoFriendlyTitle(
        categorySeoFriendlyTitle,
        structure.universe,
      )) ||
    undefined;

  const [categoryIds, setCategoryIds] = useState<string[]>();

  useEffect(() => {
    if (categoryIds) {
      void requestGetPages({
        dto: {
          categoryId: categoryIds ?? [],
          isNews: categoryIds.length === 0,
          universe: authentifiedContext.universe.value,
        },
      });
    }
  }, [authentifiedContext, categoryIds]);

  useEffect(() => {
    if (category) {
      setCategoryIds(
        [category?.key].concat(
          category.children
            .filter((cat) => cat.type === "CATEGORY")
            .map((catId) => catId.key),
        ),
      );
    } else {
      setCategoryIds([]);
    }
  }, [category]);

  useEffect(() => {
    return requestGetPages.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPages(result.data);
      }
    });
  });

  const renderSummaryArticle = (
    page: PageInfoResponseDto,
    categoryTitle: string,
  ) => {
    return (
      <SummaryArticle
        key={page.id}
        title={page.title}
        date={page.publicationDate}
        imageUrl={
          page &&
          `${configuration.backendBaseUrl}/public/resources/${page.mainImageResourceId}/file`
        }
        nbMinutes={page.readingTime}
        description={page.summary}
        url={
          page.categorySeoFriendlyTitle
            ? `${page.categorySeoFriendlyTitle}/${page.seoFriendlyTitle}`
            : `${categoryTitle}/${page.seoFriendlyTitle}`
        }
        className="mb-40"
      />
    );
  };

  return (
    <PageLayout noProSideMenu>
      <>
        <SummaryHeader
          category={category}
          setCategoryIds={setCategoryIds}
          nbPages={pages.length}
        />
        <div className="content-contained container-padding">
          {sortPages.map((page) => {
            return renderSummaryArticle(
              page,
              `/${UNIVERSES[authentifiedContext.universe.value].urlValue}`,
            );
          })}
        </div>
      </>
    </PageLayout>
  );
};

export default SummaryScreen;
