const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    void import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      /* eslint-disable @typescript-eslint/no-unsafe-argument */
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
      /* eslint-enable @typescript-eslint/no-unsafe-argument */
    });
  }
};

export default reportWebVitals;
