export interface Field {
  name: string[];
  value: string | number | undefined;
}

export interface OptionType {
  value: string;
  label: string;
}

export const FORMS_FIELD_TYPE = {
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  SELECT: "SELECT",
  MULTISELECT: "MULTISELECT",
  CUSTOM: "CUSTOM",
  CHECKBOX: "CHECKBOX",
};
