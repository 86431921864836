import { FunctionComponent, useState } from "react";
import { Form, Tooltip, Upload } from "antd";
import { useTranslation } from "react-i18next";
import BasicCard from "@components/cards/BasicCard";
import BasicButton from "@components/buttons/BasicButton";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import i18n from "i18next";
import { UploadRequestOption } from "rc-upload/lib/interface";
import {
  requestCreateResource,
  requestDeleteResource,
  requestGetResource,
} from "@state/resource/ResourceEffects";
import { uploadUtils } from "@utils/upload-utils";
import { File } from "@type/resource-type-interface";
import { InfoCircleOutlined } from "@ant-design/icons";
import { debugBase64, downloadFile } from "@utils/Base64File";
import { toastError } from "@utils/toast-helper";

function beforeUpload(file: RcFile, types?: string[], typesLabel?: string) {
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    toastError(i18n.t<string>("common.file.sizeError"));
  }
  const isTypeOk = types ? types.includes(file.type) : true;

  if (!isTypeOk) {
    toastError(
      i18n.t<string>("common.file.extensionError", {
        okExtensions: typesLabel,
      }),
    );
  }
  return isLt2M && isTypeOk;
}

function formatFileName(fileName: string) {
  return fileName.length <= 20
    ? fileName
    : fileName.substring(0, 17).concat("...");
}

const mapFileToUploadFile = (
  file: File,
  customMessage?: string,
): UploadFile => {
  return {
    uid: file.id,
    name: formatFileName(file.name),
    status: file.status && file.status === "REFUSED" ? "error" : "done",
    url: file.base64Data && `data:${file.type};base64,${file.base64Data}`,
    response: customMessage,
    size: file.size,
    type: file.type,
  };
};

interface PropsType {
  label: string;
  name: string;
  files?: File[];
  maxCount?: number;
  accept?: string;
  multiple?: boolean;
  showUploadList?: boolean;
  onChange?: () => any;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  types?: string[];
  formatLabel?: string;
  saveFile?: boolean;
  setFileId?: (id: string) => void;
  setFilesId?: (
    id:
      | string[]
      | undefined
      | ((id: string[] | undefined) => string[] | undefined),
  ) => void;
  filesId?: string[];
  domain?:
    | "RGE_PAC_HYBRIDE"
    | "WORKSITE_DEVIS"
    | "WORKSITE_BILL"
    | "WORKSITE_CC2"
    | "WORKSITE_RIB"
    | "WORKSITE_BONUS_BILL"
    | "PAC_HYBRIDE_RENEWAL_TECHNICAL_TRAINING"
    | "PAC_HYBRIDE_RENEWAL_COMMERCIAL_TRAINING"
    | "PAC_HYBRIDE_RENEWAL_INSTALLATION"
    | "RG_REQUEST_CERTIFICATE";
  onRemove?: boolean;
  labelTooltip?: string | null;
  customErrorMessage?: string;
  setIsUploading?: (isUploading: boolean) => void;
  preview?: boolean;
  buttonText?: string;
  buttonVariant?: string;
}

const UploadFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    label,
    name,
    files = [],
    maxCount = 1,
    accept,
    multiple = false,
    onChange,
    readOnly,
    disabled,
    className = "",
    types,
    formatLabel,
    saveFile,
    setFileId,
    setFilesId,
    filesId,
    domain,
    onRemove = false,
    labelTooltip,
    customErrorMessage,
    setIsUploading,
    preview = false,
    buttonText,
    buttonVariant,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>(
    files.map((f) => mapFileToUploadFile(f, customErrorMessage)),
  );

  const previewOrDownloadFromFile = (uid: string): void => {
    const file = files?.find((f) => f.id === uid);
    if (file?.id) {
      void requestGetResource(file.id).then((resource) => {
        if (resource.ok && resource.data) {
          if (file.type === "application/pdf") {
            debugBase64(resource.data.data, file.type);
          } else {
            downloadFile(resource.data.data, file.type, file.name);
          }
        }
      });
    }
  };

  const uploadProps = {
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: onRemove,
    },
    customRequest({ file }: UploadRequestOption) {
      const rcFile = file as RcFile;
      if (saveFile && domain) {
        setLoading(true);
        setIsUploading?.(true);
        const formData = new FormData();
        formData.append("file", rcFile);
        formData.append("domain", domain);
        void requestCreateResource({
          dto: formData,
        })
          .then((result) => {
            if (result.ok && result.data) {
              const resource = result.data;
              setFileList((currentList) => [
                ...(multiple ? currentList : []),
                {
                  uid: resource.id,
                  name: formatFileName(resource.filename),
                  status: "done",
                  size: resource.size,
                  type: resource.type,
                },
              ]);
              setFileId?.(resource.id);
              setFilesId?.((currentResource) =>
                currentResource
                  ? currentResource.concat(resource.id)
                  : [resource.id],
              );
            }
          })
          .finally(() => {
            setLoading(false);
            setIsUploading?.(false);
          });
      }
      return {};
    },
  };

  const handleRemoveFile = (file: UploadFile) => {
    const removingFile = fileList.find((f) => f.uid === file.uid);
    if (removingFile) {
      const newValues = [...fileList.filter((f) => f.uid !== removingFile.uid)];
      setFileList(newValues);
      setFilesId?.(
        filesId
          ? [...filesId.filter((f) => f !== removingFile.uid)]
          : [
              ...fileList
                .filter((f) => f.uid !== removingFile.uid)
                .map((f) => f.uid),
            ],
      );
      void requestDeleteResource({
        dto: {
          id: removingFile.uid,
        },
      });
    }
  };

  const handlePreview = (file: UploadFile) => {
    if (file.url) {
      debugBase64(file.url);
    } else {
      previewOrDownloadFromFile(file.uid);
    }
  };

  return (
    <BasicCard className={`app-card-upload ${className}`}>
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
        <div className="upload-label">
          {labelTooltip ? (
            <Tooltip title={labelTooltip}>
              <span>
                {label} <InfoCircleOutlined />
              </span>
            </Tooltip>
          ) : (
            label
          )}
        </div>
        <div className="upload-button ms-sm-3 mt-3 mt-sm-0">
          <Form.Item
            className="mb-0"
            name={name}
            rules={
              fileList && fileList.length >= 1
                ? []
                : [
                    {
                      required: true,
                      message: t<string>("common.empty_error_field"),
                    },
                    {
                      validator: uploadUtils.validateUploadFormField,
                      message: t<string>("common.empty_error_field"),
                    },
                  ]
            }
          >
            <Upload
              {...uploadProps}
              fileList={fileList}
              name={name}
              onChange={onChange}
              maxCount={maxCount}
              onPreview={(file) => {
                preview && handlePreview(file);
              }}
              accept={accept}
              multiple={multiple}
              onRemove={(file) => onRemove && handleRemoveFile(file)}
              disabled={readOnly ?? disabled}
              beforeUpload={(file: RcFile) =>
                beforeUpload(file, types, formatLabel)
              }
            >
              {!readOnly && (
                <BasicButton
                  isLoading={loading}
                  disabled={readOnly ?? disabled}
                  variant={buttonVariant ?? "primary-outline"}
                  text={buttonText ?? t<string>("buttons.upload")}
                />
              )}
            </Upload>
          </Form.Item>
        </div>
      </div>
    </BasicCard>
  );
};

export default UploadFormField;
