export const UNIVERSES: { [index: string]: Universe } = {
  INDIVIDUAL: {
    value: "INDIVIDUAL",
    urlValue: "particulier",
    label: "header.universe.individual",
  },
  PROFESSIONAL: {
    value: "PROFESSIONAL",
    urlValue: "professionnel",
    label: "header.universe.professional",
  },
};

export interface Universe {
  value: "INDIVIDUAL" | "PROFESSIONAL";
  urlValue: "particulier" | "professionnel";
  label: string;
}
