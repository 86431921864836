import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface TertiaryModalFinalProps {
  isLoading?: boolean;
}

export const TertiaryModalFinal: React.FC<TertiaryModalFinalProps> = ({
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex justify-content-center">
        <h3 className="text-primary">
          {parse(t<string>("pro.tertiary.modal4.title"))}
        </h3>
      </div>
      <div>{parse(t<string>("pro.tertiary.modal4.detail"))}</div>
      <div className="d-flex justify-content-center">
        <BasicButton
          text={t<string>("common.quit")}
          type="submit"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
