import { createStore } from "effector";
import { ContactInfoDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.save.request.dto";
import {
  clearSession,
  setContactGazProfessional,
} from "@state/contact/gazprofessional/ContactGazProfessionalEvents";

export const contactGazProfessionalStore = createStore<ContactInfoDto>({
  firstConnect: true,
  url: undefined,
});

contactGazProfessionalStore
  .on<ContactInfoDto>(setContactGazProfessional, (state, payload) => payload)
  .on(clearSession, () => ({
    firstConnect: true,
    source: undefined,
    medium: undefined,
    campaign: undefined,
    url: undefined,
  }));
