import { FC } from "react";
import {
  BlockInput,
  ImageContent,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import ImageGroupModuleCMS from "@components/cms/ImageGroupModuleCMS";

interface Props {
  block: PageBlockInfoResponseDto;
}

interface ReinsuranceInput {
  id: string;
  value: BlockInput[];
}

const ReinsuranceImage: FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const reinsurances =
    inputs[0].content && (inputs[0].content as unknown as ReinsuranceInput[]);
  return (
    <>
      {reinsurances && (
        <ImageGroupModuleCMS
          className={"reinsurance"}
          images={reinsurances.map((reinsurance) => {
            const imageContent = reinsurance.value[1].content as ImageContent;
            return {
              src: imageContent.content || "",
              alt: imageContent.alt,
              title: reinsurance.value[0].content as string,
              description: reinsurance.value[2].content as string,
              height: 100,
            };
          })}
        />
      )}
    </>
  );
};

export default ReinsuranceImage;
