export const RESOURCE_TYPE: { [index: string]: ResourceType } = {
  ZIP: {
    value: "ZIP",
    iconUrl: "/img/pro/zip.svg",
    altLabel: "pro.tools.alt.zip",
    applicationType: "application/zip",
  },
  PDF: {
    value: "PDF",
    iconUrl: "/img/pro/pdf.svg",
    altLabel: "pro.tools.alt.pdf",
    applicationType: "application/pdf" ?? "image/pdf",
  },
  JPEG: {
    value: "JPEG",
    iconUrl: "/img/pro/zip.svg",
    altLabel: "pro.tools.alt.jpeg",
    applicationType: "image/jpeg",
  },
  JPG: {
    value: "JPG",
    iconUrl: "/img/pro/zip.svg",
    altLabel: "pro.tools.alt.jpeg",
    applicationType: "image/jpeg",
  },
  PNG: {
    value: "PNG",
    altLabel: "pro.tools.alt.png",
    iconUrl: "/img/pro/zip.svg",
    applicationType: "image/png",
  },
};

export interface ResourceType {
  value: "ZIP" | "PDF" | "JPEG" | "PNG" | "JPG";
  iconUrl: string;
  altLabel: string;
  applicationType: string;
}
