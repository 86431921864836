import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { setMentionPacHybride } from "@state/auth/AuthEvents";
import { requestUpdateRgeMention } from "@state/mention/pachybride/MentionPacHybrideEffects";
import { Form } from "antd";
import UploadFormField from "@components/inputs/UploadFormField";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { toastSuccess } from "@utils/toast-helper";

export const PacHybrideMentionRefusedScreen: React.FC = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const pacHybride = authentifiedUser?.pacHybride;

  const [isLoadingRGE, setIsLoadingRGE] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [rgeId, setRgeId] = useState<string | undefined>();

  const [acceptedMentionTypes] = useState<string[]>([
    RESOURCE_TYPE.PDF.applicationType,
  ]);

  const handleSubmitNewRge = useCallback(() => {
    if (pacHybride && rgeId) {
      setIsLoadingRGE(true);
      void requestUpdateRgeMention({
        id: pacHybride.id,
        dto: {
          rgeId,
        },
      });
    }
  }, [pacHybride, rgeId]);

  useEffect(() => {
    return requestUpdateRgeMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
        toastSuccess(
          t<string>("pro.mentions.pacHybride.form.messages.updateRge"),
        );
      }
      setIsLoadingRGE(false);
    });
  });

  return (
    <Form onFinish={handleSubmitNewRge}>
      <div className="requested-mention-container d-flex flex-column flex-lg-row align-items-lg-start mt-2">
        <div className="request-text-container mt-20">
          <h2 className="mb-2">
            {t<string>(
              "pro.mentions.pacHybride.detail.refusedCertificate.subtitle",
            )}
          </h2>
          <div>
            {t<string>(
              "pro.mentions.pacHybride.detail.refusedCertificate.description",
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h2 className="form-title">
          {t<string>("pro.mentions.pacHybride.form.documents")}
        </h2>
        <div className="mb-4">
          <UploadFormField
            name="rge"
            label={t<string>("pro.mentions.pacHybride.form.rge")}
            domain="RGE_PAC_HYBRIDE"
            saveFile
            setFileId={setRgeId}
            types={acceptedMentionTypes}
            formatLabel="PDF"
            setIsUploading={setIsUploading}
          />
          <span className="small">
            {t<string>("pro.mentions.pacHybride.detail.helpUpload")}
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <BasicButton
          type="submit"
          text={t<string>("common.reSubmit")}
          isLoading={isUploading || isLoadingRGE}
        />
      </div>
    </Form>
  );
};
