import React from "react";
import {
  PageBlockInfoResponseDto,
  PageInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import { PageBlock } from "@components/page/PageBlock";
import { BACKGROUNDS } from "@utils/enums/background.enum";

export interface Props {
  page: PageInfoResponseDto | undefined;
}

export interface Input {
  inputType: string;
  content: string;
  properties?: string;
}

const PageContent: React.FC<Props> = ({ page }) => {
  return (
    <div>
      {page &&
        page.blocks &&
        page.blocks.map((block: PageBlockInfoResponseDto, indexBlock) => (
          <div
            key={indexBlock}
            className={`py-2 ${block.classNames ? block.classNames : ""} ${
              block.background
                ? `bg-${BACKGROUNDS[block.background].color}`
                : ""
            }`}
          >
            <div className="content-contained">
              <PageBlock block={block} />
            </div>
          </div>
        ))}
    </div>
  );
};
export { PageContent };
