import { Outlet } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Universe } from "@utils/enums/universe.enum";
import { changeUniverse } from "@state/auth/AuthEvents";

type UniverseLayoutProps = {
  universe: Universe;
};

const UniverseLayout = ({ universe }: UniverseLayoutProps): JSX.Element => {
  const isAuthentified = useStore(authentifiedStore);

  const userUniverse: Universe | undefined = isAuthentified?.universe;

  if (universe && universe !== userUniverse) {
    changeUniverse(universe);
  }

  return <Outlet />;
};

export default UniverseLayout;
