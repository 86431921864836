import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import ToolsFileCard from "@components/cards/ToolsFileCard";
import { downloadFromFile, getBase64UrlFrom } from "@utils/Base64File";
import { File } from "@type/resource-type-interface";

interface FilesCardProps {
  files: File[];
}

const FilesCard: FunctionComponent<FilesCardProps> = ({ files }) => {
  const { t } = useTranslation();
  return (
    <>
      {files.map((file) => {
        const resourceType = Object.values(RESOURCE_TYPE).find(
          (rt) => rt.applicationType === file.type,
        );
        return (
          resourceType && (
            <ToolsFileCard
              onClick={() => downloadFromFile(file)}
              key={file.id}
              icon={
                <img
                  alt={t<string>(resourceType.altLabel)}
                  src={
                    file?.thumbnail
                      ? getBase64UrlFrom(file.thumbnail, "JPEG")
                      : resourceType.iconUrl
                  }
                />
              }
              title={file.name}
              description={`${resourceType.value} - ${file.size}`}
              thumbnail={!!file?.thumbnail}
            />
          )
        );
      })}
    </>
  );
};

export default FilesCard;
