import { FC } from "react";
import Stepper from "@components/stepper/Stepper";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@utils/Routes";
import ButtonModuleCMS from "@components/cms/ButtonModuleCMS";

const HomeStepper: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={"app-home-stepper"}>
      <div className="content-contained">
        <h2 className="h3">{t<string>("home.stepper-section.title")}</h2>
        <Stepper
          steps={[
            { title: t<string>("home.stepper-section.step-1") },
            { title: t<string>("home.stepper-section.step-2") },
            { title: t<string>("home.stepper-section.step-3") },
            { title: t<string>("home.stepper-section.step-4") },
          ]}
        />
        <ButtonModuleCMS
          text={t<string>("home.stepper-section.link")}
          url={ROUTES.individual.gazProfessionals.search.generate()}
        />
      </div>
    </div>
  );
};

export default HomeStepper;
