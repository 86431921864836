import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface NewsItemProps {
  src: string;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  icon?: string | JSX.Element;
  redirectUrl: string;
  className?: string;
}

const NewsItem: FC<NewsItemProps> = ({
  src,
  title,
  icon = <img alt="reading-time" src="/img/home/hourglass.svg" />,
  description,
  redirectUrl,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <Link
      to={redirectUrl}
      className={`app-actuality cursor-pointer text-decoration-none ${className}`}
    >
      <div className="actuality-img">
        <img src={src} alt={t<string>("pro.home.actualities.imgAlt")} />
      </div>
      <h3 className="actuality-title">{title}</h3>
      {description && (
        <div className="actuality-description-container d-flex align-items-center">
          {icon && <div className="actuality-icon">{icon}</div>}
          <div className="actuality-description">{description}</div>
        </div>
      )}
    </Link>
  );
};

export default NewsItem;
