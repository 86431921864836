import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import BaseForm from "@components/forms/BaseForm";
import { Form } from "antd";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import { emailFormatRegex, phoneNumberFormatRegex } from "@utils/Constant";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import {
  CAMPAIGN_CURRENT_ENERGIES,
  CAMPAIGN_EQUIPMENT_TYPES,
  CAMPAIGN_INTERVENTION_TYPES,
} from "@utils/enums/campaign.enum";
import { Store } from "rc-field-form/lib/interface";
import { requestCreateCampaignContact } from "@state/campaign/CampaignEffects";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { useStore } from "effector-react";
import { contactGazProfessionalStore } from "@state/contact/gazprofessional/ContactGazProfessionalStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import BasicButton from "@components/buttons/BasicButton";
import DateFormat from "@utils/DateFormat";
import config from "@utils/Config";

interface LandingPageHeaderProps {
  landingPageType: string;
  className?: string;
}

const LandingPageHeader: FunctionComponent<LandingPageHeaderProps> = ({
  landingPageType,
  className = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  const contactInfo = useStore(contactGazProfessionalStore);
  const endEvent = Date.UTC(2024, 10, 30);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressFieldsDto>();

  const handleSubmit = (values: Store): void => {
    const saveRequest = {
      firstname: values.firstName as string,
      lastname: values.lastName as string,
      email: values.email as string,
      phoneNumber: (values.phone as string).replace(/\s/g, ""),
      houseNumber: selectedAddress?.properties.housenumber as string,
      street: (selectedAddress?.properties.street ??
        selectedAddress?.properties.name) as string,
      city: selectedAddress?.properties.city as string,
      zipCode: selectedAddress?.properties.postcode as string,
      intervention: values.interventionType as string,
      energy: values?.currentEnergy as string,
      equipments: values?.equipmentTypes as string[],
      message: values.message as string,
      inTiers: values.callbackGrdf as boolean,
      medium: contactInfo.medium,
      url: contactInfo.url ?? undefined,
      lp: isTempsFort ? "LP TEMPS FORT" : "LP FIL ROUGE",
      source: contactInfo.source ?? "autre",
      campaign: contactInfo.campaign,
      qwpId: new URLSearchParams(location.search).get("qwp_id") ?? undefined,
    };
    void requestCreateCampaignContact({
      dto: saveRequest,
    });
    setIsLoading(true);
  };

  useEffect(() => {
    return requestCreateCampaignContact.done.watch(({ result }) => {
      if (result.ok) {
        navigate(
          isTempsFort
            ? ROUTES.individual.rentreeChauffage.tempsFort.confirmation.generate()
            : ROUTES.individual.rentreeChauffage.filRouge.confirmation.generate(),
        );
        toastSuccess(
          t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.header.form.form.messages.success`,
          ),
        );
      } else if (
        (result.responseCode === 400 &&
          (result?.fieldErrors?.street ||
            result?.fieldErrors?.city ||
            result?.fieldErrors?.zipCode)) ||
        result.responseCode === 500
      ) {
        toastError(
          t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.header.form.form.messages.errorAddress`,
          ),
        );
      } else if (result.responseCode === 400) {
        toastError(
          t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.header.form.form.messages.invalid`,
          ),
        );
      } else {
        toastError(
          t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.header.form.form.messages.error`,
          ),
        );
      }
      setIsLoading(false);
    });
  });

  const FORM = {
    main: {
      key: "main",
      module: `landingPage.${landingPageType}${
        isTempsFort ? ".".concat(gazEventType) : ""
      }.header.form`,
      title: "form.title",
      fields: [
        [
          {
            name: "interventionType",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_INTERVENTION_TYPES),
          },
          {
            name: "currentEnergy",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_CURRENT_ENERGIES),
            size: 12,
          },
          {
            name: "equipmentTypes",
            type: FORMS_FIELD_TYPE.MULTISELECT,
            required: true,
            options: Object.values(CAMPAIGN_EQUIPMENT_TYPES),
            size: 12,
          },
          {
            name: "lastName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "firstName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            pattern: new RegExp(emailFormatRegex),
            htmlInputType: "email",
          },
          {
            name: "phone",
            type: FORMS_FIELD_TYPE.CUSTOM,
            custom: (
              <MaskInputFormField
                emptyErrorMessage={t<string>(
                  `landingPage.${landingPageType}${
                    isTempsFort ? ".".concat(gazEventType) : ""
                  }.header.form.form.fields.phone.errors.required`,
                )}
                type="tel"
                required
                mask="## ## ## ## ##"
                showLabel
                module={`landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.form`}
                field="phone"
                rules={[
                  {
                    pattern: new RegExp(phoneNumberFormatRegex),
                    message: t<string>("forms.errors.format"),
                  },
                  {
                    min: 14,
                    message: t<string>("forms.errors.length", { size: "10" }),
                  },
                ]}
              />
            ),
            size: 12,
          },
          {
            name: "address",
            type: FORMS_FIELD_TYPE.CUSTOM,
            size: 24,
            required: true,
            custom: (
              <AddressFields
                module={`landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.form`}
                field="address"
                showLabel
                setSelectedAddress={setSelectedAddress}
                emptyErrorMessage={t<string>(
                  `landingPage.${landingPageType}${
                    isTempsFort ? ".".concat(gazEventType) : ""
                  }.header.form.form.fields.address.errors.required`,
                )}
                landingPage
              />
            ),
          },
          {
            name: "message",
            type: FORMS_FIELD_TYPE.TEXTAREA,
            size: 24,
            retractable: true,
            maxLength: 3000,
          },
          {
            name: "callbackGrdf",
            type: FORMS_FIELD_TYPE.CHECKBOX,
            required: true,
            size: 24,
            parsed: true,
          },
        ],
      ],
    },
  };

  return (
    <>
      {isTempsFort && (
        <h3 className="app-rentree-du-chauffage-top-header">
          {t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.header.top`,
            {
              days: DateFormat.getNumberOfDaysBeforeTheDeadline(
                new Date(endEvent).toString(),
              ),
            },
          )}
        </h3>
      )}
      <div
        className={`app-rentree-du-chauffage-header ${
          isTempsFort ? "rentree-du-chauffage-header-temps-fort" : ""
        } ${className}`}
      >
        <div className="header-left">
          <div className="header-left-logo">
            <img
              alt={t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.home.conditions.pg.alt`,
              )}
              src="/logo-header-PG-professional.svg"
            />
            {isTempsFort && (
              <img
                alt={t<string>(
                  `landingPage.${landingPageType}${
                    isTempsFort ? ".".concat(gazEventType) : ""
                  }.rentreeDuChauffage.alt`,
                )}
                src={
                  gazEventType === "gazMonth"
                    ? "/img/gazMonth/logo-gaz-month.svg"
                    : "/img/rentreeDuChauffage/logo-rentree-chauffage-white.svg"
                }
                className="header-left-logo-rentree-chauffage"
              />
            )}
          </div>
          <div className="header-left-content">
            {isTempsFort && (
              <p className="header-left-content-subtitle">
                {parse(
                  t<string>(
                    `landingPage.${landingPageType}${
                      isTempsFort ? ".".concat(gazEventType) : ""
                    }.header.tempsFort.subtitle`,
                  ),
                )}
              </p>
            )}
            <h1 className="header-left-content-title">
              {parse(
                isTempsFort
                  ? t<string>(
                      `landingPage.${landingPageType}${
                        isTempsFort ? ".".concat(gazEventType) : ""
                      }.header.tempsFort.title`,
                    )
                  : t<string>(
                      `landingPage.${landingPageType}${
                        isTempsFort ? ".".concat(gazEventType) : ""
                      }.header.title`,
                    ),
              )}
            </h1>
            <div className="header-left-content-text">
              <h3 className="header-left-content-description">
                {isTempsFort
                  ? t<string>(
                      `landingPage.${landingPageType}${
                        isTempsFort ? ".".concat(gazEventType) : ""
                      }.header.tempsFort.description`,
                    )
                  : t<string>(
                      `landingPage.${landingPageType}${
                        isTempsFort ? ".".concat(gazEventType) : ""
                      }.header.description`,
                    )}
              </h3>
              <div className="header-left-content-points">
                <p className="header-left-content-point">
                  {isTempsFort
                    ? parse(
                        t<string>(
                          `landingPage.${landingPageType}${
                            isTempsFort ? ".".concat(gazEventType) : ""
                          }.header.tempsFort.point1`,
                        ),
                      )
                    : parse(
                        t<string>(
                          `landingPage.${landingPageType}${
                            isTempsFort ? ".".concat(gazEventType) : ""
                          }.header.point1`,
                        ),
                      )}
                </p>
                <p className="header-left-content-point">
                  {isTempsFort
                    ? parse(
                        t<string>(
                          `landingPage.${landingPageType}${
                            isTempsFort ? ".".concat(gazEventType) : ""
                          }.header.tempsFort.point2`,
                        ),
                      )
                    : parse(
                        t<string>(
                          `landingPage.${landingPageType}${
                            isTempsFort ? ".".concat(gazEventType) : ""
                          }.header.point2`,
                        ),
                      )}
                </p>
              </div>
            </div>
            <BasicButton
              text={t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.link`,
              )}
              className="header-left-content-link"
              variant="secondary-green-outline"
              onClick={() => {
                window.document
                  .getElementById("content-container-rentree-du-chauffage")
                  ?.scrollIntoView();
              }}
            />
          </div>
        </div>
        <div className="header-right" id="app-rentree-du-chauffage-form">
          <div className="header-right-form">
            <h2 className="header-right-form-title">
              {t(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.form.title`,
              )}
            </h2>
            <div className="header-right-form-required">
              {t<string>("common.field.required")}
            </div>

            <BaseForm
              module={`landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.header.form`}
              form={form}
              editMode={true}
              formConfiguration={FORM}
              hasBackButton={false}
              hasConfirmPopup={false}
              customOnSubmitCallback={handleSubmit}
              loading={isLoading}
              buttonSize="md"
              buttonCentered
              buttonVariant="secondary-green-outline"
              buttonClassName="header-right-form-link"
            />
            <div className="header-right-form-subtitle">
              {parse(
                t(
                  `landingPage.${landingPageType}${
                    isTempsFort ? ".".concat(gazEventType) : ""
                  }.header.form.subtitle`,
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageHeader;
