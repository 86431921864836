import i18n from "i18next";

type FixedAlign = boolean | "left" | "right";
export const alignRight: FixedAlign = "right";

type OrderType = "descend" | "ascend" | undefined;
export const order: OrderType = "ascend";

export const formItemBlockLayout = {
  labelCol: {
    xs: { span: 24 },
    xl: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    xl: { span: 24 },
  },
  validateTrigger: ["onBlur"],
};
export const dateFormat = "DD/MM/YYYY";

export const dateTimeFormat = "DD/MM/YYYY à HH:mm";

export const authRedirectKey = "authRedirect";

export const phoneNumberFormatRegex = /^0[1-9](?:[\s.-]*\d{2}){4}$/;

export const siretNumberFormatRegex = /^[0-9 -.]+$/;

export const emailFormatRegex =
  // eslint-disable-next-line max-len
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/i;

export const DEFAULT_500_ERROR = {
  responseCode: 500,
  errorMessage: i18n.t<string>("common.error.500"),
  data: undefined,
  ok: false,
};

export const emptyCertificate = "87582f97-b5be-4b91-empty-certificate";
export const emptySearchResult = {
  numberOfElements: 0,
  totalElements: 0,
  size: 0,
  totalPages: 0,
  number: 0,
  content: [],
};

export const FIELD_LENGTH = {
  URL: 500,
  DESCRIPTION: 255,
  NAME: 200,
  TITLE: 2000,
  TEXTAREA: 5000,
  MAIL: 255,
};

export const pgsTest = [
  "100000",
  "100001",
  "100002",
  "100003",
  "100004",
  "100005",
  "100006",
  "100007",
];
