import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { requestGetArticle } from "src/state/page/PageEffects";
import { PageComponent } from "src/components/page/PageComponent";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { PageComponentFooter } from "@components/page/PageComponentFooter";
import { ROUTES } from "@utils/Routes";

const PageScreen: React.FC = () => {
  const location = useLocation();
  const pageTitle = location.pathname.split("/").pop() || "";

  const navigate = useNavigate();

  const [page, setPage] = useState<PageInfoResponseDto | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (pageTitle !== "sommaire") {
      void requestGetArticle({ dto: { pageTitle } });
    }
  }, [pageTitle]);

  useEffect(() => {
    return requestGetArticle.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPage(result.data);
      } else {
        navigate(ROUTES.public.error404.generate());
      }
      setLoading(false);
    });
  });

  return (
    <>
      {page && page?.categoryId !== "FOOTER" ? (
        <PageComponent isLoading={isLoading} page={page} isFromArticle={true} />
      ) : (
        <PageComponentFooter
          isLoading={isLoading}
          page={page}
          isFromArticle={true}
        />
      )}
    </>
  );
};

export { PageScreen };
