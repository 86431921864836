import { FunctionComponent } from "react";
import { Dropdown, Space } from "antd";
import { CheckOutlined, DownOutlined } from "@ant-design/icons";
import { OptionType } from "@type/form/field.types";
import { useTranslation } from "react-i18next";

interface DropdownFilterProps {
  label: string;
  items: OptionType[];
  selectedItem: OptionType | undefined;
  setSelectedItem: (item: OptionType) => void;
  className?: string;
}

const DropdownFilter: FunctionComponent<DropdownFilterProps> = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  className = "",
}) => {
  const { t } = useTranslation();

  const getItem = (item: OptionType) => {
    return {
      label: `${t<string>(item.label)}`,
      key: item.value,
      className:
        selectedItem && selectedItem.value === item.value
          ? "item-selected"
          : "",
      icon:
        selectedItem && selectedItem.value === item.value ? (
          <CheckOutlined />
        ) : undefined,
    };
  };

  const getItems = () => {
    return items.map((item) => getItem(item));
  };

  return (
    <Dropdown
      placement="bottomRight"
      arrow
      trigger={["click"]}
      menu={{
        items: getItems(),
        onClick: ({ key }) => {
          items.map((item) => {
            if (key === item.value) {
              setSelectedItem(item);
            }
          });
        },
      }}
      overlayClassName="app-dropdown-filter-overlay"
      className={`app-dropdown-filter ${className}`}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {label}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default DropdownFilter;
