export const RG_REQUEST_TYPE: {
  [index: string]: RGRequestType;
} = {
  ADD: {
    value: "ADD",
    label: "pro.qualifications.requests.type.add",
    filterLabel: "organisationprofessional.requests.filters.type.options.add",
  },
  RAD: {
    value: "RAD",
    label: "pro.qualifications.requests.type.rad",
    filterLabel: "organisationprofessional.requests.filters.type.options.rad",
  },
  UPDATE: {
    value: "UPDATE",
    label: "pro.qualifications.requests.type.update",
    filterLabel:
      "organisationprofessional.requests.filters.type.options.update",
  },
};

export interface RGRequestType {
  value: string;
  label: string;
  filterLabel: string;
}
