import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import BasicButton from "../buttons/BasicButton";
import {
  requestResetPassword,
  requestValidateResetToken,
} from "@state/auth/AuthEffects";
import { useForm } from "antd/lib/form/Form";
import { logout } from "@state/auth/AuthEvents";
import { extractStringFrom } from "@utils/query-helper";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import SubmitButton from "@components/buttons/SubmitButton";
import { formHelper } from "@utils/form-helper";
import SetPasswordInputFields from "@components/forms/SetPasswordInputFields";
import { formItemBlockLayout } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface SetPasswordProps {
  isCreation?: boolean;
}

interface FormValues {
  credential: string;
  confirmCredential: string;
}

const SetPasswordComponent: FunctionComponent<SetPasswordProps> = (
  props: SetPasswordProps,
) => {
  const { isCreation } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();

  const InitialValues = {
    credential: "",
    confirmCredential: "",
  };

  const [tokenValue, setTokenValue] = useState<string | null | undefined>();
  const [userIdValue, setUserIdValue] = useState<string | null | undefined>();

  function getParams(): {
    token: string | undefined;
    userId: string | undefined;
  } {
    const searchParams = new URLSearchParams(window.location.search);

    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    const tokenValue = extractStringFrom(token);
    const userIdValue = extractStringFrom(userId);
    return { token: tokenValue, userId: userIdValue };
  }

  useEffect(() => {
    const { token, userId } = getParams();
    // remove token from url to prevent http referer leakage
    navigate(window.location.pathname, { replace: true });

    void requestValidateResetToken({
      dto: {
        token: token || "",
        userId: userId || "",
      },
    }).then((result) => {
      if (result.ok) {
        setTokenValue(token);
        setUserIdValue(userId);
      } else {
        navigate(ROUTES.professional.auth.login.generate());
      }
    });
  }, [navigate]);

  const handleSubmit = (values: any) => {
    const obj = values as FormValues;

    requestResetPassword({
      dto: {
        userType: "GAZ_PROFESSIONAL",
        token: tokenValue || "",
        gpId: userIdValue || "",
        credential: obj.credential,
        confirmCredential: obj.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(
            t<string>(
              `${isCreation ? "create" : "reset"}-password.messages.success`,
            ),
          );
          logout();
          navigate(ROUTES.professional.auth.login.generate());
        } else {
          if (result.fieldErrors) {
            if (result.fieldErrors.credential) {
              form.setFields([{ name: "credential", errors: [""] }]);
            } else {
              form.setFields(
                formHelper.addErrorsToFieldsData([], result.fieldErrors),
              );
            }
          }
          toastError(result.errorMessage);
        }
      })
      .catch((error: Error) => {
        toastError(error.message);
      });
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      initialValues={InitialValues}
      {...formItemBlockLayout}
    >
      <SetPasswordInputFields />
      <div className="d-flex align-items-center justify-content-center mt-2">
        <Link to={ROUTES.professional.auth.login.generate()}>
          <BasicButton
            text={t<string>("buttons.back")}
            variant="secondary"
            className="mt-2 me-2"
          />
        </Link>
        <div className="form-group mb-0">
          <SubmitButton
            module="set-password"
            isSubmitting={false}
            label="save"
            className="mt-2"
          />
        </div>
      </div>
    </Form>
  );
};

export default SetPasswordComponent;
