import {
  QUALIFICATION_RG_STATUS,
  QualificationRgStatus,
} from "@utils/enums/qualification.rg.status.enum";

export const maptoQualificationRgStatus = (
  status: string,
): QualificationRgStatus => {
  switch (status) {
    case "VALID":
      return QUALIFICATION_RG_STATUS.VALID;
    case "EXPIRED":
      return QUALIFICATION_RG_STATUS.EXPIRED;
    case "EXPIRED_SOON":
      return QUALIFICATION_RG_STATUS.EXPIRED_SOON;
    case "FIRED":
      return QUALIFICATION_RG_STATUS.FIRED;
    default:
      return QUALIFICATION_RG_STATUS.FIRED;
  }
};
