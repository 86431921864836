import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import { restListHandler } from "@utils/rest/RestClient";
import { StructureDto } from "@state/structure/dto/structure.dto";

const baseUrl = configuration.backendBaseUrl;
const structureUrl = baseUrl + "/structure";

export const requestGetStructure = createEffect({
  handler: restListHandler<StructureDto>(structureUrl),
});
