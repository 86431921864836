import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { PreviousAuditItemResponseDto } from "@state/audit/dto/previous.audit.item.dto";
import {
  AuditGlobalStatus,
  AuditInProgress,
  InstallationItem,
  SetInstallation,
} from "@state/audit/dto/audit.item.details.dto";

const baseUrl = `${configuration.backendBaseUrl}/audits`;

export const requestGetPreviousAudit = createEffect({
  handler:
    restListHandlerPagination<Pageable<PreviousAuditItemResponseDto>>(baseUrl),
});

export const requestGetAuditsInProgress = createEffect({
  handler: restListHandler<AuditInProgress>(`${baseUrl}/audit-in-progress`),
});

export const requestGetInstallationDetail = createEffect({
  handler: restDetailsHandler<InstallationItem>(`${baseUrl}/`, "/detail"),
});

export const requestSetSelectedInstallation = createEffect({
  handler: restCreationHandler<SetInstallation, void>(baseUrl),
});

export const requestGetAuditStatus = createEffect({
  handler: restGetUniqueHandler<AuditGlobalStatus>(`${baseUrl}/status`),
});
