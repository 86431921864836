import React from "react";
import { Checkbox, Form } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";
import parse from "html-react-parser";

interface PropsType {
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  fieldPrefix?: string;
  label?: string;
  className?: string;
  checked?: boolean;
  parsed?: boolean;
  noLabel?: boolean;
  noBold?: boolean;
}

const CheckboxFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    module,
    field,
    required,
    readOnly,
    emptyErrorMessage,
    onChange,
    fieldPrefix = "",
    label,
    className = "",
    checked,
    noLabel,
    parsed = false,
    noBold,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${i18nField}`.replace(",", "_");

  return (
    <Form.Item
      name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
      key={fieldPrefix + key}
      initialValue={checked ?? false}
      valuePropName="checked"
      rules={
        isRequired
          ? [
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        emptyErrorMessage ??
                          t<string>("common.empty_error_field"),
                      ),
              },
            ]
          : []
      }
      className={className}
    >
      <Checkbox
        disabled={readOnly}
        className={readOnly ? " readonly-form-field" : ""}
        id={fieldPrefix + key}
        onChange={onChange}
        checked={checked}
      >
        {!noLabel && (
          <span className={noBold ? "" : "font-weight-bold"}>
            {parsed
              ? label
                ? parse(label)
                : parse(t<string>(`${module}.form.${i18nField}`))
              : label
              ? label
              : t<string>(`${module}.form.${i18nField}`)}
          </span>
        )}
      </Checkbox>
    </Form.Item>
  );
};

export default CheckboxFormField;
