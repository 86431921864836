/* eslint-disable */

export const AUDIT_RESULTS: { [index: string]: AuditType } = {
  RAS: {
    value: "RAS",
    label: "pro.audit.list.historic.results.labels.ras",
    longLabel: "pro.audit.list.historic.results.longLabels.ras",
    variant: "success",
  },
  AC: {
    value: "AC",
    label: "pro.audit.list.historic.results.labels.ac",
    longLabel: "pro.audit.list.historic.results.longLabels.ac",
    variant: "warning",
  },
  AG: {
    value: "AG",
    label: "pro.audit.list.historic.results.labels.ag",
    longLabel: "pro.audit.list.historic.results.longLabels.ag",
    variant: "danger",
  },
};

export const AUDIT_PROGRAMMING_STATUS: { [index: string]: AuditType } = {
  ANNULE: {
    value: "ANNULE",
    label:
      "pro.audit.list.situation.recap.table.row.programmingStatus.canceled",
    variant: "grey",
  },
  EN_ATTENTE_RESULTAT: {
    value: "EN_ATTENTE_RESULTAT",
    label:
      "pro.audit.list.situation.recap.table.row.programmingStatus.toProgram",
    variant: "warning",
  },
  EN_ATTENTE_CC: {
    value: "EN_ATTENTE_CC",
    label: "pro.audit.list.situation.recap.table.row.programmingStatus.waiting",
    variant: "lightgrey",
  },
  WAITING_VALIDATION: {
    value: "WAITING_VALIDATION",
    label:
      "pro.audit.list.situation.recap.table.row.programmingStatus.waitingValidation",
    variant: "lightgrey",
  },
  CONTROLE: {
    value: "CONTROLE",
    label:
      "pro.audit.list.situation.recap.table.row.programmingStatus.controle",
    variant: "lightgrey",
  },
  AUDIT: {
    value: "AUDIT",
    label: "pro.audit.list.situation.recap.table.row.programmingStatus.audit",
    variant: "lightgrey",
  },
  NEW: {
    value: "NEW",
    label: "pro.audit.list.situation.recap.table.row.new",
    variant: "success",
  },
};

export const AUDIT_GLOBAL_STATUS: { [index: string]: AuditType } = {
  NVL: {
    value: "NVL",
    label: "pro.audit.status.nvl",
    variant: "success",
  },
  AGR: {
    value: "AGR",
    label: "pro.audit.status.agr",
    variant: "success",
  },
  CR: {
    value: "CR",
    label: "pro.audit.status.cr",
    variant: "warning",
  },
  REC: {
    value: "REC",
    label: "pro.audit.status.rec",
    variant: "danger",
  },
  RAD: {
    value: "RAD",
    label: "pro.audit.status.rad",
    variant: "danger",
  },
};

export interface AuditType {
  value: string;
  label: string;
  longLabel?: string;
  variant: string;
}

/* eslint-enable */
