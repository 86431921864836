import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import Section from "@components/section/Section";
import BasicButton from "@components/buttons/BasicButton";
import RentreeDuChauffageTempsFortContactModal from "@components/rentreeDuChauffage/tempsFort/RentreeDuChauffageTempsFortContactModal";
import config from "@utils/Config";
import { partners } from "@utils/enums/gaz.event.partners";

interface LandingPagePartnersProps {
  className?: string;
  tempsFort2?: boolean;
  landingPageType: string;
}

const LandingPagePartners: FunctionComponent<LandingPagePartnersProps> = ({
  className = "",
  tempsFort2 = false,
  landingPageType,
}) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  const [isContactModalVisible, setIsContactModalVisible] =
    useState<boolean>(false);

  return (
    <div className={`app-rentree-du-chauffage-partners ${className}`}>
      <div className="content-contained">
        <Section
          title={t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.partners.title2`,
          )}
        >
          <div className="rentree-du-chauffage-partners-container">
            {partners.map((partner, index) => (
              <div
                key={index.valueOf()}
                className="rentree-du-chauffage-partners-img"
              >
                <img src={partner.logo} alt={partner.name} />
              </div>
            ))}
          </div>
        </Section>
        <div className="rentree-du-chauffage-partners-contact-container">
          <BasicButton
            text={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.link`,
            )}
            className="rentree-du-chauffage-partners-contact-link"
            onClick={() => {
              tempsFort2
                ? setIsContactModalVisible(true)
                : window.document
                    .getElementById("app-rentree-du-chauffage-form")
                    ?.scrollIntoView();
            }}
          />
        </div>
      </div>
      <RentreeDuChauffageTempsFortContactModal
        open={isContactModalVisible}
        onClose={() => {
          setIsContactModalVisible(false);
        }}
      />
    </div>
  );
};

export default LandingPagePartners;
