import { FunctionComponent } from "react";
import { ImageBlockItem } from "@type/cms/cms.types";
import ImageBlock from "@components/content/ImageBlock";
import Section from "@components/section/Section";

interface ImageGroupProps {
  title?: string;
  images: ImageBlockItem[];
  backgroundColor?: string;
  packed?: boolean;
  className?: string;
  titleClassName?: string;
  imageGroupClassName?: string;
  carded?: boolean;
  cardColor?: string;
}

const ImageGroupModuleCMS: FunctionComponent<ImageGroupProps> = ({
  title = "",
  images = [],
  className = "",
  titleClassName = "",
  imageGroupClassName = "",
  backgroundColor = "transparent",
  packed = false,
  carded = false,
  cardColor = "white",
}) => {
  const renderImageGroup = () => {
    return (
      <div className="content-group-container">
        <div
          className={`content-group-content ${imageGroupClassName} ${
            packed ? "justify-content-center" : "justify-content-around"
          }`}
          style={{ backgroundColor: carded ? cardColor : "transparent" }}
        >
          {images.map((image: ImageBlockItem, index: number) => (
            <div key={index} className="my-20 mx-2">
              <ImageBlock
                title={image?.title}
                alt={image?.alt}
                description={image?.description}
                icon={image?.icon}
                src={image.src}
                height={image?.height}
                width={image?.width}
                redirectUrl={image?.redirectUrl}
                compact={packed}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`app-content-group ${className} ${
        carded ? "content-group-carded" : ""
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="content-contained">
        {title ? (
          <Section
            title={title}
            className={"content-group-section"}
            titleClassName={titleClassName}
          >
            {renderImageGroup()}
          </Section>
        ) : (
          renderImageGroup()
        )}
      </div>
    </div>
  );
};

export default ImageGroupModuleCMS;
