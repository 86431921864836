import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();
  useLayoutEffect(() => {
    const top = window.document.getElementById("top-header");
    top && top.scrollIntoView();
  }, [pathname]);

  return null;
};

export default ScrollToTop;
