import configuration from "src/utils/Config";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import {
  makeRestCall,
  restCreationHandler,
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import { GazProfessionalSearchCriteria } from "@state/gazprofessional/dto/gaz.professional.search.criteria";
import { searchHelper } from "@utils/search-helper";
import { createEffect } from "effector";
import { GazProfessionalUpdateRequestDto } from "@state/gazprofessional/dto/gaz.professional.update.request.dto";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { GPQualificationsResponseDto } from "@state/professional/dto/professional.qualifications.response.dto";
import { HabitAPlusContactResponseDto } from "@state/gazprofessional/dto/gaz.professional.habitaplus.contact.response.dto";
import { Pageable } from "@type/pagination/pagination.types";
import { MailReadRequestDto } from "@state/mailprofessional/dto/habitaplus.mails.request.dto";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import { OrganisationProfessionalGPPreviewResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.preview.response.dto";
import { GazProfessionalRGRequestItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.item.response.dto";
import { GazProfessionalRGRequestSaveRequestDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.save.request.dto";
import { GazProfessionalRGRequestResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.response.dto";
import { GazProfessionalRGRequestDetailsResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.details.response.dto";
import { GazProfessionalUpdateTertiaryRequestDto } from "@state/gazprofessional/dto/gaz.professional.update.tertiary.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/gaz-professionals/`;

export const searchGazProfessionals = (
  criteria: GazProfessionalSearchCriteria,
): Promise<ParsedResponse<GazProfessionalItemResponseDto[]>> => {
  const geoLocalization = searchHelper.buildFilter(
    criteria.geoLocalization
      ? [
          criteria.geoLocalization.lat.toString(),
          criteria.geoLocalization.lon.toString(),
        ]
      : null,
    "location",
  );
  const distance = searchHelper.buildFilter(
    criteria.distance ? [criteria.distance] : null,
    "distance",
  );
  const bounds = searchHelper.buildFilter(
    criteria.bounds
      ? [
          criteria.bounds.topLeft.lat.toString(),
          criteria.bounds.topLeft.lon.toString(),
          criteria.bounds.bottomRight.lat.toString(),
          criteria.bounds.bottomRight.lon.toString(),
        ]
      : null,
    "bounds",
  );
  const interventionType = searchHelper.buildFilter(
    criteria.interventionType,
    "interventionType",
  );
  const expertise = searchHelper.buildFilter(criteria.expertise, "expertise");
  const building = searchHelper.buildFilter(criteria.building, "building");

  const gazMonthRegistration = searchHelper.buildFilter(
    criteria.gazMonthRegistration
      ? [criteria.gazMonthRegistration.toString()]
      : null,
    "gazMonthRegistration",
  );

  return makeRestCall<void, GazProfessionalItemResponseDto[]>(
    `${baseUrl}search?${geoLocalization}${distance}${bounds}${interventionType}${expertise}${building}${gazMonthRegistration}`,
    "GET",
  );
};

export const requestGetGazProfessional = createEffect({
  handler: restDetailsHandler<GazProfessionalUser>(baseUrl),
});

export const requestUpdateGazProfessional = createEffect({
  handler: restUpdateHandler<
    GazProfessionalUpdateRequestDto,
    GazProfessionalUser
  >(`${baseUrl}me`, "", true),
});

export const requestUpdateGreenGaz = createEffect({
  handler: restUpdateHandler<null, GazProfessionalUser>(
    `${baseUrl}greenGaz`,
    "",
    true,
  ),
});

export const requestUpdateTertiaryInformation = createEffect({
  handler: restUpdateHandler<
    GazProfessionalUpdateTertiaryRequestDto,
    GazProfessionalUser
  >(`${baseUrl}tertiary`, "", true),
});

export const requestUpdateCoordinateGazProfessional = createEffect({
  handler: restUpdateHandler<
    GazProfessionalUpdateRequestDto,
    GazProfessionalUser
  >(`${baseUrl}coordinates/me`, "", true),
});

export const requestGetGazProfessionalQualifications = createEffect({
  handler: restGetUniqueHandler<GPQualificationsResponseDto>(
    `${baseUrl}qualifications`,
  ),
});

export const requestGetGazProfessionalHabitAPlusContact = createEffect({
  handler: restGetUniqueHandler<HabitAPlusContactResponseDto>(
    `${baseUrl}habitaplus-contact`,
  ),
});

export const requestGetGazProfessionalHabitAPlusMails = createEffect({
  handler: restListHandlerPagination<
    Pageable<GazProfessionalHabitAPlusMailsResponseDto>
  >(`${baseUrl}habitaplus-mails`),
});

export const requestUpdateGazProfessionalHabitAPlusMailRead = createEffect({
  handler: restUpdateHandler<MailReadRequestDto, void>(
    `${baseUrl}habitaplus-mails/`,
    "/read",
  ),
});

export const requestGetOrganisationProfessionalPreviewGazProfessional =
  createEffect({
    handler: restDetailsHandler<OrganisationProfessionalGPPreviewResponseDto>(
      `${baseUrl}`,
      "/preview",
    ),
  });

export const requestGetGazProfessionalRGRequests = createEffect({
  handler: restListHandler<GazProfessionalRGRequestItemResponseDto>(
    `${baseUrl}rg-requests`,
  ),
});

export const requestGetGazProfessionalRGRequest = createEffect({
  handler: restDetailsHandler<GazProfessionalRGRequestDetailsResponseDto>(
    `${baseUrl}rg-requests/`,
  ),
});

export const requestCreateGazProfessionalRGRequest = createEffect({
  handler: restCreationHandler<
    GazProfessionalRGRequestSaveRequestDto,
    GazProfessionalRGRequestResponseDto
  >(`${baseUrl}rg-request`),
});

export const requestUpdateGazProfessionalRGRequest = createEffect({
  handler: restUpdateHandler<
    GazProfessionalRGRequestSaveRequestDto,
    GazProfessionalRGRequestDetailsResponseDto
  >(`${baseUrl}rg-requests/`),
});
