import { FunctionComponent } from "react";
import { Col, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";

interface ModuleNameProps {
  readOnly: boolean;
}

const ModuleName: FunctionComponent<ModuleNameProps> = ({ readOnly }) => {
  return (
    <>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <InputFormField
            showLabel
            module="pro.qualifications.modals.addRG"
            field="lastname"
            readOnly={readOnly}
            required={true}
          />
        </Col>
        <Col xs={24} lg={12}>
          <InputFormField
            showLabel
            module="pro.qualifications.modals.addRG"
            field="firstname"
            readOnly={readOnly}
            required={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default ModuleName;
