import { FC, useCallback, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardProCard from "@components/cards/DashboardProCard";
import NumberCard from "@components/cards/NumberCard";
import BasicButton from "@components/buttons/BasicButton";
import News from "@components/block/widget/News";
import { requestGetContactsLightResponse } from "@state/contact/gazprofessional/ContactGazProfessionalEffects";
import { ContactLightResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.light.response.dto";
import TagManager from "react-gtm-module";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import ProfileProgress from "@components/progress/ProfileProgress";
import RewardCard from "@components/cards/RewardCard";
import { WorksiteLightResponseDto } from "@state/worksite/dto/worksite.response.dto";
import { requestGetWorksitesLightResponse } from "@state/worksite/WorksiteEffects";
import BasicCard from "@components/cards/BasicCard";
import { getCardSubtitle, getGazEvent, today } from "@utils/gazEvent-utils";

const GazProfessionalHomeScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [contacts, setContacts] = useState<ContactLightResponseDto[]>([]);
  const [worksites, setWorksites] = useState<WorksiteLightResponseDto[]>([]);

  const authentifiedContext = useStore(authentifiedStore);
  const gpUser = authentifiedContext.user as GazProfessionalUser;
  const typeUser = authentifiedContext.typeUser;
  const isUserActive = gpUser.status === "ACTIVE";

  const fetchPages = useCallback(() => {
    if (typeUser === "GazProfessionalUser") {
      setDataHasLoaded(false);
      requestGetContactsLightResponse({})
        .catch(() => {
          toastError(t<string>("contacts.void messages.error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
      requestGetWorksitesLightResponse({})
        .catch(() => {
          toastError(t<string>("worksite.void messages.error"));
        })
        .finally(() => {
          setDataHasLoaded(true);
        });
    }
  }, [t, typeUser]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetContactsLightResponse.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setContacts(result.data);
      }
    });
  });

  const newContacts = contacts.filter((c) => c.status === "UNREAD");
  const processedContacts = contacts.filter(
    (c) =>
      c.status === "PROCESSED" ||
      c.status === "SENT" ||
      c.status === "CONCLUDED",
  );

  useEffect(() => {
    return requestGetWorksitesLightResponse.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWorksites(result.data);
      }
    });
  });

  const mentions = [
    gpUser.pacHybride != undefined,

    gpUser.smokeDuct != undefined,
    gpUser.greenGaz != undefined,
  ];
  const numberMention = mentions.filter((m) => m).length;

  const numberBonus = worksites.filter(
    (c) => c.worksiteStatus === "GRDF",
  ).length;

  const hasPicture = gpUser.pictureListId && gpUser.pictureListId.length > 0;
  const hasDescription = gpUser.description && gpUser.description.length > 0;
  const hasGPSCoordinates = gpUser.latitude != null && gpUser.longitude != null;
  const hasSocialNetwork =
    (gpUser.facebook && gpUser.facebook.length > 0) ||
    (gpUser.website && gpUser.website.length > 0) ||
    (gpUser.twitter && gpUser.twitter.length > 0) ||
    (gpUser.linkedin && gpUser.linkedin.length > 0);
  const hasSetMapInformation =
    gpUser.editableEmail &&
    gpUser.editableEmail.length > 0 &&
    gpUser.editableAddress &&
    gpUser.editableAddress.length > 0 &&
    gpUser.editableZipCode &&
    gpUser.editableZipCode.length > 0 &&
    gpUser.editableCity &&
    gpUser.editableCity.length > 0 &&
    gpUser.editablePhone1 &&
    gpUser.editablePhone1.length > 0;

  const personalData = [
    hasPicture,
    hasDescription ?? false,
    hasGPSCoordinates ?? false,
    hasSocialNetwork ?? false,
    hasSetMapInformation ?? false,
  ];

  const setPercentScore = () => {
    const percentScore = personalData.filter((s) => s === true).length;
    return (percentScore / 5) * 100;
  };

  const setMessageTooltip = () => {
    return "";
  };

  return (
    <PageLayout
      container
      titleCentered={false}
      title={`${t<string>("pro.connectedHome.title")} ${
        gpUser ? gpUser.companyName : ""
      } !`}
      subtitle={
        isUserActive
          ? t<string>("pro.connectedHome.subtitle")
          : t<string>("pro.connectedHome.subtitleRemoved")
      }
      subtitleClassName={isUserActive ? "" : "app-quote quote-danger"}
    >
      <>
        {gpUser && isUserActive && (
          <>
            {isUserActive && (
              <div className="d-flex flex-column flex-lg-row align-items-lg-start justify-content-lg-center mt-3">
                <div>
                  {getGazEvent().typeEvent !== "noEvent" && (
                    <BasicCard
                      className="app-card-pro connected-home-gaz-event"
                      onClick={() =>
                        navigate(
                          ROUTES.professional.gazMonth.details.generate(),
                        )
                      }
                    >
                      <div className="d-flex flex-column">
                        <h2 className="text-primary">
                          {t<string>(
                            `${
                              getGazEvent().typeEvent
                            }.pro.notSubscribed.cardRegistration.${
                              getGazEvent().typeEvent
                            }`,
                          )}
                        </h2>
                        <span>
                          <>
                            {getCardSubtitle(
                              t,
                              getGazEvent().typeEvent,
                              getGazEvent().start,
                              getGazEvent().end,
                              today,
                            )}
                          </>
                        </span>
                      </div>
                    </BasicCard>
                  )}
                  <div className="d-flex flex-column flex-xxl-row flex-xxl-wrap">
                    {dataHasLoaded && newContacts.length > 0 && (
                      <DashboardProCard
                        title={t<string>(
                          "pro.connectedHome.cards.contact.title",
                        )}
                        icon={
                          <img
                            alt={t<string>(
                              "pro.connectedHome.cards.contact.title",
                            )}
                            src="/img/pro/envelope.png"
                          />
                        }
                        description={`${
                          newContacts.length == 1
                            ? t<string>(
                                "pro.connectedHome.cards.contact.description",
                              )
                            : t<string>(
                                "pro.connectedHome.cards.contact.descriptionPlural",
                                {
                                  newContactsNumber: newContacts.length,
                                },
                              )
                        }`}
                        className="connected-home-card red-dot-icon"
                        onClick={() => {
                          TagManager.dataLayer({
                            dataLayer: {
                              event: "clic_x_nouvelles_demandes_contact",
                            },
                          });
                          if (newContacts.length == 1) {
                            navigate(
                              ROUTES.professional.contacts.details.generate(
                                newContacts[0].id,
                              ),
                            );
                          } else {
                            navigate(
                              ROUTES.professional.contacts.list.generate(),
                            );
                          }
                        }}
                      />
                    )}
                    <DashboardProCard
                      title={t<string>(
                        "pro.connectedHome.cards.maintenance.title",
                      )}
                      icon={
                        <img
                          alt={t<string>(
                            "pro.connectedHome.cards.maintenance.title",
                          )}
                          src="/img/pro/form.svg"
                        />
                      }
                      description={t<string>(
                        "pro.connectedHome.cards.maintenance.description",
                      )}
                      className="connected-home-card"
                      onClick={() =>
                        navigate(
                          ROUTES.professional.maintenanceCertificate.add.generate(),
                        )
                      }
                    />

                    <DashboardProCard
                      title={t<string>(
                        "pro.connectedHome.cards.mentionPacHybride.title",
                      )}
                      icon={
                        <img
                          alt={t<string>(
                            "pro.connectedHome.cards.mentionPacHybride.title",
                          )}
                          src="/img/pro/logoPH.svg"
                        />
                      }
                      description={t<string>(
                        gpUser.pacHybride
                          ? "pro.connectedHome.cards.mentionPacHybride.access"
                          : "pro.connectedHome.cards.mentionPacHybride.get",
                      )}
                      className="connected-home-card"
                      onClick={() =>
                        navigate(
                          ROUTES.professional.pacsHybrides.mention.generate(),
                        )
                      }
                    />

                    <DashboardProCard
                      title={t<string>(
                        "pro.connectedHome.cards.smokeDuct.title",
                      )}
                      icon={
                        <img
                          alt={t<string>(
                            "pro.connectedHome.cards.smokeDuct.title",
                          )}
                          src="/img/pro/logo-smoke-duct-dashboard.svg"
                        />
                      }
                      description={t<string>(
                        gpUser.smokeDuct
                          ? "pro.connectedHome.cards.smokeDuct.access"
                          : "pro.connectedHome.cards.smokeDuct.get",
                      )}
                      className="connected-home-card"
                      onClick={() =>
                        navigate(
                          ROUTES.professional.smokeDucts.mention.generate(),
                        )
                      }
                    />
                  </div>
                </div>
                <DashboardProCard
                  title={t<string>("pro.connectedHome.cards.profile.title")}
                  className="connected-home-profile-card"
                >
                  <>
                    <div className="mb-30">
                      <NumberCard
                        icon={
                          <img
                            alt={t<string>(
                              "pro.connectedHome.cards.profile.contract.imgAlt",
                            )}
                            src="/img/pro/contract.svg"
                          />
                        }
                        title={t<string>(
                          "pro.connectedHome.cards.profile.contract.title",
                        )}
                        number={processedContacts.length}
                      />
                      {gpUser.smokeDuct && (
                        <NumberCard
                          icon={
                            <img
                              alt={t<string>(
                                "pro.connectedHome.cards.profile.bonus.imgAlt",
                              )}
                              src="/img/pro/bonus.svg"
                            />
                          }
                          title={t<string>(
                            "pro.connectedHome.cards.profile.bonus.title",
                          )}
                          number={numberBonus}
                        />
                      )}
                    </div>

                    <RewardCard
                      icon={
                        <img
                          alt={t<string>(
                            "pro.connectedHome.cards.profile.mention.imgAlt",
                          )}
                          src="/img/pro/badge2.svg"
                        />
                      }
                      title={`${t<string>(
                        "pro.connectedHome.cards.profile.mention.title",
                      )} ${numberMention} ${
                        numberMention <= 1
                          ? t<string>(
                              "pro.connectedHome.cards.profile.mention.mention",
                            )
                          : t<string>(
                              "pro.connectedHome.cards.profile.mention.mentions",
                            )
                      }`}
                      className="mb-30"
                    />

                    <ProfileProgress
                      title={`${t<string>(
                        "pro.connectedHome.cards.profile.progress.title",
                      )} ${setPercentScore()} %`}
                      status={`${
                        setPercentScore() == 0
                          ? "Initialisation"
                          : setPercentScore() != 100
                          ? "Intermédiaire"
                          : "Fini"
                      }`}
                      percentage={setPercentScore()}
                      className="mb-30"
                      tooltipMessage={setMessageTooltip()}
                    />

                    <div className="d-flex align-items-center justify-content-center my-2">
                      <Link to={ROUTES.professional.personalData.generate()}>
                        <BasicButton
                          text={t<string>(
                            "pro.connectedHome.cards.profile.button",
                          )}
                          size="lg"
                          onClick={() => {
                            TagManager.dataLayer({
                              dataLayer: {
                                event: "clic_completer_profil",
                              },
                            });
                          }}
                        />
                      </Link>
                    </div>
                  </>
                </DashboardProCard>
              </div>
            )}
            <News />
          </>
        )}
      </>
    </PageLayout>
  );
};

export default GazProfessionalHomeScreen;
