import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { WorksiteSaveRequestDto } from "@state/worksite/dto/worksite.save.request.dto";
import {
  WorksiteItemsResponseDto,
  WorksiteLightResponseDto,
  WorksiteResponseDto,
} from "@state/worksite/dto/worksite.response.dto";
import { Pageable } from "@type/pagination/pagination.types";

const baseUrl = `${configuration.backendBaseUrl}/worksite`;

export const requestCreateWorksite = createEffect({
  handler: restCreationHandler<WorksiteSaveRequestDto, WorksiteResponseDto>(
    baseUrl,
  ),
});

export const requestGetWorksite = createEffect({
  handler: restDetailsHandler<WorksiteResponseDto>(`${baseUrl}/`),
});

export const requestGetWorksites = createEffect({
  handler:
    restListHandlerPagination<Pageable<WorksiteItemsResponseDto>>(baseUrl),
});

export const requestUpdateWorksite = createEffect({
  handler: restPatchHandler<WorksiteSaveRequestDto, WorksiteResponseDto>(
    `${baseUrl}/`,
  ),
});

export const requestDeleteWorksite = createEffect({
  handler: restDeletionHandler<WorksiteResponseDto>(`${baseUrl}/`),
});

export const requestGetWorksitesLightResponse = createEffect({
  handler: restListHandler<WorksiteLightResponseDto>(`${baseUrl}/light`),
});
