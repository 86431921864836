import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

interface MaintenanceCertificateModalHelp1Props {
  title: string;
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const MaintenanceCertificateHelp1Modal: FunctionComponent<
  MaintenanceCertificateModalHelp1Props
> = ({ title, open, onCancel, className = "" }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      className={className}
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <>
        <div className="mb-30">
          {parse(t<string>("pro.certificate.form.help.global.text1"))}
        </div>
      </>
    </BasicModal>
  );
};

export default MaintenanceCertificateHelp1Modal;
