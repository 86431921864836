import { FunctionComponent } from "react";
import parse from "html-react-parser";
import { ButtonAction } from "@type/cms/cms.types";
import ButtonModuleCMS from "@components/cms/ButtonModuleCMS";

interface ImageSectionProps {
  src?: string;
  alt?: string;
  title?: string;
  height?: string;
  width?: string;
  sideContent?: string;
  alignImgLeft?: boolean;
  className?: string;
  imgClassName?: string;
  backgroundColor?: string;
  fillContainer?: boolean;
  verticalAlign?: "center" | "start" | "end";
  buttonAction?: ButtonAction;
  btnClassName?: string;
  carded?: boolean;
  cardColor?: string;
}

const ImageModuleCMS: FunctionComponent<ImageSectionProps> = ({
  src = undefined,
  alt,
  title,
  height = undefined,
  width = undefined,
  sideContent = undefined,
  fillContainer = false,
  alignImgLeft = false,
  verticalAlign = "center",
  className = "",
  imgClassName = "",
  backgroundColor = "transparent",
  buttonAction = undefined,
  btnClassName = "",
  carded = false,
  cardColor = "white",
}) => {
  return (
    <div
      className={`app-image-module ${className} ${
        carded ? "image-module-carded" : ""
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={`content-contained image-module-content align-items-${verticalAlign} ${
          alignImgLeft ? "flex-row-reverse" : "flex-row"
        } flex-wrap 
      ${fillContainer ? "p-0" : ""} ${
          carded && cardColor === "white" ? "bg-white" : ""
        }`}
        style={{ backgroundColor: carded ? cardColor : "transparent" }}
      >
        {(sideContent || buttonAction) && (
          <div
            className={
              "image-module-sidecontent flex-grow-1 text-left flex-column"
            }
          >
            {sideContent && (
              <div className={"mb-2 mx-0 mx-md-3"}>{parse(sideContent)}</div>
            )}
            {buttonAction && (
              <ButtonModuleCMS
                text={buttonAction.buttonLabel}
                url={buttonAction.url}
                variant={buttonAction.variant}
                size={buttonAction.size}
                btnClassName={btnClassName}
              />
            )}
          </div>
        )}
        {src && (
          <img
            src={src}
            className={`${imgClassName} mt-3 mt-md-0 ${
              fillContainer ? "image-module-img-cover" : ""
            }`}
            height={height}
            width={width}
            alt={alt}
            title={title}
          />
        )}
      </div>
    </div>
  );
};

export default ImageModuleCMS;
