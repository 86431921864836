import { FunctionComponent, useEffect, useState } from "react";
import { Upload } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { RcFile } from "antd/lib/upload/interface";
import {
  requestCreateResource,
  requestDeleteResource,
  requestGetResource,
} from "@state/resource/ResourceEffects";
import { File } from "@type/resource-type-interface";
import { useTranslation } from "react-i18next";
import Section from "@components/section/Section";
import { getBase64UrlFrom } from "@utils/Base64File";

interface PicturesUploadProps {
  picturesFile?: File[];
  pictureListId?: string[];
  setPicturesListId?: (
    id:
      | string[]
      | undefined
      | ((id: string[] | undefined) => string[] | undefined),
  ) => void;
  className?: string;
}

interface Pictures {
  id: string;
  content: string;
}

const PicturesUpload: FunctionComponent<PicturesUploadProps> = ({
  className = "",
  pictureListId,
  setPicturesListId,
}) => {
  const [picturesContent, setPicturesContent] = useState<Pictures[]>([]);
  const { t } = useTranslation();

  const uploadProps = {
    customRequest({ file }: UploadRequestOption) {
      const rcFile = file as RcFile;
      const formData = new FormData();
      formData.append("file", rcFile);
      formData.append("domain", "PICTURE");
      void requestCreateResource({
        dto: formData,
      }).then((result) => {
        if (result.ok && result.data) {
          const resource = result.data;
          setPicturesListId?.((currentResource) =>
            currentResource
              ? currentResource.concat(resource.id)
              : [resource.id],
          );
          void requestGetResource(resource.id).then((result) => {
            if (result.ok && result.data) {
              const receivedResource = result.data;
              setPicturesContent(() => [
                ...picturesContent,
                { id: resource.id, content: receivedResource.data },
              ]);
            }
          });
        }
      });
      return {};
    },
  };

  useEffect(() => {
    if (pictureListId) {
      pictureListId.forEach((pictureId) => {
        void requestGetResource(pictureId);
      });
    }
  }, []);

  useEffect(() => {
    return requestGetResource.done.watch(({ result, params }) => {
      if (result.ok && result.data) {
        const resourceData = result.data;
        setPicturesContent((currentList) => [
          ...currentList,
          { id: params, content: resourceData.data },
        ]);
      }
    });
  });

  const handleDeletePicture = (pictureId: string) => {
    const removingPicture = pictureListId?.find((f) => f === pictureId);
    if (removingPicture) {
      setPicturesListId?.(
        pictureListId && [
          ...pictureListId.filter((f) => f !== removingPicture),
        ],
      );
      void requestDeleteResource({
        dto: {
          id: pictureId,
        },
      });
      setPicturesContent(
        picturesContent.filter((picture) => picture.id !== pictureId),
      );
    }
  };

  return (
    <Section
      title={t("personalInfo.sectionTitle.picture")}
      titleClassName="text-primary px-12 mb-10"
    >
      <div
        className={`app-upload-pictures d-flex align-items-stretch flex-wrap ${className} mb-5`}
      >
        <Upload
          {...uploadProps}
          name="pictures"
          listType="picture-card"
          showUploadList={false}
          className="mr-10"
        >
          <div className="upload-pictures-icon">
            <PlusOutlined />
          </div>
        </Upload>
        {picturesContent?.map((picture) => (
          <div
            key={picture.id}
            className="upload-pictures-img d-flex align-items-center justify-content-center mr-10 mb-10"
          >
            <img src={getBase64UrlFrom(picture.content, "PNG")} alt="photo" />
            <div
              onClick={() => handleDeletePicture(picture.id)}
              className="upload-pictures-img-delete d-flex align-items-center justify-content-center"
            >
              <div className="upload-pictures-img-delete-icon">
                <DeleteOutlined />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default PicturesUpload;
