import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Spin, Tabs } from "antd";
import ToolsCard from "@components/cards/ToolsCard";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { File } from "@type/resource-type-interface";
import { mapResourceTofileType } from "@utils/upload-utils";
import FilesCard from "@components/files/FilesCard";
import TagManager from "react-gtm-module";

const { TabPane } = Tabs;

export const ToolsOrganisationProfessionalScreen: React.FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    void requestGetResources({
      dto: {
        domain: ["INSTALLATION", "MAINTENANCE", "GLOBAL"],
        ids: [],
      },
    });
  }, []);

  const [installationFiles, setInstallationFiles] = useState<File[]>([]);
  const [maintenanceFiles, setMaintenanceFiles] = useState<File[]>([]);
  const [globalFiles, setGlobalFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setInstallationFiles(
          result.data
            .filter((file) => file.domain === "INSTALLATION")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setMaintenanceFiles(
          result.data
            .filter((file) => file.domain === "MAINTENANCE")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
        setGlobalFiles(
          result.data
            .filter((file) => file.domain === "GLOBAL")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
      }
      setIsLoading(false);
    });
  }, []);

  return (
    <PageLayout
      noProSideMenu
      organisationProSideMenu
      container
      titleCentered={false}
      title={t<string>("organisationprofessional.tools.title")}
      subtitle={t<string>("organisationprofessional.tools.subtitle")}
    >
      <>
        <h2 className="text-primary mt-3 mb-20">
          {t<string>("organisationprofessional.tools.practicalTools")}
        </h2>
        <div className="d-flex justify-content-center flex-wrap">
          <ToolsCard
            icon={
              <img
                alt={t<string>("organisationprofessional.tools.alt.interface")}
                src="/img/pro/audit.svg"
              />
            }
            buttonHref="https://www.qualip.org/v2_2/qpIHM/index.php"
            buttonTarget="_blank"
            title={t<string>("organisationprofessional.tools.interface")}
            buttonText={t<string>("organisationprofessional.tools.seeMore")}
          />
          <ToolsCard
            icon={
              <img
                alt={t<string>("organisationprofessional.tools.alt.platform")}
                src="/img/pro/shop.svg"
              />
            }
            buttonHref="https://boutique-pg.niceshop.co"
            buttonTarget="_blank"
            title={t<string>("organisationprofessional.tools.shop")}
            buttonText={t<string>("organisationprofessional.tools.access")}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "clic_shop_pg",
                },
              });
            }}
          />
          <ToolsCard
            icon={
              <img
                alt={t<string>("organisationprofessional.tools.alt.platform")}
                src="/img/pro/stickers.svg"
              />
            }
            buttonHref="http://boutique-lesprofessionnelsdugaz.fr/gphrbttgazvert/index.php"
            buttonTarget="_blank"
            title={t<string>("organisationprofessional.tools.stickers")}
            buttonText={t<string>("organisationprofessional.tools.access")}
            onClick={() => {
              TagManager.dataLayer({
                dataLayer: {
                  event: "clic_stickers_pg",
                },
              });
            }}
          />
        </div>
        <Tabs defaultActiveKey="1" centered className="mt-40">
          <TabPane
            tab={t<string>("organisationprofessional.tools.ressources")}
            key="1"
          >
            <>
              <div className="mb-20">
                {t<string>("organisationprofessional.tools.documents")}
              </div>
              {!isLoading ? (
                <div>
                  <h3 className="text-primary mb-20">
                    {t<string>("organisationprofessional.tools.installation")}
                  </h3>
                  <div className="d-flex justify-content-center flex-wrap">
                    <FilesCard files={installationFiles} />
                  </div>
                  <h3 className="text-primary my-20">
                    {t<string>("organisationprofessional.tools.maintenance")}
                  </h3>
                  <div className="d-flex justify-content-center flex-wrap">
                    <FilesCard files={maintenanceFiles} />
                  </div>
                </div>
              ) : (
                <div className="anticon-resource">
                  <Spin size="large" />
                </div>
              )}
            </>
          </TabPane>
          {globalFiles.length > 0 && (
            <TabPane
              tab={t<string>("organisationprofessional.tools.reviews")}
              key="2"
            >
              {!isLoading ? (
                <div className="d-flex justify-content-center flex-wrap">
                  <FilesCard files={globalFiles} />
                </div>
              ) : (
                <div className="anticon-resource">
                  <Spin size="large" />
                </div>
              )}
            </TabPane>
          )}
        </Tabs>
      </>
    </PageLayout>
  );
};
