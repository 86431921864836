export enum QualificationStatus {
  valid = "VALID",
  expiredSoon = "EXPIRED_SOON",
  invalid = "INVALID",
  fired = "FIRED",
}

export interface QualificationValidity {
  installation: QualificationStatus;
  maintenance: QualificationStatus;
}
