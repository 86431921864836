import { FC, useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { LatLngBounds, LeafletEvent, LeafletMouseEvent } from "leaflet";
import { useLocation, useNavigate } from "react-router-dom";
import SearchFilterList from "@components/lists/SearchFilterList";
import { SearchResultDto } from "@type/list/search-result-dto-interface";
import { LocalizationDTO } from "@type/localization/LocalizationDTO";
import BasicButton from "@components/buttons/BasicButton";
import MapLeaflet, { MarkerProps } from "@components/map/MapLeaflet";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import { searchGazProfessionals } from "@state/gazprofessional/GazProfessionalEffects";
import PageLayout from "@components/layouts/PageLayout";
import { GazProfessionalSearchResult } from "@components/lists/GazProfessionalSearchResult";
import { AddressFields } from "@components/inputs/AddressFields";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import {
  BUILDING_TYPE,
  EXPERTISE,
  INTERVENTION_TYPES,
} from "@utils/enums/search.enum";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import BasicModal from "@components/modals/BasicModal";
import ProfessionalContactForm from "@components/gazprofessional/ProfessionalContactForm";
import TagManager from "react-gtm-module";
import parse from "html-react-parser";
import configuration from "@utils/Config";
import ContactItemDetails from "@components/gazprofessional/ContactItemDetails";
import { ContactEnum } from "@utils/enums/contact.enum";
import { toastError } from "@utils/toast-helper";
import { GeoLocalizationState } from "@type/localization/GeoLocalizationState";
import { BREAKPOINTS, useViewport } from "@utils/breakpoints-helper";
import BottomSheet from "@components/bottomSheets/BottomSheet";
import { SelectFormField } from "@components/inputs/SelectFormField";

export interface SearchOptions {
  init: boolean;
  isSearching: boolean;
  searchWithBounds: boolean;
  updateBounds?: boolean;
  currentLocation?: CurrentLocation;
  bounds?: BoundsDTO;
}

export interface CurrentLocation {
  currentLocation?: LocalizationDTO;
  addressLabel?: string;
  addressId?: string;
  isGeoLocation?: boolean;
}

export interface BoundsDTO {
  topLeft: LocalizationDTO;
  bottomRight: LocalizationDTO;
}

export const SearchScreen: FC = () => {
  const searchTimeout = useRef<number>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewport();

  const isGazEvent = configuration.isGazEvent;
  const gazEventType = configuration.gazEventType;

  const [searchOption, setSearchOption] = useState<SearchOptions>({
    init:
      (location.state as GeoLocalizationState)?.parentCurrentPosition ==
      undefined,
    isSearching: true,
    searchWithBounds: false,
    currentLocation: {
      currentLocation: (location.state as GeoLocalizationState)
        ?.parentCurrentPosition,
      addressLabel: (location.state as GeoLocalizationState)?.parentQueryString,
    },
    updateBounds:
      (location.state as GeoLocalizationState)?.parentCurrentPosition !=
      undefined,
  });

  const [markers, setMarkers] = useState<MarkerProps[]>([]);
  const [markerPosition, setMarkerPosition] = useState<MarkerProps | undefined>(
    searchOption.currentLocation?.currentLocation?.lat &&
      searchOption.currentLocation?.currentLocation?.lon
      ? {
          id: "markerPosition",
          position: [
            searchOption.currentLocation?.currentLocation?.lat,
            searchOption.currentLocation?.currentLocation?.lon,
          ],
          type: "position",
        }
      : undefined,
  );
  const [markersContactList, setMarkersContactList] = useState<MarkerProps[]>(
    [],
  );
  const [markerCurrentLocationId, setMarkerCurrentLocationId] = useState<
    string | undefined
  >(undefined);
  const [selectedGazProfessional, setSelectedGazProfessional] =
    useState<GazProfessionalItemResponseDto>();
  const [searchResult, setSearchResult] = useState<
    GazProfessionalItemResponseDto[]
  >([]);
  const [contactList, setContactList] = useState<
    GazProfessionalItemResponseDto[]
  >([]);
  const [mapContact, setMapContact] =
    useState<GazProfessionalItemResponseDto>();
  const [interventionType, setInterventionType] = useState<string[]>();
  const [expertise, setExpertise] = useState<string[]>();
  const [building, setBuilding] = useState<string[]>();

  const [isContactModalVisible, setIsContactModalVisible] =
    useState<boolean>(false);

  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState<boolean>(
    width < BREAKPOINTS.lg,
  );
  const [isBottomSheetHidden, setIsBottomSheetHidden] =
    useState<boolean>(false);

  const setMarkersFromSearchResult = (
    localSr: GazProfessionalItemResponseDto[],
  ): void => {
    const newMarkers = localSr
      .filter((item) => item.latitude != null && item.longitude != null)
      .map((item: SearchResultDto): MarkerProps | null => {
        return {
          id: item.id,
          position: [item.latitude, item.longitude],
          type: item.chimneyProfessional ? "pgChimney" : "pg",
        };
      }) as MarkerProps[];
    setMarkers(newMarkers);
  };

  useEffect(() => {
    if (!searchOption.init && searchOption.isSearching) {
      void new Promise(() => {
        clearTimeout(searchTimeout.current);
        searchTimeout.current = window.setTimeout(() => {
          void searchGazProfessionals({
            expertise,
            building,
            interventionType,
            geoLocalization: searchOption.currentLocation
              ? searchOption.currentLocation.currentLocation
              : undefined,
            bounds: searchOption.searchWithBounds
              ? searchOption.bounds
              : undefined,
          })
            .then(
              (result: ParsedResponse<GazProfessionalItemResponseDto[]>) => {
                if (result.ok && result.data) {
                  setSearchResult(result.data);
                  setMarkersFromSearchResult(result.data);
                } else {
                  toastError(t<string>("search.errors.messages.research"));
                }
              },
            )
            .catch(() =>
              toastError(t<string>("search.errors.messages.research")),
            )
            .finally(() => {
              setSearchOption({
                ...searchOption,
                updateBounds: !searchOption.searchWithBounds,
                isSearching: false,
                searchWithBounds: true,
              });
            });
        }, 500);
      });
    }
  }, [searchOption, expertise, interventionType, navigate, t, building]);

  const getBounds = (e: LeafletEvent): BoundsDTO => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    const mapBounds = e.target.getBounds() as LatLngBounds;
    const northWest = mapBounds.getNorthWest();
    const southEast = mapBounds.getSouthEast();
    return {
      topLeft: { lat: northWest.lat, lon: northWest.lng },
      bottomRight: { lat: southEast.lat, lon: southEast.lng },
    };
  };

  const onMapZoomEnd = (e: LeafletEvent): void => {
    // Si la map a zoomé uniquement depuis l'action d'un utilisateur et non depuis le résultat d'une recherche
    if (!searchOption.init) {
      if (searchOption.searchWithBounds) {
        setSearchOption({
          ...searchOption,
          init: false,
          bounds: getBounds(e),
          searchWithBounds: true,
          updateBounds: false,
          isSearching: true,
        });
      }
    } else {
      setSearchOption({
        ...searchOption,
        bounds: getBounds(e),
      });
    }
  };

  const onMapDragEnd = (e: LeafletEvent): void => {
    if (!searchOption.init) {
      if (searchOption.searchWithBounds) {
        setSearchOption({
          init: false,
          bounds: getBounds(e),
          searchWithBounds: true,
          updateBounds: false,
          isSearching: true,
        });
      }
    }
  };

  const onMouseUp = (e: LeafletMouseEvent): void => {
    e.originalEvent.preventDefault();
    setSearchOption({
      init: false,
      bounds: getBounds(e),
      searchWithBounds: true,
      updateBounds: false,
      isSearching: true,
    });
  };
  const onSelectAddress = (address: AddressFieldsDto): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: "carte_recherche",
      },
    });

    setSearchOption({
      ...searchOption,
      init: false,
      bounds: undefined,
      searchWithBounds: false,
      updateBounds: false,
      isSearching: true,
      currentLocation: {
        currentLocation: {
          lat: address.geometry.coordinates[1],
          lon: address.geometry.coordinates[0],
        },
        addressLabel: address.properties.label,
        addressId: address.properties.id,
        isGeoLocation: false,
      },
    });

    setMarkerPosition({
      id: "markerPosition",
      position: [
        address.geometry.coordinates[1],
        address.geometry.coordinates[0],
      ],
      type: "position",
    });
  };

  const onClickMarker = (id: string): void => {
    if (!searchOption.init) {
      const currentLocationItem = searchResult.filter((i) => i.id === id)[0];
      if (currentLocationItem) {
        setMarkerCurrentLocationId(id);
        setMapContact(currentLocationItem);
      }
    } else {
      setSearchOption({
        ...searchOption,
        init: false,
        updateBounds: false,
        isSearching: true,
        searchWithBounds: true,
      });
    }
  };

  const onChangeInterventionType = (value: string[] | string) => {
    value &&
      TagManager.dataLayer({
        dataLayer: {
          event: "carte_recherche_type",
          type: value,
        },
      });
    setInterventionType(Array.isArray(value) ? value : [value]);
    setSearchOption({
      ...searchOption,
      init: false,
      isSearching: true,
    });
  };

  const onChangeExpertise = (value: string[] | string) => {
    setExpertise(Array.isArray(value) ? value : [value]);
    setSearchOption({
      ...searchOption,
      init: false,
      isSearching: true,
    });
  };

  const onChangeBuilding = (value: string[] | string) => {
    setBuilding(Array.isArray(value) ? value : [value]);
    setSearchOption({
      ...searchOption,
      init: false,
      isSearching: true,
    });
  };

  const handleToggleContact = (item: GazProfessionalItemResponseDto) => {
    if (mapContact && mapContact.id === item.id) {
      setMapContact(undefined);
      setMarkerCurrentLocationId(undefined);
      setMarkersContactList([] as MarkerProps[]);
    } else {
      setMapContact(item);
      setMarkerCurrentLocationId(item.id);
      setMarkersContactList([
        {
          id: item.id,
          position: [item?.latitude, item?.longitude],
          type: "pgContactList",
        },
      ] as MarkerProps[]);
    }
  };

  return (
    <PageLayout
      container={false}
      noFooter
      bodyClassName={`app-search-screen ${isGazEvent ? "is-gaz-month" : ""}`}
    >
      <>
        {isGazEvent && (
          <div className="alert-map-gaz-month d-none d-lg-flex flex-row align-items-center gap-2">
            <div className="content">
              {parse(t<string>(`${gazEventType}.individual.map.alert`))}
            </div>
          </div>
        )}
        <div className="search-screen-container">
          <div className="search-screen-content">
            <Spin spinning={searchOption.isSearching && !searchOption.init}>
              <div className="search-screen-filters">
                <AddressFields
                  initSearchValue={searchOption.currentLocation?.addressLabel}
                  setSelectedAddress={onSelectAddress}
                  className="address-select mb-2"
                  geoLocation
                  loader
                  isInit={
                    (location.state as GeoLocalizationState)
                      ?.parentCurrentPosition != null
                  }
                  allowClear
                />
                {isMoreFiltersOpen ? (
                  <>
                    <div className="search-screen-more-filters mb-2">
                      <SelectFormField
                        mode="multiple"
                        module="search"
                        field="intervention"
                        options={Object.values(INTERVENTION_TYPES)}
                        onChange={onChangeInterventionType}
                        className="intervention-select mb-0"
                        initialValue={interventionType}
                        showArrow
                        selectsOption={interventionType}
                        showSearch={false}
                        checkbox
                      />
                    </div>
                    <div className="search-screen-more-filters">
                      <SelectFormField
                        mode="multiple"
                        initialValue={building}
                        module="search"
                        field="building"
                        options={Object.values(BUILDING_TYPE)}
                        onChange={onChangeBuilding}
                        className="building-select mb-0"
                        showSearch={false}
                        showArrow
                        selectsOption={building}
                        checkbox
                      />
                      <SelectFormField
                        mode="multiple"
                        initialValue={expertise}
                        module="search"
                        field="expertise"
                        options={Object.values(EXPERTISE)}
                        onChange={onChangeExpertise}
                        className="expertise-select mb-0"
                        showSearch={false}
                        showArrow
                        selectsOption={expertise}
                        checkbox
                      />
                    </div>
                  </>
                ) : (
                  <div className="search-screen-more-filters-button-container">
                    <div
                      className="search-screen-more-filters-button"
                      onClick={() => setIsMoreFiltersOpen(true)}
                    >
                      <PlusOutlined />
                      <span>{t<string>("search.more_filters")}</span>
                    </div>
                  </div>
                )}
              </div>
            </Spin>
            <div
              id="resultScrollableContainer"
              className={`search-screen-results ${
                isMoreFiltersOpen ? "more-filters-open" : ""
              }`}
            >
              {!searchOption.init ? (
                <div className="search-screen-results-list">
                  {width >= BREAKPOINTS.lg && (
                    <SearchFilterList
                      dataLoading={searchOption.isSearching}
                      data={searchResult}
                      searchResultComponent={GazProfessionalSearchResult}
                      onToggleContact={handleToggleContact}
                      setModalVisible={setIsContactModalVisible}
                      setSelectedGazProfessional={setSelectedGazProfessional}
                      contacts={contactList}
                      mapContact={mapContact}
                    />
                  )}
                </div>
              ) : (
                <div className="search-screen-results-help">
                  <h4>{t<string>("search.help.title")}</h4>
                  <div className="search-screen-results-help-steps">
                    <div className="search-screen-results-help-step">
                      <div className="search-screen-results-help-step-badge">
                        {t<string>("search.help.step1.title")}
                      </div>
                      <span>{t<string>("search.help.step1.description")}</span>
                    </div>
                    <div className="search-screen-results-help-step">
                      <div className="search-screen-results-help-step-badge">
                        {t<string>("search.help.step2.title")}
                      </div>
                      <span>{t<string>("search.help.step2.description")}</span>
                    </div>
                    <div className="search-screen-results-help-step">
                      <div className="search-screen-results-help-step-badge">
                        {t<string>("search.help.step3.title")}
                      </div>
                      <span>{t<string>("search.help.step3.description")}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {width < BREAKPOINTS.lg && !searchOption.init && (
            <>
              <BasicButton
                icon={<MenuOutlined />}
                text={t<string>("search.bottomSheet.button")}
                onClick={() => setIsBottomSheetHidden(false)}
                className="search-screen-results-bottom-sheet-button"
              />
              <BottomSheet
                title={t<string>("search.bottomSheet.title")}
                isHidden={isBottomSheetHidden}
                setIsHidden={setIsBottomSheetHidden}
              >
                <SearchFilterList
                  dataLoading={searchOption.isSearching}
                  data={searchResult}
                  searchResultComponent={GazProfessionalSearchResult}
                  setModalVisible={setIsContactModalVisible}
                  setSelectedGazProfessional={setSelectedGazProfessional}
                  onToggleContact={handleToggleContact}
                  canContact={false}
                  contacts={contactList}
                  fromBottomSheet
                  mapContact={mapContact}
                />
              </BottomSheet>
            </>
          )}
          {mapContact && (
            <div
              className={`search-screen-contact-details ${
                isMoreFiltersOpen ? "more-filters-open" : ""
              }`}
            >
              {width < BREAKPOINTS.lg && (
                <div className="search-screen-contact-details-return">
                  <div
                    className="search-screen-contact-details-return-link"
                    onClick={() => {
                      setMapContact(undefined);
                      setMarkerCurrentLocationId(undefined);
                    }}
                  >
                    <ArrowLeftOutlined /> {t<string>("search.details.return")}
                  </div>
                </div>
              )}
              <div className="search-screen-contact-details-header">
                <div className="search-screen-contact-details-title-container">
                  <h3>{mapContact.companyName}</h3>
                  {width >= BREAKPOINTS.lg && (
                    <div
                      className="search-screen-contact-details-header-close"
                      onClick={() => {
                        setMapContact(undefined);
                        setMarkerCurrentLocationId(undefined);
                      }}
                    >
                      <CloseOutlined />
                    </div>
                  )}
                </div>
                {(mapContact.installation || mapContact.maintenance) && (
                  <div className="search-screen-contact-details-type">
                    {mapContact.installation && (
                      <span className="search-screen-contact-details-type-label">
                        {t<string>("search.details.installation")}
                      </span>
                    )}
                    {mapContact.installation && mapContact.maintenance && (
                      <div className="search-screen-contact-details-type-separation" />
                    )}
                    {mapContact.maintenance && (
                      <span className="search-screen-contact-details-type-label">
                        {t<string>("search.details.maintenance")}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="search-screen-contact-details-body">
                <ContactItemDetails gazProfessionalItem={mapContact} />
              </div>
              {!mapContact.chimneyProfessional && (
                <div className="search-screen-contact-details-footer">
                  <BasicButton
                    text={t<string>("search.details.contact")}
                    onClick={() => {
                      setIsContactModalVisible(true);
                      setSelectedGazProfessional(mapContact);
                    }}
                    size={width >= BREAKPOINTS.lg ? "sm" : "md"}
                  />
                </div>
              )}
            </div>
          )}
          <div className="search-screen-map d-flex flex-column">
            {!searchOption.init ? (
              <MapLeaflet
                className="search-screen-map-leaflet flex-grow-1"
                markers={markers}
                markersContactList={markersContactList}
                markerPosition={markerPosition}
                markerCurrentLocationId={markerCurrentLocationId}
                scrollWheelZoom
                onMapDragEnd={onMapDragEnd}
                onMouseUp={onMouseUp}
                onMapZoomEnd={onMapZoomEnd}
                onClickMarker={onClickMarker}
                autoCenter={false}
                updateBounds={!searchOption.init && searchOption.updateBounds}
              />
            ) : (
              <img
                src="/img/leaflet/map-init.png"
                alt="Carte de la France avec des PGs"
                className="search-screen-map-img"
              />
            )}
          </div>
          <BasicModal
            className="search-screen-modal"
            title={t<string>("search.contacts.form.title")}
            open={isContactModalVisible}
            onCancel={() => setIsContactModalVisible(false)}
            footer={null}
          >
            <>
              {selectedGazProfessional && (
                <ProfessionalContactForm
                  originForm={ContactEnum.CARTE}
                  initSearchOption={{
                    selectedAddressLabel:
                      searchOption.currentLocation?.addressLabel ?? "",
                    selectedInterventionType:
                      interventionType?.[0] ?? undefined,
                  }}
                  gazProfessional={selectedGazProfessional}
                  setContactModalVisible={setIsContactModalVisible}
                  setContactList={setContactList}
                  contactList={contactList}
                />
              )}
            </>
          </BasicModal>
        </div>
      </>
    </PageLayout>
  );
};
