import { FunctionComponent } from "react";
import Section from "@components/section/Section";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import Slider, { Settings } from "react-slick";
import configuration from "@utils/Config";
import { partners } from "@utils/enums/gaz.event.partners";

interface PartnersSectionProps {
  className?: string;
}

const settings: Settings = {
  dots: false,
  arrows: true,
  draggable: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const PartnersSection: FunctionComponent<PartnersSectionProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const gazEventType = configuration.gazEventType;

  return (
    <div className={`app-section-partners ${className}`}>
      <div className="content-contained">
        <Section
          title={t<string>(`${gazEventType}.individual.partners.title`)}
          titleClassName="h1"
          type="h1"
        >
          <>
            <div className="partners-subtitle pt-10">
              {t<string>(`${gazEventType}.individual.partners.subtitle`)}
            </div>

            <div className="slider-container">
              <Slider {...settings}>
                {partners.map((partner, index) => (
                  <div
                    key={index.valueOf()}
                    className="partners-img d-flex align-items-center justify-content-center px-4"
                  >
                    <img
                      src={partner.logo}
                      alt={partner.name}
                      className="max-w-h-100"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="partners-divider d-flex justify-content-center">
              <BasicDivider height={2} variant="light-grey" />
            </div>
          </>
        </Section>
      </div>
    </div>
  );
};

export default PartnersSection;
