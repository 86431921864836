import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import Section from "@components/section/Section";
import { columns } from "@components/lists/OPRGRequestsColumns";
import { RGRequestType } from "@utils/enums/qualifications.rg-requests.type.enum";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { Name } from "@utils/name-utils";
import toastAlert from "@components/alerts/ToastAlert";
import { OrganisationProfessionalRGRequestItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.item.response.dto";
import {
  requestGetOrganisationProfessionalRGRequest,
  requestGetOrganisationProfessionalRGRequests,
  requestUpdateOrganisationProfessionalRGRequest,
} from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { SorterResult } from "antd/es/table/interface";
import { Pageable } from "@type/pagination/pagination.types";
import DropdownFilter from "@components/filters/DropdownFilter";
import SearchFormField from "@components/inputs/SearchFormField";
import {
  RG_REQUEST_FILTERS_STATUS,
  RG_REQUEST_FILTERS_TYPE,
} from "@utils/enums/rgrequest.filters.enum";
import { OptionType } from "@type/form/field.types";
import { Loader } from "@components/loader/Loader";
import { emptySearchResult } from "@utils/Constant";
import RGRequestModalSwitch from "@components/switch/RGRequestModalSwitch";
import {
  maptoRgRequestSpeciality,
  maptoRgRequestStatus,
  maptoRgRequestType,
} from "@utils/rgRequestMappers";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/qualifications.rg-requests.speciality.enum";
import { RgRequestStatus } from "@utils/enums/qualifications.rg-requests.status.enum";

export interface RGRequestItem {
  id: string;
  pgId: number;
  creationDate: string;
  reference: string;
  typeLabel: string;
  rg: string;
  status: RgRequestStatus;
  type: RGRequestType;
  isInstallation: boolean;
}

export const RequestsOrganisationProfessionalScreen: React.FC = () => {
  const { t } = useTranslation();

  const searchTimeout = useRef<number>();
  const [localQuery, setLocalQuery] = useState<string>("");
  const [selectedType, setSelectedType] = useState<OptionType>();
  const [selectedStatus, setSelectedStatus] = useState<OptionType>();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [showRGRequestModal, setShowRGRequestModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<RGRequestType>();
  const [modalMode, setModalMode] = useState<ModalMode>();

  const [selectedRGRequestId, setSelectedRGRequestId] = useState<string>("");
  const [isInstallation, setIsInstallation] = useState<boolean>(false);
  const [nameRG, setNameRG] = useState<Name>({
    firstName: "",
    lastName: "",
  });

  const [rgRequests, setRgRequests] =
    useState<Pageable<OrganisationProfessionalRGRequestItemResponseDto>>(
      emptySearchResult,
    );

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "creationDate",
    order: "descend",
  });

  const fetchRGRequests = useCallback(() => {
    setDataHasLoaded(false);
    requestGetOrganisationProfessionalRGRequests({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {
        query: localQuery,
        type: selectedType?.value,
        status: selectedStatus?.value,
      },
    }).catch(() => {
      toastAlert(t<string>("pro.qualifications.error"));
    });
  }, [pageNumber, limit, t, sorter, localQuery, selectedType, selectedStatus]);

  useEffect(() => {
    fetchRGRequests();
  }, [fetchRGRequests]);

  useEffect(() => {
    return requestGetOrganisationProfessionalRGRequests.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setRgRequests(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  useEffect(() => {
    return requestUpdateOrganisationProfessionalRGRequest.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setShowRGRequestModal(false);
          fetchRGRequests();
        }
      },
    );
  });

  function mapToRequest(
    rgRequest: OrganisationProfessionalRGRequestItemResponseDto,
  ) {
    const type = maptoRgRequestType(rgRequest.type);
    const speciality = maptoRgRequestSpeciality(rgRequest.speciality);
    const status = maptoRgRequestStatus(rgRequest.status);
    return {
      id: rgRequest.id,
      pgId: rgRequest.pgId,
      creationDate: rgRequest.creationDate,
      reference: rgRequest.reference,
      typeLabel: `${t(type.label)} ${t(speciality.label)}`,
      rg: `${rgRequest.lastName} ${rgRequest.firstName}`,
      status,
      type,
      isInstallation: speciality === RG_REQUEST_SPECIALITY.INSTALLATION,
    };
  }

  function buildRequests() {
    return rgRequests.content.map((rgRequest) => mapToRequest(rgRequest));
  }

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setLocalQuery(value), 800);
  };

  const fetchRGRequest = (id: string) => {
    requestGetOrganisationProfessionalRGRequest(id).catch(() => {
      toastAlert(t<string>("pro.qualifications.modals.details.error.toast"));
    });
  };

  return (
    <PageLayout
      noProSideMenu
      organisationProSideMenu
      container
      titleCentered={false}
      title={t<string>("organisationprofessional.requests.title")}
    >
      <>
        <Section
          title={t("pro.qualifications.requests.title")}
          titleClassName="text-primary"
          rightContent={
            <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
              <DropdownFilter
                label={t<string>(
                  "organisationprofessional.requests.filters.type.label",
                )}
                items={RG_REQUEST_FILTERS_TYPE}
                selectedItem={selectedType}
                setSelectedItem={setSelectedType}
              />
              <DropdownFilter
                label={t<string>(
                  "organisationprofessional.requests.filters.status.label",
                )}
                items={RG_REQUEST_FILTERS_STATUS}
                selectedItem={selectedStatus}
                setSelectedItem={setSelectedStatus}
              />
              <SearchFormField
                module="organisationprofessional.requests"
                field="search"
                onChange={onChange}
                onSearch={(value: string) => setLocalQuery(value)}
                value={localQuery}
              />
            </div>
          }
          className="mt-40"
        >
          <Table
            locale={{
              emptyText: <Empty description={t<string>("common.noData")} />,
            }}
            columns={columns(
              setShowRGRequestModal,
              setModalType,
              setModalMode,
              setIsInstallation,
              setNameRG,
              setSelectedRGRequestId,
              fetchRGRequest,
            )}
            dataSource={buildRequests()}
            loading={{
              spinning: !dataHasLoaded,
              indicator: <Loader />,
            }}
            size="small"
            className="app-contact-pro-table pro-table-cursor-default"
            pagination={{
              total: rgRequests.totalElements,
              pageSize: rgRequests.size,
              current: rgRequests.number + 1,
              onChange: setPageNumber,
              onShowSizeChange: onChangeLimit,
              showSizeChanger: true,
            }}
            onChange={(pagination, filters, sorter) =>
              setSorter(sorter as SorterResult<any>)
            }
          />
        </Section>

        {modalMode != undefined && (
          <RGRequestModalSwitch
            visible={showRGRequestModal}
            onCancel={() => setShowRGRequestModal(false)}
            isOPSection
            modalType={modalType}
            modalMode={modalMode}
            rgRequestId={selectedRGRequestId}
            isInstallationSection={isInstallation}
            name={nameRG}
          />
        )}
      </>
    </PageLayout>
  );
};
