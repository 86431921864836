import { FunctionComponent } from "react";
import { DataBlockItem } from "@type/cms/cms.types";

const DataBlock: FunctionComponent<DataBlockItem> = ({
  content = "",
  className = "",
  description = undefined,
  title = undefined,
  compact = false,
}) => {
  return (
    <div
      className={`content-block-container ${className} ${
        compact ? "content-block-compact" : ""
      }`}
    >
      <div className={"data-block-content"}>
        <h2>{content}</h2>
      </div>
      {title && <span className={"data-block-title"}>{title}</span>}
      {description && (
        <span className={"data-block-description"}>{description}</span>
      )}
    </div>
  );
};

export default DataBlock;
