import { FC } from "react";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";

interface Props {
  text: string;
  url: string;
  variant?: string;
  size?: "sm" | "md" | "lg";
  btnClassName?: string;
}

const ButtonModuleCMS: FC<Props> = ({
  text,
  url,
  variant,
  size,
  btnClassName = "",
}) => {
  const navigate = useNavigate();
  return (
    <BasicButton
      variant={variant}
      size={size ? size : "md"}
      text={text}
      className={`my-2 mx-0 mx-md-3 ${btnClassName}`}
      onClick={() => {
        if (url) {
          if (url.startsWith("/")) {
            navigate(url);
          } else if (url.startsWith("http")) {
            window.open(url);
          } else {
            window.open(`https://${url}`);
          }
        }
      }}
    />
  );
};

export default ButtonModuleCMS;
