import { FunctionComponent } from "react";
import { Progress, Tooltip } from "antd";

interface ProfileProgressProps {
  title: string | JSX.Element;
  status?: string | JSX.Element;
  percentage: number;
  className?: string;
  tooltipMessage?: string;
}

const ProfileProgress: FunctionComponent<ProfileProgressProps> = ({
  title,
  status,
  percentage,
  className = "",
  tooltipMessage,
}) => {
  return (
    <div className={`app-profile-progress ${className}`}>
      <div className="d-flex align-items-center flex-wrap">
        <div className="profile-progress-title">{title}</div>
        <div className="profile-progress-status">{status}</div>
      </div>
      <Tooltip title={tooltipMessage}>
        <Progress
          percent={percentage}
          showInfo={false}
          className="profile-progress-bar"
        />
      </Tooltip>
    </div>
  );
};

export default ProfileProgress;
