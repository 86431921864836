import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { ROUTES } from "@utils/Routes";

interface SideMenuOrganisationProfessionalProps {
  className?: string;
}

const SideMenuOrganisationProfessional: FunctionComponent<
  SideMenuOrganisationProfessionalProps
> = ({ className = "" }) => {
  const { t } = useTranslation();
  const [proSelectedKey, setProSelectedKey] = useState<string>();
  const [isMobileProSideMenuOpen, setIsMobileProSideMenuOpen] =
    useState<boolean>(false);

  const menu = [
    {
      key: "tools-op",
      link: ROUTES.professional.organisationProfessional.tools.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-dashboard.svg"
          alt={t<string>("menu.alt.op.tools")}
        />
      ),
      text: t("menu.op.tools"),
    },
    {
      key: "pgSubscribers-op",
      link: ROUTES.professional.organisationProfessional.adherentsPg.home.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-subscribers.svg"
          alt={t<string>("menu.alt.op.pgSubscribers")}
        />
      ),
      text: t("menu.op.pgSubscribers"),
    },
    {
      key: "rg-op",
      link: ROUTES.professional.organisationProfessional.rg.home.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-qualifications.svg"
          alt={t<string>("menu.alt.op.rg")}
        />
      ),
      text: t("menu.op.rg"),
    },
    {
      key: "mails-op",
      link: ROUTES.professional.organisationProfessional.mailOp.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-envelope.svg"
          alt={t<string>("menu.alt.op.mails")}
        />
      ),
      text: t("menu.op.mails"),
    },
    {
      key: "requests-op",
      link: ROUTES.professional.organisationProfessional.requests.generate(),
      icon: (
        <img
          src="/img/pro/sidemenu-requests.svg"
          alt={t<string>("menu.alt.op.requests")}
        />
      ),
      text: t("menu.op.requests"),
    },
  ];

  useEffect(() => {
    const currentItem = menu.find(
      (item) =>
        window.location.pathname.split("/")[3] === item.link.split("/")[3],
    );
    if (currentItem) setProSelectedKey(currentItem.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, menu]);

  return (
    <>
      <div
        className={`app-sidemenu-gazprofessional-overlay ${
          isMobileProSideMenuOpen ? "d-block" : "d-none"
        }`}
        onClick={() => setIsMobileProSideMenuOpen(false)}
      />
      <BasicButton
        variant="pro-side-menu"
        className={isMobileProSideMenuOpen ? "pro-side-menu-open" : ""}
        icon={isMobileProSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        onClick={() => setIsMobileProSideMenuOpen(!isMobileProSideMenuOpen)}
      />
      <div
        className={`app-sidemenu-gazprofessional d-flex flex-column ${
          isMobileProSideMenuOpen ? "mobile-sidemenu-open" : ""
        } ${className}`}
      >
        <div className="flex-grow-1">
          <>
            {menu.map((item) => (
              <Link
                key={item.key}
                to={item.link}
                className={`link d-flex align-items-center ${
                  proSelectedKey === item.key ? "selected" : ""
                }`}
              >
                <div className="icon">{item.icon}</div>
                <div className="text">{item.text}</div>
              </Link>
            ))}
          </>
        </div>
      </div>
    </>
  );
};

export default SideMenuOrganisationProfessional;
