import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "@utils/Config";
import { restCreationHandler, restUpdateHandler } from "@utils/rest/RestClient";
import { GazEventRegistrationSaveRequestDto } from "@state/gazMonth/pro/dto/gazEvent.save.request.dto";
import { GazEventRegistrationResponseDto } from "@state/gazMonth/dto/gaz.event.registration.response.dto";

const baseUrl = configuration.backendBaseUrl;
const gazMonthRegistrationUrl = baseUrl + "/gaz-event-registration";

export const requestCreateGazEventRegistration = createEffect({
  handler: restCreationHandler<
    GazEventRegistrationSaveRequestDto,
    GazEventRegistrationResponseDto
  >(gazMonthRegistrationUrl),
});
export const requestUpdateGazEventRegistration = createEffect({
  handler: restUpdateHandler<
    GazEventRegistrationSaveRequestDto,
    GazEventRegistrationResponseDto
  >(gazMonthRegistrationUrl),
});
