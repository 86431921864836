import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import BaseForm from "@components/forms/BaseForm";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import { emailFormatRegex, phoneNumberFormatRegex } from "@utils/Constant";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import { Store } from "rc-field-form/lib/interface";
import { Form } from "antd";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { requestCreateCampaignContact } from "@state/campaign/CampaignEffects";
import {
  CAMPAIGN_CURRENT_ENERGIES,
  CAMPAIGN_EQUIPMENT_TYPES,
  CAMPAIGN_INTERVENTION_TYPES,
} from "@utils/enums/campaign.enum";

import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import { useStore } from "effector-react";
import { contactGazProfessionalStore } from "@state/contact/gazprofessional/ContactGazProfessionalStore";
import { toastError } from "@utils/toast-helper";

interface IndividualGazEventFormProps {
  className?: string;
}

const IndividualGazEventForm: FunctionComponent<
  IndividualGazEventFormProps
> = ({ className = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const contactInfo = useStore(contactGazProfessionalStore);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressFieldsDto>();

  const handleSubmit = (values: Store): void => {
    setIsLoading(true);
    const saveRequest = {
      firstname: values.firstName as string,
      lastname: values.lastName as string,
      email: values.email as string,
      phoneNumber: (values.phone as string).replace(/\s/g, ""),
      houseNumber: selectedAddress?.properties.housenumber as string,
      street: (selectedAddress?.properties.street ??
        selectedAddress?.properties.name) as string,
      city: selectedAddress?.properties.city as string,
      zipCode: selectedAddress?.properties.postcode as string,
      intervention: values.interventionType as string,
      energy: values?.currentEnergy as string,
      equipments: values?.equipmentTypes as string[],
      message: values.message as string,
      inTiers: values.callbackGrdf as boolean,
      medium: contactInfo.medium,
      url: contactInfo.url ?? undefined,
      lp: "LP FIL ROUGE",

      source: contactInfo.source ?? "OTHER",
      campaign: contactInfo.campaign,
    };
    void requestCreateCampaignContact({
      dto: saveRequest,
    })
      .then((result) => {
        if (result.ok) {
          navigate(ROUTES.individual.gazMonth.confirmation.generate());
        } else if (result.responseCode === 400) {
          toastError(
            t<string>(
              "gazMonth.individual.home.form.right.form.messages.invalid",
            ),
          );
        } else {
          toastError(
            t<string>(
              "gazMonth.individual.home.form.right.form.messages.error",
            ),
          );
        }
      })
      .catch(() =>
        toastError(
          t<string>("gazMonth.individual.home.form.right.form.messages.error"),
        ),
      );
    setIsLoading(false);
  };

  const FORM = {
    main: {
      key: "main",
      module: "gazMonth.individual.home.form.right",
      title: "form.title",
      fields: [
        [
          {
            name: "lastName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "firstName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            pattern: new RegExp(emailFormatRegex),
            htmlInputType: "email",
          },
          {
            name: "phone",
            type: FORMS_FIELD_TYPE.CUSTOM,
            custom: (
              <MaskInputFormField
                emptyErrorMessage={t<string>(
                  "gazMonth.individual.home.form.right.form.fields.phone.errors.required",
                )}
                type="tel"
                required
                mask="## ## ## ## ##"
                showLabel
                module="gazMonth.individual.home.form.right"
                field="phone"
                rules={[
                  {
                    pattern: new RegExp(phoneNumberFormatRegex),
                    message: t<string>("forms.errors.format"),
                  },
                  {
                    min: 14,
                    message: t<string>("forms.errors.length", { size: "10" }),
                  },
                ]}
              />
            ),
            size: 12,
          },
          {
            name: "address",
            type: FORMS_FIELD_TYPE.CUSTOM,
            size: 24,
            required: true,
            custom: (
              <AddressFields
                module="gazMonth.individual.home.form.right"
                field="address"
                showLabel
                setSelectedAddress={setSelectedAddress}
                emptyErrorMessage={t<string>(
                  "gazMonth.individual.home.form.right.form.fields.address.errors.required",
                )}
              />
            ),
          },
          {
            name: "interventionType",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_INTERVENTION_TYPES),
            size: 12,
          },
          {
            name: "currentEnergy",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_CURRENT_ENERGIES),
            size: 12,
          },
          {
            name: "equipmentTypes",
            type: FORMS_FIELD_TYPE.MULTISELECT,
            required: true,
            options: Object.values(CAMPAIGN_EQUIPMENT_TYPES),
          },
          {
            name: "message",
            type: FORMS_FIELD_TYPE.TEXTAREA,
            size: 24,
            retractable: true,
            maxLength: 3000,
          },
          {
            name: "callbackGrdf",
            type: FORMS_FIELD_TYPE.CHECKBOX,
            required: true,
            size: 24,
            parsed: true,
          },
        ],
      ],
    },
  };

  return (
    <div className={`app-individual-gaz-month-form ${className}`}>
      <div className="individual-gaz-month-form-container content-contained">
        <div className="individual-gaz-month-form-left">
          <h3 className="individual-gaz-month-form-left-title">
            {t<string>("gazMonth.individual.home.form.left.title")}
          </h3>
          <div className="individual-gaz-month-form-left-infos">
            <div className="individual-gaz-month-form-left-info">
              <div className="individual-gaz-month-form-left-info-icon">
                <img
                  src="/img/gazMonth/form/promotion.svg"
                  alt={t<string>("gazMonth.individual.home.form.left.alt1")}
                />
              </div>
              <div className="individual-gaz-month-form-left-info-text">
                {parse(
                  t<string>("gazMonth.individual.home.form.left.description1"),
                )}
              </div>
            </div>
            <div className="individual-gaz-month-form-left-info">
              <div className="individual-gaz-month-form-left-info-icon">
                <img
                  src="/img/gazMonth/form/greenGaz.png"
                  alt={t<string>("gazMonth.individual.home.form.left.alt2")}
                />
              </div>
              <div className="individual-gaz-month-form-left-info-text">
                {parse(
                  t<string>("gazMonth.individual.home.form.left.description2"),
                )}
              </div>
            </div>
            <div className="individual-gaz-month-form-left-info">
              <div className="individual-gaz-month-form-left-info-icon">
                <img
                  src="/img/gazMonth/form/quality.svg"
                  alt={t<string>("gazMonth.individual.home.form.left.alt3")}
                />
              </div>
              <div className="individual-gaz-month-form-left-info-text">
                {parse(
                  t<string>("gazMonth.individual.home.form.left.description3"),
                )}
              </div>
            </div>
            <div className="individual-gaz-month-form-left-info">
              <div className="individual-gaz-month-form-left-info-icon">
                <img
                  src="/img/gazMonth/form/service.svg"
                  alt={t<string>("gazMonth.individual.home.form.left.alt4")}
                />
              </div>
              <div className="individual-gaz-month-form-left-info-text">
                {parse(t("gazMonth.individual.home.form.left.description4"))}
              </div>
            </div>
          </div>
        </div>
        <div className="individual-gaz-month-form-right">
          <h2 className="individual-gaz-month-form-right-title">
            {parse(t("gazMonth.individual.home.form.right.title"))}
          </h2>
          <div className="individual-gaz-month-form-right-subtitle">
            {parse(t("gazMonth.individual.home.form.right.subtitle"))}
          </div>
          <BaseForm
            module="gazMonth.individual.home.form.right"
            form={form}
            editMode={true}
            formConfiguration={FORM}
            hasBackButton={false}
            hasConfirmPopup={false}
            customOnSubmitCallback={handleSubmit}
            loading={isLoading}
            buttonSize="lg"
            buttonCentered
          />
        </div>
      </div>
    </div>
  );
};

export default IndividualGazEventForm;
