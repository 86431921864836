import { FunctionComponent } from "react";
import { CloseOutlined } from "@ant-design/icons";

interface BasicTagProps {
  label: string;
  icon?: JSX.Element;
  variant?: string;
  className?: string;
  onDeleteClick?: () => any;
}

const BasicTag: FunctionComponent<BasicTagProps> = ({
  label,
  icon,
  variant = "",
  className = "",
  onDeleteClick = null,
}) => {
  return (
    <div
      className={`app-tag tag-${variant} d-flex align-items-end justify-content-center ${className}`}
    >
      <div className="tag-label">{label}</div>
      {icon && <div className="tag-icon">{icon}</div>}
      {onDeleteClick && (
        <div
          className="tag-button d-flex align-items-center justify-content-center"
          onClick={onDeleteClick}
        >
          <CloseOutlined />
        </div>
      )}
    </div>
  );
};

export default BasicTag;
