import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";

interface ToolsFileCardProps {
  onClick?: () => any;
  icon: JSX.Element;
  title: string | JSX.Element;
  description: string | JSX.Element;
  className?: string;
  thumbnail?: boolean;
}

const ToolsFileCard: FunctionComponent<ToolsFileCardProps> = ({
  onClick,
  icon,
  title,
  description,
  className = "",
  thumbnail,
}) => {
  return (
    <BasicCard
      className={`app-card-tools-file d-flex flex-column align-items-center justify-content-center ${className}`}
      onClick={onClick}
    >
      <>
        <div
          className={`${
            thumbnail ? "card-tools-file-thumbnail" : "card-tools-file-icon"
          }`}
        >
          {icon}
        </div>
        <div className="card-tools-file-container flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <h4 className="card-tools-file-title">{title}</h4>
          <div className="card-tools-file-description">{description}</div>
        </div>
      </>
    </BasicCard>
  );
};

export default ToolsFileCard;
