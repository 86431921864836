import { FC } from "react";
import {
  BlockInput,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";

interface Props {
  block: PageBlockInfoResponseDto;
}

const Button: FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const navigate = useNavigate();
  return (
    <div
      className="app-button-group"
      style={{ textAlign: (inputs[2].content as CanvasTextAlign) || "center" }}
    >
      <BasicButton
        text={inputs[0].content as string}
        className="m-2"
        onClick={() => {
          const url = inputs[1].content as string;
          if (url) {
            if (url.startsWith("/")) {
              navigate(url);
            } else if (url.startsWith("http")) {
              window.open(url);
            } else {
              window.open(`https://${url}`);
            }
          }
        }}
      />
    </div>
  );
};

export default Button;
