import React, { Fragment, FunctionComponent } from "react";
import {
  BlockInput,
  ImageContent,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import RichTextModuleCMS from "@components/cms/RichTextModuleCMS";
import ImageModuleCMS from "@components/cms/ImageModuleCMS";
import ReinsuranceImage from "@components/block/ReinsuranceImage";
import ReinsuranceKeyData from "@components/block/ReinsuranceKeyData";
import Rebond from "@components/block/Rebond";
import Button from "@components/block/Button";
import TwoColumns from "@components/block/TwoColumns";
import News from "@components/block/widget/News";
import CollapseBlock from "@components/block/collapse/CollapseBlock";
import Html from "@components/block/Html";
import Files from "@components/block/Files";

export interface BlockOptionProps {
  block: PageBlockInfoResponseDto;
}

export interface Props {
  block: PageBlockInfoResponseDto;
}

const PageBlock: React.FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];

  type BlockOptions = {
    [key: string]: FunctionComponent<BlockOptionProps>;
  };

  const blockMapping: BlockOptions = {
    reinsuranceImage: ReinsuranceImage,
    reinsuranceKeyData: ReinsuranceKeyData,
    rebond: Rebond,
    twoColumns: TwoColumns,
    button: Button,
    news: News,
    collapse: CollapseBlock,
    html: Html,
    files: Files,
  };

  const SelectBlock = () => {
    const BlockElement = blockMapping[block.blockId];
    return BlockElement ? (
      <BlockElement block={block} />
    ) : (
      <div className="px-2rem container max-w-920">
        {inputs.map((input: BlockInput) => {
          return (
            <Fragment key={block.id}>
              {input.inputType === "TEXT" && input.content && (
                <RichTextModuleCMS content={input.content as string} />
              )}
              {input.inputType === "IMG" && input.content && (
                <ImageModuleCMS
                  imgClassName="image-block"
                  src={(input.content as ImageContent).content}
                  alt={(input.content as ImageContent).alt}
                  title={(input.content as ImageContent).title}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    );
  };

  return <SelectBlock />;
};

export { PageBlock };
