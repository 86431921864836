import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { requestGetStructure } from "@state/structure/StructureEffects";
import { setStructure } from "@state/structure/StructureEvents";
import { structureStore } from "@state/structure/StructureStore";
import {
  RootStructure,
  StructureDto,
} from "@state/structure/dto/structure.dto";
import { authentifiedStore } from "@state/auth/AuthStore";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { toastError } from "@utils/toast-helper";

export const useStructures = (): RootStructure => {
  const { t } = useTranslation();

  const structure = useStore(structureStore);
  const universe = useStore(authentifiedStore).universe;

  const fetchStructures = useCallback(() => {
    requestGetStructure({}).catch(() => {
      toastError(t<string>("structure.error.loading"));
    });
  }, [t]);

  useEffect(() => {
    if (structure.length === 0) {
      fetchStructures();
    }
  }, [structure, fetchStructures]);

  useEffect(() => {
    return requestGetStructure.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setStructure(result.data);
      }
    });
  });
  if (structure.length <= 1) {
    return { universe: [], footer: [] };
  }

  const rootStructureUniverse =
    universe.value === UNIVERSES.INDIVIDUAL.value
      ? structure.find((s) => s.key == "INDIVIDUAL")
      : structure.find((s) => s.key == "PROFESSIONAL");

  const rootStructureFooter = structure.find((s) => s.key == "FOOTER");

  return {
    universe: rootStructureUniverse?.children ?? [],
    footer: rootStructureFooter?.children ?? [],
  };
};

export const findStructureFromSeoFriendlyTitle = (
  categorySeoFriendlyTitle: string,
  arr: StructureDto[],
): StructureDto | undefined => {
  let child = undefined;
  for (const item of arr) {
    if (item.seoFriendlyTitle === categorySeoFriendlyTitle) return item;
    if (item.children) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      child = findStructureFromSeoFriendlyTitle(
        categorySeoFriendlyTitle,
        item.children,
      );
      if (child) return child;
    }
  }
  return undefined;
};
