import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";
import { useViewport } from "@utils/breakpoints-helper";
import parse from "html-react-parser";

const { Step } = Steps;

interface GreenGasCardProps {
  className?: string;
}

const GreenGasCard: FunctionComponent<GreenGasCardProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const { width } = useViewport();

  return (
    <BasicCard className={`app-card-green-gas ${className}`}>
      <>
        <div className="d-flex align-items-center justify-content-end flex-wrap">
          <div className="flex-grow-1">
            <div className="card-green-gas-title">
              {t<string>("pro.greenGas.cards.title")}
            </div>
          </div>
          <>
            <Steps
              current={3}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              <Step key={1} title={t<string>("pro.greenGas.cards.step1")} />
              <Step
                key={2}
                title={parse(t<string>("pro.greenGas.cards.step2"))}
              />
              <Step key={3} title={t<string>("pro.greenGas.cards.step3")} />
            </Steps>
          </>
        </div>
      </>
    </BasicCard>
  );
};

export default GreenGasCard;
