import React, { FunctionComponent, useEffect, useState } from "react";
import { List, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import { SearchResultDto } from "@type/list/search-result-dto-interface";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import InfiniteScroll from "react-infinite-scroll-component";

export interface SearchResultComponentProps {
  item: SearchResultDto;
  onToggleContact?: (entity: GazProfessionalItemResponseDto) => void;
  canContact?: boolean;
  search?: () => void;
  inContactList?: boolean;
  setSelectedGazProfessional?: (entity: GazProfessionalItemResponseDto) => void;
  setModalVisible?: (item: boolean) => void;
  mapContact?: GazProfessionalItemResponseDto;
}

interface SearchFilterListProps {
  data: SearchResultDto[];
  className?: string;
  searchResultComponent: FunctionComponent<SearchResultComponentProps>;
  dataLoading: boolean;
  search?: () => void;
  onToggleContact?: (entity: GazProfessionalItemResponseDto) => void;
  canContact?: boolean;
  contacts: SearchResultDto[];
  setModalVisible?: (item: boolean) => void;
  setSelectedGazProfessional?: (entity: GazProfessionalItemResponseDto) => void;
  fromBottomSheet?: boolean;
  mapContact?: GazProfessionalItemResponseDto;
}

const SearchFilterList: FunctionComponent<SearchFilterListProps> = ({
  data,
  className = "",
  searchResultComponent,
  dataLoading,
  search,
  onToggleContact,
  canContact,
  contacts,
  setModalVisible,
  setSelectedGazProfessional,
  fromBottomSheet = false,
  mapContact,
}) => {
  const { t } = useTranslation();
  const LIMIT_PER_PAGE = 20;
  const [loading, setLoading] = useState(false);
  const [listResult, setListResult] = useState<SearchResultDto[]>([]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setListResult([
        ...listResult,
        ...data.slice(listResult.length, listResult.length + LIMIT_PER_PAGE),
      ]);
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const top = window.document.getElementById("resultListContainer");
    top?.scrollIntoView();
    setListResult(data.slice(0, LIMIT_PER_PAGE));
  }, [data]);

  return (
    <InfiniteScroll
      next={loadMoreData}
      hasMore={listResult.length < data.length}
      loader={
        <div className="search-screen-results-list-skeleton">
          <Skeleton paragraph={{ rows: 1 }} active />
        </div>
      }
      dataLength={listResult.length}
      scrollableTarget={
        fromBottomSheet
          ? "bottomSheetScrollableContainer"
          : "resultScrollableContainer"
      }
    >
      <List
        id="resultListContainer"
        locale={{
          emptyText: (
            <div className="search-screen-results-list-empty">
              <h4>{t<string>("search.list.noDataTitle")}</h4>
              <p>{t<string>("search.list.noDataDescription1")}</p>
              <p>{t<string>("search.list.noDataDescription2")}</p>
            </div>
          ),
        }}
        loading={dataLoading}
        className={`list-filter-search ${className}`}
        itemLayout="vertical"
        size="large"
        pagination={false}
        dataSource={listResult}
        renderItem={(item: SearchResultDto) =>
          React.createElement(searchResultComponent, {
            key: item.id,
            item,
            canContact,
            onToggleContact,
            setSelectedGazProfessional,
            search,
            inContactList: contacts.map((m) => m.id).includes(item.id),
            setModalVisible,
            mapContact,
          })
        }
      />
    </InfiniteScroll>
  );
};

export default SearchFilterList;
