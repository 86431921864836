const renderIconOC = (id: string) => {
  let image,
    title = "";
  if (id === "2") {
    image = "icon-qualigaz.png";
    title = "Qualigaz";
  } else if (id === "3") {
    image = "icon-dekra.png";
    title = "Dekra";
  } else if (id === "4") {
    image = "icon-copraudit.png";
    title = "COPRAUDIT";
  }
  return image ? <img src={`/img/pro/${image}`} alt={title} /> : null;
};

export const auditUtils = {
  renderIconOC,
};
