import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import config from "@utils/Config";

interface LandingPageTempsFortConfirmationHeaderProps {
  className?: string;
  landingPageType: string;
}

const LandingPageTempsFortConfirmationHeader: FunctionComponent<
  LandingPageTempsFortConfirmationHeaderProps
> = ({ className = "", landingPageType }) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div
      className={`app-rentree-du-chauffage-confirmation-header temps-fort-confirmation-header ${className}`}
    >
      <div className="header-container content-contained">
        <div className="header-logo">
          <img
            alt={t<string>(
              `landingPage.${landingPageType}.home.conditions.pg.alt`,
            )}
            src="/logo-header-PG-individual.svg"
          />
          <img
            alt={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.header.rentreeDuChauffage.alt`,
            )}
            src={
              gazEventType === "gazMonth"
                ? "/img/gazMonth/logo-gaz-month-confirmation.svg"
                : "/img/rentreeDuChauffage/logo-rentree-chauffage.svg"
            }
            className="logo-rentree"
          />
        </div>
        <div className="header-content">
          <p className="header-content-subtitle">
            {t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.header.subtitleConfirmation`,
            )}
          </p>
          <h1 className="header-content-title">
            {parse(
              t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.titleConfirmation`,
              ),
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LandingPageTempsFortConfirmationHeader;
