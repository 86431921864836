import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import Section from "@components/section/Section";
import { partners } from "@utils/enums/gaz.event.partners";

interface IndividualGazEventPartnersProps {
  className?: string;
}

const IndividualGazEventPartners: FunctionComponent<
  IndividualGazEventPartnersProps
> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={`app-individual-gaz-month-partners ${className}`}>
      <div className="content-contained">
        <Section
          title={t<string>("gazMonth.individual.home.partners.title")}
          titleClassName="text-center text-sm-start"
        >
          <div className="individual-gaz-month-partners-container">
            {partners.map((partner, index) => (
              <div
                key={index.valueOf()}
                className="individual-gaz-month-partners-img"
              >
                <img src={partner.logo} alt={partner.name} />
              </div>
            ))}
          </div>
        </Section>
      </div>
    </div>
  );
};

export default IndividualGazEventPartners;
