import {
  Dispatch,
  FunctionComponent,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { motion, useAnimation, useMotionValue, PanInfo } from "framer-motion";
import { CloseOutlined } from "@ant-design/icons";

function usePrevious(value: boolean) {
  const previousValueRef: MutableRefObject<boolean | undefined> = useRef();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
}

interface BottomSheetProps {
  title: string;
  children: string | JSX.Element;
  isHidden: boolean;
  setIsHidden: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

const BottomSheet: FunctionComponent<BottomSheetProps> = ({
  title,
  children,
  isHidden,
  setIsHidden,
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const y = useMotionValue(0);

  function onClose() {
    setIsOpen(false);
  }

  function onOpen() {
    setIsOpen(true);
  }

  const prevIsOpen = usePrevious(isOpen);
  const controls = useAnimation();

  function onDragEnd(
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) {
    const shouldClose =
      info.velocity.y > 20 || (info.velocity.y >= 0 && info.point.y > 45);
    if (shouldClose) {
      void controls.start("closed");
      onClose();
    } else {
      void controls.start("open");
      onOpen();
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      void controls.start("closed");
    } else if (!prevIsOpen && isOpen) {
      void controls.start("open");
    } else if (!isHidden) {
      void controls.start("closed");
    } else if (isHidden) {
      void controls.start("hidden");
    }
  }, [controls, isOpen, isHidden, prevIsOpen]);

  return (
    <motion.div
      drag="y"
      onDragEnd={onDragEnd}
      initial="hidden"
      animate={controls}
      transition={{
        type: "spring",
        damping: 40,
        stiffness: 400,
      }}
      variants={{
        hidden: {
          y: "100%",
        },
        closed: {
          y: "322px",
        },
        open: {
          y: 0,
        },
      }}
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragElastic={0.2}
      className={`app-bottom-sheet ${
        isOpen ? "bottom-sheet-open" : ""
      } ${className}`}
      style={{ y }}
    >
      <div className="bottom-sheet-navbar">
        <motion.div
          animate={controls}
          className="bottom-sheet-navbar-drag-handle"
        />
        <span className="bottom-sheet-navbar-title">{title}</span>
        <div
          className="bottom-sheet-navbar-close"
          onClick={() => setIsHidden(true)}
        >
          <CloseOutlined />
        </div>
      </div>
      <div className="bottom-sheet-content-container">
        <div
          id="bottomSheetScrollableContainer"
          className="bottom-sheet-content"
        >
          {children}
        </div>
      </div>
    </motion.div>
  );
};

export default BottomSheet;
