import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

interface NewFunctionalityCardProps {
  className?: string;
}

const NewFunctionalityCard: FunctionComponent<NewFunctionalityCardProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <BasicCard
      className={`app-card-new-functionality no-shadow-card ${className}`}
    >
      <>
        <img
          src="/img/pro/eye2.svg"
          alt={t<string>("menu.newFunctionality.alt")}
        />
        <h3 className="functionality-title">
          {t<string>("menu.newFunctionality.title")}
        </h3>
        <div className="functionality-description">
          {t<string>("menu.newFunctionality.description")}
        </div>
        <BasicButton
          text={t<string>("menu.newFunctionality.buttonText")}
          onClick={() =>
            navigate(ROUTES.professional.gazMonth.details.generate())
          }
          size="sm"
        />
      </>
    </BasicCard>
  );
};

export default NewFunctionalityCard;
