import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import config from "@utils/Config";

interface RentreeDuChauffageConfirmationSectionProps {
  className?: string;
  landingPageType: string;
}

const LandingPageConfirmationSection: FunctionComponent<
  RentreeDuChauffageConfirmationSectionProps
> = ({ className = "", landingPageType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div
      className={`app-rentree-du-chauffage-confirmation-section ${className}`}
    >
      <div className="content-contained">
        <div className="confirmation-section-container">
          <div className="confirmation-section-left">
            <img
              alt={t<string>(
                "rentreeDuChauffage.individual.confirmation.section.alt",
              )}
              src={"/img/rentreeDuChauffage/confirmation/icon-check.svg"}
            />
          </div>
          <div className="confirmation-section-right">
            <h2 className="confirmation-section-right-title">
              {t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.confirmation.section.title`,
              )}
            </h2>
            <p className="confirmation-section-right-description">
              {t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.confirmation.section.description`,
              )}
            </p>
          </div>
        </div>
        <div className="confirmation-section-button-container">
          <BasicButton
            text={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.confirmation.section.button`,
            )}
            onClick={() => navigate(ROUTES.individual.publicHome.generate())}
            className="confirmation-section-button"
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPageConfirmationSection;
