import { FunctionComponent, useCallback, useEffect } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { requestUpdateMarkMention } from "@state/mention/pachybride/MentionPacHybrideEffects";
import {
  setMentionPacHybride,
  setMentionSmokeDuct,
} from "@state/auth/AuthEvents";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { requestUpdateMarkMentionSmokeDuct } from "@state/mention/smokeduct/MentionSmokeDuctEffects";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

interface CertificateModalProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
  type: "pacHybride" | "evapdc" | "smokeDuct";
}

const CertificateModal: FunctionComponent<CertificateModalProps> = ({
  open,
  onCancel,
  className = "",
  type,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;

  const handleSubmitMark = useCallback(
    (mark: number) => {
      if (type === "pacHybride") {
        if (authentifiedUser?.pacHybride) {
          void requestUpdateMarkMention({
            id: authentifiedUser.pacHybride.id,
            dto: {
              mark,
            },
          });
        }
      }
      if (type === "smokeDuct") {
        if (authentifiedUser?.smokeDuct) {
          void requestUpdateMarkMentionSmokeDuct({
            id: authentifiedUser && authentifiedUser.smokeDuct.id,
            dto: {
              mark,
            },
          });
        }
      }
    },
    [authentifiedUser, type],
  );

  useEffect(() => {
    return requestUpdateMarkMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
        navigate(ROUTES.professional.pacsHybrides.mention.generate());
      }
    });
  });

  useEffect(() => {
    return requestUpdateMarkMentionSmokeDuct.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionSmokeDuct(result.data);
        navigate(ROUTES.professional.smokeDucts.mention.generate());
      }
    });
  });

  return (
    <BasicModal
      open={open}
      onCancel={onCancel}
      className={`app-modal-certificate ${className}`}
      footer={
        <>
          <h5 className="text-center mb-30">
            {t<string>(`pro.mentions.${type}.form.modal.experience`)}
          </h5>
          <div className="d-flex align-items-center justify-content-around">
            <div className="cursor-pointer" onClick={() => handleSubmitMark(0)}>
              <img
                src="/img/pro/emoji-sad.png"
                alt={t<string>(`pro.mentions.${type}.form.modal.emojiAlt`)}
              />
            </div>
            <div className="cursor-pointer" onClick={() => handleSubmitMark(1)}>
              <img
                src="/img/pro/emoji-normal.png"
                alt={t<string>(`pro.mentions.${type}.form.modal.emojiAlt`)}
              />
            </div>
            <div className="cursor-pointer" onClick={() => handleSubmitMark(2)}>
              <img
                src="/img/pro/emoji-happy.png"
                alt={t<string>(`pro.mentions.${type}.form.modal.emojiAlt`)}
              />
            </div>
          </div>
        </>
      }
    >
      <>
        <div className="d-flex flex-column align-items-center justify-content-center mt-30">
          <img
            src="/img/pro/badge.svg"
            alt={t<string>(`pro.mentions.${type}.form.modal.imgAlt`)}
          />
          <h3 className="font-weight-bold mt-30 mb-30">
            {t<string>(`pro.mentions.${type}.form.modal.title`)}
          </h3>
          <div className="text-center mb-10">
            {t<string>(`pro.mentions.${type}.form.modal.description`)}
          </div>
        </div>
      </>
    </BasicModal>
  );
};

export default CertificateModal;
