import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import LandingPageConfirmationSection from "@components/rentreeDuChauffage/LandingPageConfirmationSection";
import LandingPageCommitments from "@components/landingpage/LandingPageCommitments";
import LandingPageTempsFortConfirmationHeader from "@components/rentreeDuChauffage/tempsFort/LandingPageTempsFortConfirmationHeader";

const LandingPageTempsFortConfirmationScreen: FC = () => {
  return (
    <PageLayout noHeader>
      <>
        <LandingPageTempsFortConfirmationHeader landingPageType="tempsFort" />
        <LandingPageConfirmationSection landingPageType="tempsFort" />
        <LandingPageCommitments
          landingPageType="tempsFort"
          confirmationScreen
        />
      </>
    </PageLayout>
  );
};

export default LandingPageTempsFortConfirmationScreen;
