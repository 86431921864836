import { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { FieldData, Store } from "rc-field-form/lib/interface";
import SubmitButton from "@components/buttons/SubmitButton";
import InputFormField from "@components/inputs/InputFormField";
import { requestRegistration } from "@state/auth/AuthEffects";
import BasicButton from "@components/buttons/BasicButton";
import { formHelper } from "@utils/form-helper";
import { formItemBlockLayout } from "@utils/Constant";
import { hideEmail } from "@utils/email-utils";
import { ROUTES } from "@utils/Routes";

const RegistrationScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fields, setFields] = useState<FieldData[]>([]);

  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  const InitialValues = {
    email: "",
  };

  useEffect(() => {
    return requestRegistration.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setSuccessMessage(
          t<string>("registration.form.messages.success", {
            email: hideEmail(result.data.email),
          }),
        );
      } else if (result.errorCode === 1006) {
        navigate(ROUTES.professional.errors.wrongId.generate());
      } else if (result.fieldErrors) {
        setFields(formHelper.addErrorsToFieldsData([], result.fieldErrors));
      }
      setButtonLoading(false);
    });
  });

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    setErrorMessage(undefined);
    void requestRegistration({
      dto: {
        username: values.username as string,
      },
    });
  };

  return (
    <PageLayout
      container
      contentInCard
      title={t<string>("registration.title")}
      subtitle={
        (!successMessage && t<string>("registration.subtitle")) || undefined
      }
      bodyClassName="login-body"
      mainClassName="bg-light-blue"
    >
      <>
        {successMessage && (
          <>
            <div className="d-flex align-items-center justify-content-center mb-3">
              {successMessage}
            </div>
            <div className="d-flex align-items-center justify-content-center mb-3 text-danger">
              {t<string>("common.spam")}
            </div>
          </>
        )}
        {!successMessage && (
          <Form
            onFinish={handleSubmit}
            initialValues={InitialValues}
            form={form}
            fields={fields}
            {...formItemBlockLayout}
          >
            {errorMessage && (
              <div className="text-danger d-flex align-items-center justify-content-center mb-3">
                {errorMessage}
              </div>
            )}
            <InputFormField
              showLabel
              module="registration"
              field="username"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[A-Z]{2}\d{4}/i),
                  message: t<string>(
                    "registration.form.fields.username.errors.invalid",
                  ),
                },
              ]}
            />
            <div className="d-flex align-items-center justify-content-center mb-3">
              <SubmitButton
                module="registration"
                isSubmitting={buttonLoading}
                label="submit"
              />
            </div>
          </Form>
        )}
        {successMessage && (
          <div className="d-flex align-items-center justify-content-center mb-3">
            <Link to={ROUTES.professional.auth.login.generate()}>
              <BasicButton
                text={t<string>("login.back")}
                className="mt-2 me-2"
              />
            </Link>
          </div>
        )}
      </>
    </PageLayout>
  );
};

export default RegistrationScreen;
