import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import WebinarRegister from "@components/webinar/WebinarRegister";
import { requestGetWebinar } from "@state/webinar/WebinarEffects";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import { ROUTES } from "@utils/Routes";
import { PacHybrideMentionAcceptedScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionAcceptedScreen";
import { PacHybrideMentionRefusedScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionRefusedScreen";
import { PacHybrideMentionInitScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionInitScreen";
import { PacHybrideMentionPendinScreen } from "@views/pro/gazProfessional/mentions/pac-hybride/PacHybrideMentionPendinScreen";

export const PacHybrideMentionScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const hasRequestedCertificate = authentifiedUser?.pacHybride || false;
  const pacHybride = authentifiedUser?.pacHybride;

  const [webinar, setWebinar] = useState<WebinarItemResponse>();

  const needToPassElearning =
    pacHybride?.numberPacHybride === "no" &&
    pacHybride?.technicalTraining === "no" &&
    pacHybride?.commercialTraining === "no" &&
    pacHybride.elearningStatus === "IN_PROGRESS";

  useEffect(() => {
    if (needToPassElearning && pacHybride.status !== "ACCEPTED") {
      navigate(ROUTES.professional.pacsHybrides.iframe.generate());
    }
    if (pacHybride?.status === "EXPIRED") {
      navigate(ROUTES.professional.pacsHybrides.renewal.generate());
    }
  });

  useEffect(() => {
    void requestGetWebinar("presentation-pac-hybride-20221020");
  }, []);

  useEffect(() => {
    return requestGetWebinar.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWebinar(result.data);
      }
    });
  });

  const renderScreenFromMentionStatus = () => {
    if (
      pacHybride == null ||
      (pacHybride?.elearningStatus === "IN_PROGRESS" &&
        pacHybride?.status !== "ACCEPTED" &&
        !pacHybride.validatedAt)
    ) {
      return <PacHybrideMentionInitScreen />;
    } else if (
      pacHybride?.status === "PENDING" &&
      pacHybride?.statusRge !== "REFUSED"
    ) {
      {
        return <PacHybrideMentionPendinScreen />;
      }
    } else if (
      pacHybride?.status === "ACCEPTED" ||
      pacHybride?.status === "EXPIRED"
    ) {
      return <PacHybrideMentionAcceptedScreen />;
    } else if (
      pacHybride?.status === "REFUSED" &&
      pacHybride?.elearningStatus !== "REFUSED"
    ) {
      return <PacHybrideMentionRefusedScreen />;
    } else if (
      (pacHybride?.statusRge === "REFUSED" ||
        pacHybride?.elearningStatus !== "REFUSED") &&
      pacHybride.numberPacHybride != null
    ) {
      return navigate(ROUTES.professional.pacsHybrides.renewal.generate());
    } else return null;
  };

  return (
    <PageLayout
      container
      title={
        hasRequestedCertificate
          ? t<string>("pro.mentions.pacHybride.detail.certificate.title")
          : t<string>("pro.mentions.pacHybride.detail.noCertificate.title")
      }
      titleClassName={
        !hasRequestedCertificate ||
        pacHybride?.statusRge === "PENDING" ||
        pacHybride?.elearningStatus === "IN_PROGRESS"
          ? "mention-title"
          : ""
      }
      titleCentered={false}
      headerRightContent={
        !hasRequestedCertificate ||
        (pacHybride?.elearningStatus === "IN_PROGRESS" &&
          pacHybride?.status === "PENDING" &&
          !pacHybride?.validatedAt) ? (
          <img
            src="/img/pro/logo-pac-hybride.png"
            alt={t<string>(
              "pro.mentions.pacHybride.detail.noCertificate.imgAlt",
            )}
            className="mention-logo"
          />
        ) : null
      }
      headerLeftContent={
        !hasRequestedCertificate ? (
          <div className="mt-20">
            <BasicButton
              variant="primary"
              text={t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.buttonText",
              )}
              onClick={() =>
                navigate(ROUTES.professional.pacsHybrides.form.generate())
              }
              className="mention-button mb-4"
            />
          </div>
        ) : (
          <>
            <div className="d-flex mt-20 gap-5">
              {pacHybride?.statusRge === "PENDING" &&
                !pacHybride.validatedAt && (
                  <BasicButton
                    variant="primary"
                    text={t<string>(
                      "pro.mentions.pacHybride.detail.certificate.buttonText.accessForm",
                    )}
                    onClick={() =>
                      navigate(ROUTES.professional.pacsHybrides.form.generate())
                    }
                    className="mention-button mb-4"
                  />
                )}
              {pacHybride?.elearningStatus === "IN_PROGRESS" &&
                (!pacHybride.validatedAt ||
                  pacHybride.commercialTraining === "no") && (
                  <BasicButton
                    variant="primary"
                    text={t<string>(
                      "pro.mentions.pacHybride.detail.certificate.buttonText.iframe",
                    )}
                    onClick={() =>
                      navigate(
                        ROUTES.professional.pacsHybrides.iframe.generate(),
                      )
                    }
                    className="mention-button mb-4"
                  />
                )}
            </div>
          </>
        )
      }
    >
      <>
        {webinar && (
          <WebinarRegister
            webinar={webinar}
            title="webinar.presentation-pac-hybride.title"
            description="webinar.presentation-pac-hybride.description"
            modalContent="webinar.presentation-pac-hybride.modal.content"
            className="mb-20"
          />
        )}
        {renderScreenFromMentionStatus()}
      </>
    </PageLayout>
  );
};
