import { Col, Form, Row } from "antd";
import Section from "@components/section/Section";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import SubmitButton from "@components/buttons/SubmitButton";
import { FunctionComponent } from "react";
import SetPasswordInputFields from "@components/forms/SetPasswordInputFields";
import { requestUpdatePassword } from "@state/auth/AuthEffects";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import InputFormField from "@components/inputs/InputFormField";
import { formItemBlockLayout } from "@utils/Constant";
import { toastError, toastSuccess } from "@utils/toast-helper";

interface FormValues {
  currentCredential: string;
  credential: string;
  confirmCredential: string;
}

const PasswordSection: FunctionComponent = () => {
  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    const obj = values as FormValues;

    requestUpdatePassword({
      dto: {
        currentCredential: obj.currentCredential,
        credential: obj.credential,
        confirmCredential: obj.confirmCredential,
      },
    })
      .then((result: ParsedResponse<void>) => {
        if (result.ok) {
          toastSuccess(t<string>("reset-password.messages.success"));
        } else {
          toastError(result.errorMessage);
        }
      })
      .catch((error: Error) => {
        toastError(error.message);
      });
  };

  return (
    <>
      <div className="px-12">
        <BasicDivider variant="primary" className="mb-40 w-100" height={1} />
      </div>
      <Section
        type="h2"
        title={t<string>("personalInfo.sectionTitle.password")}
        titleClassName="px-12 text-primary"
        className="mb-40"
      >
        <div className="px-12">
          <Form onFinish={handleSubmit} {...formItemBlockLayout}>
            <Row gutter={16}>
              <Col xs={0} lg={1} className="mx-0 px-0">
                <img src="/img/icons/personalData/edit.svg" />
              </Col>
              <Col xs={24} lg={23}>
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="currentCredential"
                      type="password"
                      required
                    />
                  </Col>
                  <SetPasswordInputFields isFromPersonalDatas />
                </Row>
                <Row justify="end">
                  <SubmitButton
                    module="personalInfo"
                    isSubmitting={false}
                    label="submit"
                  />
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Section>
    </>
  );
};

export default PasswordSection;
