import { FunctionComponent } from "react";
import ImageModuleCMS from "@components/cms/ImageModuleCMS";
import {
  BlockInput,
  ImageContent,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";

interface Props {
  block: PageBlockInfoResponseDto;
}

const TwoColumns: FunctionComponent<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const imageContent = inputs[3].content as ImageContent;
  return (
    <ImageModuleCMS
      className={"two-columns"}
      src={imageContent.content}
      sideContent={inputs[0].content as string}
      buttonAction={
        (inputs[1].content &&
          inputs[2].content && {
            buttonLabel: inputs[1].content as string,
            url: inputs[2].content as string,
            variant: "primary",
          }) ||
        undefined
      }
      alignImgLeft={inputs[4].content === "left"}
      height={"300"}
      width={"400"}
    />
  );
};

export default TwoColumns;
