import configuration from "@utils/Config";
import {
  restCreationHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandlerPagination,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { createEffect } from "effector";
import { Pageable } from "@type/pagination/pagination.types";
import {
  MaintenanceCertificateItemResponseDto,
  MaintenanceCertificateResponseDto,
} from "@state/maintenancecertificate/dto/maintenance.certificate.item.response.dto";
import { MaintenanceCertificateSaveRequestDto } from "@state/maintenancecertificate/dto/maintenance.certificate.save.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/maintenance-certificate`;

export const requestCreateMaintenanceCertificate = createEffect({
  handler: restCreationHandler<MaintenanceCertificateSaveRequestDto, void>(
    baseUrl,
  ),
});

export const requestPublishMaintenanceCertificate = createEffect({
  handler: restCreationHandler<
    MaintenanceCertificateSaveRequestDto,
    MaintenanceCertificateResponseDto
  >(`${baseUrl}/publish`),
});

export const requestPublishUpdatedMaintenanceCertificate = createEffect({
  handler: restPatchHandler<
    MaintenanceCertificateSaveRequestDto,
    MaintenanceCertificateResponseDto
  >(`${baseUrl}/`, "/publish"),
});

export const requestUpdateMaintenanceCertificate = createEffect({
  handler: restPatchHandler<
    MaintenanceCertificateSaveRequestDto,
    MaintenanceCertificateResponseDto
  >(`${baseUrl}/`),
});

export const requestGetMaintenanceCertificate = createEffect({
  handler: restDetailsHandler<MaintenanceCertificateResponseDto>(`${baseUrl}/`),
});

export const requestGetMaintenanceCertificates = createEffect({
  handler:
    restListHandlerPagination<Pageable<MaintenanceCertificateItemResponseDto>>(
      baseUrl,
    ),
});

export const requestDeleteMaintenanceCertificates = createEffect({
  handler: restDeletionHandler<MaintenanceCertificateItemResponseDto>(
    `${baseUrl}/`,
  ),
});
