import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restDetailsHandler,
  restGetUniqueHandler,
  restListHandlerPagination,
  restUpdateHandler,
} from "@utils/rest/RestClient";
import { Pageable } from "@type/pagination/pagination.types";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";
import { MailReadRequestDto } from "@state/mailprofessional/dto/habitaplus.mails.request.dto";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.item.response.dto";
import { GPQualificationsResponseDto } from "@state/professional/dto/professional.qualifications.response.dto";
import { OrganisationProfessionalRGItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.responsable.gaz.item.response.dto";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import { OrganisationProfessionalRGRequestItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.item.response.dto";
import { OrganisationProfessionalRGRequestDetailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.details.response.dto";
import { OrganisationProfessionalRGRequestSaveRequestDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.save.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/organisation-professionals/`;

export const requestGetOrganisationProfessionalHabitAPlusMails = createEffect({
  handler: restListHandlerPagination<
    Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>
  >(`${baseUrl}mails`),
});

export const requestUpdateOrganisationProfessionalHabitAPlusMailRead =
  createEffect({
    handler: restUpdateHandler<MailReadRequestDto, void>(
      `${baseUrl}mails/`,
      "/read",
    ),
  });

export const requestGetOrganisationProfessionalPGs = createEffect({
  handler: restListHandlerPagination<
    Pageable<OrganisationProfessionalGPItemResponseDto>
  >(`${baseUrl}pgs`),
});

export const requestGetOrganisationProfessionalQualificationsGazProfessional =
  createEffect({
    handler: restDetailsHandler<GPQualificationsResponseDto>(`${baseUrl}pgs/`),
  });

export const requestGetOrganisationProfessionalRGs = createEffect({
  handler: restListHandlerPagination<
    Pageable<OrganisationProfessionalRGItemResponseDto>
  >(`${baseUrl}rgs`),
});

export const requestGetGazProfessionalHabitAPlusMailsByOrganisationProfessional =
  createEffect({
    handler: restListHandlerPagination<
      Pageable<GazProfessionalHabitAPlusMailsResponseDto>
    >(`${baseUrl}pgs/`, "mails"),
  });

export const requestGetOrganisationProfessionalRGRequests = createEffect({
  handler: restListHandlerPagination<
    Pageable<OrganisationProfessionalRGRequestItemResponseDto>
  >(`${baseUrl}rg-requests`),
});

export const requestGetOrganisationProfessionalRGRequest = createEffect({
  handler:
    restDetailsHandler<OrganisationProfessionalRGRequestDetailsResponseDto>(
      `${baseUrl}rg-requests/`,
    ),
});

export const requestUpdateOrganisationProfessionalRGRequest = createEffect({
  handler: restUpdateHandler<
    OrganisationProfessionalRGRequestSaveRequestDto,
    OrganisationProfessionalRGRequestDetailsResponseDto
  >(`${baseUrl}rg-requests/`, "/proceed"),
});

export const requestGetOrganisationProfessionalPGsExportExcel = createEffect({
  handler: restGetUniqueHandler<Blob>(`${baseUrl}pgs/export-excel`),
});

export const requestGetOrganisationProfessionalRGsExportExcel = createEffect({
  handler: restGetUniqueHandler<Blob>(`${baseUrl}rgs/export-excel`),
});
