import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import LandingPageConfirmationSection from "@components/rentreeDuChauffage/LandingPageConfirmationSection";
import LandingPageCommitments from "@components/landingpage/LandingPageCommitments";
import LandingPageConfirmationHeader from "@components/rentreeDuChauffage/LandingPageConfirmationHeader";

const LandingPageFilRougeConfirmationScreen: FC = () => {
  return (
    <PageLayout noHeader>
      <>
        <LandingPageConfirmationHeader landingPageType="filRouge" />
        <LandingPageConfirmationSection landingPageType="filRouge" />
        <LandingPageCommitments landingPageType="filRouge" confirmationScreen />
      </>
    </PageLayout>
  );
};

export default LandingPageFilRougeConfirmationScreen;
