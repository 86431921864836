import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { columns } from "@components/lists/MailsColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";
import MailDetailModal from "@components/modals/MailDetailModal";
import SearchFormField from "@components/inputs/SearchFormField";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import {
  requestGetGazProfessionalHabitAPlusMails,
  requestUpdateGazProfessionalHabitAPlusMailRead,
} from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import configuration from "@utils/Config";
import { Loader } from "@components/loader/Loader";
import { useStore } from "effector-react";
import {
  MailProfessionalContext,
  mailProfessionalStore,
} from "@state/mailprofessional/MailProfessionalStore";
import { setPgMails } from "@state/mailprofessional/MailProfessionalEvents";
import BasicButton from "@components/buttons/BasicButton";

export const MailsListScreen: React.FC = () => {
  const { t } = useTranslation();
  const { loading, pgMails } = useStore<MailProfessionalContext>(
    mailProfessionalStore,
  );

  const [mails, setMails] =
    useState<Pageable<GazProfessionalHabitAPlusMailsResponseDto>>(pgMails);

  const searchTimeout = useRef<number>();
  const shouldFetch = useRef<boolean>(false);
  const [localQuery, setLocalQuery] = useState<string>("");

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(!loading);

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [sorter, setSorter] = useState<
    SorterResult<GazProfessionalHabitAPlusMailsResponseDto>
  >({
    columnKey: "date",
    order: "descend",
  });

  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [detailModalTitle, setDetailModalTitle] = useState<string>("");
  const [detailModalUrl, setDetailModalUrl] = useState<string>();

  const onChangeLimit = (current: number, newLimit: number): void => {
    shouldFetch.current = true;
    setLimit(newLimit);
  };

  const updateRead = (mail: GazProfessionalHabitAPlusMailsResponseDto) => {
    if (!mail.read) {
      requestUpdateGazProfessionalHabitAPlusMailRead({
        id: mail.id,
        dto: { read: true },
      })
        .then(() => {
          // Mise à jour des données du state
          setMails((state: Pageable<any>) => {
            return {
              ...state,
              content: state.content.map(
                (
                  m: GazProfessionalHabitAPlusMailsResponseDto,
                ): GazProfessionalHabitAPlusMailsResponseDto => ({
                  ...m,
                  read: m.id === mail.id || m.read,
                }),
              ),
            };
          });
          // Mise à jour des données du store
          setPgMails({
            ...pgMails,
            content: pgMails.content.map(
              (
                m: GazProfessionalHabitAPlusMailsResponseDto,
              ): GazProfessionalHabitAPlusMailsResponseDto => ({
                ...m,
                read: m.id === mail.id || m.read,
              }),
            ),
          });
        })
        .catch(() => {
          toastAlert(t<string>("pro.mail.error"));
        });
    }
  };

  const onRowClick = (
    record: GazProfessionalHabitAPlusMailsResponseDto,
  ): void => {
    setDetailModalTitle(record.title);
    setDetailModalUrl(getPDFLink(record.id));
    if (record.title) {
      setShowDetailModal(true);
    }
    updateRead(record);
  };

  const fetchMails = useCallback(() => {
    setDataHasLoaded(false);
    requestGetGazProfessionalHabitAPlusMails({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: { query: localQuery },
    }).catch(() => {
      toastAlert(t<string>("pro.mail.error"));
    });
  }, [pageNumber, limit, t, sorter, localQuery]);

  useEffect(() => {
    if (shouldFetch.current) {
      fetchMails();
    }
  }, [fetchMails]);

  useEffect(() => {
    return requestGetGazProfessionalHabitAPlusMails.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setMails(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    shouldFetch.current = true;
    const value = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setLocalQuery(value), 800);
  };

  const onPageChange = (page: number) => {
    shouldFetch.current = true;
    setPageNumber(page);
  };

  const getPDFLink = (id: string) => {
    return `${configuration.backendBaseUrl}/gaz-professionals/habitaplus-mails/${id}/file`;
  };

  const unreadMailCount = mails.content.filter(
    (mail: GazProfessionalHabitAPlusMailsResponseDto): boolean => !mail.read,
  ).length;
  return (
    <PageLayout
      container
      title={t<string>("pro.mail.title")}
      titleCentered={false}
    >
      {mails == emptySearchResult ? (
        <Loader text={t<string>("pro.loader")} />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
            <h3 className="flex-grow-1 text-primary mb-0 d-flex align-items-center">
              {t("pro.mail.newMail", { count: unreadMailCount })}
              <BasicButton
                className="ms-3"
                text={t<string>("pro.mail.refresh")}
                onClick={fetchMails}
                isLoading={!dataHasLoaded}
              />
            </h3>
            <SearchFormField
              module="pro.mail"
              field="search"
              onChange={onChange}
              onSearch={(value: string) => setLocalQuery(value)}
              value={localQuery}
            />
          </div>
          <Table
            locale={{
              emptyText: <Empty description={t<string>("common.noData")} />,
            }}
            columns={columns(onRowClick, {
              type: "GP",
              getPDFLink,
              updateRead,
            })}
            dataSource={mails.content}
            loading={{
              spinning: !dataHasLoaded,
              indicator: <Loader />,
            }}
            size="small"
            rowKey={(record) => record.id}
            rowClassName={(record: GazProfessionalHabitAPlusMailsResponseDto) =>
              record.read ? "" : "new"
            }
            className="app-contact-pro-table pro-table-large-font"
            pagination={{
              total: mails.totalElements,
              pageSize: mails.size,
              current: mails.number + 1,
              onChange: onPageChange,
              onShowSizeChange: onChangeLimit,
              showSizeChanger: true,
            }}
            onChange={(pagination, filters, sorter) => {
              shouldFetch.current = true;
              setSorter(sorter as SorterResult<any>);
            }}
            onRow={(record) => {
              return {
                onClick: () => onRowClick(record),
              };
            }}
          />
          <MailDetailModal
            visible={showDetailModal}
            onCancel={() => setShowDetailModal(false)}
            title={detailModalTitle}
            url={detailModalUrl}
          />
        </>
      )}
    </PageLayout>
  );
};
