import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationHandler,
  restGetUniqueHandler,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { SmokeDuctUpdateMarkRequestDto } from "@state/mention/smokeduct/dto/smoke.duct.update.mark.request.dto";
import { SmokeDuctUpdateRgeRequestDto } from "@state/mention/smokeduct/dto/smoke.duct.update.rge.request.dto";
import { MentionResponseDto } from "../dto/mention.response.dto";
import { AbstractMentionSaveRequestDto } from "@state/mention/dto/abstract.mention.save.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/mentions/smoke-duct`;

export const requestCreateMentionSmokeDuct = createEffect({
  handler: restCreationHandler<
    AbstractMentionSaveRequestDto,
    MentionResponseDto
  >(baseUrl),
});

export const requestUpdateMention = createEffect({
  handler: restPatchHandler<AbstractMentionSaveRequestDto, MentionResponseDto>(
    `${baseUrl}/`,
  ),
});
export const requestGetMentionSmokeDuctExport = createEffect({
  handler: restGetUniqueHandler<Blob>(`${baseUrl}/export`),
});

export const requestUpdateStatusELearningMentionSmokeDuct = createEffect({
  handler: restPatchHandler<undefined, void>(`${baseUrl}/status-e-learning/`),
});

export const requestUpdateMarkMentionSmokeDuct = createEffect({
  handler: restPatchHandler<SmokeDuctUpdateMarkRequestDto, void>(
    `${baseUrl}/mark/`,
  ),
});
export const requestUpdateRgeMentionSmokeDuct = createEffect({
  handler: restPatchHandler<SmokeDuctUpdateRgeRequestDto, void>(
    `${baseUrl}/rge/`,
  ),
});
