import React, { FunctionComponent, useState } from "react";
import { Collapse } from "antd";
import parse from "html-react-parser";

const { Panel } = Collapse;

export interface AccordionSection {
  title: string | React.JSX.Element;
  content: string | React.JSX.Element;
}

interface AccordionsProps {
  sections?: AccordionSection[];
  children?: React.JSX.Element;
  collapseOthers?: boolean;
  defaultActiveKey?: number[];
  expandIconLeft?: boolean;
  hideOnSelect?: boolean;
  prefixId?: string;
  className?: string;
}

const Accordions: FunctionComponent<AccordionsProps> = ({
  sections = [],
  children,
  collapseOthers = false,
  defaultActiveKey,
  expandIconLeft = false,
  hideOnSelect = false,
  prefixId = "",
  className = "",
}) => {
  const [expandedIndex, setExpandedIndex] = useState<string | undefined>(
    undefined,
  );

  const handleChange = (index: string | string[]) => {
    if (collapseOthers) {
      if (index) setExpandedIndex(index[0]);
      else setExpandedIndex(undefined);
    }
  };

  return (
    <Collapse
      onChange={handleChange}
      accordion={collapseOthers}
      className={`app-accordions ${className}`}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition={expandIconLeft ? "start" : "end"}
    >
      {children ??
        sections?.map((section: AccordionSection, index: number) => (
          <Panel
            header={section.title}
            id={prefixId ? `${prefixId}-panel-${index}` : undefined}
            key={index.valueOf()}
            forceRender={true}
            className={
              hideOnSelect &&
              collapseOthers &&
              expandedIndex &&
              expandedIndex !== index.toString()
                ? "d-none"
                : ""
            }
          >
            <>
              {typeof section.content === "string"
                ? parse(section.content)
                : section.content}
            </>
          </Panel>
        ))}
    </Collapse>
  );
};

export default Accordions;
