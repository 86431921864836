import React, { useEffect, useState } from "react";
import WebinarRegister from "@components/webinar/WebinarRegister";
import { requestGetWebinar } from "@state/webinar/WebinarEffects";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Section from "@components/section/Section";
import GreenGasCard from "@components/cards/GreenGasCard";
import { useViewport } from "@utils/breakpoints-helper";
import { Form } from "antd";
import SubmitButton from "@components/buttons/SubmitButton";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import { requestUpdateGreenGaz } from "@state/gazprofessional/GazProfessionalEffects";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import BasicModal from "@components/modals/BasicModal";
import BasicButton from "@components/buttons/BasicButton";
import DateFormat from "@utils/DateFormat";

export const GreenGasScreen: React.FC = () => {
  const { t } = useTranslation();
  const [webinar, setWebinar] = useState<WebinarItemResponse>();
  const { width } = useViewport();
  const authentifiedContext = useStore(authentifiedStore);
  const [userPg, setUserPg] = useState<GazProfessionalUser>(
    authentifiedContext.user as GazProfessionalUser,
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const startDate = webinar?.startAt ? new Date(webinar.startAt) : undefined;
  const endDate = webinar?.endAt ? new Date(webinar.endAt) : undefined;
  const translationParams = {
    date: startDate ? DateFormat.standardDate(startDate) : "",
    startTime: startDate ? DateFormat.standardTime(startDate) : "",
    endTime: endDate ? DateFormat.standardTime(endDate) : "",
  };
  const canSubscribe = startDate && startDate > new Date();
  const isSubscribed = userPg.greenGaz;

  useEffect(() => {
    void requestGetWebinar("presentation-green-gas-20230124");
  }, []);

  useEffect(() => {
    return requestGetWebinar.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWebinar(result.data);
      }
    });
  });

  const handleActionSubmit = () => {
    void requestUpdateGreenGaz({ dto: null, id: "" }).finally(() =>
      setIsModalVisible(true),
    );
  };

  useEffect(() => {
    return requestUpdateGreenGaz.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setUserPg(result.data);
      }
    });
  });

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>("pro.greenGas.title")}
      noSideMenu={false}
    >
      <>
        {webinar && canSubscribe && (
          <>
            <WebinarRegister
              webinar={webinar}
              title="webinar.presentation-green-gas.title"
              description="webinar.presentation-green-gas.description"
              modalContent="webinar.presentation-green-gas.modal.content"
              className="mb-20"
            />
            <div className="smoke-duct-frieze mt-5">
              {width >= 992 ? (
                <img
                  alt={t<string>("pro.greenGas.friezeAlt")}
                  src="/img/pro/green-gaz-frieze.svg"
                />
              ) : (
                <GreenGasCard />
              )}
            </div>
          </>
        )}

        <div className="d-flex flex-column-reverse flex-md-row mention-greenGaz-detail justify-content-lg-between">
          {isSubscribed ? (
            <div className="mb-3 ">
              {parse(t<string>("pro.greenGas.suscribed.detail"))}
            </div>
          ) : (
            <div>
              <div className="mb-3 ">
                {parse(t<string>("pro.greenGas.detail"))}
              </div>
              <ul>
                <li className="mt-1">{t<string>("pro.greenGas.list1")}</li>
                <li className="mt-1">{t<string>("pro.greenGas.list2")}</li>
                <li className="mt-1">
                  {parse(t<string>("pro.greenGas.list3"))}
                </li>
              </ul>
            </div>
          )}

          <div className="align-items-end">
            <img
              src="/img/pro/logo-green-gaz.svg"
              alt={t<string>("pro.greenGas.imgAlt")}
              className="mention-greenGas-logo"
            />
          </div>
        </div>
        {!isSubscribed && (
          <Section
            title={t<string>("pro.greenGas.subTitle1")}
            titleClassName="text-primary mt-4"
            type="h2"
          >
            <div className="d-flex flex-column flex-md-row">
              <div className="mt-3 mention-greenGaz-paragraph">
                <div className="mb-2">
                  {parse(t<string>("pro.greenGas.text1"))}
                </div>
                {webinar && canSubscribe && (
                  <div>
                    {parse(t<string>("pro.greenGas.text2", translationParams))}
                  </div>
                )}
              </div>
              <div>
                <img
                  alt={t<string>("pro.greenGas.dot")}
                  src="/img/pro/green-gaz-dot.svg"
                />
              </div>
            </div>
          </Section>
        )}
        <Section
          title={t<string>("pro.greenGas.subTitle2")}
          titleClassName="text-primary mt-4"
          type="h2"
        >
          <div className="mt-4">
            {parse(
              t<string>(
                isSubscribed
                  ? "pro.greenGas.suscribed.commitment"
                  : "pro.greenGas.commitment.form.detail",
              ),
            )}
          </div>
          <Form onFinish={handleActionSubmit}>
            <div className="d-flex flex-column flex-md-row">
              <div className="p-3">
                <CheckboxFormField
                  className="mb-2"
                  field="checkbox1"
                  module="pro.greenGas.commitment"
                  required
                  checked={isSubscribed}
                />
                <CheckboxFormField
                  className="mb-2"
                  field="checkbox2"
                  module="pro.greenGas.commitment"
                  required
                  checked={isSubscribed}
                />
                <CheckboxFormField
                  className="mb-2"
                  field="checkbox3"
                  module="pro.greenGas.commitment"
                  required
                  checked={isSubscribed}
                />
                <CheckboxFormField
                  parsed
                  className="mb-2"
                  field="checkbox4"
                  module="pro.greenGas.commitment"
                  required
                  checked={isSubscribed}
                />
              </div>

              <div className="p-3">
                <img
                  alt={t<string>("pro.greenGas.qrcode")}
                  className="mention-greenGas-qrcode"
                  src="/img/pro/green-gaz-qrcode.png"
                />
              </div>
            </div>

            <div className="d-flex justify-content-center">
              {!isSubscribed && (
                <SubmitButton
                  module="pro.greenGas.commitment"
                  isSubmitting={false}
                  label="submit"
                />
              )}
            </div>
          </Form>
        </Section>
        <BasicModal
          open={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
        >
          <>
            <h3 className="mb-30">{t<string>("pro.greenGas.modal.title")}</h3>
            <div className="mb-30">
              {t<string>("pro.greenGas.modal.detail")}
            </div>
            <div className="text-center">
              <BasicButton
                onClick={() => setIsModalVisible(false)}
                text={t<string>("pro.greenGas.modal.action")}
              />
            </div>
          </>
        </BasicModal>
      </>
    </PageLayout>
  );
};
