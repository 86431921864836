import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { formItemBlockLayout } from "@utils/Constant";
import { Form, RadioChangeEvent } from "antd";
import { useForm } from "antd/lib/form/Form";
import { GazProfessionalRGRequestSaveRequestDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.save.request.dto";
import { RG_REQUEST_TYPE } from "@utils/enums/qualifications.rg-requests.type.enum";
import {
  requestCreateGazProfessionalRGRequest,
  requestUpdateGazProfessionalRGRequest,
} from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/qualifications.rg-requests.speciality.enum";
import RadioFormField from "@components/inputs/RadioFormField";
import {
  confirmationPopup,
  isReadOnly,
  options,
  requestRGRequestOPProceed,
} from "@utils/rgrequest-module-utils";
import ModuleSubmissionFooter from "@components/rgRequestModules/ModuleSubmissionFooter";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { GazProfessionalRGRequestDetailsResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.details.response.dto";
import {
  maptoRgRequestMode,
  maptoRgRequestRadReason,
} from "@utils/rgRequestMappers";
import ModuleFeedBack from "@components/rgRequestModules/ModuleFeedBack";
import { OrganisationProfessionalRGRequestDetailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.details.response.dto";
import ModuleCompanyName from "@components/rgRequestModules/ModuleCompanyName";
import { OrganisationProfessionalRGRequestSaveRequestDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.save.request.dto";
import { requestUpdateOrganisationProfessionalRGRequest } from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { RG_REQUEST_STATUS } from "@utils/enums/qualifications.rg-requests.status.enum";
import ModuleSubtitle from "@components/rgRequestModules/ModuleSubtitle";

interface RadRGFormValues {
  reason?: string;
  personalisedReason?: string;
  comment?: string;
}
interface QualificationsRadRGModalProps {
  visible: boolean;
  onCancel: () => void;
  className?: string;
  isOPSection?: boolean;
  modalMode: ModalMode;
  rgRequest?:
    | GazProfessionalRGRequestDetailsResponseDto
    | OrganisationProfessionalRGRequestDetailsResponseDto;
  isInstallationSection?: boolean;
  name: {
    firstName: string;
    lastName: string;
  };
}

const RadRGModal: FunctionComponent<QualificationsRadRGModalProps> = ({
  visible,
  onCancel,
  className = "",
  isOPSection = false,
  modalMode,
  rgRequest,
  isInstallationSection,
  name,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [radioValue, setRadioValue] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const readOnly = isReadOnly(modalMode, isOPSection);

  const onChange = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    if (rgRequest?.reason) {
      const reason = maptoRgRequestRadReason(rgRequest.reason);
      setRadioValue(reason.value);
      form.setFields([
        {
          name: ["reason"],
          value: reason.value,
        },
        {
          name: ["personalisedReason"],
          value: rgRequest.personalisedReason,
        },
        {
          name: ["comment"],
          value: rgRequest.comment,
        },
      ]);
    } else {
      setRadioValue("");
      form.resetFields();
    }
  }, [rgRequest, form]);

  const handleSubmit = (values: RadRGFormValues) => {
    void confirmationPopup(isOPSection, RG_REQUEST_TYPE.RAD, t).then(
      (confirmed: boolean) => {
        if (confirmed) {
          setIsLoading(true);
          if (isOPSection) {
            const rgRequestToSave: OrganisationProfessionalRGRequestSaveRequestDto =
              {
                comment: values.comment,
              };
            if (rgRequest) {
              void requestUpdateOrganisationProfessionalRGRequest({
                id: rgRequest.id,
                dto: rgRequestToSave,
              });
            }
          } else {
            const speciality = isInstallationSection
              ? RG_REQUEST_SPECIALITY.INSTALLATION.value
              : RG_REQUEST_SPECIALITY.MAINTENANCE.value;
            const rgRequestToSave: GazProfessionalRGRequestSaveRequestDto = {
              type: RG_REQUEST_TYPE.RAD.value,
              speciality,
              firstName: name.firstName,
              lastName: name.lastName,
              reason: values.reason,
              personalisedReason: values.personalisedReason,
            };
            if (rgRequest) {
              void requestUpdateGazProfessionalRGRequest({
                id: rgRequest.id,
                dto: rgRequestToSave,
              });
            } else {
              void requestCreateGazProfessionalRGRequest({
                dto: rgRequestToSave,
              });
            }
          }
        }
      },
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const validation = () => {
    if (rgRequest) {
      requestRGRequestOPProceed(true, rgRequest.id, undefined);
    }
  };

  const onFinishFailed = () => {
    toastAlert(t<string>("pro.qualifications.modals.radRG.toast"));
  };

  return (
    <BasicModal
      open={visible}
      onCancel={onCancel}
      className={`app-modal-qualifications-add-rg ${className}`}
      title={t<string>(
        `pro.qualifications.modals.radRG.title.${
          isInstallationSection ? "installation" : "maintenance"
        }.${maptoRgRequestMode(modalMode)}`,
      )}
      footer={null}
    >
      <>
        {isOPSection ? (
          <ModuleCompanyName companyName={rgRequest?.companyName} />
        ) : (
          <>
            <ModuleSubtitle status={rgRequest?.status} />
            {modalMode === ModalMode.CREATE && (
              <div className="mb-20">
                {t<string>("pro.qualifications.modals.radRG.description")}
              </div>
            )}
            {modalMode === ModalMode.UPDATE && (
              <ModuleFeedBack
                refusalDate={rgRequest?.refusalDate}
                comment={rgRequest?.comment}
              />
            )}{" "}
          </>
        )}
        <div className="mb-20 fw-bold">
          {t<string>("pro.qualifications.modals.radRG.subtitle", {
            name: `${name.firstName} ${name.lastName}`,
          })}
        </div>
        <div className="rg-request-form-section-title">
          {t<string>("pro.qualifications.modals.radRG.section.reason")}
        </div>
        <Form
          form={form}
          {...formItemBlockLayout}
          onFinishFailed={onFinishFailed}
          onFinish={handleSubmit}
        >
          <div className="rg-request-form-section-container">
            <div className={"w-100"}>
              <RadioFormField
                required
                direction="vertical"
                field="reason"
                onChange={onChange}
                value={radioValue}
                hasOther={true}
                otherField="personalisedReason"
                otherModule="pro.qualifications.modals.radRG"
                options={options(t)}
                readOnly={readOnly}
              />
            </div>
            {isOPSection &&
              !(rgRequest?.status === RG_REQUEST_STATUS.IN_PROGRESS.value) && (
                <ModuleFeedBack isOPSection modalMode={modalMode} />
              )}
          </div>
          {modalMode !== ModalMode.DETAILS && (
            <ModuleSubmissionFooter
              isOPSection={isOPSection}
              onClick={validation}
              type={RG_REQUEST_TYPE.RAD}
              modalMode={modalMode}
              isLoading={isLoading}
            />
          )}
        </Form>
      </>
    </BasicModal>
  );
};

export default RadRGModal;
