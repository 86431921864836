/* eslint-disable */

export const INTERVENTION_TYPES: { [index: string]: SearchType } = {
  INSTALLATION: {
    value: "INSTALLATION",
    label: "search.intervention.installation",
    shortLabel: "search.intervention.installationShort",
  },
  MAINTENANCE: {
    value: "MAINTENANCE",
    label: "search.intervention.maintenance",
  },
  CHEMINISTE: {
    value: "CHEMINISTE",
    label: "search.intervention.cheministe",
  },
};

export const CURRENT_ENERGIES: { [index: string]: SearchType } = {
  GAZ_NATUREL: {
    value: "GAZ_NATUREL",
    label: "search.currentEnergy.gazNaturel",
  },
  GAZ_PROPANE: {
    value: "GAZ_PROPANE",
    label: "search.currentEnergy.gazPropane",
  },
  FIOUL: {
    value: "FIOUL",
    label: "search.currentEnergy.fioul",
  },
  ELECTRICITE: {
    value: "ELECTRICITE",
    label: "search.currentEnergy.electricite",
  },
  BOIS: {
    value: "BOIS",
    label: "search.currentEnergy.bois",
  },
};

export const EQUIPMENT_TYPES: { [index: string]: SearchType } = {
  CHAUDIERE_THPE: {
    value: "CHAUDIERE_THPE",
    label: "search.equipmentType.chaudiereThpe",
  },
  PAC_HYBRIDE_GAZ: {
    value: "PAC_HYBRIDE_GAZ",
    label: "search.equipmentType.pacHybrideGaz",
  },
  POELE_ET_CHEMINEE_GAZ: {
    value: "POELE_ET_CHEMINEE_GAZ",
    label: "search.equipmentType.poeleEtChemineeGaz",
  },
  TABLE_CUISSON: {
    value: "TABLE_CUISSON",
    label: "search.equipmentType.tableCuisson",
  },
  PRISE_GAZ_USAGE_CUISSON_EXTERIEURE: {
    value: "PRISE_GAZ_USAGE_CUISSON_EXTERIEURE",
    label: "search.equipmentType.priseGazUsageCuissonExterieure",
  },
  AUTRES: {
    value: "AUTRES",
    label: "search.equipmentType.other",
  },
};

export const EXPERTISE: { [index: string]: SearchType } = {
  PAC_HYBRIDE: {
    value: "PAC_HYBRIDE",
    label: "search.expertise.pac",
  },
  SMOKE_DUCT: {
    value: "SMOKE_DUCT",
    label: "search.expertise.smoke",
  },
  GREEN_GAZ: {
    value: "GREEN_GAZ",
    label: "search.expertise.green-gas",
  },
};

export const BUILDING_TYPE: { [index: string]: SearchType } = {
  // PAVILLON: {
  //     value: "PAVILLON",
  //     label: "search.buildingtype.pavillon",
  // },
  // APPARTMENT: {
  //     value: "APPARTMENT",
  //     label: "search.buildingtype.appartment",
  // },
  // IMMEUBLE: {
  //     value: "IMMEUBLE",
  //     label: "search.buildingtype.collectifBoiler",
  // },
  ERP: {
    value: "ERP",
    label: "search.buildingtype.erp",
  },
};

export interface SearchType {
  value: string;
  label: string;
  shortLabel?: string;
}

/* eslint-enable */
