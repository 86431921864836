import React from "react";
import SetPasswordComponent from "@components/forms/SetPasswordForm";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";

const InitPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout
      container
      contentInCard
      bodyClassName="login-body"
      title={t<string>("set-password.title")}
      mainClassName="bg-light-blue"
    >
      <SetPasswordComponent isCreation />
    </PageLayout>
  );
};

export default InitPasswordScreen;
