import React from "react";
import { Radio, Form, Space, RadioChangeEvent } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath, Rule } from "rc-field-form/lib/interface";
import { formUtils } from "@utils/form-utils";
import TextAreaFormField from "@components/inputs/TextAreaFormField";

interface PropsType {
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  initialValue?: string;
  value?: string;
  onChange?: (e: RadioChangeEvent) => void;
  fieldPrefix?: string;
  options: {
    value: number | string;
    label: string;
  }[];
  direction?: "horizontal" | "vertical";
  optionClassName?: string;
  rules?: Rule[];
  hasOther?: boolean;
  otherField?: string;
  otherModule?: string;
}

const RadioFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    field,
    required,
    readOnly,
    emptyErrorMessage,
    initialValue,
    value,
    onChange,
    fieldPrefix = "",
    options,
    direction = "horizontal",
    optionClassName = "",
    rules = [],
    hasOther = false,
    otherField,
    otherModule,
  } = props;

  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${i18nField}`.replace(",", "_");

  const getValueOfOther = () =>
    typeof options[0].value === "string" ? "OTHER" : options.length;

  return (
    <>
      <Form.Item
        name={fieldPrefix ? `${fieldPrefix}${field.toString()}` : field}
        key={fieldPrefix + key}
        initialValue={initialValue}
        rules={
          isRequired
            ? [
                ...rules,
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          emptyErrorMessage
                            ? emptyErrorMessage
                            : t<string>("common.empty_error_field"),
                        ),
                },
              ]
            : []
        }
      >
        <Radio.Group
          disabled={readOnly}
          className={`${direction === "vertical" ? "d-block" : ""} ${
            readOnly ? " readonly-form-field" : ""
          }`}
          id={fieldPrefix + key}
          onChange={onChange}
          value={value}
        >
          <Space
            direction={direction}
            className={`${direction === "vertical" ? "d-flex" : ""}`}
          >
            {options.map((option, index) => (
              <Radio
                key={index}
                value={option.value}
                className={optionClassName}
              >
                {option.label}
              </Radio>
            ))}
            {hasOther && (
              <div>
                <Radio
                  key={options.length}
                  value={getValueOfOther()}
                  className={optionClassName}
                >
                  {t("common.other")}
                </Radio>
                {value === getValueOfOther() && otherField && otherModule && (
                  <TextAreaFormField
                    className="mt-2"
                    readOnly={readOnly}
                    module={otherModule}
                    field={otherField}
                    required={true}
                  />
                )}
              </div>
            )}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default RadioFormField;
