import React from "react";
import { useTranslation } from "react-i18next";
import { Popconfirm, Tooltip, Button } from "antd";
import { ButtonType } from "antd/lib/button";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { TooltipPlacement } from "antd/lib/tooltip";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface TableProps {
  icon?: React.ReactNode;
  tooltipTitle?: string | null;
  buttonClassName?: string | null;
  popupTitle?: string | null;
  buttonType?: ButtonType | null;
  danger?: boolean;
  disabled?: boolean;
  size?: SizeType;
  children?: string;
  onConfirm?: () => void;
  onClick?: () => void;
  placement?: TooltipPlacement;
  tooltipPlacement?: TooltipPlacement;
}

const TableButton: React.FunctionComponent<TableProps> = (
  props: TableProps,
) => {
  const {
    popupTitle,
    tooltipTitle,
    tooltipPlacement,
    icon,
    buttonType,
    danger,
    buttonClassName,
    disabled,
    size = "large",
    children,
    onConfirm,
    onClick,
    placement,
  } = props;
  const { t } = useTranslation();
  const innerButton = tooltipTitle ? (
    <Tooltip
      title={tooltipTitle}
      mouseEnterDelay={0.2}
      placement={tooltipPlacement ? tooltipPlacement : "bottom"}
    >
      <Button
        disabled={disabled}
        danger={danger}
        type={buttonType !== null ? buttonType : "default"}
        icon={icon}
        size={size}
        className={!disabled && buttonClassName !== null ? buttonClassName : ""}
        onClick={onClick}
      >
        {children ? children : ""}
      </Button>
    </Tooltip>
  ) : (
    <Button
      disabled={disabled}
      danger={danger}
      type={buttonType !== null ? buttonType : "default"}
      icon={icon}
      size={size}
      className={!disabled && buttonClassName !== null ? buttonClassName : ""}
      onClick={onClick}
    >
      {children ? children : ""}
    </Button>
  );

  return (
    <>
      {popupTitle ? (
        <Popconfirm
          disabled={disabled}
          title={popupTitle}
          okText={t<string>("global.confirm")}
          cancelText={t<string>("global.cancel")}
          placement={placement ? placement : "top"}
          icon={<QuestionCircleOutlined />}
          onConfirm={() => {
            if (onConfirm) {
              onConfirm();
            }
          }}
        >
          {innerButton}
        </Popconfirm>
      ) : (
        <>{innerButton}</>
      )}
    </>
  );
};
TableButton.defaultProps = {
  buttonClassName: null,
  popupTitle: null,
  buttonType: null,
  danger: false,
};

export default TableButton;
