import { FunctionComponent, ReactElement } from "react";
import { FormItem, FormItemField } from "@components/forms/BaseForm";
import InputFormField from "@components/inputs/InputFormField";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import { SelectFormField } from "@components/inputs/SelectFormField";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import CheckboxFormField from "@components/inputs/CheckboxFormField";

interface Props {
  item: FormItem;
  editMode?: boolean;
}

const FormItemData: FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const getField = (
    formItemField: FormItemField,
    module: string,
    editMode?: boolean,
  ): ReactElement => {
    return (
      <Col xs={24} sm={formItemField.size ? formItemField.size : 24}>
        {formItemField.type === FORMS_FIELD_TYPE.TEXT && (
          <InputFormField
            showLabel
            module={module ? module : "default"}
            field={formItemField.name}
            maxLength={formItemField.maxLength}
            readOnly={!editMode}
            type={formItemField.htmlInputType}
            rules={[
              {
                required: formItemField.required,
                message: t<string>(
                  `${module}.form.fields.${formItemField.name}.errors.required`,
                ),
              },
              {
                pattern: formItemField.pattern
                  ? formItemField.pattern
                  : new RegExp(/.*/),
                message: t<string>(
                  `${module}.form.fields.${formItemField.name}.errors.invalid`,
                ),
              },
            ]}
          />
        )}
        {formItemField.type === FORMS_FIELD_TYPE.TEXTAREA && (
          <TextAreaFormField
            showLabel
            module={module ? module : "default"}
            field={formItemField.name}
            maxLength={formItemField.maxLength}
            readOnly={!editMode}
            required={formItemField.required}
            retractable={!!formItemField.retractable}
          />
        )}
        {(formItemField.type === FORMS_FIELD_TYPE.SELECT ||
          formItemField.type === FORMS_FIELD_TYPE.MULTISELECT) && (
          <SelectFormField
            showLabel
            module={module}
            mode={
              (formItemField.type === FORMS_FIELD_TYPE.MULTISELECT &&
                "multiple") ||
              undefined
            }
            field={formItemField.name}
            options={formItemField.options ? formItemField.options : []}
            readOnly={!editMode}
            rules={[
              {
                required: formItemField.required,
                message: t<string>(
                  `${module}.form.fields.${formItemField.name}.errors.required`,
                ),
              },
            ]}
          />
        )}
        {formItemField.type === FORMS_FIELD_TYPE.CHECKBOX && (
          <CheckboxFormField
            module={module}
            field={formItemField.name}
            readOnly={!editMode}
            label={t<string>(
              `${module}.form.fields.${formItemField.name}.label`,
            )}
            required={formItemField.required}
            parsed={formItemField.parsed}
          />
        )}
        {formItemField.type === FORMS_FIELD_TYPE.CUSTOM && formItemField.custom}
      </Col>
    );
  };

  return (
    <>
      {props.item &&
        props.item.fields.map((fields: FormItemField[], index: number) => (
          <Row key={index}>
            {fields.map((field: FormItemField) =>
              getField(
                field,
                props.item.module ? props.item.module : "default",
                props.editMode,
              ),
            )}
          </Row>
        ))}
    </>
  );
};

export default FormItemData;
