import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface TertiaryModalDetailProps {
  setStep: (step: number) => void;
}

export const TertiaryModalDetail: React.FC<TertiaryModalDetailProps> = ({
  setStep,
}) => {
  const { t } = useTranslation();

  return (
    <div className="professional">
      <h3 className="text-primary">
        {parse(t<string>("pro.tertiary.modal1.section1.title"))}
      </h3>
      <div className="mb-30">
        {parse(t<string>("pro.tertiary.modal1.section1.detail"))}
      </div>
      <h3 className="text-primary">
        {parse(t<string>("pro.tertiary.modal1.section2.title"))}
      </h3>
      <div className="mb-30">
        {parse(t<string>("pro.tertiary.modal1.section2.detail"))}
      </div>
      <h3 className="text-primary">
        {parse(t<string>("pro.tertiary.modal1.section3.title"))}
      </h3>
      <div className="mb-30">
        {parse(t<string>("pro.tertiary.modal1.section3.detail"))}
      </div>
      <div className="d-flex justify-content-center">
        <BasicButton
          text={t<string>("common.start")}
          onClick={() => setStep(1)}
        />
      </div>
    </div>
  );
};
