import { ROUTES } from "@utils/Routes";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Fragment, useEffect, useMemo, useState } from "react";
import { requestGetUserInfo } from "@state/auth/AuthEffects";
import { setUser } from "@state/auth/AuthEvents";

const AuthLayout = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthentified = useStore(authentifiedStore);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loginRedirect = useMemo(
    () =>
      ROUTES.professional.auth.login.generate({
        callback: location.pathname,
      }),
    [location],
  );

  useEffect(() => {
    void requestGetUserInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        setUser(ar.data);
      } else {
        // setUser() à remettre à null ?
        navigate(loginRedirect);
      }
      setIsLoading(false);
    });
  }, [navigate, loginRedirect]);

  if (!isLoading) {
    if (!isAuthentified?.user) {
      return (
        <Routes>
          <Route path="/*" element={<Navigate to={loginRedirect} />} />
        </Routes>
      );
    }
    return <Outlet />;
  }
  return <Fragment />;
};

export default AuthLayout;
