import { FunctionComponent, ReactElement } from "react";
import BasicCard from "@components/cards/BasicCard";
import { Checkbox } from "antd";

interface TertiaryCardProps {
  label: string;
  onClick?: () => any;
  className?: string;
  icon?: ReactElement;
  active: boolean;
}

const TertiaryCard: FunctionComponent<TertiaryCardProps> = ({
  label,
  active,
  icon,
  onClick,
  className = "",
}) => {
  return (
    <BasicCard
      className={`app-card-tertiary ${className} ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="d-flex flex-column ">
        <Checkbox
          checked={active}
          onClick={(e) => {
            e.stopPropagation();
            onClick;
          }}
        ></Checkbox>
        <div className="d-flex card-tertiary-icon  justify-content-center">
          {icon}
        </div>
        <span className="d-flex justify-content-center">{label}</span>
      </div>
    </BasicCard>
  );
};

export default TertiaryCard;
