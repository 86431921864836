import { ApiResponseDto } from "@type/rest.types";
import { GazEventRegistrationItemDetailsDto } from "@state/gazMonth/pro/dto/gazEvent.item.detail.dto";
import { GazProfessionalUser } from "@type/auth/auth.types";

export interface GazProfessionalItemDetailsDto extends ApiResponseDto {
  companyName: string;
  address1: string;
  address2?: string;
  address3?: string;
  zipcode: string;
  city: string;
  email: string;
  emailOptional?: string;
  phone1: string;
  phoneOptional?: string;
  latitude?: number;
  longitude?: number;
  editableEmail: string;
  editableAddress: string;
  editableZipCode: string;
  editableCity: string;
  editablePhone1?: string;
  editablePhone2?: string;
  website: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  description: string;
  visible: boolean;
  alert: boolean;
  bankAccountResourceId: string;
  rgeResourceId: string;
  thpeEvapdc: boolean;
  pacHybride: boolean;
  smokeDuct: boolean;
  installation?: boolean;
  maintenance?: boolean;
  pictureListId?: string[];
  gazMonth?: GazEventRegistrationItemDetailsDto;
}

export const getAddressString = (dto: GazProfessionalUser): string => {
  return [
    dto.editableAddress || dto.address1,
    dto.editableZipCode || dto.zipcode,
    dto.editableCity || dto.city,
  ].join(" ");
};
