import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ChildrenFormField } from "@components/inputs/ChildrenFormField";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { AddressVanillaField } from "@components/inputs/vanilla/AddressVanillaField";
import { useViewport } from "@utils/breakpoints-helper";

interface Props {
  module?: string;
  field?: string;
  setSelectedAddress?: (selectedAddress: AddressFieldsDto) => void;
  readOnly?: boolean;
  fieldPrefix?: string;
  initSearchValue?: string;
  autoSelect?: boolean;
  className?: string;
  geoLocation?: boolean;
  showLabel?: boolean;
  loader?: boolean;
  required?: boolean;
  onEnterPress?: () => void;
  onChange?: () => void;
  emptyErrorMessage?: string;
  setIsSelectedAddressExists?: (isSelectedAddressExists: boolean) => void;
  isInit?: boolean;
  landingPage?: boolean;
  placeholder?: string;
  allowClear?: boolean;
}

const AddressFields = ({
  module = "search",
  field = "street",
  setSelectedAddress,
  readOnly,
  fieldPrefix = "",
  initSearchValue,
  autoSelect = false,
  className = "",
  geoLocation = false,
  showLabel = false,
  required = true,
  loader = false,
  onEnterPress,
  emptyErrorMessage,
  setIsSelectedAddressExists,
  isInit,
  landingPage,
  placeholder,
  allowClear = false,
  onChange,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const { width } = useViewport();

  return (
    <>
      <ChildrenFormField
        showLabel={showLabel}
        readOnly={readOnly}
        required={required}
        module={module}
        field={field}
        fieldPrefix={fieldPrefix}
        className={className}
        emptyErrorMessage={emptyErrorMessage}
      >
        <AddressVanillaField
          id="addressSelect"
          disabled={readOnly}
          placeholder={
            placeholder ?? width >= 576
              ? t<string>("search.fields.street.placeholder")
              : t<string>("search.fields.street.placeholderMobile")
          }
          geoLocation={geoLocation}
          setSelectedAddress={setSelectedAddress}
          autoSelect={autoSelect}
          initialValue={initSearchValue}
          loader={loader}
          onEnterPress={onEnterPress}
          setIsSelectedAddressExists={setIsSelectedAddressExists}
          isInit={isInit}
          landingPage={landingPage}
          allowClear={allowClear}
          onChange={onChange}
        />
      </ChildrenFormField>
    </>
  );
};

export { AddressFields };
