import { ReactElement } from "react";
import Section from "@components/section/Section";
import { Col, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { useTranslation } from "react-i18next";
import SettingsSection from "@views/pro/gazProfessional/account/section/SettingsSection";
import { emailFormatRegex, phoneNumberFormatRegex } from "@utils/Constant";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { GazProfessionalUser } from "@type/auth/auth.types";
import parse from "html-react-parser";

interface Props {
  setSelectedAddress?: (selectedAddress: AddressFieldsDto) => void;
  initAddress?: string;
  userPg?: GazProfessionalUser;
  setIsSelectedAddressExists?: (isSelectedAddressExists: boolean) => void;
}

const MapSection = ({
  setSelectedAddress,
  initAddress,
  userPg,
  setIsSelectedAddressExists,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const isUserActive = userPg && userPg.status === "ACTIVE";

  return (
    <>
      <Section
        type="h2"
        title={t<string>("personalInfo.sectionTitle.map")}
        titleClassName="text-primary px-12 mb-10"
      >
        <>
          {isUserActive ? (
            <>
              <div className="mb-20 px-12">
                {t<string>("personalInfo.sectionSubtitle.map")}
              </div>
              <SettingsSection yoocanId={userPg?.yoocanId} />
              <div className="px-12">
                <Row gutter={16}>
                  <Col xs={0} lg={1} className="mx-0 px-0">
                    <img src="/img/icons/personalData/edit.svg" />
                  </Col>
                  <Col xs={24} lg={23}>
                    <Row gutter={16}>
                      <Col xs={24} lg={24}>
                        <AddressFields
                          required={false}
                          className={"address-select mb-4"}
                          geoLocation
                          showLabel
                          module="personalInfo"
                          field="addressComplet"
                          setSelectedAddress={setSelectedAddress}
                          initSearchValue={initAddress}
                          setIsSelectedAddressExists={
                            setIsSelectedAddressExists
                          }
                        />
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col xs={24} lg={12}>
                        <MaskInputFormField
                          mask="## ## ## ## ##"
                          showLabel
                          module="personalInfo"
                          field="editablePhone1"
                          rules={[
                            {
                              pattern: new RegExp(phoneNumberFormatRegex),
                              message: t<string>("forms.errors.format"),
                            },
                            {
                              min: 14,
                              message: t<string>("forms.errors.length", {
                                size: "10",
                              }),
                            },
                          ]}
                          type="tel"
                        />
                      </Col>
                      <Col xs={24} lg={12}>
                        <MaskInputFormField
                          mask="## ## ## ## ##"
                          showLabel
                          module="personalInfo"
                          field="editablePhone2"
                          rules={[
                            {
                              pattern: new RegExp(phoneNumberFormatRegex),
                              message: t<string>("forms.errors.format"),
                            },
                            {
                              min: 14,
                              message: t<string>("forms.errors.length", {
                                size: "10",
                              }),
                            },
                          ]}
                          type="tel"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} lg={12}>
                        <InputFormField
                          showLabel
                          module="personalInfo"
                          field="editableEmail"
                          rules={[
                            {
                              pattern: new RegExp(emailFormatRegex),
                              message: "common.email.errors.invalid",
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <div className="personal-data-screen">
              <div className="mb-20 px-12 removed-alert">
                {parse(t<string>("personalInfo.sectionSubtitle.removed"))}
              </div>
            </div>
          )}
        </>
      </Section>
    </>
  );
};

export default MapSection;
