import React, { createContext, useContext, useState } from "react";

export const MyContext = createContext<{
  value: string;
  setValue: (value: string) => void;
}>({
  value: "0",
  setValue: () => {
    throw new Error("Not implemented yet");
  },
});

export const MyContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [value, setValue] = useState("0");

  return (
    <MyContext.Provider value={{ value, setValue }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);
