import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { columns } from "@components/lists/PGSubscribersOPColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";
import BasicButton from "@components/buttons/BasicButton";
import { DownloadOutlined, MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import SearchFormField from "@components/inputs/SearchFormField";
import DropdownFilter from "@components/filters/DropdownFilter";
import { OptionType } from "@type/form/field.types";
import {
  PO_FILTERS_RENEWAL_STATE,
  PO_FILTERS_TITLE_PG,
} from "@utils/enums/po.filters.enum";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.item.response.dto";
import {
  requestGetOrganisationProfessionalPGs,
  requestGetOrganisationProfessionalPGsExportExcel,
} from "@state/organisationprofessional/OrganisationProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import { Loader } from "@components/loader/Loader";
import { mailToSelection } from "@utils/email-utils";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import FileSaver from "file-saver";
import { toastError, toastSuccess } from "@utils/toast-helper";

export const PGSubscribersOrganisationProfessionalScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [subscribers, setSubscribers] =
    useState<Pageable<OrganisationProfessionalGPItemResponseDto>>(
      emptySearchResult,
    );

  const searchTimeout = useRef<number>();
  const [localQuery, setLocalQuery] = useState<string>("");

  const [selectedRows, setSelectedRows] = useState<
    OrganisationProfessionalGPItemResponseDto[]
  >([]);

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [selectedRenewalState, setSelectedRenewalState] =
    useState<OptionType>();
  const [selectedTitle, setSelectedTitle] = useState<OptionType>();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "code",
    order: "ascend",
  });

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const fetchPgs = useCallback(() => {
    setDataHasLoaded(false);
    requestGetOrganisationProfessionalPGs({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {
        query: localQuery,
        renewalState: selectedRenewalState?.value,
        title: selectedTitle?.value,
      },
    }).catch(() => {
      toastAlert(t<string>("organisationprofessional.pgSubscribers.error"));
    });
  }, [
    pageNumber,
    limit,
    t,
    sorter,
    localQuery,
    selectedRenewalState,
    selectedTitle,
  ]);

  useEffect(() => {
    fetchPgs();
  }, [fetchPgs]);

  useEffect(() => {
    return requestGetOrganisationProfessionalPGs.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setSubscribers(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  const handleSubmit = () => {
    setButtonLoading(true);
    void requestGetOrganisationProfessionalPGsExportExcel({});
  };

  useEffect(() => {
    return requestGetOrganisationProfessionalPGsExportExcel.done.watch(
      ({ result }) => {
        afterExport(result);
      },
    );
  });

  const afterExport = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: "application/octet-stream",
      });
      FileSaver.saveAs(data, result.fileName);
      toastSuccess(
        t<string>("organisationprofessional.pgSubscribers.export.success"),
      );
    } else {
      toastError(
        t<string>("organisationprofessional.pgSubscribers.export.error"),
      );
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setLocalQuery(value), 800);
  };

  return (
    <PageLayout
      noProSideMenu
      organisationProSideMenu
      container
      titleCentered={false}
      title={t<string>("organisationprofessional.pgSubscribers.title")}
    >
      <>
        <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
          <h3 className="flex-grow-1 text-primary mb-0">
            {t("organisationprofessional.pgSubscribers.pgSubscriber", {
              count: subscribers.totalElements,
            })}
          </h3>
          <BasicButton
            variant="transparent-blue"
            text={t<string>(
              "organisationprofessional.pgSubscribers.downloadCSV",
            )}
            icon={<DownloadOutlined />}
            iconRight
            className="download-csv-button"
            onClick={handleSubmit}
            isLoading={buttonLoading}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end flex-wrap mb-3 gap-3">
          <div className="flex-grow-1 d-flex align-items-center flex-wrap gap-3">
            <h4 className="text-primary mb-0">
              {t("organisationprofessional.pgSubscribers.selectedSubscriber", {
                count: selectedRows.length,
              })}
            </h4>
            <BasicButton
              text={t<string>(
                "organisationprofessional.pgSubscribers.groupedMail",
              )}
              icon={<MailOutlined />}
              iconRight
              disabled={selectedRows.length < 1}
              size="sm"
              onClick={() =>
                (window.location.href = `mailto:${mailToSelection(
                  selectedRows,
                )}`)
              }
            />
          </div>
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
            <DropdownFilter
              label={t<string>(
                "organisationprofessional.pgSubscribers.filters.renewalState.label",
              )}
              items={PO_FILTERS_RENEWAL_STATE}
              selectedItem={selectedRenewalState}
              setSelectedItem={setSelectedRenewalState}
            />
            <DropdownFilter
              label={t<string>(
                "organisationprofessional.pgSubscribers.filters.title.label",
              )}
              items={PO_FILTERS_TITLE_PG}
              selectedItem={selectedTitle}
              setSelectedItem={setSelectedTitle}
            />
            <SearchFormField
              module="organisationprofessional.pgSubscribers"
              field="search"
              onChange={onChange}
              onSearch={(value: string) => setLocalQuery(value)}
              value={localQuery}
            />
          </div>
        </div>
        <Table
          locale={{
            emptyText: <Empty description={t<string>("common.noData")} />,
          }}
          columns={columns}
          dataSource={subscribers.content}
          loading={{
            spinning: !dataHasLoaded,
            indicator: <Loader />,
          }}
          size="small"
          rowKey={(record: OrganisationProfessionalGPItemResponseDto) =>
            record?.id
          }
          className="app-contact-pro-table pro-table-large-font"
          pagination={{
            total: subscribers.totalElements,
            pageSize: subscribers.size,
            current: subscribers.number + 1,
            onChange: setPageNumber,
            onShowSizeChange: onChangeLimit,
            showSizeChanger: true,
          }}
          onChange={(pagination, filters, sorter) =>
            setSorter(sorter as SorterResult<any>)
          }
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(
                  ROUTES.professional.organisationProfessional.adherentsPg.detail.generate(
                    record.id,
                  ),
                ),
            };
          }}
          rowSelection={{
            type: "checkbox",
            onChange: (
              selectedRowKeys: React.Key[],
              selectedRows: OrganisationProfessionalGPItemResponseDto[],
            ) => {
              setSelectedRows(selectedRows);
            },
          }}
        />
      </>
    </PageLayout>
  );
};
