import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import CertificateModal from "@components/modals/CertificateModal";
import { requestUpdateStatusELearningMention } from "@state/mention/pachybride/MentionPacHybrideEffects";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { setMentionPacHybride } from "@state/auth/AuthEvents";
import BasicModal from "@components/modals/BasicModal";
import { GazProfessionalUser } from "@type/auth/auth.types";
import BasicButton from "@components/buttons/BasicButton";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export const PacHybrideMentionIframeScreen: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const [isCertificateModalVisible, setIsCertificateModalVisible] =
    useState<boolean>(false);

  const [isMobileModalVisible, setIsMobileModalVisible] = useState<boolean>(
    window.innerWidth < 767,
  );

  const handleIframeTask = useCallback(
    (e: MessageEvent) => {
      if (e.data === "e-learning-ok" && authentifiedUser?.pacHybride) {
        window.removeEventListener("message", handleIframeTask);
        void requestUpdateStatusELearningMention({
          id: authentifiedUser.pacHybride.id,
          dto: undefined,
        });
        setIsCertificateModalVisible(true);
      }
    },
    [authentifiedUser.pacHybride],
  );

  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
  }, []);

  useEffect(() => {
    return requestUpdateStatusELearningMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
      }
    });
  });

  return (
    <PageLayout
      container
      title={
        authentifiedUser.pacHybride?.numberPacHybride == null
          ? t<string>("pro.mentions.pacHybride.form.title")
          : t<string>("pro.mentions.pacHybride.detail.renewalMention.title")
      }
      titleCentered={false}
      subtitle={
        authentifiedUser.pacHybride?.numberPacHybride != null ? (
          <div>
            {parse(
              t<string>(
                "pro.mentions.pacHybride.detail.renewalMention.iframeSubtitle",
              ),
            )}
          </div>
        ) : null
      }
      returnRoute={
        authentifiedUser.pacHybride?.numberPacHybride != null &&
        authentifiedUser.pacHybride?.status !== "PENDING"
          ? ROUTES.professional.pacsHybrides.mention.generate()
          : undefined
      }
    >
      <>
        {authentifiedUser.pacHybride?.numberPacHybride == null && (
          <div>
            <BasicButton
              variant="primary"
              text={t<string>("common.return")}
              onClick={() => {
                navigate(ROUTES.professional.pacsHybrides.mention.generate());
              }}
              className="mention-button mb-4"
            />
          </div>
        )}
        <div className="mention-iframe-container">
          <iframe
            src="/iframe/pac-hybride/story.html"
            className="mention-iframe"
          />
        </div>
        <CertificateModal
          open={isCertificateModalVisible}
          onCancel={() => {
            setIsCertificateModalVisible(false);
            navigate(ROUTES.professional.pacsHybrides.mention.generate());
          }}
          type={"pacHybride"}
        />
        <div className="pacHybride-mobile-modal-container">
          <BasicModal
            className="pacHybride-mobile-modal"
            open={isMobileModalVisible}
            buttonText={t<string>("common.ok")}
            onOk={() => setIsMobileModalVisible(false)}
            onCancel={() => setIsMobileModalVisible(false)}
          >
            <span>
              {t<string>("pro.mentions.pacHybride.mobileModal.experience")}
            </span>
          </BasicModal>
        </div>
      </>
    </PageLayout>
  );
};
