import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface IndividualGazEventHeaderProps {
  className?: string;
}

const IndividualGazEventHeader: FunctionComponent<
  IndividualGazEventHeaderProps
> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`app-individual-gaz-month-header ${className}`}
      style={{
        backgroundImage: "url('/img/home/header-rdc.webp')",
        backgroundPosition: "50% 25%",
      }}
    >
      <div className="gaz-month-header-container">
        <div className="gaz-month-header-left">
          <div className="gaz-month-header-description">
            {t<string>("gazMonth.individual.home.header.description")}
          </div>
          <h1 className="gaz-month-header-title">
            {t<string>("gazMonth.individual.home.header.title")}
          </h1>
        </div>
        <div className="gaz-month-header-right">
          <img
            alt={t<string>("gazMonth.individual.home.header.alt")}
            src="/img/gazMonth/logo-gaz-month.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default IndividualGazEventHeader;
