import { FC } from "react";
import BasicTag from "@components/tags/BasicTag";
import DateFormat from "@utils/DateFormat";
import Breadcrumbs, {
  BreadcrumbsItem,
} from "@components/breadcrumbs/Breadcrumbs";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

interface HeaderProps {
  title?: string | JSX.Element | null;
  breadcrumbs?: BreadcrumbsItem[] | null;
  date?: string | null;
  readingTime?: number;
  imageUrl?: string;
  imageAlt?: string;
  imageTitle?: string;
  tags?: string[];
  className?: string;
}

const ArticleHeader: FC<HeaderProps> = ({
  title,
  breadcrumbs = null,
  date,
  readingTime,
  imageUrl,
  imageAlt,
  imageTitle,
  tags = [],
  className = "",
}) => {
  const { t } = useTranslation();
  return (
    <div id={"top-header"} className={`app-article-header ${className}`}>
      <div className="container d-flex flex-column flex-lg-row align-items-center justify-content-center">
        <div className="article-header-left flex-grow-1 d-flex flex-column">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-end flex-wrap">
            <div className="article-header-breadcrumb flex-grow-1">
              {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div className="article-header-date">
                {date && DateFormat.articleDate(new Date(date))}
              </div>
              {readingTime ? (
                <div className="article-header-time d-flex align-items-center">
                  <img alt="reading-time" src="/img/icons/reading-time.svg" />
                  <div className="article-header-time-text">{`${readingTime} min`}</div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="article-header-title flex-grow-1 d-flex align-items-center">
            <h1>{title}</h1>
          </div>
          <div className="article-header-tags d-flex align-items-center flex-wrap">
            {tags &&
              tags?.length > 0 &&
              tags.map((tag) => {
                return (
                  <BasicTag
                    key={tag}
                    variant="light-primary"
                    label={tag}
                    className="article-header-tag"
                  />
                );
              })}
          </div>
        </div>
        {imageUrl && (
          <div className="article-header-img">
            <img
              alt={imageAlt || "Image principale"}
              title={imageTitle || "Titre image principale"}
              src={imageUrl}
            />
          </div>
        )}
        <div className="article-header-links d-flex flex-row flex-lg-column align-items-center justify-content-center">
          <FacebookShareButton url={window.location.href} className="icon">
            <img
              alt={t<string>("footer.icons.facebook")}
              src="/img/icons/icon-facebook.svg"
            />
          </FacebookShareButton>
          <TwitterShareButton url={window.location.href} className="icon">
            <img
              alt={t<string>("footer.icons.twitter")}
              src="/img/icons/icon-twitter.svg"
            />
          </TwitterShareButton>
          <LinkedinShareButton url={window.location.href} className="icon">
            <img
              alt={t<string>("footer.icons.linkedin")}
              src="/img/icons/icon-linkedin.svg"
            />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  );
};

export default ArticleHeader;
