import { FC } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";

import LoginOrganisationProfessionalForm from "@components/forms/LoginOrganisationProfessionalForm";

const LoginOrganisationProfessionalScreen: FC = () => {
  const { t } = useTranslation();
  return (
    <PageLayout
      container
      contentInCard
      title={t<string>("login.title")}
      bodyClassName="login-body"
      mainClassName="bg-light-blue"
      noProSideMenu
    >
      <LoginOrganisationProfessionalForm />
    </PageLayout>
  );
};

export default LoginOrganisationProfessionalScreen;
