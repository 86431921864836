import { Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { formUtils } from "@utils/form-utils";
import { useState } from "react";
import { IconPlus } from "../../static/icons";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string;
  required?: boolean;
  emptyErrorMessage?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: any) => void;
  initialValue?: string;
  fieldPrefix?: string;
  retractable?: boolean;
  className?: string;
}

const TextAreaFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    initialValue,
    fieldPrefix = "",
    retractable = false,
    className = "",
    maxLength,
  } = props;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const formItem = () => {
    return (
      <Form.Item
        name={fieldPrefix + field}
        key={fieldPrefix + field}
        label={
          showLabel ? (
            <span
              className={`${labelClassName || ""} ${
                readOnly ? "label-readonly" : ""
              }`}
            >
              {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
              {labelTooltip && <Tooltip title={labelTooltip} />}
            </span>
          ) : (
            ""
          )
        }
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage
              ? emptyErrorMessage
              : t<string>(`${module}.form.fields.${field}.errors.required`),
            whitespace: isRequired,
          },
        ]}
        initialValue={initialValue}
        className={className}
      >
        <Input.TextArea
          className={`${fieldClassName ? fieldClassName : ""} ${
            readOnly ? " readonly-form-field" : ""
          }`}
          placeholder={t<string>(
            `${module}.form.fields.${i18nField}.placeholder`,
          )}
          disabled={readOnly}
          autoSize={{ minRows: 3 }}
          onChange={onChange}
          maxLength={maxLength}
        />
      </Form.Item>
    );
  };

  return (
    <>
      {retractable ? (
        <div className="app-textarea-retractable">
          {isOpen ? (
            formItem()
          ) : (
            <div
              onClick={() => setIsOpen(true)}
              className="textarea-retractable-action"
            >
              <IconPlus />
              {t<string>("forms.textarea.addMessage")}
            </div>
          )}
        </div>
      ) : (
        formItem()
      )}
    </>
  );
};

export default TextAreaFormField;
