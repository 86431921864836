import { Checkbox, Form } from "antd";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ModuleSpecialitiesProps {
  readOnly: boolean;
}

const ModuleSpecialities: FunctionComponent<ModuleSpecialitiesProps> = ({
  readOnly,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="rg-request-form-type-label">
        {t<string>("pro.qualifications.modals.addRG.form.labels.type")}
      </div>
      <div className={"d-flex w-100"}>
        <Form.Item name="installation" valuePropName="checked">
          <Checkbox disabled={readOnly}>
            {t<string>(
              "pro.qualifications.modals.addRG.form.fields.type.installation",
            )}
          </Checkbox>
        </Form.Item>
        <Form.Item name="maintenance" valuePropName="checked">
          <Checkbox disabled={readOnly}>
            {t<string>(
              "pro.qualifications.modals.addRG.form.fields.type.maintenance",
            )}
          </Checkbox>
        </Form.Item>
      </div>
    </>
  );
};

export default ModuleSpecialities;
