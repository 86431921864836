import { FunctionComponent } from "react";
import BasicButton from "@components/buttons/BasicButton";
import { ButtonAction } from "@type/cms/cms.types";
import { clearHtml } from "@utils/html-utils";

interface RichTextProps {
  content: string;
  className?: string;
  backgroundColor?: string;
  carded?: boolean;
  cardColor?: string;
  buttonAction?: ButtonAction;
  buttonClassName?: string;
  buttonAlignment?: "start" | "center" | "end";
}

const RichTextModuleCMS: FunctionComponent<RichTextProps> = ({
  content = "",
  className = "",
  backgroundColor = "transparent",
  carded = false,
  cardColor = "white",
  buttonAction = undefined,
  buttonClassName = "",
  buttonAlignment = "center",
}) => {
  return (
    <div
      className={`app-richtext ${className} ${carded ? "richtext-carded" : ""}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={"richtext-content content-contained"}
        style={{ backgroundColor: carded ? cardColor : "transparent" }}
      >
        <div
          dangerouslySetInnerHTML={{
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            __html: clearHtml(content),
          }}
        />
        {buttonAction && (
          <a
            href={buttonAction.url}
            className={`d-flex justify-content-${buttonAlignment} unstyled-a`}
          >
            <BasicButton
              variant={buttonAction?.variant}
              text={buttonAction.buttonLabel}
              className={`richtext-button ${buttonClassName}`}
            />
          </a>
        )}
      </div>
    </div>
  );
};

export default RichTextModuleCMS;
