import { File } from "@type/resource-type-interface";
import { requestGetResource } from "@state/resource/ResourceEffects";
import FileSaver from "file-saver";

export const getBase64 = (
  img: Blob,
  callback: (imageUrl: string | ArrayBuffer | null) => void,
): void => {
  const reader = new FileReader();
  reader.addEventListener("load", () =>
    callback(removeBase64UrlPrefix(reader.result as string)),
  );
  reader.readAsDataURL(img);
};

export const removeBase64UrlPrefix = (base64File: string): string => {
  const i = base64File.indexOf("base64,");
  return base64File.slice(i + 7);
};

export const buildBase64Image = (file?: File, defaultImage = ""): string => {
  return file ? `data:image/png;base64, ${file.base64Data}` : defaultImage;
};

export const getBase64UrlFrom = (base64: string, type: string): string => {
  return `data:${type};base64,${base64}`;
};

export const downloadFile = (
  url: string,
  type: string,
  filename: string,
): void => {
  const blob = b64toBlob(url, type);
  FileSaver.saveAs(blob, filename);
};

export const debugBase64 = (
  base64URL: string,
  type = "application/pdf",
): void => {
  const blob = b64toBlob(base64URL, type);
  const blobURL = URL.createObjectURL(blob);
  const win = window.open(blobURL);
  if (win) {
    const theDoc = win.document;
    const theScript = theDoc.createElement("script");
    theDoc.body.appendChild(theScript);
  }
};

const b64toBlob = (content: string, contentType: string) => {
  contentType = contentType || "";
  const sliceSize = 512;
  // method which converts base64 to binary
  const byteCharacters = window.atob(content);

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, {
    type: contentType,
  });
};

export const downloadFromFile = (file: File): void => {
  const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
  let safariAgent = navigator.userAgent.indexOf("Safari") > -1;
  if (chromeAgent && safariAgent) safariAgent = false;
  if (file && file.id) {
    void requestGetResource(file.id).then((resource) => {
      if (resource.ok && resource.data) {
        downloadFile(resource.data.data, file.type, file.name);
      }
    });
  }
};
