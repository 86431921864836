import i18n from "i18next";
import { Tooltip } from "antd";
import { tooltipDateNoTimeRender } from "@components/tooltips/CustomTooltip";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import TableButton from "@components/buttons/TableButton";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";

interface GPParams {
  type: "GP";
  getPDFLink: (mailId: string) => string;
  updateRead: (mail: GazProfessionalHabitAPlusMailsResponseDto) => void;
}

interface OPParams {
  type: "OP";
  getPDFLink: (mailId: string, pgId: string) => string;
  pgId: string;
}

export const columns = (
  onRowClick: (record: GazProfessionalHabitAPlusMailsResponseDto) => void,
  params: GPParams | OPParams,
): ColumnsType<GazProfessionalHabitAPlusMailsResponseDto> => [
  {
    title: i18n.t("pro.mail.table.headers.date"),
    dataIndex: "date",
    key: "date",
    className: "date",
    width: 150,
    render: tooltipDateNoTimeRender,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("pro.mail.table.headers.title"),
    key: "label",
    className: "label",
    ellipsis: {
      showTitle: false,
    },
    render: (record: GazProfessionalHabitAPlusMailsResponseDto): ReactNode => {
      return (
        <div className="d-flex align-items-center gap-3">
          <Tooltip placement="topLeft" title={record.title}>
            {record.title}
          </Tooltip>
          {params.type === "GP" && !record.read && (
            <BasicTag
              label={i18n.t("pro.mail.table.isNotRead")}
              variant="light-green"
            />
          )}
        </div>
      );
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("pro.mail.table.headers.actions"),
    key: "id",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 105,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (
      record: GazProfessionalHabitAPlusMailsResponseDto,
    ): JSX.Element => (
      <div className="d-inline-flex">
        <TableButton
          tooltipTitle={i18n.t("pro.mail.table.tooltip.details")}
          icon={<EyeOutlined />}
          buttonClassName="btn-transparent w-auto"
          onClick={() => {
            onRowClick(record);
          }}
        />
        <TableButton
          tooltipTitle={i18n.t("pro.mail.table.tooltip.download")}
          icon={<DownloadOutlined />}
          buttonClassName="btn-transparent w-auto ms-3"
          onClick={() => {
            if (params.type === "OP") {
              window.open(params.getPDFLink(record.id, params.pgId), "_blank");
            } else {
              window.open(params.getPDFLink(record.id), "_blank");
              params.updateRead(record);
            }
          }}
        />
      </div>
    ),
  },
];
