import { createEvent } from "effector";
import {
  GazProfessionalUser,
  AuthResult,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import { Universe } from "@utils/enums/universe.enum";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import {
  GazEventActionResponseDto,
  GazEventRegistrationResponseDto,
} from "@state/gazMonth/dto/gaz.event.registration.response.dto";
import { WorksiteResponseDto } from "@state/worksite/dto/worksite.response.dto";

export const authentifiedContextEvent = createEvent<
  GazProfessionalUser | OrganisationProfessionalUser | undefined
>();

export const login = createEvent<AuthResult>();

export const changeUniverse = createEvent<Universe>("CHANGE_UNIVERSE");

export const setUser = createEvent<GazProfessionalUser>("SET_USER");

export const setOrganisationProfessional =
  createEvent<OrganisationProfessionalUser>("SET_ORGANISATION_PROFESSIONAL");

export const clearUser = createEvent<void>("CLEAR_USER");

export const setMentionPacHybride = createEvent<MentionResponseDto>(
  "SET_MENTION_PAC_HYBRIDE",
);
export const setMentionSmokeDuct = createEvent<MentionResponseDto>(
  "SET_MENTION_SMOKE_DUCT",
);
export const setShowGazEventAlert = createEvent<boolean>(
  "SET_SHOW_GAZ_EVENT_ALERT",
);
export const setWorksite = createEvent<WorksiteResponseDto>("SET_WORKSITE");

export const setGazEventRegistration =
  createEvent<GazEventRegistrationResponseDto>("SET_GAZ_MONTH_REGISTRATION");

export const addGazEventAction = createEvent<GazEventActionResponseDto>(
  "ADD_GAZ_MONTH_ACTION",
);

export const updateGazEventAction = createEvent<GazEventActionResponseDto>(
  "UPDATE_GAZ_MONTH_ACTION",
);

export const deleteGazEventAction = createEvent<string>(
  "DELETE_GAZ_MONTH_ACTION",
);

export const logout = createEvent<void>();
