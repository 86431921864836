import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Col, Form, Modal, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { Store } from "rc-field-form/lib/interface";
import { emailFormatRegex, formItemBlockLayout } from "@utils/Constant";
import RadioFormField from "@components/inputs/RadioFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import UploadFormField from "@components/inputs/UploadFormField";
import { setWorksite } from "@state/auth/AuthEvents";
import { NUMBER_BOILER, TYPE_RENOVATION } from "@utils/enums/worksite.enum";
import { requestCreateWorksite } from "@state/worksite/WorksiteEffects";
import SubmitButton from "@components/buttons/SubmitButton";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import { toastError } from "@utils/toast-helper";

const { confirm } = Modal;

export const WorksiteFormScreen: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [typePipe, setTypePipe] = useState<string>("individual");
  const [resourceId, setResourceId] = useState<string[] | undefined>();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const [acceptedMentionTypes] = useState<string[]>([
    RESOURCE_TYPE.PDF.applicationType,
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isDevisLoading, setIsDevisLoading] = useState<boolean>(false);
  const [isRibLoading, setIsRibLoading] = useState<boolean>(false);

  const onChange = (): void => {
    typePipe === "individual"
      ? setTypePipe("collectif")
      : setTypePipe("individual");
  };

  const isIndividual = typePipe === "individual";

  const InitialValues = {
    email: authentifiedUser?.email,
  };
  useEffect(() => {
    form.resetFields(["numberBoiler", "typeRenovation"]);
  }, [form, typePipe]);

  const confirmationPopup = async () => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content: t<string>("worksite.modal.save.title"),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        className: "app-modal professional",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const handleSubmit = (values: Store) => {
    void confirmationPopup().then((confirmed: boolean) => {
      if (confirmed) {
        setIsLoading(true);
        void requestCreateWorksite({
          dto: {
            name: values.name as string,
            city: values.city as string,
            address: values.address as string,
            zip: values.zip as string,
            typePipe: values.typePipe as string,
            numberBoiler: values.numberBoiler as number,
            typeRenovation: values.typeRenovation as string,
            resourceId: resourceId,
            email: values.worksiteEmail as string,
          },
        });
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    return requestCreateWorksite.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setWorksite(result.data);
        navigate(ROUTES.professional.smokeDucts.mention.generate());
      } else if (result?.errorMessage) {
        toastError(result?.errorMessage);
      }
    });
  });

  return (
    <PageLayout
      container
      title={t<string>("worksite.form.title")}
      titleCentered={false}
      bodyClassName="app-worksite-form-page"
      noProSideMenu
      returnRoute={ROUTES.professional.smokeDucts.mention.generate()}
      returnText={t<string>("worksite.detail.return")}
      confirmReturnRoute={true}
      confirmReturnRouteText={t<string>("worksite.modal.exit.title")}
    >
      <div className="worksite-form-page-right ps-lg-4">
        <Form
          form={form}
          {...formItemBlockLayout}
          onFinish={handleSubmit}
          initialValues={InitialValues}
        >
          <div className="required">
            {t<string>("pro.mentions.smokeDuct.form.required")}
          </div>
          <InputFormField showLabel module="worksite" field="name" required />
          <InputFormField
            showLabel
            module="worksite"
            field="address"
            required
          />
          <Row gutter={10}>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                showLabel
                module="worksite"
                field="city"
                required
              />
            </Col>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <InputFormField
                showLabel
                module="worksite"
                field="zip"
                required
              />
            </Col>
          </Row>
          <div className="px-12">
            <div className="mb-2 font-weight-bold">
              {t<string>("worksite.form.radioGroup.typePipe.title")}
            </div>
            <RadioFormField
              initialValue={typePipe}
              required
              field="typePipe"
              onChange={onChange}
              options={[
                {
                  value: "individual",
                  label: t<string>(
                    "worksite.form.radioGroup.typePipe.individual",
                  ),
                },
                {
                  value: "collectif",
                  label: t<string>(
                    "worksite.form.radioGroup.typePipe.collectif",
                  ),
                },
              ]}
            />
          </div>
          <Row gutter={10}>
            <Col xs={24} sm={12} lg={24} xl={12}>
              <SelectFormField
                initialValue={isIndividual ? "1" : "2"}
                module="worksite"
                field="numberBoiler"
                showLabel
                required
                options={
                  isIndividual
                    ? NUMBER_BOILER.filter((s) => s.value === "1")
                    : NUMBER_BOILER.filter((s) => s.value !== "1")
                }
                readOnly={isIndividual}
              />
            </Col>

            <Col xs={24} sm={12} lg={24} xl={12}>
              <SelectFormField
                module="worksite"
                field="typeRenovation"
                showLabel
                required
                readOnly={isIndividual}
                options={
                  isIndividual
                    ? TYPE_RENOVATION.filter(
                        (s) => s.value === "INDIVIDUAL_RENOVATION",
                      )
                    : TYPE_RENOVATION.filter(
                        (s) => s.value !== "INDIVIDUAL_RENOVATION",
                      )
                }
                initialValue={
                  isIndividual
                    ? "INDIVIDUAL_RENOVATION"
                    : "COLLECTIVE_RENOVATION_SHUNT_COL"
                }
              />
            </Col>
          </Row>
          {isIndividual ? (
            <div>
              <h1 className="worksite-form-title">
                {t<string>("worksite.form.ressources.title")}
              </h1>

              <UploadFormField
                name="devis"
                label={t<string>("worksite.form.devis")}
                domain="WORKSITE_DEVIS"
                saveFile
                setFilesId={setResourceId}
                filesId={resourceId}
                types={acceptedMentionTypes}
                formatLabel="PDF"
                multiple
                onRemove
                setIsUploading={setIsDevisLoading}
              />
              <UploadFormField
                name="rib"
                label={t<string>("worksite.form.rib")}
                labelTooltip={t<string>("worksite.form.tooltip.rib")}
                domain="WORKSITE_RIB"
                saveFile
                setFilesId={setResourceId}
                filesId={resourceId}
                types={acceptedMentionTypes}
                formatLabel="PDF"
                multiple
                onRemove
                setIsUploading={setIsRibLoading}
              />
            </div>
          ) : (
            <>
              <div className="text-center font-weight-bold">
                {t<string>("worksite.collectif.form.label1")}
              </div>
              <div className="text-center font-weight-bold mb-5">
                {t<string>("worksite.collectif.form.label2")}
              </div>
              <Row>
                <Col xs={24} sm={12} lg={24} xl={12}>
                  <InputFormField
                    showLabel
                    module="worksite"
                    field="email"
                    required
                    readOnly
                  />
                </Col>
                <Col xs={24} sm={12} lg={24} xl={12}>
                  <InputFormField
                    showLabel
                    module="worksite"
                    field="worksiteEmail"
                    rules={[
                      {
                        pattern: new RegExp(emailFormatRegex),
                        message: t<string>("common.email.errors.invalid"),
                      },
                    ]}
                  />
                </Col>
              </Row>
            </>
          )}
          <div className="ant-btn-right">
            <Row>
              <SubmitButton
                module={isIndividual ? "worksite" : "worksite.collectif"}
                className="mw-190 mb-30 mx-2 it-button"
                label="submit"
                isSubmitting={isDevisLoading || isRibLoading || isLoading}
              />
            </Row>
          </div>
        </Form>
      </div>
    </PageLayout>
  );
};
