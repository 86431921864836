export const BACKGROUNDS: { [index: string]: Background } = {
  GREEN: {
    value: "GREEN",
    color: "primary-green",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LIGHT_GREEN: {
    value: "LIGHT_GREEN",
    color: "light-green",
  },
  DARK: {
    value: "DARK",
    color: "dark",
  },
  BLUE: {
    value: "BLUE",
    color: "primary-blue",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LIGHT_BLUE: {
    value: "LIGHT_BLUE",
    color: "light-blue",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LIGHT_GREY: {
    value: "LIGHT_GREY",
    color: "light-grey",
  },
};

export interface Background {
  value:
    | "GREEN"
    | "LIGHT_GREEN"
    | "BLUE"
    | "LIGHT_BLUE"
    | "DARK"
    | "LIGHT_GREY";
  color: string;
}
