import { FieldData } from "rc-field-form/lib/interface";
import { NamePath } from "antd/lib/form/interface";
import { FieldErrors } from "@utils/rest/ServerResponseParse";

const replaceKey = (originalName: NamePath, keys: string[]): string => {
  const name = originalName.toString();
  const fields = name.split(".");
  const key = fields.find((field: string): boolean => !isNaN(Number(field)));
  if (key) {
    const index = fields.findIndex((field: string): boolean => field === key);
    fields[index] = keys[parseInt(key)];
    return fields.join(".");
  }
  return name;
};

function addErrorsToFieldsData(
  fieldsData: FieldData[],
  fieldsErrors: FieldErrors,
  keys?: string[],
): FieldData[] {
  const newFieldsData = [...fieldsData];
  Object.keys(fieldsErrors).forEach((fieldError: string) => {
    const fieldData = fieldsData.find((f: FieldData) => f.name === fieldError);
    const fieldDataIndex = fieldsData.findIndex(
      (f: FieldData) => f.name === fieldError,
    );
    if (fieldData) {
      newFieldsData.splice(fieldDataIndex, 0, {
        ...fieldData,
        name: fieldError,
        errors: [fieldsErrors[fieldError]],
      });
    } else {
      newFieldsData.push({
        name: fieldError,
        errors: [fieldsErrors[fieldError]],
      });
    }
  });
  if (keys) {
    return newFieldsData.map((fieldData: FieldData) => ({
      name: replaceKey(fieldData.name, keys),
      errors: fieldData.errors,
    }));
  } else {
    return newFieldsData;
  }
}

const generateRndKeyInteger = (): number => {
  return Math.floor(Math.random() * 100000);
};

export const formHelper = {
  addErrorsToFieldsData,
  generateRndKeyInteger,
};
