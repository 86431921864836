import { FunctionComponent, useState } from "react";
import BasicCard from "@components/cards/BasicCard";
import GazEventActionTag from "@components/tags/GazEventActionTag";
import BasicButton from "@components/buttons/BasicButton";
import { useTranslation } from "react-i18next";
import BasicModal from "@components/modals/BasicModal";
import { formItemBlockLayout } from "@utils/Constant";
import { Divider, Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import SubmitButton from "@components/buttons/SubmitButton";
import { Store } from "rc-field-form/lib/interface";
import { GazEventActionSaveRequestDto } from "@state/gazMonth/pro/dto/gazEvent.save.request.dto";
import {
  requestUpdateGazEventAction,
  requestUpdateGazEventActionStatus,
} from "@state/gazMonth/pro/GazEventActionEffect";
import GazEventActionForm from "@components/forms/GazEventActionForm";
import moment, { MomentInput } from "moment";
import { ActionType } from "@utils/enums/gaz.month.action.type.enum";
import configuration from "@utils/Config";
import DateFormat from "@utils/DateFormat";

interface GazEventActionCardProps {
  id: string;
  actionType: ActionType;
  title: string;
  actionFrom: string;
  actionTo: string;
  description?: string;
  onDelete?: () => any;
  className?: string;
  amount?: string;
  otherAmount?: number;
  partner?: string[];
  status?: string;
}

export interface GazEventActionFormValues {
  title: string;
  actionFrom: MomentInput;
  actionTo: MomentInput;
  description?: string;
  amount?: string;
  otherAmount?: number;
  partner?: string[];
}

const GazEventActionCard: FunctionComponent<GazEventActionCardProps> = ({
  actionType,
  title,
  partner,
  actionFrom,
  actionTo,
  description,
  onDelete,
  className = "",
  amount,
  otherAmount,
  id,
  status,
}) => {
  const { t } = useTranslation();
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isChangeStatusModalVisible, setIsChangeStatusModalVisible] =
    useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [form] = useForm();
  const gazEventType = configuration.gazEventType;
  const isActionVisible = status && status === "ENABLED";

  const initialValue = (): GazEventActionFormValues => {
    return {
      title: title,
      actionFrom: moment.utc(actionFrom),
      actionTo: moment.utc(actionTo),
      description: description || "",
      amount: amount ?? "",
      otherAmount: otherAmount ?? 0,
      partner: partner,
    };
  };

  const handleValue = (values: Store, actionType: string) => {
    const amount = (values[`${actionType}.amount`] as string) ?? null,
      description = (values[`${actionType}.description`] as string) ?? null,
      otherAmount = (values[`${actionType}.otherAmount`] as string) ?? null;
    partner = (values[`${actionType}.partner`] as string[]) ?? null;

    const actionToSave: GazEventActionSaveRequestDto = {
      actionType: actionType.toLocaleUpperCase(),
      amount: amount,
      description: description,
      otherAmount: otherAmount,
      partner: partner,
    };
    return actionToSave;
  };

  const handleChangeStatus = (status: string) => {
    void requestUpdateGazEventActionStatus({
      dto: { status },
      id: id,
    });
  };

  const handleSubmit = (values: Store) => {
    const actionToSave = handleValue(values, actionType.value.toLowerCase());

    void requestUpdateGazEventAction({
      dto: actionToSave,
      id: id,
    });
    setIsEditModalVisible(false);
  };

  return (
    <>
      <BasicCard
        className={`app-card-action-gaz-month ${
          isActionVisible ? "" : "card-gaz-event-disabled"
        } ${className}`}
      >
        <>
          <div className="d-flex align-items-center">
            <div className="action-type mb-20">
              <GazEventActionTag label={"Offre"} />
            </div>
            <div className="action-title mb-20">{title}</div>
            <div className="action-date flex-grow-1 mb-20">
              {`${t<string>(
                `${gazEventType}.pro.subscribed.actions.from`,
              )} ${DateFormat.standardDate(new Date(actionFrom))}${
                actionTo
                  ? ` ${t<string>(
                      `${gazEventType}.pro.subscribed.actions.to`,
                    )} ${DateFormat.standardDate(new Date(actionTo))}`
                  : ""
              }`}
            </div>
          </div>
          {description && <div>{description}</div>}
          <Divider className="mt-3" />
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="action-footer">
              {isActionVisible
                ? t<string>(
                    `${gazEventType}.pro.subscribed.actions.offer.visible`,
                  )
                : t<string>(
                    `${gazEventType}.pro.subscribed.actions.offer.notVisible`,
                  )}
            </div>
            <div className="d-flex align-items-center justify-content-end flex-wrap gap-3">
              <div
                onClick={() => setIsChangeStatusModalVisible(true)}
                className="action-footer-link"
              >
                <img
                  src={
                    isActionVisible
                      ? "/img/pro/gazevent/barred-eye.svg"
                      : "/img/pro/gazevent/eye.svg"
                  }
                  alt={t<string>("menu.alt.audit")}
                />
                {t<string>(
                  isActionVisible
                    ? `${gazEventType}.pro.subscribed.actions.offer.disabled`
                    : `${gazEventType}.pro.subscribed.actions.offer.enabled`,
                )}
              </div>

              <div
                onClick={() => setIsEditModalVisible(true)}
                className="action-footer-link"
              >
                <img
                  src={"/img/pro/gazevent/pen.svg"}
                  alt={t<string>("menu.alt.audit")}
                />
                {t<string>(`${gazEventType}.pro.subscribed.actions.offer.edit`)}
              </div>
              <div
                onClick={() => setIsDeleteModalVisible(true)}
                className="action-footer-link"
              >
                <img
                  src="/img/pro/gazevent/delete-cross.svg"
                  alt={t<string>("menu.alt.audit")}
                />
                {t<string>(
                  `${gazEventType}.pro.subscribed.actions.offer.delete`,
                )}
              </div>
            </div>
          </div>
        </>
      </BasicCard>
      <BasicModal
        open={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        footer={null}
      >
        <>
          <div className="text-center mt-30 mb-30">
            {t<string>(
              `${gazEventType}.pro.subscribed.actions.modals.delete.offer.title`,
            )}
          </div>
          <div className="d-flex justify-content-center">
            <BasicButton
              variant="primary"
              className="mw-190 mb-30 mx-2"
              text={t<string>("buttons.validate")}
              onClick={() => {
                onDelete && onDelete();
                setIsDeleteModalVisible(false);
              }}
            />
            <BasicButton
              variant="primary-outline"
              className="mw-190 mb-30 mx-2"
              text={t<string>("buttons.cancel")}
              onClick={() => setIsDeleteModalVisible(false)}
            />
          </div>
        </>
      </BasicModal>
      <BasicModal
        open={isChangeStatusModalVisible}
        onCancel={() => setIsChangeStatusModalVisible(false)}
        footer={null}
      >
        <>
          <div className="text-center mt-30 mb-30">
            {t<string>(
              `${gazEventType}.pro.subscribed.actions.modals.status.${
                isActionVisible ? "enabled" : "disabled"
              }.title`,
            )}
          </div>
          <div className="d-flex justify-content-center">
            <BasicButton
              variant="primary"
              className="mw-190 mb-30 mx-2"
              text={t<string>("buttons.validate")}
              onClick={() => {
                isActionVisible
                  ? handleChangeStatus("DISABLED")
                  : handleChangeStatus("ENABLED");
                setIsChangeStatusModalVisible(false);
              }}
            />
            <BasicButton
              variant="primary-outline"
              className="mw-190 mb-30 mx-2"
              text={t<string>("buttons.cancel")}
              onClick={() => setIsChangeStatusModalVisible(false)}
            />
          </div>
        </>
      </BasicModal>
      <BasicModal
        open={isEditModalVisible}
        footer={null}
        className="modal-action-gaz-month-edit"
      >
        <>
          <div className="modal-type mb-20">{actionType.label}</div>
          <div className="px-12">
            <Form
              onFinish={handleSubmit}
              form={form}
              initialValues={initialValue()}
              {...formItemBlockLayout}
            >
              <GazEventActionForm
                initialValue={initialValue()}
                actionType={actionType}
              />
              <div className="d-flex align-items-center justify-content-end flex-wrap">
                <div
                  onClick={() => setIsEditModalVisible(false)}
                  className="btn-primary-link mb-20"
                >
                  {t<string>("buttons.cancel")}
                </div>
                <SubmitButton
                  module="gazMonth.pro.subscribed.actions.modals.edit"
                  isSubmitting={false}
                  label="submit"
                  className="mb-20 ms-4"
                />
              </div>
            </Form>
          </div>
        </>
      </BasicModal>
    </>
  );
};

export default GazEventActionCard;
