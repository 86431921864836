import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { UNIVERSES } from "@utils/enums/universe.enum";
import BasicButton from "@components/buttons/BasicButton";
import { useStructures } from "@state/structure/Structures";
import { ROUTES } from "@utils/Routes";
interface FooterProps {
  className?: string;
}

const Footer: FunctionComponent<FooterProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const structure = useStructures();

  return (
    <footer className={className}>
      <div className="footer-container">
        <div className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-center flex-wrap">
          <div className="logo-container">
            <Link
              to={`/${UNIVERSES[authentifiedContext.universe.value].urlValue}`}
            >
              <img
                alt={t<string>("footer.logo.alt")}
                className="logo px-2"
                src={`/logo-header-PG-${UNIVERSES[
                  authentifiedContext.universe.value
                ].value.toLocaleLowerCase()}.svg`}
              />
            </Link>
          </div>
          <div className="links flex-grow-1 d-flex flex-column mx-5">
            {structure?.universe
              .filter(
                (structure) =>
                  structure.type === "CATEGORY" &&
                  structure.children.length != 0,
              )
              .map((category) => (
                <Link
                  key={category.key}
                  className="link"
                  to={ROUTES.public.summary.generate(category.seoFriendlyTitle)}
                >
                  {category.title}
                </Link>
              ))}
            <Link
              to={ROUTES.public.actualites.generate(
                authentifiedContext.universe,
              )}
              className="link"
            >
              {t<string>("menu.news")}
            </Link>
            {authentifiedContext.universe.value ===
              UNIVERSES.PROFESSIONAL.value && (
              <Link
                to={ROUTES.professional.contacts.publicForm.generate()}
                className="link"
              >
                {t<string>("menu.contact")}
              </Link>
            )}
          </div>
          <div className="user d-flex align-items-center mx-5">
            {authentifiedContext &&
              !authentifiedContext.user &&
              authentifiedContext.universe === UNIVERSES.PROFESSIONAL && (
                <Link to={ROUTES.professional.auth.login.generate()}>
                  <BasicButton
                    text={t<string>("footer.login")}
                    className="btn-login ms-2"
                  />
                </Link>
              )}
          </div>
          <div className="icons d-flex align-items-center justify-content-center">
            <div
              className="icon"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/PG-Professionnel-du-Gaz-270730473857893",
                )
              }
            >
              <img
                alt={t<string>("footer.icons.facebook")}
                src={"/img/icons/facebook-individual-v2.svg"}
              />
            </div>
            <div
              className="icon"
              onClick={() => window.open("https://twitter.com/ProsduGaz")}
            >
              <img
                alt={t<string>("footer.icons.twitter")}
                src={"/img/icons/twitter-individual-v2.svg"}
              />
            </div>
            <div
              className="icon"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCPSDiIGl5iIUlHpDAqaZtkw",
                )
              }
            >
              <img
                alt={t<string>("footer.icons.youtube")}
                src={"/img/icons/youtube-individual-v2.svg"}
              />
            </div>
            <a
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/pg-professionnel-du-gaz/",
                )
              }
              className="icon"
            >
              <img
                alt={t<string>("footer.icons.linkedin")}
                src={"/img/icons/linkedin-individual-v2.svg"}
              />
            </a>
          </div>
        </div>
        <div className="sublinks d-flex flex-column flex-sm-row align-items-center justify-content-center">
          {structure?.footer.map(
            (category) =>
              category.type === "PAGE" && (
                <Link
                  key={category.key}
                  className="sublink mx-4 text-center"
                  to={`/footer/${category.seoFriendlyTitle}`}
                >
                  {category.title}
                </Link>
              ),
          )}
          <div className="sublink cursor-pointer mx-4">
            {t<string>("footer.links.cookies")}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
