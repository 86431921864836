import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { IconQualificationDanger } from "../../static/icons";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import DateFormat from "@utils/DateFormat";

interface ModuleFeedBackProps {
  isOPSection?: boolean;
  modalMode?: ModalMode;
  refusalDate?: string | undefined;
  comment?: string | undefined;
}

const ModuleFeedBack: FunctionComponent<ModuleFeedBackProps> = ({
  isOPSection = false,
  modalMode,
  refusalDate,
  comment,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isOPSection ? (
        <div className={"w-100"}>
          <hr />
          <div className="mb-4">{`${t<string>(
            `organisationprofessional.requests.modals.form.fields.comment.${
              modalMode === ModalMode.DETAILS ? "info" : "description"
            }`,
          )}`}</div>
          <TextAreaFormField
            className="mt-2"
            module="organisationprofessional.requests.modals"
            field="comment"
            readOnly={modalMode === ModalMode.DETAILS}
            required={true}
          />
        </div>
      ) : (
        <>
          {refusalDate && (
            <div
              className={`rg-request-form-warning ${comment ? "mb-10" : ""}`}
            >
              <IconQualificationDanger />
              <span>{`${t<string>(
                "pro.qualifications.modals.updateCertificate.rejected",
              )} ${DateFormat.standardDate(new Date(refusalDate))}`}</span>
            </div>
          )}
          {comment && (
            <div className="mb-4">{`${t<string>(
              "pro.qualifications.modals.updateCertificate.comment",
            )} : “${comment}”`}</div>
          )}
          {(comment || refusalDate) && <hr />}
        </>
      )}
    </>
  );
};

export default ModuleFeedBack;
