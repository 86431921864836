import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import RentreeDuChauffageContactItem from "@components/rentreeDuChauffage/RentreeDuChauffageContactItem";
import BasicButton from "@components/buttons/BasicButton";
import RentreeDuChauffageTempsFortContactModal from "@components/rentreeDuChauffage/tempsFort/RentreeDuChauffageTempsFortContactModal";
import config from "@utils/Config";

interface LandingPageContactProps {
  className?: string;
  confirmationScreen?: boolean;
  tempsFort2?: boolean;
  landingPageType: string;
}

const LandingPageContact: FunctionComponent<LandingPageContactProps> = ({
  className = "",
  confirmationScreen = false,
  tempsFort2 = false,
  landingPageType,
}) => {
  const { t } = useTranslation();
  const [isContactModalVisible, setIsContactModalVisible] =
    useState<boolean>(false);
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div
      id="content-container-rentree-du-chauffage"
      className={`app-rentree-du-chauffage-contact ${className}`}
    >
      <div className="content-contained">
        <div className="contact-container">
          <RentreeDuChauffageContactItem
            title={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item1.title`,
            )}
            description={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item1.description`,
            )}
            src="/img/rentreeDuChauffage/contact-item1.svg"
          />
          <RentreeDuChauffageContactItem
            title={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item2.title`,
            )}
            description={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item2.description`,
            )}
            src={
              landingPageType === "tempsFort"
                ? "/img/rentreeDuChauffage/contact-item2-tempsfort.svg"
                : "/img/rentreeDuChauffage/contact-item2.svg"
            }
            imgLeft
          />
          <RentreeDuChauffageContactItem
            title={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item3.title`,
            )}
            description={t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.contact.item3.description`,
            )}
            src="/img/rentreeDuChauffage/contact-item3.svg"
          />
          {!confirmationScreen && (
            <BasicButton
              text={t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.contact.link`,
              )}
              className="contact-link"
              onClick={() =>
                tempsFort2
                  ? setIsContactModalVisible(true)
                  : window.document
                      .getElementById("app-rentree-du-chauffage-form")
                      ?.scrollIntoView()
              }
            />
          )}
        </div>
      </div>
      <RentreeDuChauffageTempsFortContactModal
        open={isContactModalVisible}
        onClose={() => {
          setIsContactModalVisible(false);
        }}
      />
    </div>
  );
};

export default LandingPageContact;
