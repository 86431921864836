import { FC } from "react";

interface DividerProps {
  width?: number;
  height?: number;
  variant?: string;
  className?: string;
}

interface DividerStyle {
  width: string;
  height: string;
}

const BasicDivider: FC<DividerProps> = ({
  width = 74,
  height = 4,
  variant = "",
  className = "",
}) => {
  const dividerStyle: DividerStyle = {
    width: `${width.toString()}px`,
    height: `${height.toString()}px`,
  };

  return (
    <div
      className={`app-divider my-3 divider-${variant} ${className}`}
      style={dividerStyle}
    />
  );
};

export default BasicDivider;
