import React, { FunctionComponent } from "react";
import { Breadcrumb } from "antd";

export interface BreadcrumbsItem {
  icon?: JSX.Element;
  link?: string;
  label?: string;
  type?: string;
}

interface BreadcrumbsProps {
  breadcrumbs: BreadcrumbsItem[] | null;
  className?: string;
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbs,
  className = "",
}) => {
  return (
    <Breadcrumb separator=">" className={`app-breadcrumbs ${className}`}>
      {breadcrumbs?.map((item, index) => (
        <React.Fragment key={index}>
          {item.link ? (
            <Breadcrumb.Item
              href={
                item.type === "PAGE" ||
                item.label === "Particulier" ||
                item.label === "Professionnel"
                  ? item.link
                  : `${item.link}/sommaire`
              }
            >
              {item.icon}
              <span className={item.icon ? "ms-2" : ""}>{item.label}</span>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item>
              {item.icon}
              <span className={item.icon ? "ms-2" : ""}>{item.label}</span>
            </Breadcrumb.Item>
          )}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
