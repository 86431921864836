import { FunctionComponent, ReactElement, useState } from "react";
import Header from "@components/headers/Header";
import Footer from "@components/footers/Footer";
import BasicCard from "@components/cards/BasicCard";
import { Spin } from "antd";
import SideMenu from "@components/menus/SideMenu";
import SideMenuGazProfessional from "@components/menus/SideMenuGazProfessional";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import ArticleHeader from "@components/articles/ArticleHeader";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { UNIVERSES } from "@utils/enums/universe.enum";
import BasicButton from "@components/buttons/BasicButton";
import { LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Seo from "@components/seo/Seo";
import ScrollToTop from "@utils/ScrollToTop";
import BasicModal from "@components/modals/BasicModal";
import { BreadcrumbsItem } from "@components/breadcrumbs/Breadcrumbs";
import configuration from "@utils/Config";
import SideMenuOrganisationProfessional from "@components/menus/SideMenuOrganisationProfessional";
import parse from "html-react-parser";
import { getGazEvent } from "@utils/gazEvent-utils";
import { setShowGazEventAlert } from "@state/auth/AuthEvents";

interface PageLayoutProps {
  title?: string | JSX.Element;
  titleClassName?: string;
  titleCentered?: boolean;
  rightContent?: JSX.Element;
  subtitle?: string | JSX.Element | null;
  subtitleClassName?: string;
  headerLeftContent?: JSX.Element | null;
  headerRightContent?: JSX.Element | null;
  headerClassName?: string;
  bodyClassName?: string;
  container?: boolean;
  imageUrl?: string;
  imageAlt?: string;
  imageTitle?: string;
  noSideMenu?: boolean;
  noProSideMenu?: boolean;
  organisationProSideMenu?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  contentInCard?: boolean;
  breadcrumbs?: BreadcrumbsItem[];
  children: string | ReactElement;
  isLoading?: boolean;
  isFromArticle?: boolean;
  page?: PageInfoResponseDto;
  mainClassName?: string;
  returnRoute?: string;
  additionalButtons?: JSX.Element | null;
  confirmReturnRoute?: boolean;
  confirmReturnRouteText?: string;
  confirmReturnButtonAccepted?: () => any;
  confirmReturnButtonRefused?: () => any;
  returnText?: string;
  lightHeader?: boolean;
  seoTitle?: string;
}

const PageLayout: FunctionComponent<PageLayoutProps> = ({
  title = undefined,
  titleClassName = "",
  titleCentered = true,
  rightContent,
  subtitle,
  subtitleClassName,
  headerLeftContent,
  headerRightContent,
  headerClassName = "",
  bodyClassName = "",
  container = false,
  imageUrl,
  imageAlt,
  imageTitle,
  noSideMenu = false,
  noProSideMenu = false,
  organisationProSideMenu = false,
  noHeader = false,
  noFooter = false,
  contentInCard = false,
  breadcrumbs = null,
  children,
  isLoading = false,
  isFromArticle = false,
  page,
  mainClassName = "",
  returnRoute,
  additionalButtons,
  confirmReturnRoute = false,
  returnText,
  confirmReturnRouteText,
  confirmReturnButtonRefused,
  confirmReturnButtonAccepted,
  lightHeader = false,
  seoTitle,
}) => {
  const authentifiedContext = useStore(authentifiedStore);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const frontendBaseUrl = configuration.frontendBaseUrl;
  const gazEventType = getGazEvent();

  const getShowGazEventAlert = () => {
    const today = new Date();
    const oneMonthBeforeGazEventType = new Date(gazEventType.start);
    oneMonthBeforeGazEventType.setMonth(gazEventType.start.getMonth() - 1);
    const showModal = today > oneMonthBeforeGazEventType;
    return (
      showModal &&
      authentifiedContext.user?.yoocanId !== null &&
      authentifiedContext.universe.value === UNIVERSES.PROFESSIONAL.value &&
      gazEventType.typeEvent === "rentreeDuChauffage" &&
      authentifiedContext.user?.showGazEventAlert === true
    );
  };

  const showGazEventAlert = getShowGazEventAlert();

  const newSeoTitle =
    seoTitle ??
    (title && (typeof title === "string" ? title : title.toString()));

  const [
    isConfirmReturnRouteModalVisible,
    setIsConfirmReturnRouteModalVisible,
  ] = useState<boolean>(false);

  return (
    <>
      <Seo
        title={page?.title ?? newSeoTitle}
        description={page?.summary ?? ""}
        url={frontendBaseUrl.concat(window.location.pathname.toString())}
        mainImageResourceId={page?.mainImageResourceId}
        shareImageResourceId={page?.shareImageResourceId}
      />
      {!noHeader && <Header />}
      <main className={noHeader ? "" : "with-header"}>
        {!noSideMenu && <SideMenu />}
        {!noProSideMenu &&
          authentifiedContext.user &&
          authentifiedContext.universe.value ===
            UNIVERSES.PROFESSIONAL.value && (
            <SideMenuGazProfessional user={authentifiedContext.user} />
          )}
        {organisationProSideMenu &&
          authentifiedContext.user &&
          authentifiedContext.universe.value ===
            UNIVERSES.PROFESSIONAL.value && (
            <SideMenuOrganisationProfessional />
          )}
        {isLoading ? (
          <div className="ant-spin-page">
            <Spin size="large" />
          </div>
        ) : (
          <div className={`scrollable-container ${mainClassName}`}>
            <ScrollToTop />
            {isFromArticle && page && (
              <>
                {lightHeader ? (
                  <>
                    <div
                      id={"top-header"}
                      className="app-page-footer-header mt-5"
                    >
                      <div className="page-footer-header-title flex-grow-1 d-flex justify-content-center">
                        <h1>{title?.toString().toUpperCase()}</h1>
                      </div>
                    </div>
                  </>
                ) : (
                  <ArticleHeader
                    title={title}
                    breadcrumbs={breadcrumbs}
                    date={page.publicationDate}
                    readingTime={page.readingTime}
                    imageUrl={imageUrl}
                    imageAlt={imageAlt}
                    imageTitle={imageTitle}
                    tags={page.keywords?.map((k) => k.label)}
                  />
                )}
              </>
            )}
            {showGazEventAlert && (
              <div className="alert-gaz-event d-none d-lg-flex flex-row align-items-center gap-2 mt-2">
                <div
                  className="content"
                  onClick={() => setShowGazEventAlert(false)}
                >
                  {parse(
                    t<string>(`${gazEventType.typeEvent}.connected.alert`),
                  )}
                </div>
              </div>
            )}
            <div
              className={`app-content position-relative  ${
                container ? "content-contained container-padding" : ""
              }`}
            >
              {returnRoute || additionalButtons ? (
                <div className="d-flex align-items-start flex-wrap gap-3 mb-10">
                  {returnRoute &&
                    (confirmReturnRoute ? (
                      <>
                        <BasicButton
                          icon={<LeftOutlined />}
                          variant="transparent"
                          text={returnText ?? t<string>("buttons.back")}
                          onClick={() =>
                            setIsConfirmReturnRouteModalVisible(true)
                          }
                          className="p-0 mb-10"
                        />
                        <BasicModal
                          open={isConfirmReturnRouteModalVisible}
                          onCancel={() =>
                            setIsConfirmReturnRouteModalVisible(false)
                          }
                          footer={null}
                        >
                          <>
                            <div className="text-center mt-30 mb-30">
                              {confirmReturnRouteText ??
                                t<string>("common.modal.exit.title")}
                            </div>

                            <div className="d-flex justify-content-center flex-column">
                              <BasicButton
                                variant="primary-outline"
                                className="mb-30 mx-auto w-55"
                                text={
                                  confirmReturnButtonAccepted
                                    ? t<string>("common.yes")
                                    : t<string>("buttons.validate")
                                }
                                onClick={() => {
                                  confirmReturnButtonAccepted
                                    ? confirmReturnButtonAccepted()
                                    : navigate(returnRoute);
                                }}
                              />
                              <BasicButton
                                variant="primary-outline"
                                className="mb-30 mx-auto btn-primary w-55"
                                text={
                                  confirmReturnButtonRefused
                                    ? t<string>("common.no")
                                    : t<string>("buttons.cancel")
                                }
                                onClick={() => {
                                  confirmReturnButtonRefused
                                    ? confirmReturnButtonRefused()
                                    : setIsConfirmReturnRouteModalVisible(
                                        false,
                                      );
                                }}
                              />
                            </div>
                          </>
                        </BasicModal>
                      </>
                    ) : (
                      <BasicButton
                        icon={<LeftOutlined />}
                        variant="transparent"
                        text={returnText ?? t<string>("buttons.back")}
                        onClick={() => navigate(returnRoute)}
                        className="p-0 mb-10"
                      />
                    ))}
                  {additionalButtons && (
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end flex-wrap gap-3">
                      {additionalButtons}
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
              {!isFromArticle && (
                <>
                  {title && !contentInCard && (
                    <div className="d-flex justify-content-end flex-wrap-reverse">
                      <div
                        className={`content-header flex-grow-1 pb-4 ${headerClassName}`}
                      >
                        <div className="content-header-title-container">
                          <h1
                            className={`it-title ${
                              titleCentered ? "text-center" : ""
                            } ${titleClassName}`}
                          >
                            {title}
                          </h1>
                          {rightContent}
                        </div>
                        {subtitle && (
                          <p
                            className={`content-subtitle mb-0 ${
                              titleCentered ? "text-center" : ""
                            }${subtitleClassName ?? ""}`}
                          >
                            {subtitle}
                          </p>
                        )}
                        {headerLeftContent}
                      </div>
                      {headerRightContent && (
                        <div className="content-header-right pb-4">
                          {headerRightContent}
                        </div>
                      )}
                    </div>
                  )}
                  {imageUrl && (
                    <div className="page-context-logo mt-4">
                      <BasicCard className="justify-content-center d-flex">
                        <img alt="" width={250} src={imageUrl} />
                      </BasicCard>
                    </div>
                  )}
                </>
              )}

              <div className={`content-body ${bodyClassName}`}>
                {contentInCard ? (
                  <BasicCard>
                    <>
                      {title && (
                        <div className="py-4">
                          <h1
                            className={`it-title ${
                              titleCentered ? "text-center" : ""
                            }`}
                          >
                            {title}
                          </h1>
                          {subtitle && (
                            <h4 className={titleCentered ? "text-center" : ""}>
                              {subtitle}
                            </h4>
                          )}
                        </div>
                      )}
                      {children}
                    </>
                  </BasicCard>
                ) : (
                  children
                )}
              </div>
            </div>
            {!noFooter && <Footer />}
          </div>
        )}
      </main>
    </>
  );
};

export default PageLayout;
