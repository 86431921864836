import { ChangeEvent, FunctionComponent } from "react";
import { Form, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import Search from "antd/es/input/Search";
import { formUtils } from "@utils/form-utils";
import { IconSearch } from "../../static/icons";

export interface PropsType {
  showLabel?: boolean;
  labelTooltip?: string | null;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onSearch?: (value: string) => void;
  disabled?: boolean;
  value?: string;
}

const SearchFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName = "",
    fieldClassName = "",
    onChange,
    maxLength = 100,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onSearch,
    disabled,
    value,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  return (
    <Form.Item
      name={`${fieldPrefix}${String(field)}`}
      key={key}
      label={
        showLabel ? (
          <span
            className={`${labelClassName ? labelClassName : ""} ${
              readOnly ? "label-readonly" : ""
            }`}
          >
            {t(`${module}.form.fields.${i18nField}.label`)}
            {labelTooltip && <Tooltip title={labelTooltip} />}
          </span>
        ) : (
          ""
        )
      }
      rules={[
        {
          required: isRequired,
          message: emptyErrorMessage
            ? emptyErrorMessage
            : t<string>("forms.errors.mandatory"),
          whitespace: isRequired,
        },
        ...rules,
      ]}
      className={`search ${className}`}
    >
      <Search
        disabled={readOnly || disabled}
        className={`${readOnly ? "readonly-form-field" : ""} ${fieldClassName}`}
        placeholder={
          placeholder
            ? placeholder
            : t<string>(`${module}.form.fields.${i18nField}.placeholder`)
        }
        id={key}
        onChange={onChange}
        maxLength={maxLength}
        onSearch={onSearch}
        enterButton={<IconSearch />}
        value={value}
        data-test-type="input-text"
        data-test={field}
      />
    </Form.Item>
  );
};

export default SearchFormField;
