import sanitizeHtml from "sanitize-html";

export const clearHtml = (html: string): string => {
  const sanitizeOptions: sanitizeHtml.IOptions = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["iframe"]),
    allowedAttributes: {
      iframe: ["src", "width", "height"],
      a: ["href", "target"],
      div: ["style", "class"],
      h2: ["style"],
      h3: ["style"],
      h4: ["style"],
      h5: ["style"],
      blockquote: ["style"],
      span: ["style", "class"],
    },
    allowedIframeHostnames: [
      "www.youtube.com",
      "www.dailymotion.com",
      "www.facebook.com",
      "www.linkedin.com",
      "player.vimeo.com",
    ],
  };
  return sanitizeHtml(html, sanitizeOptions);
};
