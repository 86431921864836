import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicCard from "@components/cards/BasicCard";
import parse from "html-react-parser";
import BasicTag from "@components/tags/BasicTag";
import BasicButton from "@components/buttons/BasicButton";
import ConfirmInstallationModal from "@components/modals/ConfirmInstallationModal";
import { IconWarning, InfoBulle } from "../../../../static/icons";
import { AUDIT_PROGRAMMING_STATUS } from "@utils/enums/audit.enum";
import {
  requestGetInstallationDetail,
  requestSetSelectedInstallation,
} from "@state/audit/AuditEffects";
import { InstallationItem } from "@state/audit/dto/audit.item.details.dto";
import { useNavigate, useLocation } from "react-router-dom";
import { useStore } from "effector-react";
import { auditStore } from "@state/audit/AuditStore";
import DateFormat from "@utils/DateFormat";
import { ROUTES } from "@utils/Routes";
import { auditUtils } from "@views/pro/audit-utils";
import { addressUtils } from "@utils/address-utils";

export const AuditDetailScreen: React.FC = () => {
  const { t } = useTranslation();
  const auditToProgram = useStore(auditStore);
  const location = useLocation();
  const [auditInstallations, setAuditInstallations] = useState<
    InstallationItem[]
  >([]);
  const installationId = location.pathname.split("/").pop() ?? "";
  const lastInstallation =
    auditToProgram.length > 0
      ? auditToProgram.find((installation) => installation.id == installationId)
      : null;
  const timerCancelAudit = lastInstallation?.timerCancelAudit
    ? DateFormat.standardDate(new Date(lastInstallation.timerCancelAudit))
    : "00/00/0000";
  const timerChoixCC = lastInstallation?.timerChoixCC
    ? DateFormat.standardDate(new Date(lastInstallation.timerChoixCC))
    : "00/00/0000";
  const navigate = useNavigate();
  const nbCanceled = lastInstallation?.rang ?? 0;
  const [index, setIndex] = useState<number>(0);
  const [preSelectedInstallation, setPreSelectedInstallation] =
    useState<InstallationItem>();
  const [selectedInstallation, setSelectedInstallation] =
    useState<InstallationItem>();
  const [
    isConfirmInstallationModalVisible,
    setIsConfirmInstallationModalVisible,
  ] = useState<boolean>(false);

  if (!lastInstallation) {
    navigate(ROUTES.professional.audits.list.generate());
  }

  const fetchAudit = useCallback(() => {
    if (installationId != null) {
      const installationToDetailId = lastInstallation?.equivalence;
      installationToDetailId?.forEach((installation) => {
        void requestGetInstallationDetail(installation);
      });
    }
  }, [lastInstallation, installationId]);

  const handleSelectedInstallation = useCallback(
    (installation?: InstallationItem) => {
      if (installation != undefined) {
        void requestSetSelectedInstallation({
          dto: {
            installationId: installation.installationId,
            timerCancelAudit: timerCancelAudit,
          },
        });
      }
    },
    [timerCancelAudit],
  );

  useEffect(() => {
    return requestSetSelectedInstallation.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setSelectedInstallation(result.data);
      }
    });
  });

  useEffect(() => {
    fetchAudit();
  }, [fetchAudit]);

  useEffect(() => {
    return requestGetInstallationDetail.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setAuditInstallations((a) => [...a, result.data as InstallationItem]);
      }
    });
  });

  const renderTitleOC = (id: string) => {
    let title = "";
    if (id === "2") {
      title = "Qualigaz";
    } else if (id === "3") {
      title = "Dekra";
    } else if (id === "4") {
      title = "COPRAUDIT";
    }
    return title ?? null;
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.audit.detail.title")}
      titleCentered={false}
      returnRoute={ROUTES.professional.audits.list.generate()}
      returnText={t<string>("pro.audit.detail.return")}
      bodyClassName="app-audit-block-detail"
    >
      <>
        {lastInstallation && (
          <>
            <BasicCard className="audit-card card-no-margin-mobile audit-detail-header">
              <>
                <div className="audit-detail-header-title-container">
                  <h2 className="audit-detail-header-title">
                    {t<string>("pro.audit.detail.header.title")}
                    {lastInstallation.ocCcId}
                  </h2>
                  {nbCanceled === 3 ? (
                    <div className="audit-detail-header-status-blocked">
                      {t<string>("pro.audit.detail.header.statusBlocked")}
                    </div>
                  ) : (
                    <div className="audit-detail-header-status">
                      <div className="audit-detail-header-status-text">{`${t<string>(
                        "pro.audit.detail.header.status",
                      )} ${timerCancelAudit}`}</div>
                      <BasicTag
                        label={`${DateFormat.getNumberOfDaysBeforeTheDeadline(
                          lastInstallation.timerCancelAudit,
                        )} ${t<string>("pro.audit.detail.header.daysLeft")}`}
                        variant="primary"
                      />
                    </div>
                  )}
                </div>
                {nbCanceled === 3 ? (
                  <div className="audit-detail-header-content">
                    <div className="audit-detail-header-content-status status-blocked">
                      <div className="audit-detail-header-content-status-text">
                        {t<string>(
                          "pro.audit.detail.header.content.statusBlocked",
                        )}
                      </div>
                      <div className="audit-detail-header-content-status-icon">
                        <img
                          alt={t<string>("pro.audit.detail.header.content.alt")}
                          src="/img/pro/audit-detail-status-blocked.svg"
                        />
                      </div>
                    </div>
                    <div className="audit-detail-header-content-description">
                      <div className="audit-detail-header-content-description-icon">
                        <IconWarning />
                      </div>
                      <div className="audit-detail-header-content-description-text">
                        {t<string>(
                          "pro.audit.detail.header.content.descriptionBlocked",
                        )}
                      </div>
                    </div>
                    <div className="audit-detail-header-content-info">
                      <InfoBulle className="audit-detail-header-content-info-icon" />
                      <div className="audit-detail-header-content-info-text">
                        {t<string>(
                          "pro.audit.detail.header.content.infoBlocked",
                        )}
                      </div>
                    </div>
                  </div>
                ) : nbCanceled > 0 ? (
                  <div className="audit-detail-header-content">
                    <div className="audit-detail-header-content-status status-canceled">
                      <div className="audit-detail-header-content-status-text">
                        {t<string>(
                          AUDIT_PROGRAMMING_STATUS[lastInstallation.lastStatus]
                            .label,
                        )}
                      </div>
                      <div className="audit-detail-header-content-status-icon">
                        <img
                          alt={t<string>("pro.audit.detail.header.content.alt")}
                          src="/img/pro/audit-detail-status-canceled.svg"
                        />
                      </div>
                    </div>
                    <div className="audit-detail-header-content-description">
                      <div className="audit-detail-header-content-description-icon">
                        <IconWarning />
                      </div>
                      <div className="audit-detail-header-content-description-text">
                        {`${t<string>(
                          "pro.audit.detail.header.content.descriptionCanceled1",
                        )} ${nbCanceled} ${t<string>(
                          "pro.audit.detail.header.content.descriptionCanceled2",
                        )}`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="audit-detail-header-content">
                    <div className="audit-detail-header-content-status">
                      <div className="audit-detail-header-content-status-text">
                        {t<string>(
                          AUDIT_PROGRAMMING_STATUS[lastInstallation.lastStatus]
                            .label,
                        )}
                      </div>
                      <div className="audit-detail-header-content-status-icon">
                        <img
                          alt={t<string>("pro.audit.detail.header.content.alt")}
                          src="/img/pro/audit-detail-status.svg"
                        />
                      </div>
                    </div>
                    <div className="audit-detail-header-content-description">
                      <div className="audit-detail-header-content-description-text">
                        {t<string>(
                          "pro.audit.detail.header.content.description",
                        )}
                      </div>
                    </div>
                    <div className="audit-detail-header-content-info">
                      <InfoBulle className="audit-detail-header-content-info-icon" />
                      <div className="audit-detail-header-content-info-text">
                        {t<string>("pro.audit.detail.header.content.info")}
                      </div>
                    </div>
                  </div>
                )}
              </>
            </BasicCard>
            <div className="audit-detail-installations">
              {selectedInstallation ? (
                <>
                  <div className="audit-detail-selected-installation-title">
                    {t<string>("pro.audit.detail.selectedInstallation.title")}
                  </div>
                  <div className="audit-detail-selected-installation-item-container">
                    <div className="audit-detail-selected-installation-item">
                      <div className="audit-detail-selected-installation-item-name">
                        {`${renderTitleOC(
                          selectedInstallation.ocId,
                        )} ${t<string>(
                          "pro.audit.detail.installations.items.number",
                        )} ${selectedInstallation.ocCcId}`}
                      </div>
                      <div className="audit-detail-selected-installation-item-rgi">
                        {`${
                          selectedInstallation.dateOfRegistration
                        } - ${t<string>(
                          "pro.audit.detail.installations.items.rgi",
                          { name: selectedInstallation.nameRg },
                        )}`}
                      </div>
                      <div className="audit-detail-selected-installation-item-address">
                        {selectedInstallation &&
                          parse(addressUtils.getAddress(selectedInstallation))}
                        <div>
                          {selectedInstallation?.zipCode
                            .concat(" ")
                            .concat(selectedInstallation?.city)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="audit-detail-installations-title">
                    {nbCanceled === 3
                      ? t<string>("pro.audit.detail.installations.titleBlocked")
                      : `${t<string>(
                          "pro.audit.detail.installations.title",
                        )} ${timerChoixCC}`}
                  </div>
                  {nbCanceled < 3 && (
                    <div className="audit-detail-installations-date">
                      <BasicTag
                        label={`${t<string>(
                          "pro.audit.detail.installations.toGo",
                        )} ${DateFormat.getNumberOfDaysBeforeTheDeadline(
                          lastInstallation.timerChoixCC,
                        )} ${t<string>("pro.audit.detail.installations.days")}`}
                        variant="danger-light"
                      />
                    </div>
                  )}
                  <div className="audit-detail-installations-items">
                    {auditInstallations &&
                      auditInstallations.map((installation, index) => (
                        <div
                          key={index.valueOf()}
                          className="audit-detail-installations-item"
                        >
                          <div className="audit-detail-installations-item-choice">{`${t<string>(
                            "pro.audit.detail.installations.items.choice",
                          )} ${index + 1}`}</div>
                          <div className="audit-detail-installations-item-name">
                            {auditUtils.renderIconOC(installation.ocId)}
                          </div>
                          <div className="audit-detail-installations-item-number">{`${t<string>(
                            "pro.audit.detail.installations.items.number",
                          )} ${installation.ocCcId}`}</div>
                          <div className="audit-detail-installations-item-rgi">
                            {t<string>(
                              "pro.audit.detail.installations.items.rgi",
                              {
                                name: installation.nameRg,
                              },
                            )}
                          </div>
                          <div className="audit-detail-installations-item-address">
                            <div>
                              {" "}
                              {installation &&
                                parse(addressUtils.getAddress(installation))}
                            </div>
                            <div>
                              {installation?.zipCode
                                .concat(" ")
                                .concat(installation?.city)}
                            </div>
                          </div>
                          <BasicButton
                            text={t<string>(
                              "pro.audit.detail.installations.items.action",
                            )}
                            className="audit-detail-installations-item-action"
                            onClick={() => {
                              setPreSelectedInstallation(installation);
                              setIsConfirmInstallationModalVisible(true);
                              setIndex(index + 1);
                            }}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="audit-detail-installations-additional-text">
                    {nbCanceled < 3 &&
                      t<string>(
                        "pro.audit.detail.installations.additionalText",
                      )}
                  </div>
                </>
              )}
            </div>
            <div className="audit-detail-infos">
              <h3 className="audit-detail-infos-title">
                {t<string>("pro.audit.detail.infos.title")}
              </h3>
              {nbCanceled > 0 ? (
                <>
                  <div className="audit-detail-infos-alert">
                    {parse(`<b>${t<string>(
                      "pro.audit.detail.infos.warning",
                    )}</b> ${t<string>(
                      "pro.audit.detail.infos.cancel",
                    )} <b>${nbCanceled} ${t<string>(
                      "pro.audit.detail.infos.times",
                    )}</b>, 
                ${
                  nbCanceled === 3
                    ? t<string>("pro.audit.detail.infos.control")
                    : t<string>("pro.audit.detail.infos.willControl")
                }`)}
                  </div>
                  <div className="audit-detail-infos-details">
                    <div>{t<string>("pro.audit.detail.infos.details")}</div>
                    {nbCanceled === 3 && (
                      <div>
                        {t<string>("pro.audit.detail.infos.justification")}
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>{t<string>("pro.audit.detail.infos.noInfos")}</>
              )}
            </div>
            <ConfirmInstallationModal
              titleOC={
                preSelectedInstallation
                  ? renderTitleOC(preSelectedInstallation?.ocId)
                  : undefined
              }
              index={index}
              preSelectedInstallation={preSelectedInstallation}
              open={isConfirmInstallationModalVisible}
              onCancel={() => {
                setPreSelectedInstallation(undefined);
                setIsConfirmInstallationModalVisible(false);
              }}
              onConfirm={() => {
                setSelectedInstallation(preSelectedInstallation);
                setPreSelectedInstallation(undefined);
                setIsConfirmInstallationModalVisible(false);
                handleSelectedInstallation(preSelectedInstallation);
              }}
            />
          </>
        )}
      </>
    </PageLayout>
  );
};
