import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { ROUTES } from "@utils/Routes";

const Error404Screen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);

  return (
    <PageLayout
      noFooter
      bodyClassName="app-error-screen h-100 d-flex flex-column flex-xl-row align-items-center justify-content-center"
      noProSideMenu
    >
      <>
        <div className="error-text-container">
          <div className="error-text-404 mb-10">{t<string>("error.404")}</div>
          <h1>{t<string>("error.pageNotFound")}</h1>
          <div>{t<string>("error.notExist1")}</div>
          <div>{t<string>("error.notExist2")}</div>
          <BasicButton
            text={t<string>("error.goBack")}
            size="lg"
            onClick={() => navigate(ROUTES.public.home.generate())}
            className="mt-30"
          />
        </div>
        <div className="error-img">
          <img
            alt={t<string>("error.img.alt")}
            src={
              authentifiedContext.universe.value === UNIVERSES.INDIVIDUAL.value
                ? "/img/error404/error.svg"
                : "/img/error404/error-pro.svg"
            }
          />
        </div>
      </>
    </PageLayout>
  );
};

export default Error404Screen;
