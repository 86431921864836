import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { DownloadOutlined } from "@ant-design/icons";
import PdfViewer from "@components/pdf/PdfViewer";

interface MailDetailModalProps {
  visible: boolean;
  onCancel: () => void;
  title: string;
  url?: string;
  className?: string;
}

const MailDetailModal: FunctionComponent<MailDetailModalProps> = ({
  visible,
  onCancel,
  title,
  url,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      open={visible}
      onCancel={onCancel}
      className={`app-modal-mail-detail ${className}`}
      title={title}
      footer={null}
    >
      {url ? (
        <>
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-3 mb-4">
            <BasicButton
              size="sm"
              variant="primary"
              text={t<string>("pro.mail.modals.download")}
              icon={<DownloadOutlined />}
              iconRight
              onClick={() => {
                window.open(url, "_blank");
              }}
            />
          </div>
          <PdfViewer url={url} />
        </>
      ) : (
        <div className="text-center">{t("pro.mail.modals.noContent")}</div>
      )}
    </BasicModal>
  );
};

export default MailDetailModal;
