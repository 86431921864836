import { FC, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { requestGetPage } from "src/state/page/PageEffects";
import { PageComponent } from "src/components/page/PageComponent";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { ROUTES } from "@utils/Routes";

const PagePreviewScreen: FC = () => {
  const location = useLocation();

  const pageId = location.pathname.split("/").pop() || "";

  const navigate = useNavigate();

  const [page, setPage] = useState<PageInfoResponseDto | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    void requestGetPage(pageId);
  }, [pageId]);

  useEffect(() => {
    return requestGetPage.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPage(result.data);
      } else {
        navigate(ROUTES.public.error404.generate());
      }
      setLoading(false);
    });
  });

  return (
    <PageComponent isLoading={isLoading} page={page} isFromArticle={true} />
  );
};

export { PagePreviewScreen };
