import configuration from "@utils/Config";
import { createEffect } from "effector/effector.mjs";
import { restDetailsHandler, restPostHandler } from "@utils/rest/RestClient";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import { WebinarRegistrationCreateRequest } from "@state/webinar/dto/webinar.registration.create.request";
import { WebinarRegistrationItemResponse } from "@state/webinar/dto/webinar.registration.item.response";

const baseUrl = configuration.backendBaseUrl;
const webinarsUrl = baseUrl + "/webinars/";
const webinarRegistrationsUrl = baseUrl + "/webinar-registrations/";

export const requestGetWebinar = createEffect({
  handler: restDetailsHandler<WebinarItemResponse>(webinarsUrl),
});

export const requestPostWebinarRegistration = createEffect({
  handler: restPostHandler<
    WebinarRegistrationCreateRequest,
    WebinarRegistrationItemResponse
  >(webinarRegistrationsUrl),
});
