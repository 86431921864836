import { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormItemData from "@components/forms/FormItemData";
import {
  Button,
  Divider,
  Form,
  FormInstance,
  Modal,
  Popconfirm,
  Spin,
} from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import { Field, OptionType } from "@type/form/field.types";
import { Store, ValidateErrorEntity } from "rc-field-form/lib/interface";
import {
  ArrowLeftOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { Effect } from "effector";
import { useNavigate } from "react-router-dom";
import { toastError } from "@utils/toast-helper";
import { formUtils } from "@utils/form-utils";

interface Props {
  title?: string;
  module: string;
  entityId?: string;
  editMode?: boolean;
  setEditMode?: (editMode: boolean) => void;
  fields?: Field[];
  form: FormInstance;
  loading?: boolean;
  getEntityConfiguration?: GetEntityConfiguration;
  saveEntityConfiguration?: SaveEntityConfiguration;
  customOnSubmitCallback?: (values: Store) => void;
  formConfiguration: {
    [key in string]: FormItem;
  };
  hasBackButton?: boolean;
  hasConfirmPopup?: boolean;
  buttonSize?: "sm" | "md" | "lg";
  buttonCentered?: boolean;
  buttonVariant?: string;
  buttonClassName?: string;
  buttonDisabled?: boolean;
}

interface GetEntityConfiguration {
  requestGet: Effect<string, ParsedResponse<any>>;
}

interface SaveEntityConfiguration {
  confirmationContent?: string;
  requestCreate: Effect<{ dto: any }, ParsedResponse<any>>;
  requestUpdate: Effect<{ id: string; dto: any }, ParsedResponse<any>>;
}

interface FormContentItem {
  key: string;
  title?: string;
  component: JSX.Element;
}

export interface FormItem {
  key: string;
  title: string;
  fields: FormItemField[][];
  module?: string;
}

export interface FormItemField {
  name: string;
  type: string;
  htmlInputType?: string;
  size?: number;
  initialValue?: string;
  required?: boolean;
  mode?: "multiple";
  maxLength?: number;
  pattern?: RegExp;
  options?: OptionType[];
  custom?: ReactElement;
  retractable?: boolean;
  parsed?: boolean;
}

const { confirm } = Modal;

const BaseForm: FunctionComponent<Props> = (props: Props) => {
  const {
    entityId,
    editMode,
    setEditMode,
    hasBackButton = true,
    hasConfirmPopup = true,
    module,
    buttonSize,
    buttonCentered = false,
    buttonVariant = "primary",
    buttonClassName = "",
    buttonDisabled = false,
  } = props;
  const { t } = useTranslation();

  const [fields, setFields] = useState<Field[]>([]);

  const navigate = useNavigate();

  const [entity, setEntity] = useState<unknown>();

  const [getResponseReceived, setGetResponseReceived] =
    useState<boolean>(false);

  const getFormContent = (): FormContentItem[] => {
    const content: FormContentItem[] = [];

    for (const [key, formItem] of Object.entries(props.formConfiguration)) {
      content.push({
        key,
        title: t<string>(formItem.title),
        component: (
          <FormItemData key={key} item={formItem} editMode={editMode} />
        ),
      });
    }

    return content;
  };

  const confirmationPopup = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>(
          `${props.module ? props.module : "default"}.modals.save.title`,
        ),
        content: props.saveEntityConfiguration?.confirmationContent
          ? props.saveEntityConfiguration?.confirmationContent
          : t<string>(
              `${props.module ? props.module : "default"}.modals.save.content`,
            ),
        okText: t<string>("buttons.yes"),
        cancelText: t<string>("buttons.no"),
        centered: true,
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  const mapDtoFrom = (values: Store): unknown => {
    return {
      ...values,
    };
  };

  const handleSubmit = (values: Store): void => {
    if (hasConfirmPopup) {
      void confirmationPopup().then((confirmed: boolean) => {
        if (confirmed) {
          handleRequest(values);
        }
      });
    } else {
      handleRequest(values);
    }
  };

  const handleRequest = (values: Store): void => {
    if (props.saveEntityConfiguration) {
      const dtoToSave = mapDtoFrom(values);
      if (props.entityId) {
        void props.saveEntityConfiguration.requestUpdate({
          id: props.entityId,
          dto: dtoToSave,
        });
      } else {
        void props.saveEntityConfiguration.requestCreate({
          dto: dtoToSave,
        });
      }
    }
    if (props.customOnSubmitCallback) {
      props.customOnSubmitCallback(values);
    }
  };

  const onFinishFailed = ({ errorFields }: ValidateErrorEntity<Store>) => {
    toastError(t<string>("forms.errors.failed-validation"));
    props.form.scrollToField(errorFields[0].name);
  };

  const spinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    return props.getEntityConfiguration?.requestGet.done.watch(({ result }) => {
      setGetResponseReceived(true);
      if (result.ok && result.data) {
        setEntity(result.data);
        setFields(formUtils.mapFieldsFrom(result.data));
      } else {
        toastError(t<string>("user.notFound"));
      }
    });
  });

  return (
    <>
      {!getResponseReceived && entityId ? (
        <div style={{ textAlign: "center" }}>
          <Spin indicator={spinIcon} />
        </div>
      ) : !entityId || entity ? (
        <Form
          {...formItemBlockLayout}
          onFinish={handleSubmit}
          form={props.form}
          fields={fields}
          onFinishFailed={onFinishFailed}
        >
          {props.title && (
            <Divider orientation="left">
              <h3 className="text-secondary mb-0">{t<string>(props.title)}</h3>
            </Divider>
          )}
          {getFormContent().map((content) => content.component)}
          <div
            className={`form-actions d-flex align-items-center flex-wrap px-3 ${
              buttonCentered
                ? "justify-content-center"
                : "justify-content-between"
            }`}
          >
            {editMode && setEditMode ? (
              <div className="my-3">
                <Popconfirm
                  title={t<string>("forms.actions.modals.cancel.content")}
                  okText={t<string>("buttons.yes")}
                  cancelText={t<string>("buttons.no")}
                  onConfirm={() => {
                    setEditMode(false);
                  }}
                  placement="top"
                  icon={<QuestionCircleOutlined />}
                  className="m-2"
                >
                  <Button htmlType="reset">
                    <StopOutlined /> {t<string>("buttons.cancel")}
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <div className="my-3">
                {hasBackButton && (
                  <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Button
                      htmlType="reset"
                      className="m-2"
                      onClick={() => navigate(-1)}
                    >
                      <ArrowLeftOutlined /> {t<string>("buttons.back")}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <div className={buttonCentered ? "" : "my-3"}>
              {editMode ? (
                <BasicButton
                  size={buttonSize}
                  className={`btn-primary ${
                    buttonCentered ? "" : "m-2"
                  } ${buttonClassName}`}
                  type="submit"
                  text={t<string>(`${module}.form.buttons.save`)}
                  isLoading={props.loading}
                  variant={buttonVariant}
                  disabled={buttonDisabled}
                />
              ) : entity ? (
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                  {setEditMode && (
                    <BasicButton
                      className={`btn-primary m-2 ${buttonClassName}`}
                      onClick={() => setEditMode(true)}
                      text={t<string>("buttons.edit")}
                      icon={<EditOutlined />}
                      variant={buttonVariant}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Form>
      ) : (
        <div>{t<string>("entity.notFound")}</div>
      )}
    </>
  );
};

export default BaseForm;
