import { InstallationItem } from "@state/audit/dto/audit.item.details.dto";
import { GazProfessionalUser } from "@type/auth/auth.types";

const getAddress = (installation: InstallationItem) => {
  let address = `<div>${installation.address1 ?? ""}`;
  if ((installation.address2?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address2 ?? "");
  }
  if ((installation.address3?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address3 ?? "");
  }
  if ((installation.address4?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(installation.address4 ?? "");
  }
  return address.concat("</div>");
};

const getAddressMap = (pg: GazProfessionalUser) => {
  let address = pg.address1 ?? "";
  if ((pg.address2?.length ?? 0) > 0) {
    address = address.concat(" ").concat(pg.address2 ?? "");
  }
  if ((pg.address3?.length ?? 0) > 0) {
    address = address.concat(" ").concat(pg.address3 ?? "");
  }
  return address;
};

export const addressUtils = {
  getAddress,
  getAddressMap,
};
