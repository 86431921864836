import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";

interface NumberCardProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  number: string | number;
  className?: string;
}

const NumberCard: FunctionComponent<NumberCardProps> = ({
  icon,
  title,
  subtitle,
  number,
  className = "",
}) => {
  return (
    <BasicCard className={`app-card-number ${className}`}>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        <div className="flex-grow-1 d-flex align-items-center justify-content-center">
          <div className="card-number-icon">{icon}</div>
          <div className="flex-grow-1">
            <div className="card-number-title">{title}</div>
            {subtitle && <div className="card-number-subtitle">{subtitle}</div>}
          </div>
        </div>
        <div className="card-number-number">{number}</div>
      </div>
    </BasicCard>
  );
};

export default NumberCard;
