import { FC } from "react";
import ImageModuleCMS from "@components/cms/ImageModuleCMS";
import {
  BlockInput,
  ImageContent,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";

interface Props {
  block: PageBlockInfoResponseDto;
}

const Rebond: FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const imageContent = inputs[1].content
    ? (inputs[1].content as ImageContent)
    : undefined;
  return (
    <ImageModuleCMS
      src={imageContent && imageContent.content}
      alt={imageContent && imageContent.alt}
      title={imageContent && imageContent.title}
      sideContent={inputs[0].content as string}
      buttonAction={
        (inputs[2].content &&
          inputs[3].content && {
            buttonLabel: inputs[2].content as string,
            url: inputs[3].content as string,
            variant: "primary",
          }) ||
        undefined
      }
      carded
      height={"300"}
      width={"400"}
      imgClassName={"image-module-object-fit"}
    />
  );
};

export default Rebond;
