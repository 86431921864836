import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import CertificateModal from "@components/modals/CertificateModal";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { setMentionSmokeDuct } from "@state/auth/AuthEvents";
import BasicModal from "@components/modals/BasicModal";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { requestUpdateStatusELearningMentionSmokeDuct } from "@state/mention/smokeduct/MentionSmokeDuctEffects";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export const SmokeDuctMentionIframeScreen: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const [isCertificateModalVisible, setIsCertificateModalVisible] =
    useState<boolean>(false);

  const [isMobileModalVisible, setIsMobileModalVisible] = useState<boolean>(
    window.innerWidth < 767,
  );

  const handleIframeTask = useCallback(
    (e: MessageEvent) => {
      if (e.data === "e-learning-ok" && authentifiedUser?.smokeDuct) {
        void requestUpdateStatusELearningMentionSmokeDuct({
          id: authentifiedUser && authentifiedUser.smokeDuct.id,
          dto: undefined,
        });
        setIsCertificateModalVisible(true);
      }
    },
    [authentifiedUser],
  );

  useEffect(() => {
    return requestUpdateStatusELearningMentionSmokeDuct.done.watch(
      ({ result }) => {
        if (result.ok && result.data) {
          setMentionSmokeDuct(result.data);
        }
      },
    );
  });

  useEffect(() => {
    window.addEventListener("message", handleIframeTask);
  }, [handleIframeTask]);

  return (
    <PageLayout
      container
      title={t<string>("pro.mentions.smokeDuct.form.title")}
      titleCentered={false}
    >
      <>
        <div className="mention-iframe-container">
          <iframe
            src="/iframe/smoke-duct/story.html"
            className="mention-iframe"
          />
        </div>
        <CertificateModal
          open={isCertificateModalVisible}
          onCancel={() => {
            setIsCertificateModalVisible(false);
            navigate(ROUTES.professional.smokeDucts.iframe.generate());
          }}
          type={"smokeDuct"}
        />
        <div className="smokeDuct-mobile-modal-container">
          <BasicModal
            className="smokeDuct-mobile-modal"
            open={isMobileModalVisible}
            buttonText={t<string>("common.ok")}
            onOk={() => setIsMobileModalVisible(false)}
            onCancel={() => setIsMobileModalVisible(false)}
          >
            <span>
              {t<string>("pro.mentions.smokeDuct.mobileModal.experience")}
            </span>
          </BasicModal>
        </div>
      </>
    </PageLayout>
  );
};
