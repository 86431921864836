import configuration from "@utils/Config";
import { createEffect } from "effector";
import {
  restCreationMultipartHandler,
  restDeletionHandler,
  restDetailsHandler,
  restListHandler,
} from "@utils/rest/RestClient";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import { ResourceSearchCriteria } from "@state/resource/dto/resource.search.criteria";

const baseUrl = configuration.backendBaseUrl;
const resourcesUrl = baseUrl + "/resources";

export const requestGetResource = createEffect({
  handler: restDetailsHandler<ResourceDetailsResponseDto>(
    `${resourcesUrl}/`,
    "/content",
  ),
});

export const requestGetResources = createEffect({
  handler: restListHandler<ResourceDetailsResponseDto, ResourceSearchCriteria>(
    `${resourcesUrl}`,
  ),
});

export const requestCreateResource = createEffect({
  handler: restCreationMultipartHandler<ResourceDetailsResponseDto>(
    `${resourcesUrl}`,
  ),
});

export const requestDeleteResource = createEffect({
  handler: restDeletionHandler(`${resourcesUrl}/`),
});
