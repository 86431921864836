import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Col, Form, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { requestCreateContact } from "@state/contact/backoffice/ContactEffects";
import { Store } from "rc-field-form/lib/interface";
import {
  emailFormatRegex,
  formItemBlockLayout,
  phoneNumberFormatRegex,
  siretNumberFormatRegex,
} from "@utils/Constant";
import TagManager from "react-gtm-module";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { toastError, toastSuccess } from "@utils/toast-helper";
import parse from "html-react-parser";

interface FormValues {
  subject: string;
  siret: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
}

export const ContactFormScreen: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    TagManager.dataLayer({
      dataLayer: {
        event: "contact_pro_envoi_demande",
      },
    });
    const obj = values as FormValues;
    void requestCreateContact({
      dto: {
        ...obj,
        siret: parseInt((values["siret"] as string).replace(/\s/g, "")),
        contactType: "BACK_OFFICE",
      },
    });
  };

  useEffect(() => {
    return requestCreateContact.done.watch(({ result }) => {
      if (result.ok && result.data) {
        toastSuccess(t<string>("pro.contact.create.success"));
        setButtonLoading(false);
        form.resetFields();
      } else {
        toastError(
          result.errorMessage || t<string>("pro.contact.create.error"),
        );
        setButtonLoading(false);
      }
    });
  });

  return (
    <PageLayout
      container
      title={t<string>("pro.contact.form.title")}
      titleCentered={false}
      subtitle={t<string>("pro.contact.form.subtitle")}
      bodyClassName="app-contact-form-page"
      noProSideMenu
    >
      <div className="d-flex flex-column flex-lg-row align-items-stretch justify-content-center">
        <div className="contact-form-page-left d-flex flex-column align-items-center align-items-lg-start justify-content-center pe-lg-4">
          <div className="contact-form-page-left-container">
            <div>{parse(t<string>("pro.contact.form.description"))}</div>
            <div className="contact-form-page-left-img d-flex justify-content-center">
              <img
                src={"/img/pro/mailbox.png"}
                alt={t<string>("pro.contact.form.imgAlt")}
              />
            </div>
          </div>
        </div>
        <div className="contact-form-page-right ps-lg-4">
          <Form form={form} onFinish={handleSubmit} {...formItemBlockLayout}>
            <h2 className="contact-form-title">
              {t<string>("pro.contact.form.message")}
            </h2>
            <Row gutter={16}>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <InputFormField
                  showLabel
                  module="pro.contact"
                  field="lastName"
                  required
                />
              </Col>
              <Col xs={24} sm={12} lg={24} xl={12}>
                <InputFormField
                  showLabel
                  module="pro.contact"
                  field="firstName"
                  required
                />
              </Col>
            </Row>
            <MaskInputFormField
              required
              mask="### ### ### #####"
              showLabel
              module="search.contacts"
              field="siret"
              rules={[
                {
                  pattern: new RegExp(siretNumberFormatRegex),
                  message: t<string>("forms.errors.format"),
                },
                {
                  min: 17,
                  message: t<string>("forms.errors.length", { size: "14" }),
                },
              ]}
            />
            <MaskInputFormField
              required
              mask="## ## ## ## ##"
              showLabel
              module="search.contacts"
              field="phone"
              rules={[
                {
                  pattern: new RegExp(phoneNumberFormatRegex),
                  message: t<string>("forms.errors.format"),
                },
                {
                  min: 14,
                  message: t<string>("forms.errors.length", { size: "10" }),
                },
              ]}
              type="tel"
            />
            <InputFormField
              showLabel
              module="pro.contact"
              field="email"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(emailFormatRegex),
                  message: t<string>("common.email.errors.invalid"),
                },
              ]}
            />
            <InputFormField
              showLabel
              module="pro.contact"
              field="subject"
              required
            />
            <TextAreaFormField
              showLabel
              module="pro.contact"
              field="message"
              required
            />
            <SubmitButton
              module="pro.contact"
              isSubmitting={buttonLoading}
              label="submit"
              size="lg"
              className="contact-form-button"
            />
          </Form>
        </div>
      </div>
    </PageLayout>
  );
};
