import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import LoginForm from "@components/forms/LoginForm";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import BasicButton from "@components/buttons/BasicButton";
import { getConnectedHomeRoute } from "@utils/pg-user-utils";
import { useNavigate } from "react-router-dom";

interface ProHomeHeaderProps {
  className?: string;
}

const ProHomeHeader: FunctionComponent<ProHomeHeaderProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const authentifiedUser = useStore(authentifiedStore);

  return (
    <div
      className={`app-pro-home-header d-flex flex-column flex-lg-row align-items-stretch justify-content-center ${className}`}
    >
      <div className="header-left d-flex flex-column align-items-center justify-content-center">
        <div className="header-left-container">
          <h1 className="mb-30">{t<string>("pro.home.header.left.title")}</h1>
          {authentifiedUser.user ? (
            <BasicButton
              onClick={() => navigate(getConnectedHomeRoute(authentifiedUser))}
              text={t<string>("pro.home.header.left.my-account")}
              size="lg"
            />
          ) : (
            <LoginForm proHome />
          )}
        </div>
      </div>
      <div className="header-right d-flex flex-column align-items-center justify-content-center">
        <div className="header-right-img">
          <img
            src={"/img/home/computer.png"}
            alt={t<string>("pro.home.header.right.img")}
          />
        </div>
        <div className="w-100 d-flex align-items-center justify-content-around flex-wrap">
          <div className="header-right-icon-container d-flex flex-column align-items-center justify-content-center">
            <div className="header-right-icon">
              <img
                src={"/img/home/envelope.svg"}
                alt={t<string>("pro.home.header.right.contacts.alt")}
              />
            </div>
            <div className="header-right-description description-contacts">
              {t<string>("pro.home.header.right.contacts.description")}
            </div>
          </div>
          <div className="header-right-icon-container d-flex flex-column align-items-center justify-content-center">
            <div className="header-right-icon">
              <img
                src={"/img/home/dossier.svg"}
                alt={t<string>("pro.home.header.right.files.alt")}
              />
            </div>
            <div className="header-right-description description-files">
              {t<string>("pro.home.header.right.files.description")}
            </div>
          </div>
          <div className="header-right-icon-container d-flex flex-column align-items-center justify-content-center">
            <div className="header-right-icon">
              <img
                src={"/img/home/badge.svg"}
                alt={t<string>("pro.home.header.right.mentions.alt")}
              />
            </div>
            <div className="header-right-description description-mentions">
              {t<string>("pro.home.header.right.mentions.description")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProHomeHeader;
