import { FC } from "react";
import { useTranslation } from "react-i18next";
import DateFormat from "@utils/DateFormat";
import { Link } from "react-router-dom";

interface HeaderProps {
  title: string | JSX.Element;
  date: string;
  nbMinutes: number | string | undefined;
  description: string | JSX.Element;
  url: string;
  imageUrl?: string;
  className?: string;
}

const SummaryArticle: FC<HeaderProps> = ({
  title,
  date,
  nbMinutes,
  description,
  url,
  imageUrl,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`app-article-summary ${className}`}>
      <Link to={url} className="summary-title d-none d-sm-block mb-20 no-link">
        {title}
      </Link>
      <div className="d-flex flex-column flex-sm-row align-items-stretch">
        <div className="summary-img">
          <img src={imageUrl} alt={t<string>("summary.Article.imgAlt")} />
        </div>
        <div className="flex-grow-1 d-flex flex-column">
          <div className="d-flex align-items-center flex-wrap mb-10">
            <div className="flex-grow-1 d-flex align-items-center justify-content-end flex-wrap">
              {date && (
                <div className="summary-date flex-grow-1 mb-10">
                  {DateFormat.standardDate(new Date(date))}
                </div>
              )}
              {nbMinutes ? (
                <div className="summary-time ms-2 mb-10">
                  {`${nbMinutes} ${t<string>("summary.article.time")}`}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="summary-title d-sm-none">{title}</div>
          <div className="summary-description mt-10 mb-3">{description}</div>
          <div className="flex-grow-1" />
          <div>
            <Link to={url} className="summary-more">
              {t<string>("summary.article.readMore")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryArticle;
