import configuration from "@utils/Config";
import {
  restCreationHandler,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
  restWorkflowHandler,
} from "@utils/rest/RestClient";
import { createEffect } from "effector";
import { Pageable } from "@type/pagination/pagination.types";
import { ContactsGazprofessionalSaveRequestDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.save.request.dto";
import { ContactItemResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.item.response.dto";
import { ContactGazprofessionalWorkflowRequestDto } from "@state/contact/gazprofessional/dto/contact.gazprofessional.workflow.request.dto";
import { ContactLightResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.light.response.dto";

const baseUrl = `${configuration.backendBaseUrl}/gaz-professionals/contacts`;

export const requestCreateContacts = createEffect({
  handler: restCreationHandler<ContactsGazprofessionalSaveRequestDto, void>(
    baseUrl,
  ),
});

export const requestGetContact = createEffect({
  handler: restDetailsHandler<ContactItemResponseDto>(`${baseUrl}/`),
});

export const requestGetContacts = createEffect({
  handler: restListHandlerPagination<Pageable<ContactItemResponseDto>>(baseUrl),
});

export const requestGetContactsLightResponse = createEffect({
  handler: restListHandler<ContactLightResponseDto>(`${baseUrl}/light`),
});

export const requestWorkflowContact = createEffect({
  handler: restWorkflowHandler<
    ContactGazprofessionalWorkflowRequestDto,
    ContactItemResponseDto
  >(`${baseUrl}/`),
});
