import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import AddRGModal from "@components/modals/AddRGModal";
import RadRGModal from "@components/modals/RadRGModal";
import UpdateCertificateModal from "@components/modals/UpdateCertificateModal";
import { requestGetGazProfessionalRGRequest } from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import { useTranslation } from "react-i18next";
import { GazProfessionalRGRequestDetailsResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.details.response.dto";
import { requestGetOrganisationProfessionalRGRequest } from "@state/organisationprofessional/OrganisationProfessionalEffects";

interface QualificationRGRequestModalProps {
  visible: boolean;
  onCancel: () => void;
  className?: string;
  isOPSection?: boolean;
  modalType?: RGRequestType;
  modalMode: ModalMode;
  rgRequestId?: string;
  isInstallationSection?: boolean;
  name?: {
    firstName: string;
    lastName: string;
  };
}

const RGRequestModalSwitch: FunctionComponent<
  QualificationRGRequestModalProps
> = ({
  visible,
  onCancel,
  className,
  isOPSection = false,
  modalType,
  modalMode,
  rgRequestId,
  isInstallationSection,
  name,
}) => {
  const { t } = useTranslation();
  const [rgRequest, setRgRequest] =
    useState<GazProfessionalRGRequestDetailsResponseDto>();

  const fetchRGRequest = useCallback(() => {
    if (rgRequestId) {
      if (isOPSection) {
        requestGetOrganisationProfessionalRGRequest(rgRequestId).catch(() => {
          toastAlert(
            t<string>("pro.qualifications.modals.details.error.toast"),
          );
        });
      } else {
        requestGetGazProfessionalRGRequest(rgRequestId).catch(() => {
          toastAlert(
            t<string>("pro.qualifications.modals.details.error.toast"),
          );
        });
      }
    } else {
      setRgRequest(undefined);
    }
  }, [t, rgRequestId, isOPSection]);

  useEffect(() => {
    fetchRGRequest();
  }, [fetchRGRequest]);

  useEffect(() => {
    return requestGetGazProfessionalRGRequest.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data && visible) {
          setRgRequest(data);
        }
      },
    );
  });

  useEffect(() => {
    return requestGetOrganisationProfessionalRGRequest.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data && visible) {
          setRgRequest(data);
        }
      },
    );
  });

  return (
    <>
      {modalType === RG_REQUEST_TYPE.ADD && (
        <AddRGModal
          visible={visible}
          onCancel={onCancel}
          className={className}
          isOPSection={isOPSection}
          modalMode={modalMode}
          rgRequest={rgRequest}
        />
      )}

      {modalType === RG_REQUEST_TYPE.RAD && name && (
        <RadRGModal
          visible={visible}
          onCancel={onCancel}
          className={className}
          isOPSection={isOPSection}
          modalMode={modalMode}
          rgRequest={rgRequest}
          isInstallationSection={isInstallationSection}
          name={name}
        />
      )}

      {modalType === RG_REQUEST_TYPE.UPDATE && name && (
        <UpdateCertificateModal
          visible={visible}
          onCancel={onCancel}
          className={className}
          isOPSection={isOPSection}
          modalMode={modalMode}
          rgRequest={rgRequest}
          isInstallationSection={isInstallationSection}
          name={name}
        />
      )}
    </>
  );
};

export default RGRequestModalSwitch;
