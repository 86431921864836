import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ModuleCompanyNameProps {
  companyName: string | undefined;
}

const ModuleCompanyName: FunctionComponent<ModuleCompanyNameProps> = ({
  companyName,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {companyName && (
        <div className="mb-4">{`${t<string>(
          "organisationprofessional.requests.companyName",
        )} “${companyName}”`}</div>
      )}
    </>
  );
};

export default ModuleCompanyName;
