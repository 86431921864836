import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

const MaintenanceScreen: FC = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);

  const navigate = useNavigate();

  return (
    <PageLayout
      noFooter
      bodyClassName="app-maintenance-screen h-100 d-flex flex-column-reverse flex-xl-row align-items-center justify-content-center"
      noProSideMenu
    >
      <>
        <div className="maintenance-img">
          <img
            alt={t<string>("maintenance.img.alt")}
            src={
              authentifiedContext.universe.value === UNIVERSES.INDIVIDUAL.value
                ? "/img/error404/error.svg"
                : "/img/error404/error-pro.svg"
            }
          />
        </div>
        <div className="maintenance-text-container">
          <h1>{t<string>("maintenance.siteInMaintenance")}</h1>
          <div>{t<string>("maintenance.notAccessible1")}</div>
          <div>{t<string>("maintenance.notAccessible2")}</div>
          <BasicButton
            text={t<string>("maintenance.reload")}
            size="lg"
            onClick={() => navigate(ROUTES.public.home.generate())}
            className="mt-30"
          />
        </div>
      </>
    </PageLayout>
  );
};

export default MaintenanceScreen;
