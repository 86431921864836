import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressFields } from "@components/inputs/AddressFields";
import { useNavigate } from "react-router-dom";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { requestGetAddressReverse } from "@state/address/AddressesEffects";
import { Spin } from "antd";
import TagManager from "react-gtm-module";
import { ROUTES } from "@utils/Routes";

interface HomeHeaderProps {
  noDescription?: boolean;
  fullWidth?: boolean;
}

const HomeHeader: FunctionComponent<HomeHeaderProps> = ({
  noDescription = false,
  fullWidth = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCurrentLocationLoading, setCurrentLocationLoading] =
    useState<boolean>(false);

  const [selectedAddress, setSelectedAddress] = useState<
    AddressFieldsDto | undefined
  >();
  const [isSelectedAddressExists, setIsSelectedAddressExists] =
    useState<boolean>(false);

  const handleSearch = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "lancer_recherche_home_part",
      },
    });
    if (selectedAddress) {
      navigate(ROUTES.individual.gazProfessionals.search.generate(), {
        state: {
          parentQueryString: selectedAddress.properties.label,
          parentCurrentPosition: {
            lat: selectedAddress.geometry.coordinates[1],
            lon: selectedAddress.geometry.coordinates[0],
          },
        },
      });
    } else {
      navigate(ROUTES.individual.gazProfessionals.search.generate());
    }
  }, [navigate, selectedAddress]);

  return (
    <div
      className={"app-home-header"}
      style={{ backgroundImage: "url('/img/home/header-rdc.webp')" }}
    >
      <div
        className={`app-home-header-content content-contained ${
          fullWidth ? "px-xl-0" : ""
        }`}
      >
        <div className={"d-flex w-100 h-100"}>
          <div
            className={`home-header-content d-flex flex-column h-100 ${
              fullWidth ? "" : "w-60"
            }`}
          >
            <div
              className={
                "content-header pb-4 flex-grow-1 d-flex align-items-center"
              }
            >
              <h1 className="it-title" style={{ color: "white" }}>
                {t<string>("home.header-section.title")}
              </h1>
            </div>
            {!noDescription && (
              <span>{t<string>("home.header-section.description")}</span>
            )}
          </div>
        </div>
        <div className="header-searchbar">
          <Spin spinning={isCurrentLocationLoading} style={{ height: "60px" }}>
            <div
              className={"searchbar-locate-btn"}
              onClick={() => {
                setCurrentLocationLoading(true);
                setIsSelectedAddressExists(true);
                if (navigator.geolocation) {
                  navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition): void => {
                      void requestGetAddressReverse(
                        `lon=${position.coords.longitude}&lat=${position.coords.latitude}`,
                      );
                    },
                  );
                }
              }}
            >
              <img src={"/img/icons/target.svg"} alt={"geoLocationIcon"} />
            </div>
          </Spin>
          <AddressFields
            setSelectedAddress={(val) => {
              setSelectedAddress(val);
              setCurrentLocationLoading(false);
            }}
            onEnterPress={handleSearch}
            className={"searchbar-addressField"}
            setIsSelectedAddressExists={setIsSelectedAddressExists}
          />
          {isSelectedAddressExists && (
            <div
              className={"searchbar-submit-btn"}
              onClick={() => handleSearch()}
            >
              <img src={"/img/icons/search.svg"} alt={"searchIcon"} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeHeader;
