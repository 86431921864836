import { FunctionComponent } from "react";

interface StepProps {
  title: string;
}

interface StepperProps {
  steps?: StepProps[];
  className?: string;
}

const Stepper: FunctionComponent<StepperProps> = ({
  steps = [],
  className = "",
}) => {
  return (
    <div className={`app-stepper ${className} `}>
      {steps.map((step: StepProps, index: number) => [
        <div key={`step_${index}`} className={"app-step"}>
          <div className={"step-item"}>
            <div className={"step-indicator"} />
            <span className={"step-label"}>{step.title}</span>
          </div>
        </div>,
        <div key={index} className={"step-divider"} />,
      ])}
    </div>
  );
};

export default Stepper;
