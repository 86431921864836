import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { Pageable } from "@type/pagination/pagination.types";
import { emptyCertificate, emptySearchResult } from "@utils/Constant";
import { useNavigate } from "react-router-dom";
import { SorterResult } from "antd/es/table/interface";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import { MaintenanceCertificateItemResponseDto } from "@state/maintenancecertificate/dto/maintenance.certificate.item.response.dto";
import {
  requestDeleteMaintenanceCertificates,
  requestGetMaintenanceCertificates,
} from "@state/maintenancecertificate/MaintenanceCertificateEffects";
import { columns } from "@components/lists/MaintenanceCertificateColumns";
import SearchFormField from "@components/inputs/SearchFormField";
import BasicButton from "@components/buttons/BasicButton";
import { requestGetResource } from "@state/resource/ResourceEffects";
import { downloadFile } from "@utils/Base64File";

export const MaintenancesCertificateListScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [limit, setLimit] = useState<number>(10);

  const [maintenancesCertificate, setMaintenancesCertificate] =
    useState<Pageable<MaintenanceCertificateItemResponseDto>>(
      emptySearchResult,
    );

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "status",
    order: "ascend",
  });

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };
  const onDelete = (maitenance: MaintenanceCertificateItemResponseDto) => {
    void requestDeleteMaintenanceCertificates({ dto: maitenance }).then(
      (result) => {
        if (result.ok) {
          toastSuccess(t<string>("Suppression effectuée"));
          fetchPages();
        } else {
          toastError(result.errorMessage);
        }
      },
    );
  };
  const onClick = (id: string) => {
    navigate(ROUTES.professional.maintenanceCertificate.details.generate(id));
  };

  const onDownload = (id: string) => {
    void requestGetResource(id).then((resource) => {
      if (resource.ok && resource.data) {
        downloadFile(resource.data.data, "pdf", "Attestation d'entretien.pdf");
      }
    });
  };

  const handleEmptyCertificate = () => {
    void requestGetResource(emptyCertificate).then((resource) => {
      if (resource.ok && resource.data) {
        const emptyCertificate = resource.data;
        downloadFile(
          emptyCertificate.data,
          "pdf",
          "Attestation d'entretien 2023.pdf",
        );
      }
    });
  };

  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetMaintenanceCertificates({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: { query: query },
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, sorter, query, t]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetMaintenanceCertificates.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenancesCertificate(result.data);
      }
    });
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setQuery(value);
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.certificate.list.title")}
      titleCentered={false}
    >
      <>
        <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
          <SearchFormField
            module="pro.mail"
            field="search"
            onChange={onChange}
            onSearch={(value: string) => setQuery(value)}
            value={query}
          />
          <Table
            locale={{
              emptyText: <Empty description={t<string>("common.noData")} />,
            }}
            columns={columns(onClick, onDelete, onDownload)}
            dataSource={maintenancesCertificate.content}
            loading={!dataHasLoaded}
            size="small"
            className="app-contact-pro-table"
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(
                    ROUTES.professional.maintenanceCertificate.details.generate(
                      record.id,
                    ),
                    {
                      state: {
                        initialContact: record,
                      },
                    },
                  );
                },
              };
            }}
            pagination={{
              total: maintenancesCertificate.totalElements,
              pageSize: maintenancesCertificate.size,
              current: maintenancesCertificate.number + 1,
              onChange: setPageNumber,
              onShowSizeChange: onChangeLimit,
              showSizeChanger: true,
            }}
            onChange={(pagination, filters, sorter) =>
              setSorter(sorter as SorterResult<any>)
            }
          />
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center px-12 gap-1">
          <BasicButton
            variant="primary"
            text={t<string>("pro.certificate.list.button.newCertificate")}
            onClick={() =>
              navigate(
                ROUTES.professional.maintenanceCertificate.add.generate(),
              )
            }
            className="mention-button mb-4"
          />
          <BasicButton
            variant="primary"
            text={t<string>("pro.certificate.list.button.emptyCertificate")}
            onClick={handleEmptyCertificate}
            className="mention-button mb-4"
          />
        </div>
      </>
    </PageLayout>
  );
};
