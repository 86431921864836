import i18n from "i18next";
import TableButton from "@components/buttons/TableButton";
import { MailOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.item.response.dto";

export const columns = [
  {
    title: i18n.t("organisationprofessional.pgSubscribers.table.headers.code"),
    dataIndex: "code",
    key: "code",
    width: 150,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationprofessional.pgSubscribers.table.headers.companyName",
    ),
    dataIndex: "companyName",
    key: "companyName",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t(
      "organisationprofessional.pgSubscribers.table.headers.qualifications",
    ),
    key: "qualifications",
    width: 269,
    render: (record: OrganisationProfessionalGPItemResponseDto): ReactNode => {
      return record.pgiYear || record.pgmYear ? (
        <div className="app-pg-subscribers-tags">
          <div className="pg-subscribers-tag">
            {record.pgiYear && (
              <BasicTag
                label={`${i18n.t(
                  "organisationprofessional.pgSubscribers.table.pgi",
                )} ${record.pgiYear}`}
                variant={record.pgiYearValid ? "light-green" : "light-red"}
              />
            )}
          </div>
          <div className="pg-subscribers-tag">
            {record.pgmYear && (
              <BasicTag
                label={`${i18n.t(
                  "organisationprofessional.pgSubscribers.table.pgm",
                )} ${record.pgmYear}`}
                variant={record.pgmYearValid ? "light-green" : "light-red"}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      );
    },
  },
  {
    title: i18n.t(
      "organisationprofessional.pgSubscribers.table.headers.contact",
    ),
    key: "email",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 110,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (
      record: OrganisationProfessionalGPItemResponseDto,
    ): JSX.Element => (
      <div className="w-100 d-inline-flex justify-content-center">
        <TableButton
          tooltipTitle={i18n.t(
            "organisationprofessional.pgSubscribers.table.tooltip.contact",
          )}
          icon={<MailOutlined />}
          buttonClassName="btn-transparent w-auto"
          onClick={() => (window.location.href = `mailto:${record.email}`)}
        />
      </div>
    ),
  },
];
