/* eslint-disable */

export const CAMPAIGN_INTERVENTION_TYPES: { [index: string]: SearchType } = {
  INSTALLATION_REMPLACEMENT: {
    value: "INSTALLATION_REMPLACEMENT",
    label: "search.intervention.installation",
    shortLabel: "gazMonth.individual.home.intervention.installation",
  },
  MAINTENANCE_ENTRETIEN: {
    value: "MAINTENANCE_ENTRETIEN",
    label: "gazMonth.individual.home.intervention.maintenance",
  },
};

export const CAMPAIGN_CURRENT_ENERGIES: { [index: string]: SearchType } = {
  GAZ_NATUREL: {
    value: "GAZ_NATUREL",
    label: "gazMonth.individual.home.currentEnergy.gazNaturel",
  },
  BUTANE_PROPANE: {
    value: "BUTANE_PROPANE",
    label: "gazMonth.individual.home.currentEnergy.propaneButane",
  },
  FIOUL: {
    value: "FIOUL",
    label: "gazMonth.individual.home.currentEnergy.fioul",
  },
  ELECTRICITE: {
    value: "ELECTRICITE",
    label: "gazMonth.individual.home.currentEnergy.electricite",
  },
  BOIS: {
    value: "BOIS",
    label: "gazMonth.individual.home.currentEnergy.bois",
  },
};

export const CAMPAIGN_EQUIPMENT_TYPES: { [index: string]: SearchType } = {
  CHAUDIERE_HAUTE_PERFORMANCE: {
    value: "CHAUDIERE_HAUTE_PERFORMANCE",
    label: "gazMonth.individual.home.equipmentType.chaudiereThpe",
  },
  POMPE_CHALEUR: {
    value: "POMPE_CHALEUR",
    label: "gazMonth.individual.home.equipmentType.pacHybrideGaz",
  },
  POELE_INSERT_GAZ: {
    value: "POELE_INSERT_GAZ",
    label: "gazMonth.individual.home.equipmentType.peoleGaz",
  },
  REGULATION_EMETTEURS: {
    value: "REGULATION_EMETTEURS",
    label: "gazMonth.individual.home.equipmentType.regulationEmetteurs",
  },
};

export interface SearchType {
  value: string;
  label: string;
  shortLabel?: string;
}

/* eslint-enable */
