import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

interface MaintenanceCertificateModalHelp3Props {
  title?: string;
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const MaintenanceCertificateHelp3Modal: FunctionComponent<
  MaintenanceCertificateModalHelp3Props
> = ({ title, open, onCancel, className = "" }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      className={className}
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <ul>
        <li>{parse(t<string>("pro.certificate.form.help.section4.text2b"))}</li>
        <li>{parse(t<string>("pro.certificate.form.help.section4.text3"))}</li>
      </ul>
    </BasicModal>
  );
};

export default MaintenanceCertificateHelp3Modal;
