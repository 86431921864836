import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.item.response.dto";
import { OrganisationProfessionalRGItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.responsable.gaz.item.response.dto";

export const hideEmail = (email: string): string => {
  return email.replace(
    /(.{2})(.*)(?=@)/,
    function (gp1, gp2: string, gp3: string) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    },
  );
};

export const mailToSelection = (
  selectedRows: (
    | OrganisationProfessionalGPItemResponseDto
    | OrganisationProfessionalRGItemResponseDto
  )[],
): string => {
  return selectedRows.reduce(
    (prev, curr) => `${prev}${!prev ? "" : ";"}${curr.email}`,
    "",
  );
};
