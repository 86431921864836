import React, { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import BasicButton from "@components/buttons/BasicButton";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { SelectFormField } from "@components/inputs/SelectFormField";
import { Collapse } from "antd";
import { PageStructureDto } from "@state/structure/dto/structure.dto";
import { useStructures } from "@state/structure/Structures";
import { renderTitle } from "@components/headers/Header";
import { ROUTES } from "@utils/Routes";

interface SideMenuProps {
  className?: string;
}

const SideMenu: FunctionComponent<SideMenuProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const authentifiedContext = useStore(authentifiedStore);
  const structure = useStructures();
  const [isMobileSideMenuOpen, setIsMobileSideMenuOpen] =
    useState<boolean>(false);

  return (
    <>
      <div
        className={`app-sidemenu-overlay ${
          isMobileSideMenuOpen ? "d-block" : "d-none"
        }`}
        onClick={() => setIsMobileSideMenuOpen(false)}
      />
      <BasicButton
        variant="side-menu"
        icon={isMobileSideMenuOpen ? <CloseOutlined /> : <MenuOutlined />}
        onClick={() => setIsMobileSideMenuOpen(!isMobileSideMenuOpen)}
      />
      <div
        className={`app-sidemenu d-flex flex-column ${
          isMobileSideMenuOpen ? "mobile-sidemenu-open" : ""
        } ${className}`}
      >
        <div className="logo-container d-flex justify-content-center">
          <div className="bg-white pt-20 pb-20 px-3">
            <Link to="/">
              <img
                alt={t<string>("header.logo.alt")}
                className="logo px-2"
                src="/logo-header-PG-professional.svg"
              />
            </Link>
          </div>
        </div>
        <div className="links flex-grow-1 d-flex flex-column">
          <Link to={ROUTES.public.home.generate()} className="link">
            {t<string>("menu.home")}
          </Link>
          {structure?.universe
            .filter((s) => s.type === "CATEGORY" && s.children.length > 0)
            .map((category) =>
              category.children && category.children.length > 0 ? (
                <Collapse
                  key={category.key}
                  className={`sidemenu-link-collapse ${authentifiedContext.universe.value.toLowerCase()}`}
                  expandIconPosition="end"
                >
                  <Collapse.Panel header={category.title} key={category.key}>
                    <>
                      {category.children.map((item) => (
                        <div key={item.key} className="collapse-link">
                          {item.type === "PAGE" && (
                            <Link
                              to={`${category.seoFriendlyTitle}/${
                                (item as PageStructureDto).seoFriendlyTitle
                              }`}
                            >
                              {renderTitle(item.title)}
                            </Link>
                          )}
                          {item.type === "CATEGORY" && (
                            <Collapse
                              key={item.key}
                              className="sidemenu-link-sub-collapse"
                              expandIconPosition="end"
                            >
                              <Collapse.Panel
                                header={item.title}
                                key={item.key}
                              >
                                {item.children &&
                                  item.children.map((subItem) => (
                                    <React.Fragment key={subItem.key}>
                                      {subItem.type === "PAGE" && (
                                        <div className="collapse-link">
                                          <Link
                                            to={`${item.seoFriendlyTitle}/${
                                              (subItem as PageStructureDto)
                                                .seoFriendlyTitle
                                            }`}
                                          >
                                            {renderTitle(subItem.title)}
                                          </Link>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </Collapse.Panel>
                            </Collapse>
                          )}
                        </div>
                      ))}
                    </>
                  </Collapse.Panel>
                </Collapse>
              ) : (
                <Link
                  key={category.key}
                  className="link"
                  to={ROUTES.public.summary.generate(category.seoFriendlyTitle)}
                >
                  {category.title}
                </Link>
              ),
            )}
          <Link
            to={ROUTES.public.actualites.generate(authentifiedContext.universe)}
            className="link"
          >
            {t<string>("menu.news")}
          </Link>
          {authentifiedContext.universe.value ===
            UNIVERSES.PROFESSIONAL.value && (
            <Link
              to={ROUTES.professional.contacts.publicForm.generate()}
              className="link"
            >
              {t<string>("menu.contact")}
            </Link>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <div className="sidemenu-select">
            <SelectFormField
              initialValue={authentifiedContext.universe.value}
              module="folders.personalInfos"
              field="universe"
              required={true}
              options={Object.values(UNIVERSES)}
              onSelect={(value) =>
                navigate(ROUTES.public.universe.generate(UNIVERSES[value]))
              }
            />
            <div className="sidemenu-select-text">
              {t<string>("header.universe.youAre")}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
