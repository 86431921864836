import { FunctionComponent } from "react";
import BasicTag from "@components/tags/BasicTag";

interface GazEventActionTagProps {
  label: string;
  variant?: string;
  className?: string;
}

const GazEventActionTag: FunctionComponent<GazEventActionTagProps> = ({
  label,
  variant = "primary",
  className = "",
}) => {
  return (
    <BasicTag
      className={`tag-action-gaz-month tag-${variant} ${className}`}
      label={label}
    />
  );
};

export default GazEventActionTag;
