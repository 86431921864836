import { FunctionComponent } from "react";

interface QualificationDetailItemProps {
  title: string | JSX.Element;
  description: string | number | JSX.Element;
  icon?: JSX.Element;
  className?: string;
}

const QualificationDetailItem: FunctionComponent<
  QualificationDetailItemProps
> = ({ title, description, icon, className = "" }) => {
  return (
    <div className={`app-qualification-item ${className}`}>
      <div className="item-title">{title}</div>
      <div className="item-description">
        {description}
        {icon && icon}
      </div>
    </div>
  );
};

export default QualificationDetailItem;
