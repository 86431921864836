import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import configuration from "@utils/Config";
import { Tabs } from "antd";
import { prependHttps } from "@utils/history";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { requestGetGazProfessional } from "@state/gazprofessional/GazProfessionalEffects";
import Slider, { Settings } from "react-slick";
import { getBase64UrlFrom } from "@utils/Base64File";
import { requestGetResource } from "@state/resource/ResourceEffects";
import { addressUtils } from "@utils/address-utils";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import { ACTION_TYPE } from "@utils/enums/gaz.month.action.type.enum";
import { getOptionPartner } from "@utils/gazEvent-utils";
import GazEventIndividualPromotionCard from "@components/cards/GazEventIndividualPromotionCard";
import { ERP } from "@utils/enums/erp.enum";

const { TabPane } = Tabs;

interface ContactItemDetailsProps {
  gazProfessionalItem: GazProfessionalItemResponseDto;
  className?: string;
}

interface Pictures {
  id: string;
  content: string;
}

const settingsPicture: Settings = {
  dots: false,
  arrows: true,
  draggable: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3000,
};

const ContactItemDetails: FunctionComponent<ContactItemDetailsProps> = ({
  gazProfessionalItem,
  className = "",
}) => {
  const { t } = useTranslation();
  const isGazEvent = configuration.isGazEvent;
  const gazEventType = configuration.gazEventType;

  const [hidePhone, setHidePhone] = useState<boolean>(true);
  const [professional, setProfessional] = useState<
    GazProfessionalUser | undefined
  >();
  const [isLegalMentionShow, setLegalMentionShow] = useState<boolean>(false);
  const isParticipatedInTheCurrentEvent =
    professional?.gazEvent?.[gazEventType];

  const getERP = () => {
    return professional?.gazProfessionalTertiaryDetail?.erp
      .map((m) => t<string>(ERP[m.toUpperCase()]?.label) ?? m)
      .join(" • ");
  };

  useEffect(() => {
    if (gazProfessionalItem?.yoocanId) {
      void requestGetGazProfessional(gazProfessionalItem?.yoocanId.toString());
      setHidePhone(true);
    }
  }, [gazProfessionalItem?.yoocanId]);

  useEffect(() => {
    return requestGetGazProfessional.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setProfessional(result.data);
      }
    });
  });

  const handleShowPhone = (e: React.MouseEvent<HTMLElement>) => {
    setHidePhone(false);
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  const pictureListId = professional?.pictureListId;
  const [picturesContent, setPicturesContent] = useState<Pictures[]>([]);

  useEffect(() => {
    if (pictureListId) {
      pictureListId.forEach((pictureId) => {
        void requestGetResource(pictureId);
      });
    }
  }, [pictureListId]);

  useEffect(() => {
    setPicturesContent([]);
  }, [professional]);

  useEffect(() => {
    return requestGetResource.done.watch(({ result, params }) => {
      if (result.ok && result.data) {
        const resourceData = result.data;
        setPicturesContent((currentList) => [
          ...currentList,
          { id: params, content: resourceData.data },
        ]);
      }
    });
  });

  return (
    <>
      {professional ? (
        <div className={`app-contact-item-details ${className}`}>
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab={t<string>("gazprofessional.item.contact")} key="1">
              <>
                <div className="contact-infos">
                  <div className="contact-infos-item">
                    <img src="/img/search/contact/contact-address.svg" alt="" />
                    <span>{`${
                      professional.editableAddress ??
                      addressUtils.getAddressMap(professional)
                    }, ${
                      professional.editableZipCode ?? professional.zipcode
                    } ${professional.editableCity ?? professional.city}`}</span>
                  </div>
                  {(professional.editablePhone1 || professional.phone1) && (
                    <div className="d-flex justify-content-between contact-infos-item">
                      {hidePhone ? (
                        <a className="btn-phone" onClick={handleShowPhone}>
                          <img
                            src="/img/search/contact/contact-phone.svg"
                            alt=""
                          />
                          <span>
                            {t<string>("gazprofessional.item.showPhone")}
                          </span>
                        </a>
                      ) : (
                        <>
                          <a
                            className="btn-phone"
                            href={`tel:${
                              professional.editablePhone1 ?? professional.phone1
                            }`}
                          >
                            <img
                              src="/img/search/contact/contact-phone.svg"
                              alt=""
                            />
                            <span>
                              {professional.editablePhone1 ??
                                professional.phone1}
                            </span>
                          </a>
                          {professional.editablePhone2 && (
                            <a
                              className="professional-details-info"
                              href={`tel:${professional?.editablePhone2}`}
                            >
                              <img
                                src="/img/search/contact/contact-phone.svg"
                                alt=""
                              />
                              {professional.editablePhone2}
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {(professional.editableEmail || professional.email) && (
                    <div className="contact-infos-item">
                      <a
                        className="professional-details-info"
                        href={`mailto:${
                          professional.editableEmail || professional.email
                        }`}
                      >
                        <img
                          src="/img/search/contact/contact-email.svg"
                          alt=""
                        />
                        {professional.editableEmail || professional.email}
                      </a>
                    </div>
                  )}
                  {professional.website && (
                    <div className="contact-infos-item">
                      <a
                        className="btn-phone"
                        href={prependHttps(professional.website)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/img/search/contact/contact-website.svg"
                          alt=""
                        />
                        <span>
                          {t<string>("gazprofessional.icons.network.text")}
                        </span>
                      </a>
                    </div>
                  )}
                </div>
                {(professional.linkedin ||
                  professional.facebook ||
                  professional.twitter) && (
                  <div className="contact-socials">
                    {professional.linkedin && (
                      <a
                        className="contact-socials-item"
                        href={prependHttps(professional.linkedin)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={t<string>("gazprofessional.icons.linkedin")}
                          src={"/img/icons/linkedin-individual-v2.svg"}
                        />
                        <span>
                          {t<string>("gazprofessional.item.linkedin")}
                        </span>
                      </a>
                    )}
                    {professional.facebook && (
                      <a
                        className="contact-socials-item"
                        href={prependHttps(professional.facebook)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={t<string>("gazprofessional.icons.facebook")}
                          src={"/img/icons/facebook-individual-v2.svg"}
                        />
                        <span>
                          {t<string>("gazprofessional.item.facebook")}
                        </span>
                      </a>
                    )}
                    {professional.twitter && (
                      <a
                        className="contact-socials-item"
                        href={prependHttps(professional.twitter)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          alt={t<string>("gazprofessional.icons.twitter")}
                          src={"/img/icons/twitter-individual-v2.svg"}
                        />
                        <span>{t<string>("gazprofessional.item.twitter")}</span>
                      </a>
                    )}
                  </div>
                )}
              </>
            </TabPane>
            {isGazEvent &&
              isParticipatedInTheCurrentEvent &&
              professional.gazEvent?.actions &&
              professional.gazEvent?.actions?.length > 0 && (
                <TabPane
                  tab={
                    <div className="contact-offers-title">
                      <span>{t<string>("gazprofessional.item.offers")}</span>
                      {professional.gazEvent?.actions &&
                        professional.gazEvent?.actions.length > 0 && (
                          <div className="contact-offers-title-badge">
                            {professional.gazEvent?.actions.length}
                          </div>
                        )}
                    </div>
                  }
                  key="2"
                >
                  <div className="contact-offers">
                    {professional.gazEvent?.actions.map((action) => (
                      <div key={action.id} className="contact-offers-item">
                        <p className="contact-offers-item-title">
                          {action.title
                            .concat(": ")
                            .concat(
                              action.partner
                                .map(
                                  (part) =>
                                    Object.values(
                                      getOptionPartner(
                                        ACTION_TYPE[action.actionType],
                                      ),
                                    ).filter((p) => p.value === part)[0].label,
                                )
                                .join(", "),
                            )}
                        </p>
                        <p className="contact-offers-item-description">
                          {action?.description &&
                          action?.description?.length > 0
                            ? action.description
                            : t<string>(
                                `${gazEventType}.individual.professional.actions.description`,
                              )}
                        </p>
                      </div>
                    ))}

                    <GazEventIndividualPromotionCard
                      title={t<string>(
                        `${gazEventType}.individual.professional.connection.title`,
                      )}
                      promotionFrom="2024-10-01"
                      promotionTo="2024-11-30"
                    />
                    <a
                      onClick={() => {
                        setLegalMentionShow(!isLegalMentionShow);
                      }}
                    >
                      <div className="contact-offers-link">
                        {t<string>(
                          `gazprofessional.${
                            isLegalMentionShow
                              ? "closeMentionsLegales"
                              : "openMentionsLegales"
                          }`,
                        )}
                      </div>
                    </a>
                    {isLegalMentionShow && (
                      <div className="small mb-1">
                        {t<string>(
                          `${gazEventType}.individual.professional.connection.mention`,
                        )}
                      </div>
                    )}
                  </div>
                </TabPane>
              )}
            <TabPane tab={t<string>("gazprofessional.item.about")} key="3">
              <div className="contact-about">
                {professional.description && (
                  <p className="contact-about-description mb-3">
                    {professional.description}
                  </p>
                )}
                {(professional?.smokeDuct ||
                  professional?.gazEvent ||
                  professional?.pacHybride ||
                  professional.greenGaz != undefined) && (
                  <div className="contact-about-certifications mb-3">
                    {professional?.smokeDuct?.status === "ACCEPTED" && (
                      <div className="contact-about-certifications-item">
                        <div className="contact-about-certifications-item-infos">
                          <span className="contact-about-certifications-item-infos-title">
                            {t<string>("gazprofessional.item.mention")}
                          </span>
                          <span className="contact-about-certifications-item-infos-name">
                            {t<string>(
                              "gazprofessional.certifications.smokeDuct",
                            )}
                          </span>
                        </div>
                        <img
                          src="/img/icons/certif-smoke-duct-v2.svg"
                          alt={t<string>(
                            "gazprofessional.certifications.smokeDuct",
                          )}
                        />
                      </div>
                    )}
                    {professional?.pacHybride?.status === "ACCEPTED" && (
                      <div className="contact-about-certifications-item">
                        <div className="contact-about-certifications-item-infos">
                          <span className="contact-about-certifications-item-infos-title">
                            {t<string>("gazprofessional.item.mention")}
                          </span>
                          <span className="contact-about-certifications-item-infos-name">
                            {t<string>(
                              "gazprofessional.certifications.pacHybride",
                            )}
                          </span>
                        </div>
                        <img
                          src="/img/icons/certif-pac-hybride.svg"
                          alt={t<string>(
                            "gazprofessional.certifications.pacHybride",
                          )}
                        />
                      </div>
                    )}
                    {professional.greenGaz != undefined && (
                      <div className="contact-about-certifications-item">
                        <div className="contact-about-certifications-item-infos">
                          <span className="contact-about-certifications-item-infos-title">
                            {t<string>("gazprofessional.item.mention")}
                          </span>
                          <span className="contact-about-certifications-item-infos-name">
                            {t<string>(
                              "gazprofessional.certifications.greenGaz",
                            )}
                          </span>
                        </div>
                        <img
                          src="/img/icons/certif-green-gaz.svg"
                          alt={t<string>(
                            "gazprofessional.certifications.greenGaz",
                          )}
                        />
                      </div>
                    )}
                    {isGazEvent && professional?.gazEvent?.gazMonth && (
                      <div className="contact-about-certifications-item">
                        <div className="contact-about-certifications-item-infos">
                          <span className="contact-about-certifications-item-infos-title">
                            {t<string>("gazprofessional.item.event")}
                          </span>
                          <span className="contact-about-certifications-item-infos-name">
                            {t<string>(
                              "gazprofessional.certifications.gazMonth",
                            )}
                          </span>
                        </div>
                        <img
                          src="/img/icons/certif-mois-du-chauffage.svg"
                          alt={t<string>(
                            "gazprofessional.certifications.gazMonth",
                          )}
                        />
                      </div>
                    )}
                    {isGazEvent &&
                      professional?.gazEvent?.rentreeDuChauffage && (
                        <div className="contact-about-certifications-item">
                          <div className="contact-about-certifications-item-infos">
                            <span className="contact-about-certifications-item-infos-title">
                              {t<string>("gazprofessional.item.event")}
                            </span>
                            <span className="contact-about-certifications-item-infos-name">
                              {t<string>(
                                "gazprofessional.certifications.rentreeDuChauffage",
                              )}
                            </span>
                          </div>
                          <img
                            src="/img/icons/certif-rentree-du-chauffage.svg"
                            alt={t<string>(
                              "gazprofessional.certifications.rentreeDuChauffage",
                            )}
                          />
                        </div>
                      )}
                  </div>
                )}
                {(professional?.gazProfessionalTertiaryDetail
                  ?.participatesErp ||
                  picturesContent.length > 0) && (
                  <div className="contact-about-certifications mb-3">
                    {professional?.gazProfessionalTertiaryDetail
                      ?.participatesErp && (
                      <div className="contact-about-certifications-item">
                        <div className="contact-about-certifications-item-infos">
                          <span className="contact-about-certifications-item-infos-name">
                            {t<string>("gazprofessional.certifications.erp")}
                          </span>
                        </div>
                        <div className="professional-item-mention">
                          {getERP()}
                        </div>
                      </div>
                    )}
                    {picturesContent.length > 0 && (
                      <div className="contact-about-pictures">
                        <span className="contact-about-pictures-title">
                          {t<string>("gazprofessional.item.pictures")}
                        </span>
                        <div className="slider-container contact-about-pictures-slider">
                          <Slider {...settingsPicture}>
                            {picturesContent.map((picture, index) => (
                              <div
                                key={index.valueOf()}
                                className="d-flex align-items-center justify-content-center px-5"
                              >
                                <img
                                  src={getBase64UrlFrom(picture.content, "PNG")}
                                  alt={"Photos du PG"}
                                  className="max-w-100"
                                />
                              </div>
                            ))}
                          </Slider>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {professional?.yoocanId && (
                  <p className="contact-about-yoocanId">
                    {t<string>("gazprofessional.yoocanId", {
                      yoocanId: professional.yoocanId,
                    })}
                  </p>
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      ) : (
        <div className="app-contact-item-details">
          <div className="contact-infos mb-3">
            <div className="contact-infos-item">
              <img src="/img/search/contact/contact-address.svg" alt="" />
              <span>{`${gazProfessionalItem?.address}, ${gazProfessionalItem?.postalCode} ${gazProfessionalItem?.city}`}</span>
            </div>
            {gazProfessionalItem?.phone && (
              <div className="d-flex justify-content-between contact-infos-item">
                {hidePhone ? (
                  <a
                    className="btn-phone"
                    onClick={handleShowPhone}
                    onKeyDown={() => handleShowPhone}
                  >
                    <img src="/img/search/contact/contact-phone.svg" alt="" />
                    <span>{t<string>("gazprofessional.item.showPhone")}</span>
                  </a>
                ) : (
                  <a
                    className="btn-phone"
                    href={`tel:${gazProfessionalItem?.phone}`}
                  >
                    <img src="/img/search/contact/contact-phone.svg" alt="" />
                    <span>{gazProfessionalItem?.phone}</span>
                  </a>
                )}
              </div>
            )}
            {gazProfessionalItem?.email && (
              <div className="contact-infos-item">
                <a
                  className="professional-details-info"
                  href={`mailto:${gazProfessionalItem?.email}`}
                >
                  <img src="/img/search/contact/contact-email.svg" alt="" />
                  {gazProfessionalItem?.email}
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ContactItemDetails;
