import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import { Form, Steps } from "antd";
import { useViewport } from "@utils/breakpoints-helper";
import { WORKSITE_STATUS, WorksiteStatus } from "@utils/enums/worksite.enum";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { IconInit } from "../../static/icons";
import { ROUTES } from "@utils/Routes";

const { Step } = Steps;

interface UpdateStatusWorksiteCardProps {
  worksiteStatus: WorksiteStatus;
  className?: string;
  comment?: string;
}

const UpdateStatusWorksiteCard: FunctionComponent<
  UpdateStatusWorksiteCardProps
> = ({ worksiteStatus, className = "", comment }) => {
  const { t } = useTranslation();
  const { width } = useViewport();

  const InitialValue = {
    comment: comment,
  };

  const renderCardFromWorksiteStatus = () => {
    switch (worksiteStatus.value) {
      case "INIT_INELIGIBLE":
        return (
          <>
            <div className="card-update-status-description">
              {t<string>("worksite.cards.updateStatus.descriptionIneligible")}
            </div>
            <Steps
              current={worksiteStatus.position}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "PENDING" &&
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) =>
                  status.value === "INIT_INELIGIBLE" ? (
                    <Step
                      key={status.value}
                      title={t<string>(`worksite.status.${status.value}`)}
                      status={"error"}
                    />
                  ) : (
                    <Step
                      key={status.value}
                      title={t<string>(`worksite.status.${status.value}`)}
                      icon={status.value === "INIT" && <IconInit />}
                    />
                  ),
                )}
            </Steps>
          </>
        );
      case "PENDING":
        return (
          <>
            <div className="card-update-status-description">
              <b>
                {t<string>("worksite.cards.updateStatus.descriptionInitBold")}
              </b>
              {t<string>("worksite.cards.updateStatus.descriptionInit")}
            </div>
            <Steps
              current={worksiteStatus.position - 1}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) => (
                  <Step
                    key={status.value}
                    title={t<string>(`worksite.status.${status.value}`)}
                    icon={status.value === "INIT" && <IconInit />}
                  />
                ))}
            </Steps>
          </>
        );
      case "PENDING_INELIGIBLE":
        return (
          <>
            <div className="card-update-status-description">
              {t<string>("worksite.cards.updateStatus.descriptionIneligible")}
            </div>
            <Steps
              current={worksiteStatus.position - 2}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "WAITING" &&
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) =>
                  status.value === "PENDING_INELIGIBLE" ? (
                    <Step
                      key={status.value}
                      title={t<string>(`worksite.status.${status.value}`)}
                      status={"error"}
                    />
                  ) : (
                    <Step
                      key={status.value}
                      title={t<string>(`worksite.status.${status.value}`)}
                      icon={status.value === "INIT" && <IconInit />}
                    />
                  ),
                )}
            </Steps>
          </>
        );
      case "ELIGIBLE":
        return (
          <>
            <div className="card-update-status-description">
              {t<string>("worksite.cards.updateStatus.descriptionEligible")}
            </div>
            <Steps
              current={worksiteStatus.position - 2}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) => (
                  <Step
                    key={status.value}
                    title={t<string>(`worksite.status.${status.value}`)}
                    icon={status.value === "INIT" && <IconInit />}
                  />
                ))}
            </Steps>
          </>
        );
      case "WAITING":
        return (
          <>
            <div className="card-update-status-description">
              {t<string>("worksite.cards.updateStatus.descriptionWaiting")}
            </div>
            <Steps
              current={worksiteStatus.position - 1}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) => (
                  <Step
                    key={status.value}
                    title={t<string>(`worksite.status.${status.value}`)}
                    icon={status.value === "INIT" && <IconInit />}
                  />
                ))}
            </Steps>
          </>
        );
      case "GRDF":
        return (
          <>
            <div className="card-update-status-description">
              {t<string>("worksite.cards.updateStatus.descriptionGRDF")}
            </div>
            <Steps
              current={worksiteStatus.position - 2}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) => (
                  <Step
                    key={status.value}
                    title={t<string>(`worksite.status.${status.value}`)}
                    icon={status.value === "INIT" && <IconInit />}
                  />
                ))}
            </Steps>
          </>
        );
      default:
        return (
          <>
            <div className="card-update-status-description">
              <b>
                {t<string>("worksite.cards.updateStatus.descriptionInitBold")}
              </b>
              {t<string>("worksite.cards.updateStatus.descriptionInit")}
            </div>
            <Steps
              current={worksiteStatus.position}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(WORKSITE_STATUS)
                .filter(
                  (s) =>
                    s.value !== "PENDING_INELIGIBLE" &&
                    s.value !== "INIT_INELIGIBLE" &&
                    s.value !== "GRDF_SUPPORT",
                )
                .map((status) => (
                  <Step
                    key={status.value}
                    title={t<string>(`worksite.status.${status.value}`)}
                    icon={status.value === "INIT" && <IconInit />}
                  />
                ))}
            </Steps>
          </>
        );
    }
  };

  return (
    <BasicCard className={`app-card-update-status ${className}`}>
      <>
        <div className="d-flex align-items-center justify-content-end flex-wrap-reverse">
          <div className="flex-grow-1">
            <div className="card-update-status-title">
              {t<string>("worksite.cards.updateStatus.title")}
            </div>
          </div>
        </div>
        <>
          {renderCardFromWorksiteStatus()}
          {(worksiteStatus.value === WORKSITE_STATUS.PENDING_INELIGIBLE.value ||
            worksiteStatus.value === WORKSITE_STATUS.INIT_INELIGIBLE.value) && (
            <Form initialValues={InitialValue}>
              <TextAreaFormField
                className="card-update-status-textarea"
                module="worksite"
                field="comment"
                showLabel
                readOnly
              />
            </Form>
          )}

          <div className="card-update-status-under-description font-weight-bold">
            {t<string>("worksite.cards.updateStatus.underdescription")}
            <a href={ROUTES.professional.contacts.publicForm.generate()}>
              <span>{t<string>("worksite.cards.updateStatus.link")}</span>
            </a>
          </div>
        </>
      </>
    </BasicCard>
  );
};

export default UpdateStatusWorksiteCard;
