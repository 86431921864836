import React from "react";
import parse from "html-react-parser";
import BasicButton from "@components/buttons/BasicButton";
import RadioFormField from "@components/inputs/RadioFormField";
import { useTranslation } from "react-i18next";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import { arrayUtils } from "@utils/array-utils";

interface TertiaryModalSecondStepProps {
  setStep: (step: number) => void;
  setParticipatesErp: (participatesErp: boolean) => void;
  setErps: (erp: string[]) => void;
  setUsage1: (usage1: string[]) => void;
  setUsage2: (usage2: string[]) => void;
  setUsage3: (usage3: string[]) => void;
  setUsage4: (usage4: string[]) => void;
  setUsage5: (usage5: string[]) => void;
  participatesErp: boolean;

  erps: string[];
  usage1: string[];
  usage2: string[];
  usage3: string[];
  usage4: string[];
  usage5: string[];
}

export const TertiaryModalSecondStep: React.FC<
  TertiaryModalSecondStepProps
> = ({
  setStep,
  usage1,
  usage2,
  usage3,
  usage4,
  usage5,
  erps,
  setUsage1,
  setUsage2,
  setUsage3,
  setUsage4,
  participatesErp = false,
  setUsage5,
  setErps,
  setParticipatesErp,
}) => {
  const { t } = useTranslation();

  const handleErp = (erp: string) => {
    setErps(arrayUtils.addOrRemoveUniqueItem(erps, erp));
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <h3 className="text-primary">
          {parse(t<string>("pro.tertiary.modal3.step"))}
        </h3>
      </div>
      <div className="mt-30">
        <h3 className="text-primary">
          {parse(t<string>("pro.tertiary.modal3.title"))}
        </h3>
        <RadioFormField
          initialValue={"no"}
          required
          field="participatesErp"
          onChange={(value) => setParticipatesErp(value.target.value === "yes")}
          options={[
            {
              value: "yes",
              label: t("common.yes"),
            },
            {
              value: "no",
              label: t("common.no"),
            },
          ]}
        />

        {participatesErp && (
          <div className="tertiary">
            <h4 className="text-primary">
              {parse(t<string>("pro.tertiary.modal3.detail"))}
            </h4>
            <div className="mb-4">
              <CheckboxFormField
                module="pro.tertiary.modal3"
                field="erp5"
                parsed
                noBold
                onChange={() => handleErp("erp5")}
              />
              {erps.includes("erp5") && (
                <div className="usage-item">
                  <span>
                    {parse(t<string>("pro.tertiary.modal3.form.usage"))}
                  </span>
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp5.chauffage"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage5(
                        arrayUtils.addOrRemoveUniqueItem(usage5, "chauffage"),
                      )
                    }
                  />
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp5.ECS"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage5(arrayUtils.addOrRemoveUniqueItem(usage5, "ecs"))
                    }
                  />
                </div>
              )}
            </div>
            <div className="mb-4">
              <CheckboxFormField
                module="pro.tertiary.modal3"
                field="erp4"
                parsed
                noBold
                onChange={() => handleErp("erp4")}
              />
              {erps.includes("erp4") && (
                <div className="usage-item">
                  <span>
                    {parse(t<string>("pro.tertiary.modal3.form.usage"))}
                  </span>
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp4.chauffage"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage4(
                        arrayUtils.addOrRemoveUniqueItem(usage4, "chauffage"),
                      )
                    }
                  />
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp4.ECS"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage4(arrayUtils.addOrRemoveUniqueItem(usage4, "ecs"))
                    }
                  />
                </div>
              )}
            </div>
            <div className="mb-4">
              <CheckboxFormField
                module="pro.tertiary.modal3"
                field="erp3"
                parsed
                noBold
                onChange={() => handleErp("erp3")}
              />
              {erps.includes("erp3") && (
                <div className="usage-item">
                  <span>
                    {parse(t<string>("pro.tertiary.modal3.form.usage"))}
                  </span>
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp3.chauffage"
                    parsed
                    onChange={() =>
                      setUsage3(
                        arrayUtils.addOrRemoveUniqueItem(usage3, "chauffage"),
                      )
                    }
                    noBold
                  />
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp3.ECS"
                    parsed
                    onChange={() =>
                      setUsage3(arrayUtils.addOrRemoveUniqueItem(usage3, "ecs"))
                    }
                    noBold
                  />
                </div>
              )}
            </div>
            <div className="mb-4">
              <CheckboxFormField
                module="pro.tertiary.modal3"
                field="erp2"
                parsed
                onChange={() => handleErp("erp2")}
                noBold
              />
              {erps.includes("erp2") && (
                <div className="usage-item">
                  <span>
                    {parse(t<string>("pro.tertiary.modal3.form.usage"))}
                  </span>
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp2.chauffage"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage2(
                        arrayUtils.addOrRemoveUniqueItem(usage2, "chauffage"),
                      )
                    }
                  />
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp2.ECS"
                    parsed
                    onChange={() =>
                      setUsage2(arrayUtils.addOrRemoveUniqueItem(usage2, "ecs"))
                    }
                    noBold
                  />
                </div>
              )}
            </div>
            <div className="mb-4">
              <CheckboxFormField
                module="pro.tertiary.modal3"
                field="erp1"
                parsed
                onChange={() => handleErp("erp1")}
                noBold
              />
              {erps.includes("erp1") && (
                <div className="usage-item">
                  <span>
                    {parse(t<string>("pro.tertiary.modal3.form.usage"))}
                  </span>
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp1.chauffage"
                    parsed
                    noBold
                    onChange={() =>
                      setUsage1(
                        arrayUtils.addOrRemoveUniqueItem(usage1, "chauffage"),
                      )
                    }
                  />
                  <CheckboxFormField
                    module="pro.tertiary.modal3"
                    field="erp1.ECS"
                    parsed
                    onChange={() =>
                      setUsage1(arrayUtils.addOrRemoveUniqueItem(usage1, "ecs"))
                    }
                    noBold
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <BasicButton
            text={t<string>("pro.tertiary.modal3.nextStep")}
            onClick={() => setStep(3)}
            disabled={participatesErp && erps.length === 0}
          />
        </div>
      </div>
    </div>
  );
};
