import { FunctionComponent } from "react";
import { ImageBlockItem } from "@type/cms/cms.types";

const ImageBlock: FunctionComponent<ImageBlockItem> = ({
  src = "",
  alt = "",
  width = undefined,
  height = undefined,
  className = "",
  title = undefined,
  icon = undefined,
  redirectUrl = "",
  description = undefined,
  compact = false,
}) => {
  const handleClick = () => {
    if (redirectUrl) window.location.replace(redirectUrl);
  };

  return (
    <a
      href={redirectUrl}
      className={`${redirectUrl ? "" : "pe-none"} unstyled-a`}
    >
      <div
        className={`content-block-container ${className} ${
          redirectUrl ? "image-block-interactive" : ""
        } ${compact ? "content-block-compact" : ""}`}
        onClick={handleClick}
      >
        <div className="content-block-head">
          <div className={"image-block-img"}>
            <img
              width={width}
              height={height}
              src={src}
              alt={alt}
              title={title}
            />
          </div>
          {title && <h4 className={"image-block-title"}>{title}</h4>}
        </div>
        {description && (
          <div className={"image-block-description-container"}>
            {icon && (
              <span className={"image-block-description-icon"}>{icon}</span>
            )}
            <span className={"image-block-description"}>{description}</span>
          </div>
        )}
      </div>
    </a>
  );
};

export default ImageBlock;
