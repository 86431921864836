export const ACTION_TYPE: { [index: string]: ActionType } = {
  PAC: {
    value: "PAC",
    label: "Offre PAC Hybride",
  },
  THPE: {
    value: "THPE",
    label: "Offre THPE",
  },
  POELE: {
    value: "POELE",
    label: "Offre Poêle et insert gaz",
  },
  REGULATION: {
    value: "REGULATION",
    label: "Offre Régulation & émetteurs",
  },
};

export interface ActionType {
  value: "PAC" | "THPE" | "POELE" | "REGULATION";
  label: string;
}
