import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Form, Modal, Row } from "antd";
import { emailFormatRegex, formItemBlockLayout } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import RadioFormField from "@components/inputs/RadioFormField";
import { SelectFormField } from "@components/inputs/SelectFormField";
import {
  NUMBER_BOILER,
  TYPE_RENOVATION,
  WORKSITE_STATUS,
} from "@utils/enums/worksite.enum";
import UploadFormField from "@components/inputs/UploadFormField";
import { Store } from "rc-field-form/lib/interface";
import {
  requestDeleteWorksite,
  requestGetWorksite,
  requestUpdateWorksite,
} from "@state/worksite/WorksiteEffects";
import { WorksiteResponseDto } from "@state/worksite/dto/worksite.response.dto";
import UpdateStatusWorksiteCard from "@components/cards/UpdateStatusWorksiteCard";
import SubmitButton from "@components/buttons/SubmitButton";
import BasicButton from "@components/buttons/BasicButton";
import { deleteGazEventAction } from "@state/auth/AuthEvents";
import { mapResourceTofileType } from "@utils/upload-utils";
import { File } from "@type/resource-type-interface";
import { WorksiteSaveRequestDto } from "@state/worksite/dto/worksite.save.request.dto";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import { requestGetResource } from "@state/resource/ResourceEffects";
import { debugBase64, downloadFile } from "@utils/Base64File";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { WorksiteState } from "@type/worksite/WorksiteState";
import { ROUTES } from "@utils/Routes";

interface FormValues {
  name: string;
  city: string;
  address: string;
  zip: string;
  typePipe: string;
  numberBoiler: number;
  typeRenovation: string;
  email: string;
  worksiteEmail: string;
}

const initialValue = (
  worksite: WorksiteResponseDto,
  user: GazProfessionalUser,
): FormValues => {
  return {
    name: worksite.name,
    city: worksite.city,
    address: worksite.address,
    zip: worksite.zip,
    typePipe: worksite.typePipe,
    numberBoiler: worksite.numberBoiler,
    typeRenovation: worksite.typeRenovation,
    email: user?.email,
    worksiteEmail: worksite.email,
  };
};

const { confirm } = Modal;

export const WorksiteDetailScreen: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [resourceId, setResourceId] = useState<string[] | undefined>();

  const [devis, setDevis] = useState<File[]>();
  const [bill, setBill] = useState<File[]>();
  const [cc2, setCc2] = useState<File[]>();
  const [rib, setRib] = useState<File[]>();
  const [bonusBill, setBonusBill] = useState<File[]>();
  const [acceptedMentionTypes] = useState<string[]>([
    RESOURCE_TYPE.PDF.applicationType,
  ]);

  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;

  const location = useLocation();

  const worksiteId = location.pathname.split("/").pop() || "";

  const [worksite, setWorksite] = useState<WorksiteResponseDto>(
    (location.state as WorksiteState)?.initialWorksite || undefined,
  );

  const [typePipe, setTypePipe] = useState<string>(
    worksite ? worksite?.typePipe : "individual",
  );

  const [editMode, setEditMode] = useState<boolean>(false);
  const [removeEditMode, setRemoveEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDevisOrFactureLoading, setIsDevisOrFactureLoading] =
    useState<boolean>(false);
  const [isRibOrCC2Loading, setIsRibOrCC2Loading] = useState<boolean>(false);

  const onChange = (): void => {
    typePipe === "individual"
      ? setTypePipe("collectif")
      : setTypePipe("individual");
  };

  useEffect(() => {
    return requestGetWorksite.done.watch(({ result }) => {
      if (result.ok && result.data) {
        if (result.data.resource && result.data.resource.length > 0) {
          setDevis(
            result.data.resource
              .filter((file) => file.domain === "WORKSITE_DEVIS")
              .map((s) => mapResourceTofileType(s)),
          );
          setBill(
            result.data.resource
              .filter((file) => file.domain === "WORKSITE_BILL")
              .map((s) => mapResourceTofileType(s)),
          );
          setCc2(
            result.data.resource
              .filter((file) => file.domain === "WORKSITE_CC2")
              .map((s) => mapResourceTofileType(s)),
          );
          setRib(
            result.data.resource
              .filter((file) => file.domain === "WORKSITE_RIB")
              .map((s) => mapResourceTofileType(s)),
          );
          setBonusBill(
            result.data.resource
              .filter((file) => file.domain === "WORKSITE_BONUS_BILL")
              .map((s) => mapResourceTofileType(s)),
          );
          setResourceId(result.data.resource.map((r) => r.id));
        }
        setWorksite(result.data);
        setTypePipe(result.data.typePipe);
      } else {
        toastError(t<string>("pro.contact.workflowFailed"));
      }
    });
  });

  const previewOrDownloadFromBonusFile = (uid: string): void => {
    const bonusBillFile = bonusBill?.find((f) => f.id === uid);
    if (bonusBillFile?.id) {
      void requestGetResource(bonusBillFile.id).then((resource) => {
        if (resource.ok && resource.data) {
          if (bonusBillFile.type === "application/pdf") {
            debugBase64(resource.data.data, bonusBillFile.type);
          } else {
            downloadFile(
              resource.data.data,
              bonusBillFile.type,
              bonusBillFile.name,
            );
          }
        }
      });
    }
  };

  const confirmationPopup = async (action?: string) => {
    return new Promise<boolean>((resolve) => {
      confirm({
        title: t<string>("common.confirm"),
        content:
          action === "submit"
            ? t<string>("worksite.modal.save.title")
            : t<string>("worksite.modal.delete.title"),
        okText: t<string>("common.yes"),
        cancelText: t<string>("common.no"),
        centered: true,
        className: "app-modal professional",
        onOk() {
          resolve(true);
        },
        onCancel() {
          resolve(false);
        },
      });
    });
  };

  useEffect(() => {
    form.resetFields(["numberBoiler", "typeRenovation"]);
  }, [form, typePipe]);

  const isPendingIneligible =
    WORKSITE_STATUS.PENDING_INELIGIBLE.value === worksite?.worksiteStatus;
  const isInit = WORKSITE_STATUS.INIT.value === worksite?.worksiteStatus;
  const isInitIneligible =
    WORKSITE_STATUS.INIT_INELIGIBLE.value === worksite?.worksiteStatus;
  const isPending = WORKSITE_STATUS.PENDING.value === worksite?.worksiteStatus;
  const isGRDF = WORKSITE_STATUS.GRDF.value === worksite?.worksiteStatus;

  const isIndividual = typePipe === "individual";

  const handleSubmit = (values: Store) => {
    if (editMode || isIndividual) {
      void confirmationPopup("submit").then((confirmed: boolean) => {
        if (confirmed) {
          setIsLoading(true);
          const saveRequest: WorksiteSaveRequestDto = {
            name: values.name as string,
            city: values.city as string,
            address: values.address as string,
            zip: values.zip as string,
            typePipe: values.typePipe as string,
            numberBoiler: values.numberBoiler as number,
            typeRenovation: values.typeRenovation as string,
            resourceId: resourceId,
            email: values.worksiteEmail as string,
          };
          void requestUpdateWorksite({
            dto: saveRequest,
            id: worksiteId,
          });
        }
        setEditMode(false);
      });
    } else {
      if (removeEditMode) {
        setEditMode(false);
      } else {
        setEditMode(true);
        setRemoveEditMode(true);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return requestUpdateWorksite.done.watch(({ result }) => {
      if (result.ok && result.data) {
        navigate(ROUTES.professional.smokeDucts.mention.generate());
      } else if (result?.errorMessage) {
        toastError(result?.errorMessage);
      }
    });
  });

  const handleDelete = () => {
    void confirmationPopup("submit").then((confirmed: boolean) => {
      if (confirmed) {
        void requestDeleteWorksite({ dto: worksite }).then((result) => {
          if (result.ok) {
            deleteGazEventAction(worksite.id);
            toastSuccess(t<string>("worksite.actions.modals.delete.success"));
            navigate(ROUTES.professional.smokeDucts.mention.generate());
          } else {
            toastError(result.errorMessage);
          }
        });
      }
    });
  };
  useEffect(() => {
    if (worksite == undefined) void requestGetWorksite(worksiteId);
  }, [worksite, worksiteId]);

  return (
    <PageLayout
      container
      title={`${t<string>("worksite.detail.title")} ${
        worksite ? worksite.name : " "
      }`}
      titleCentered={false}
      returnRoute={ROUTES.professional.smokeDucts.mention.generate()}
      returnText={t<string>("worksite.detail.return")}
      bodyClassName="app-worksite-form-page"
      headerRightContent={
        worksite && !isIndividual ? (
          <img
            src="/img/pro/logo-grdf.svg"
            alt={t<string>(
              "pro.mentions.smokeDuct.detail.noCertificate.imgAlt",
            )}
            className="worksite-logo"
          />
        ) : isGRDF && bonusBill?.[0] != null ? (
          <BasicButton
            text={t<string>("worksite.bonusBill.download")}
            onClick={() =>
              bonusBill && previewOrDownloadFromBonusFile(bonusBill[0].id)
            }
          />
        ) : null
      }
    >
      <>
        {worksite && (
          <>
            {isIndividual && (
              <UpdateStatusWorksiteCard
                worksiteStatus={WORKSITE_STATUS[worksite.worksiteStatus]}
                className="mt-3 mb-40"
                comment={worksite.comment}
              />
            )}
            <div className="worksite-form-page-right ps-lg-4">
              <Form
                onFinish={handleSubmit}
                initialValues={initialValue(worksite, authentifiedUser)}
                {...formItemBlockLayout}
              >
                <h2 className="worksite-form-title">
                  {t<string>("worksite.form.information")}
                </h2>
                <InputFormField
                  showLabel
                  module="worksite"
                  field="name"
                  required
                  readOnly={!editMode}
                />
                <InputFormField
                  showLabel
                  module="worksite"
                  field="address"
                  required
                  readOnly={!editMode}
                />
                <Row gutter={10}>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <InputFormField
                      showLabel
                      module="worksite"
                      field="city"
                      required
                      readOnly={!editMode}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <InputFormField
                      showLabel
                      module="worksite"
                      field="zip"
                      required
                      readOnly={!editMode}
                    />
                  </Col>
                </Row>

                <h4 className="worksite-form-title">
                  {t<string>("worksite.form.radioGroup.typePipe.title")}
                </h4>
                <RadioFormField
                  initialValue={typePipe}
                  required
                  onChange={onChange}
                  field="typePipe"
                  options={[
                    {
                      value: "individual",
                      label: t<string>(
                        "worksite.form.radioGroup.typePipe.individual",
                      ),
                    },
                    {
                      value: "collectif",
                      label: t<string>(
                        "worksite.form.radioGroup.typePipe.collectif",
                      ),
                    },
                  ]}
                  readOnly
                />
                <Row gutter={10}>
                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <SelectFormField
                      initialValue={isIndividual ? "1" : "2"}
                      module="worksite"
                      field="numberBoiler"
                      options={
                        isIndividual
                          ? NUMBER_BOILER.filter((s) => s.value === "1")
                          : NUMBER_BOILER.filter((s) => s.value !== "1")
                      }
                      showLabel
                      required
                      readOnly={!editMode}
                    />
                  </Col>

                  <Col xs={24} sm={12} lg={24} xl={12}>
                    <SelectFormField
                      module="worksite"
                      field="typeRenovation"
                      showLabel
                      required
                      readOnly={!editMode}
                      options={
                        isIndividual
                          ? TYPE_RENOVATION.filter(
                              (s) => s.value === "INDIVIDUAL_RENOVATION",
                            )
                          : TYPE_RENOVATION.filter(
                              (s) => s.value !== "INDIVIDUAL_RENOVATION",
                            )
                      }
                      initialValue={
                        isIndividual
                          ? "INDIVIDUAL_RENOVATION"
                          : "COLLECTIVE_RENOVATION_SHUNT_COL"
                      }
                    />
                  </Col>
                </Row>
                {isIndividual ? (
                  <>
                    <h2 className="worksite-form-title">
                      {t<string>("worksite.form.ressources.title")}
                    </h2>
                    <UploadFormField
                      readOnly={!isInitIneligible}
                      name="devis"
                      label={t<string>("worksite.form.devis")}
                      domain="WORKSITE_DEVIS"
                      saveFile
                      setFilesId={setResourceId}
                      filesId={resourceId}
                      types={acceptedMentionTypes}
                      formatLabel="PDF"
                      multiple
                      files={devis}
                      showUploadList
                      onRemove
                      setIsUploading={setIsDevisOrFactureLoading}
                      customErrorMessage={t<string>(
                        "worksite.form.errorUploadMessage.label",
                      )}
                    />
                    <UploadFormField
                      readOnly={!isInitIneligible}
                      name="rib"
                      label={t<string>("worksite.form.rib")}
                      labelTooltip={t<string>("worksite.form.tooltip.rib")}
                      domain="WORKSITE_RIB"
                      saveFile
                      setFilesId={setResourceId}
                      filesId={resourceId}
                      types={acceptedMentionTypes}
                      formatLabel="PDF"
                      multiple
                      files={rib}
                      showUploadList
                      onRemove
                      setIsUploading={setIsRibOrCC2Loading}
                      customErrorMessage={t<string>(
                        "worksite.form.errorUploadMessage.label",
                      )}
                    />
                    {!isInit && !isInitIneligible && (
                      <>
                        <UploadFormField
                          name="bill"
                          label={t<string>("worksite.form.bill")}
                          domain="WORKSITE_BILL"
                          saveFile
                          setFilesId={setResourceId}
                          filesId={resourceId}
                          types={acceptedMentionTypes}
                          formatLabel="PDF"
                          multiple
                          setIsUploading={setIsDevisOrFactureLoading}
                          readOnly={
                            !isInitIneligible &&
                            !isPendingIneligible &&
                            !isPending
                          }
                          files={bill}
                          showUploadList
                          onRemove
                          customErrorMessage={t<string>(
                            "worksite.form.errorUploadMessage.label",
                          )}
                        />
                        <UploadFormField
                          name="cc2"
                          label={t<string>("worksite.form.cc2")}
                          domain="WORKSITE_CC2"
                          saveFile
                          setFilesId={setResourceId}
                          filesId={resourceId}
                          types={acceptedMentionTypes}
                          setIsUploading={setIsRibOrCC2Loading}
                          formatLabel="PDF"
                          multiple
                          readOnly={
                            !isInitIneligible &&
                            !isPendingIneligible &&
                            !isPending
                          }
                          files={cc2}
                          showUploadList
                          onRemove
                          customErrorMessage={t<string>(
                            "worksite.form.errorUploadMessage.label",
                          )}
                        />
                        {isGRDF && (
                          <>
                            <h2 className="worksite-form-title mt-30">
                              {t<string>("worksite.form.bonusBill.title")}
                            </h2>
                            <UploadFormField
                              name="bonusBill"
                              label={t<string>(
                                "worksite.form.bonusBill.subtitle",
                              )}
                              domain="WORKSITE_BONUS_BILL"
                              saveFile
                              setFilesId={setResourceId}
                              filesId={resourceId}
                              types={acceptedMentionTypes}
                              formatLabel="PDF"
                              multiple
                              readOnly={isGRDF}
                              files={bonusBill}
                              preview={isGRDF}
                              showUploadList
                              onRemove
                              customErrorMessage={t<string>(
                                "worksite.form.errorUploadMessage.label",
                              )}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        <InputFormField
                          showLabel
                          module="worksite"
                          field="email"
                          required
                          readOnly
                        />
                      </Col>
                      <Col xs={24} sm={12} lg={24} xl={12}>
                        <InputFormField
                          showLabel
                          module="worksite"
                          field="worksiteEmail"
                          rules={[
                            {
                              pattern: new RegExp(emailFormatRegex),
                              message: t<string>("common.email.errors.invalid"),
                            },
                          ]}
                          readOnly={!editMode}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <div className="ant-btn-right">
                  <Row>
                    {(isPendingIneligible ||
                      isInitIneligible ||
                      isPending ||
                      !isIndividual) && (
                      <SubmitButton
                        module="worksite"
                        className="mw-190 mb-30 mx-2 it-button"
                        label={
                          isIndividual ? "updateIndividual" : "updateCollectif"
                        }
                        isSubmitting={
                          isLoading ||
                          isDevisOrFactureLoading ||
                          isRibOrCC2Loading
                        }
                      />
                    )}
                  </Row>
                  <Row>
                    {(isPendingIneligible || isInit || !isIndividual) && (
                      <BasicButton
                        text={t<string>("worksite.form.actions.delete")}
                        onClick={() => handleDelete()}
                      />
                    )}
                  </Row>
                </div>
              </Form>
            </div>
          </>
        )}
      </>
    </PageLayout>
  );
};
