function buildQuery(query: string | undefined | null): string {
  if (!query || query.length === 0) {
    return "";
  }
  return `&query=${query}`;
}

function buildFilter(
  values: (string | null)[] | null | undefined,
  parameter: string,
): string {
  if (!values || values.filter((x) => x != null).length === 0) {
    return "";
  }
  return `&${values.map((value) => `${parameter}=${value || ""}`).join("&")}`;
}

export const searchHelper = {
  buildQuery,
  buildFilter,
};
