import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "antd";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { Store } from "rc-field-form/lib/interface";
import { setMentionSmokeDuct } from "@state/auth/AuthEvents";
import PageLayout from "@components/layouts/PageLayout";
import { emailFormatRegex, formItemBlockLayout } from "@utils/Constant";
import InputFormField from "@components/inputs/InputFormField";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import {
  requestCreateMentionSmokeDuct,
  requestUpdateMention,
} from "@state/mention/smokeduct/MentionSmokeDuctEffects";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export const SmokeDuctMentionFormScreen: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;

  const hasRequestedCertificate = !!authentifiedUser?.smokeDuct;

  const handleSubmit = (values: Store) => {
    !authentifiedUser?.smokeDuct
      ? void requestCreateMentionSmokeDuct({
          dto: {
            firstName: values.firstName as string,
            lastName: values.lastName as string,
            email: values.emailMention as string,
          },
        })
      : void requestUpdateMention({
          id: authentifiedUser?.smokeDuct.id,
          dto: {
            firstName: values.firstName as string,
            lastName: values.lastName as string,
            email: values.emailMention as string,
          },
        });
  };

  useEffect(() => {
    return requestCreateMentionSmokeDuct.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionSmokeDuct(result.data);
        navigate(ROUTES.professional.smokeDucts.iframe.generate());
      }
    });
  });

  useEffect(() => {
    return requestUpdateMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionSmokeDuct(result.data);
        navigate(ROUTES.professional.smokeDucts.mention.generate());
      }
    });
  });

  const InitialValues = {
    email: authentifiedUser?.email,
    phone: authentifiedUser?.phone1,
    siret: authentifiedUser?.siret,
    username: authentifiedUser?.username,
    companyName: authentifiedUser?.companyName,
    firstName: authentifiedUser?.smokeDuct?.firstName,
    lastName: authentifiedUser?.smokeDuct?.lastName,
    emailMention: authentifiedUser?.smokeDuct?.emailMention,
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.mentions.smokeDuct.form.title")}
      titleCentered={false}
      subtitle={
        <span>
          {t<string>("pro.mentions.smokeDuct.form.subtitle")}{" "}
          <a href="https://lesprofessionnelsdugaz.com/">
            https://lesprofessionnelsdugaz.com/
          </a>
        </span>
      }
    >
      <>
        <Form
          form={form}
          onFinish={handleSubmit}
          {...formItemBlockLayout}
          initialValues={InitialValues}
        >
          <div className="d-flex justify-content-between bd-highlight mb-6">
            <h2 className="form-title p-2 bd-highlight">
              {t<string>("pro.mentions.smokeDuct.form.inscription")}
            </h2>
            <div className="ml-auto p-2 justify-content-end">
              {t<string>("pro.mentions.smokeDuct.form.required")}
            </div>
          </div>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="firstName"
                required
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="lastName"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="emailMention"
                rules={[
                  {
                    pattern: new RegExp(emailFormatRegex),
                    message: t<string>("common.email.errors.invalid"),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="siret"
                required
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="companyName"
                required
                readOnly
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="username"
                required
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="email"
                readOnly
                required
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.smokeDuct"
                field="phone"
                readOnly
              />
            </Col>
          </Row>
          <h2 className="form-title">
            {t<string>("pro.mentions.smokeDuct.form.convention.title")}
          </h2>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label1"
              module="pro.mentions.smokeDuct.form.convention"
              required
              checked={hasRequestedCertificate}
            />
            <CheckboxFormField
              className="mb-2"
              field="label2"
              module="pro.mentions.smokeDuct.form.convention"
              required
              checked={hasRequestedCertificate}
            />
            <CheckboxFormField
              className="mb-2"
              field="label3"
              module="pro.mentions.smokeDuct.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="d-flex justify-content-center px-12 mt-5">
            <SubmitButton
              module="pro.mentions.smokeDuct"
              label={hasRequestedCertificate ? "submitInfo" : "submit"}
            />
          </div>
        </Form>
      </>
    </PageLayout>
  );
};
