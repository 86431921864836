import i18n from "i18next";
import { Tooltip } from "antd";
import BasicTag from "@components/tags/BasicTag";
import { INTERVENTION_TYPES } from "@utils/enums/search.enum";
import { tooltipDateTimeRender } from "@components/tooltips/CustomTooltip";
import { ReactNode } from "react";
import { ContactItemResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.item.response.dto";
import { CONTACT_GAZ_PROFESSIONAL_STATUS } from "@utils/enums/contact.gazprofessional.status";

export const columns = [
  {
    title: i18n.t<string>("pro.contact.table.headers.name"),
    key: "lastName",
    className: "name",
    ellipsis: {
      showTitle: false,
    },
    render: (record: ContactItemResponseDto): ReactNode => {
      const name = `${record.firstName} ${record.lastName}`;
      return (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      );
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("pro.contact.table.headers.type"),
    dataIndex: "interventionType",
    key: "interventionType",
    className: "type",
    render: (type: string): ReactNode => (
      <BasicTag
        variant="primary"
        label={i18n.t<string>(
          INTERVENTION_TYPES[type].shortLabel ?? INTERVENTION_TYPES[type].label,
        )}
      />
    ),
    width: 140,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("pro.contact.table.headers.message"),
    dataIndex: "message",
    key: "message",
    className: "message",
    ellipsis: {
      showTitle: false,
    },
    render: (message: string): ReactNode => {
      let messageResult;
      if (message === null || message === undefined || message === "") {
        messageResult = i18n.t<string>("pro.contact.table.noRemark");
      } else if (message.length > 50) {
        messageResult = message.substring(0, 50);
      } else {
        messageResult = message;
      }
      return (
        <Tooltip placement="topLeft" title={messageResult}>
          {messageResult}
        </Tooltip>
      );
    },
  },
  {
    title: i18n.t<string>("pro.contact.table.headers.reception"),
    dataIndex: "createdAt",
    key: "createdAt",
    className: "reception",
    width: 170,
    render: tooltipDateTimeRender,
    sorter: true,
  },
  {
    title: i18n.t<string>("pro.contact.table.headers.status"),
    dataIndex: "status",
    key: "status",
    className: "status",
    render: (status: string): ReactNode => (
      <div className="status-container">
        {i18n.t<string>(
          `pro.contact.status.${CONTACT_GAZ_PROFESSIONAL_STATUS[status].value}`,
        )}
      </div>
    ),
    width: 170,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("pro.contact.table.headers.updated"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    className: "updated",
    width: 170,
    render: tooltipDateTimeRender,
    sorter: true,
    showSorterTooltip: false,
  },
];
