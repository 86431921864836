import React from "react";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import PageLayout from "@components/layouts/PageLayout";
import { PageContent } from "@components/page/PageContent";

export interface Props {
  page: PageInfoResponseDto | undefined;
  isLoading?: boolean;
  isFromArticle?: boolean;
}

export interface Input {
  inputType: string;
  content: string;
  properties?: string;
}

const PageComponentFooter: React.FC<Props> = ({
  page,
  isLoading = false,
  isFromArticle = false,
}) => {
  return (
    <PageLayout
      title={page && page.title}
      isLoading={isLoading}
      isFromArticle={isFromArticle}
      page={page}
      noProSideMenu
      lightHeader={true}
    >
      <PageContent page={page} />
    </PageLayout>
  );
};

export { PageComponentFooter };
