import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { TertiaryModalDetail } from "@components/tertiary/TertiaryModalDetail";
import { TertiaryModalFirstStep } from "@components/tertiary/TertiaryModalFirstStep";
import { TertiaryModalSecondStep } from "@components/tertiary/TertiaryModalSecondStep";
import { TertiaryModalFinal } from "@components/tertiary/TertiaryModalFinal";
import { Form } from "antd";
import { formItemBlockLayout } from "@utils/Constant";
import { toastError } from "@utils/toast-helper";
import { requestUpdateTertiaryInformation } from "@state/gazprofessional/GazProfessionalEffects";

interface TertiaryModalProps {
  open: boolean;
  onCancel?: () => void;
  className?: string;
}

const TertiaryModal: FunctionComponent<TertiaryModalProps> = ({
  open,
  onCancel,
  className,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [habitats, setHabitats] = useState<string[]>([]);
  const [boilerPower, setBoilerPower] = useState<string[]>([]);
  const [participatesErp, setParticipatesErp] = useState<boolean>(false);
  const [erps, setErps] = useState<string[]>([]);
  const [usage1, setUsage1] = useState<string[]>([]);
  const [usage2, setUsage2] = useState<string[]>([]);
  const [usage3, setUsage3] = useState<string[]>([]);
  const [usage4, setUsage4] = useState<string[]>([]);
  const [usage5, setUsage5] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
    }
  }, [open]);
  const handleSave = () => {
    setIsLoading(true);
    void requestUpdateTertiaryInformation({
      dto: {
        habitat: habitats,
        boilerPower: habitats.includes("immeuble") ? boilerPower : undefined,
        participatesErp: participatesErp,
        erp: erps,
        usage1: erps.includes("erp1") ? usage1 : undefined,
        usage2: erps.includes("erp2") ? usage2 : undefined,
        usage3: erps.includes("erp3") ? usage3 : undefined,
        usage4: erps.includes("erp4") ? usage4 : undefined,
        usage5: erps.includes("erp5") ? usage5 : undefined,
      },
      id: "",
    });
    setIsLoading(false);
  };

  useEffect(() => {
    return requestUpdateTertiaryInformation.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setIsOpen(false);
      } else {
        toastError(result.errorMessage);
      }
    });
  });

  const getRender = () => {
    switch (step) {
      case 0:
        return <TertiaryModalDetail setStep={setStep} />;
      case 1:
        return (
          <TertiaryModalFirstStep
            boilerPower={boilerPower}
            setStep={setStep}
            setBoilerPower={setBoilerPower}
            setHabitats={setHabitats}
            habitats={habitats}
          />
        );
      case 2:
        return (
          <TertiaryModalSecondStep
            setStep={setStep}
            setParticipatesErp={setParticipatesErp}
            setErps={setErps}
            setUsage1={setUsage1}
            setUsage2={setUsage2}
            setUsage3={setUsage3}
            setUsage4={setUsage4}
            setUsage5={setUsage5}
            participatesErp={participatesErp}
            erps={erps}
            usage1={usage1}
            usage2={usage2}
            usage3={usage3}
            usage4={usage4}
            usage5={usage5}
          />
        );
      case 3:
        return <TertiaryModalFinal isLoading={isLoading} />;
      default:
        return <TertiaryModalDetail setStep={setStep} />;
    }
  };

  return (
    <BasicModal
      title={
        <div>
          <img
            alt={t<string>("gazMonth.individual.home.conditions.pg.alt")}
            src="/logo-header-PG-professional.svg"
          />
        </div>
      }
      open={isOpen}
      onCancel={onCancel}
      className={className}
      footer={null}
    >
      <Form form={form} onFinish={handleSave} {...formItemBlockLayout}>
        {getRender()}
      </Form>
    </BasicModal>
  );
};

export default TertiaryModal;
