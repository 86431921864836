import React, { useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { MaintenanceCertificateFormScreen } from "@views/pro/gazProfessional/maitenanceCertificate/MaintenanceCertificateFormScreen";
import { ROUTES } from "@utils/Routes";
import MaintenanceCertificateHelp1Modal from "@components/modals/maintenancecertificate/MaintenanceCertificateHelp1Modal";
import BasicButton from "@components/buttons/BasicButton";
import { requestCreateMaintenanceCertificate } from "@state/maintenancecertificate/MaintenanceCertificateEffects";
import { getSaveRequest } from "@utils/maintenanceCertificate-utils";
import { toastError } from "@utils/toast-helper";
import { Form } from "antd";
import { useNavigate } from "react-router-dom";

export const MaintenanceCertificateAddScreen: React.FC = () => {
  const { t } = useTranslation();
  const [isModalHelpOpen1, setModalHelpOpen1] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [panelWithError, setPanelWithError] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSave = () => {
    form
      .validateFields(["section1clientFirstname", "section1clientLastname"])
      .then(() => {
        const values = form.getFieldsValue();
        setIsLoading(true);
        void requestCreateMaintenanceCertificate({
          dto: getSaveRequest(values),
        });
      })
      .catch(() => {
        toastError(t<string>("pro.certificate.form.form.errors"));
        let sectionWithError: string[] = [];
        setPanelWithError(sectionWithError);
        form
          .getFieldsError(["section1clientFirstname", "section1clientLastname"])
          .forEach((m) => {
            if (m.errors.length != 0) {
              const section: string = m.name[0].toString().substring(0, 8);
              if (!sectionWithError.includes(section)) {
                sectionWithError = [...sectionWithError, section];
              }
            }
          });
        setPanelWithError(sectionWithError);
      });
  };

  useEffect(() => {
    return requestCreateMaintenanceCertificate.done.watch(({ result }) => {
      if (result.ok && result.data) {
        navigate(ROUTES.professional.maintenanceCertificate.list.generate());
      } else {
        toastError(result.errorMessage);
      }
      setIsLoading(false);
    });
  });

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>("pro.certificate.form.title")}
      noSideMenu={false}
      returnRoute={ROUTES.professional.maintenanceCertificate.list.generate()}
      confirmReturnRoute
      rightContent={
        <BasicButton
          text={t<string>("common.help")}
          className="primary"
          onClick={() => setModalHelpOpen1(true)}
        />
      }
      confirmReturnButtonAccepted={handleSave}
      confirmReturnButtonRefused={() =>
        navigate(ROUTES.professional.maintenanceCertificate.list.generate())
      }
      confirmReturnRouteText={t<string>(
        "pro.certificate.form.return.newAttestationTitle",
      )}
    >
      <>
        <MaintenanceCertificateFormScreen
          form={form}
          handleSave={handleSave}
          panelsWithError={panelWithError}
          isLoadingParent={isLoading}
        />
        <MaintenanceCertificateHelp1Modal
          className="large-modal"
          title="Aide"
          open={isModalHelpOpen1}
          onCancel={() => setModalHelpOpen1(false)}
        />
      </>
    </PageLayout>
  );
};
