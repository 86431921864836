import { createStore } from "effector";
import { setAuditToProgram } from "@state/audit/AuditEvents";
import { AuditListInstallationToChooseDto } from "@state/audit/dto/previous.audit.item.dto";

export const auditStore = createStore<AuditListInstallationToChooseDto[]>([
  {
    id: "",
    equivalence: [],
    rang: 0,
    lastStatus: "",
    timerChoixCC: "",
    timerCancelAudit: "",
  },
]);

auditStore.on<AuditListInstallationToChooseDto[]>(
  setAuditToProgram,
  (state, payload) => payload,
);
