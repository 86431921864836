import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import LandingPageHeader from "@components/landingpage/LandingPageHeader";
import LandingPageCommitments from "@components/landingpage/LandingPageCommitments";
import LandingPageContact from "@components/landingpage/LandingPageContact";
import LandingPageStepper from "@components/landingpage/LandingPageStepper";
import LandingPageConditions from "@components/landingpage/LandingPageConditions";

const LandingPageFilRougeScreen: FC = () => {
  return (
    <PageLayout noHeader>
      <>
        <LandingPageHeader landingPageType="filRouge" />
        <LandingPageCommitments landingPageType="filRouge" />
        <LandingPageContact landingPageType="filRouge" />
        <LandingPageStepper landingPageType="filRouge" />
        <LandingPageConditions landingPageType="filRouge" />
      </>
    </PageLayout>
  );
};

export default LandingPageFilRougeScreen;
