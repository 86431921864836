import { FunctionComponent, useState } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import { Steps } from "antd";
import UpdateStatusModal from "@components/modals/UpdateStatusModal";
import { useViewport } from "@utils/breakpoints-helper";
import {
  ABANDON_REASONS,
  CONTACT_GAZ_PROFESSIONAL_STATUS,
  ContactGazprofessionalStatus,
} from "@utils/enums/contact.gazprofessional.status";

const { Step } = Steps;

interface UpdateStatusCardProps {
  contactId: string;
  status: ContactGazprofessionalStatus;
  abandonReason?: string;
  className?: string;
  handleContactWorkflow: (status: ContactGazprofessionalStatus) => void;
}

const UpdateStatusCard: FunctionComponent<UpdateStatusCardProps> = ({
  contactId,
  status,
  abandonReason,
  className = "",
  handleContactWorkflow,
}) => {
  const { t } = useTranslation();
  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] =
    useState<boolean>(false);
  const { width } = useViewport();

  const isAbandoned = status === CONTACT_GAZ_PROFESSIONAL_STATUS.ABANDONED;

  return (
    <BasicCard className={`app-card-update-status ${className}`}>
      <>
        <div className="d-flex align-items-center justify-content-end flex-wrap-reverse">
          <div className="flex-grow-1">
            <div className="card-update-status-title">
              {`${t<string>("pro.contact.cards.updateStatus.title")} ${
                (isAbandoned &&
                  abandonReason &&
                  ` : ${t<string>("pro.contact.cards.updateStatus.abandoned", {
                    abandonReason: ABANDON_REASONS[abandonReason].label,
                  })}`) ||
                ""
              }`}
            </div>
          </div>
          {!isAbandoned ? (
            <div
              onClick={() => setIsUpdateStatusModalVisible(true)}
              className="card-update-status-button ms-4"
            >
              {t<string>("pro.contact.cards.updateStatus.abandonButtonLabel")}
            </div>
          ) : (
            <div
              onClick={() =>
                handleContactWorkflow(CONTACT_GAZ_PROFESSIONAL_STATUS.READ)
              }
              className="card-update-status-button ms-4"
            >
              {t<string>("pro.contact.cards.updateStatus.reopenButtonLabel")}
            </div>
          )}
        </div>
        {!isAbandoned && (
          <>
            <div className="card-update-status-description">
              {t<string>("pro.contact.cards.updateStatus.description")}
            </div>
            <Steps
              current={status.position}
              direction={width >= 992 ? "horizontal" : "vertical"}
              labelPlacement={width >= 992 ? "vertical" : "horizontal"}
              className="mt-30"
            >
              {Object.values(CONTACT_GAZ_PROFESSIONAL_STATUS)
                .filter((s) => s.value !== "ABANDONED")
                .map((status) => (
                  <Step
                    onClick={() => handleContactWorkflow(status)}
                    key={status.value}
                    title={t<string>(`pro.contact.status.${status.value}`)}
                  />
                ))}
            </Steps>
            <UpdateStatusModal
              contactId={contactId}
              open={isUpdateStatusModalVisible}
              onCancel={() => setIsUpdateStatusModalVisible(false)}
            />
          </>
        )}
      </>
    </BasicCard>
  );
};

export default UpdateStatusCard;
