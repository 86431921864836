import { useEffect, useState } from "react";

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const useViewport = (): { width: number; headerOffset: number } => {
  const [width, setWidth] = useState(window.innerWidth);
  const [headerOffset, setHeaderOffset] = useState(
    width > BREAKPOINTS.lg ? 100 : 60,
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setHeaderOffset(window.innerWidth > BREAKPOINTS.lg ? 100 : 60);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width, headerOffset };
};
