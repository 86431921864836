import { FunctionComponent } from "react";

interface SectionProps {
  title: string;
  subtitle?: string;
  rightContent?: string | JSX.Element;
  children: string | JSX.Element | JSX.Element[];
  type?: "h1" | "h2" | "h3";
  className?: string;
  titleClassName?: string;
}

const Section: FunctionComponent<SectionProps> = ({
  title,
  subtitle,
  rightContent,
  children,
  type = "h3",
  className = "",
  titleClassName = "",
}) => {
  return (
    <section className={className}>
      <div
        className={
          rightContent ? "d-flex align-items-center flex-wrap mb-10" : "mb-10"
        }
      >
        {type === "h1" && (
          <h1
            className={`mb-10 d-flex align-items-center flex-wrap ${titleClassName}`}
          >
            <div className={subtitle ? "mr-10" : ""}>{title}</div>
            {subtitle && <div className="section-subtitle">{subtitle}</div>}
          </h1>
        )}

        {type === "h2" && (
          <h2
            className={`mb-10 d-flex align-items-center flex-wrap ${titleClassName}`}
          >
            <div className={subtitle ? "mr-10" : ""}>{title}</div>
            {subtitle && <div className="section-subtitle">{subtitle}</div>}
          </h2>
        )}
        {type === "h3" && (
          <h3 className={`mb-10 ${titleClassName}`}>{title}</h3>
        )}
        {rightContent && (
          <div className="mb-10 ms-3 flex-grow-1 text-end">{rightContent}</div>
        )}
      </div>
      <div>{children}</div>
    </section>
  );
};

export default Section;
