import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import {
  IconContactAddress,
  IconContactEmail,
  IconContactPhone,
  IconContactTime,
} from "../../static/icons";
import BasicButton from "@components/buttons/BasicButton";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface ContactInformationsCardProps {
  title: string;
  subtitle?: string;
  assistanceCode?: string;
  timetable?: string;
  address?: string;
  email?: string;
  emails?: string[];
  phone?: string;
  phoneService?: string;
  className?: string;
  limitMails?: number;
}

const ContactInformationsCard: FunctionComponent<
  ContactInformationsCardProps
> = ({
  title,
  assistanceCode,
  timetable,
  subtitle,
  address,
  email,
  emails,
  phone,
  phoneService,
  className = "",
  limitMails = 5,
}) => {
  const { t } = useTranslation();

  return (
    <BasicCard className={`app-card-contact-informations ${className}`}>
      <>
        <div className="card-contact-header">
          <h3 className="card-contact-title">{title}</h3>
          {subtitle && <div className="card-contact-subtitle">{subtitle}</div>}
        </div>
        {assistanceCode && (
          <div className="card-contact-item">
            <span className="card-contact-item-text">
              {`${t("pro.tools.assistanceCode")} : `}
              <span className="text-primary font-weight-bold">
                {assistanceCode}
              </span>
            </span>
            <Tooltip
              placement="topRight"
              title={i18n.t("pro.tools.assistanceCodeTooltip")}
              className="card-contact-item-info"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}
        {timetable && (
          <div className="card-contact-item">
            <div className="card-contact-item-icon">
              <IconContactTime />
            </div>
            <span className="card-contact-item-text">{parse(timetable)}</span>
          </div>
        )}
        {address && (
          <div className="card-contact-item">
            <div className="card-contact-item-icon">
              <IconContactAddress />
            </div>
            <span className="card-contact-item-text">{address}</span>
          </div>
        )}
        {email && (
          <div className="card-contact-item">
            <div className="card-contact-item-icon">
              <IconContactEmail />
            </div>
            <span className="card-contact-item-text">{email}</span>
          </div>
        )}
        {emails?.slice(0, limitMails).map((mail, index) => (
          <div className="card-contact-item" key={index}>
            <div className="card-contact-item-icon">
              <IconContactEmail />
            </div>
            <span className="card-contact-item-text">{mail}</span>
          </div>
        ))}
        {phone && (
          <a href={`tel:${phone}`} className="card-contact-phone">
            <BasicButton
              variant={!!phoneService ? "primary" : "white"}
              icon={<IconContactPhone />}
              text={
                !!phoneService
                  ? phone.replace(
                      /(\d{1})(\d{3})(\d{2})(\d{2})(\d{2})/,
                      "$1 $2 $3 $4 $5",
                    )
                  : phone.replace(
                      /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
                      "$1 $2 $3 $4 $5",
                    )
              }
              additionalText={phoneService}
              className="card-contact-button"
            />
          </a>
        )}
      </>
    </BasicCard>
  );
};

export default ContactInformationsCard;
