import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import {
  CURRENT_ENERGIES,
  EQUIPMENT_TYPES,
  INTERVENTION_TYPES,
} from "@utils/enums/search.enum";
import ContactDetailItem from "@components/items/ContactDetailItem";
import BasicButton from "@components/buttons/BasicButton";
import UpdateStatusCard from "@components/cards/UpdateStatusCard";
import { useLocation } from "react-router-dom";
import { ContactItemResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.item.response.dto";
import { Link } from "react-router-dom";
import UpdateStatusModal from "@components/modals/UpdateStatusModal";
import DateFormat from "@utils/DateFormat";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import {
  CONTACT_GAZ_PROFESSIONAL_STATUS,
  ContactGazprofessionalStatus,
} from "@utils/enums/contact.gazprofessional.status";
import {
  requestGetContact,
  requestWorkflowContact,
} from "@state/contact/gazprofessional/ContactGazProfessionalEffects";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { toastError } from "@utils/toast-helper";
import { ContactState } from "@type/contact/ContactState";
import { ROUTES } from "@utils/Routes";

export const ContactDetailScreen: React.FC = () => {
  const { t } = useTranslation();

  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] =
    useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);

  const location = useLocation();

  const contactId = location.pathname.split("/").pop() || "";

  const [contact, setContact] = useState<ContactItemResponseDto>(
    (location.state as ContactState)?.initialContact || undefined,
  );

  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;

  const handleContactWorkflow = (status: ContactGazprofessionalStatus) => {
    if (
      status.position > CONTACT_GAZ_PROFESSIONAL_STATUS[contact.status].position
    ) {
      void requestWorkflowContact({
        id: contact.id,
        dto: {
          targetStatus: status.value,
        },
      });
    } else if (status.position > 1) {
      const targetStatus = Object.values(CONTACT_GAZ_PROFESSIONAL_STATUS).find(
        (s) => s.position === status.position - 1,
      );
      if (targetStatus) {
        void requestWorkflowContact({
          id: contact.id,
          dto: {
            targetStatus: targetStatus.value,
          },
        });
      }
    }
  };

  const handleMailTo = (e: React.MouseEvent<HTMLElement>) => {
    window.location.href = `mailto:${contact?.email}?body=${t<string>(
      "pro.contact.mailTo.body",
      {
        companyName: authentifiedUser?.companyName,
      },
    )}&subject=${t<string>("pro.contact.mailTo.subject", {
      dateContact: DateFormat.standardDateTime(new Date(contact?.createdAt)),
    })}`;
    e.preventDefault();
    // if (
    //   CONTACT_GAZ_PROFESSIONAL_STATUS[contact.status].position <
    //   CONTACT_GAZ_PROFESSIONAL_STATUS.PROCESSED.position
    // ) {
    //   void requestWorkflowContact({
    //     id: contact.id,
    //     dto: {
    //       targetStatus: "PROCESSED",
    //     },
    //   });
    // }
  };

  useEffect(() => {
    if (contact == undefined) void requestGetContact(contactId);
  }, [contact, contactId]);

  useEffect(() => {
    if (
      contact &&
      init &&
      CONTACT_GAZ_PROFESSIONAL_STATUS[contact.status].value === "UNREAD"
    )
      void requestWorkflowContact({
        id: contact.id,
        dto: {
          targetStatus: "READ",
        },
      });
  }, [contact, init]);

  useEffect(() => {
    return requestWorkflowContact.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setInit(false);
        setContact(result.data);
      } else {
        toastError(t<string>("pro.contact.workflowFailed"));
      }
    });
  });

  useEffect(() => {
    return requestGetContact.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setContact(result.data);
      } else {
        toastError(t<string>("pro.contact.workflowFailed"));
      }
    });
  });

  return (
    <PageLayout
      container
      title={`${t<string>("pro.contact.detail.title")} ${
        contact ? contact.firstName : ""
      } ${contact ? contact.lastName : ""}`}
      titleCentered={false}
      returnRoute={ROUTES.professional.contacts.list.generate()}
      returnText={t<string>("pro.contact.detail.return")}
    >
      <>
        {contact && (
          <>
            <UpdateStatusCard
              contactId={contact.id}
              handleContactWorkflow={handleContactWorkflow}
              status={CONTACT_GAZ_PROFESSIONAL_STATUS[contact.status]}
              abandonReason={contact.abandonReason}
              className="mt-3 mb-40"
            />
            <div className="mb-20">
              <h3 className="text-primary mb-20">
                {t<string>("pro.contact.detail.clientDetails")}
              </h3>
              <div className="d-flex align-items-center flex-wrap">
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.prospect")}
                  description={`${contact.firstName} ${contact.lastName}`}
                  className="mb-20 me-5"
                />
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.phone")}
                  description={contact.phone}
                  className="mb-20 me-5"
                />
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.email")}
                  description={contact.email}
                  className="mb-20 me-5"
                />
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.address")}
                  description={contact.address}
                  className="mb-20 me-5"
                />
              </div>
            </div>
            <div>
              <h3 className="text-primary mb-20">
                {t<string>("pro.contact.detail.projectDetails")}
              </h3>
              <div className="d-flex align-items-center flex-wrap">
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.interventionType")}
                  description={t<string>(
                    INTERVENTION_TYPES[contact.interventionType].label,
                  )}
                  className="mb-20 me-5"
                />
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.currentEnergy")}
                  description={t<string>(
                    CURRENT_ENERGIES[contact.currentEnergy].label,
                  )}
                  className="mb-20 me-5"
                />
                <ContactDetailItem
                  title={t<string>("pro.contact.detail.equipmentType")}
                  description={contact.equipmentTypes
                    .map((et, index) =>
                      index === 0
                        ? t<string>(EQUIPMENT_TYPES[et].label)
                        : ` ${t<string>(EQUIPMENT_TYPES[et].label)}`,
                    )
                    .toString()}
                  className="mb-20 me-5"
                />
              </div>
            </div>
            <div className="d-flex flex-column flex-lg-row align-items-lg-stretch">
              <div className="me-lg-5 mb-30 flex-grow-1">
                <div className="font-weight-bold mb-10">{`${t<string>(
                  "pro.contact.detail.message",
                )} ${contact.firstName} ${contact.lastName}`}</div>
                <div>{contact.message}</div>
              </div>
              <div className="d-flex flex-column align-items-stretch">
                <Link to="#" onClick={handleMailTo}>
                  <BasicButton
                    text={t<string>("pro.contact.detail.answer")}
                    variant="primary"
                    size="lg"
                    className="mb-30"
                  />
                </Link>
                <BasicButton
                  text={t<string>("pro.contact.detail.refuse")}
                  variant="primary-outline"
                  size="lg"
                  onClick={() => setIsUpdateStatusModalVisible(true)}
                />
              </div>
            </div>
            <UpdateStatusModal
              contactId={contact.id}
              open={isUpdateStatusModalVisible}
              onCancel={() => setIsUpdateStatusModalVisible(false)}
            />
          </>
        )}
      </>
    </PageLayout>
  );
};
