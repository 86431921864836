import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

const tagManagerArgs = {
  gtmId: "GTM-PWJXWRX",
  dataLayer: {
    events: {
      /* eslint-disable */
      contact_pro_envoi_demande: "contact_pro_envoi_demande",
      se_connecter_menu_pro: "se_connecter_menu_pro",
      trouver_pg_part: "trouver_pg_part",
      se_connecter_home_pro: "se_connecter_home_pro",
      carte_recherche: "carte_recherche",
      carte_recherche_type: "carte_recherche_type",
      carte_recherche_expertise: "carte_recherche_expertise",
      carte_recherche_mdg: "carte_recherche_mdg",
      contacter_pg_selectionnes: "contacter_pg_selectionnes",
      contacter_formulaire_carte: "contacter_formulaire_carte",
      lancer_recherche_home_part: "lancer_recherche_home_part",
      clic_x_nouvelles_demandes_contact: "clic_x_nouvelles_demandes_contact",
      clic_nouvelles_mentions: "clic_nouvelles_mentions",
      clic_completer_profil: "clic_completer_profil",
      clic_app_pros_du_gaz: "clic_app_pros_du_gaz",
      clic_appli_pg: "clic_appli_pg",
      clic_hotline: "clic_hotline",
      clic_plateforme_pg: "clic_plateforme_pg",
      /* eslint-enable */
    },
  },
};
TagManager.initialize(tagManagerArgs);

hotjar.initialize(2804472, 6);

const rootElement = document.getElementById("root");
const root = createRoot(rootElement as Element);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
