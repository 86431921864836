import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ModuleSubtitleProps {
  status: string | undefined;
}

const ModuleSubtitle: FunctionComponent<ModuleSubtitleProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <>
      {status && (
        <>
          <div className="mb-3 fs-7">
            {t<string>(`pro.qualifications.modals.details.subtitle.${status}`)}
          </div>
          <hr />
        </>
      )}
    </>
  );
};

export default ModuleSubtitle;
