import { FC } from "react";
import { useNavigate } from "react-router-dom";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { ROUTES } from "@utils/Routes";

const WrongIdScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <PageLayout
      container
      contentInCard
      title={t<string>("wrongId.title")}
      subtitle={t<string>("wrongId.subtitle")}
      bodyClassName="login-body"
      mainClassName="bg-light-blue"
    >
      <div className="d-flex align-items-center justify-content-center mb-3">
        <BasicButton
          text={t<string>("wrongId.buttons.contact")}
          onClick={() =>
            navigate(ROUTES.professional.contacts.publicForm.generate())
          }
        />
      </div>
    </PageLayout>
  );
};

export default WrongIdScreen;
