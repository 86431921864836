import { FunctionComponent } from "react";
import Section from "@components/section/Section";
import { Checkbox, Col, Form, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import { PATTERNS } from "@utils/enums/pattern.enum";

interface RegisterInformationSectionProps {
  noDivider?: boolean;
  title?: string;
  titleClassName?: string;
  noEditableLabel?: boolean;
  subtitle?: string;
  noIcon?: boolean;
}

const RegisterInformationSection: FunctionComponent<
  RegisterInformationSectionProps
> = ({
  noDivider = false,
  title,
  titleClassName = "",
  noEditableLabel = false,
  subtitle,
  noIcon = false,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!noDivider && (
        <div className="px-12">
          <BasicDivider variant="primary" className="mb-40 w-100" height={1} />
        </div>
      )}
      <Section
        type="h2"
        title={
          title ? title : t<string>("personalInfo.sectionTitle.registerInfo")
        }
        subtitle={
          noEditableLabel ? undefined : t<string>("personalInfo.notEditable")
        }
        titleClassName={`px-12 mb-10 ${titleClassName}`}
      >
        <>
          <div className="mb-20 px-12">
            {subtitle
              ? subtitle
              : t("personalInfo.sectionSubtitle.registerInfo")}
          </div>
          <div className="px-12">
            <Row gutter={16}>
              <Col xs={0} lg={1} className="mx-0 px-0">
                {!noIcon && <img src="/img/icons/personalData/lock.svg" />}
              </Col>
              <Col xs={24} lg={23}>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="siret"
                      readOnly
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="businessName"
                      readOnly
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="username"
                      readOnly
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="phone"
                      readOnly
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="email"
                      rules={[
                        {
                          pattern: new RegExp(PATTERNS.EMAIL),
                          message: "example.form.fields.email.errors.invalid",
                        },
                      ]}
                      readOnly
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="address"
                      readOnly
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="postcode"
                      readOnly
                    />
                  </Col>
                  <Col xs={24} lg={12}>
                    <InputFormField
                      showLabel
                      module="personalInfo"
                      field="city"
                      readOnly
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Row>
                      {t("personalInfo.form.fields.accreditation.label")}
                    </Row>
                    <Row>
                      <div className={"d-flex w-100"}>
                        <Form.Item name="installation" valuePropName="checked">
                          <Checkbox disabled>
                            {t(
                              "personalInfo.form.fields.accreditation.installation",
                            )}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name="maintenance" valuePropName="checked">
                          <Checkbox disabled>
                            {t(
                              "personalInfo.form.fields.accreditation.maintenance",
                            )}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row>{t("personalInfo.form.fields.mention.label")}</Row>
                    <Row>
                      <div className={"d-flex w-100"}>
                        <Form.Item name="pac" valuePropName="checked">
                          <Checkbox disabled>
                            {t("personalInfo.form.fields.mention.pac")}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name="smokeDuct" valuePropName="checked">
                          <Checkbox disabled>
                            {t("personalInfo.form.fields.mention.smokeDuct")}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      </Section>
    </>
  );
};

export default RegisterInformationSection;
