import React, { ReactNode } from "react";
import { Tooltip } from "antd";
import DateFormat from "@utils/DateFormat";

const customTooltipDelay = 0.05;

export class CustomTooltip extends React.PureComponent<{ data: string }> {
  render(): ReactNode {
    const { data } = this.props;
    return (
      <Tooltip
        mouseEnterDelay={customTooltipDelay}
        mouseLeaveDelay={customTooltipDelay}
        placement="top"
        title={data}
      >
        <span>{data}</span>
      </Tooltip>
    );
  }
}

export const tooltipRender = (data: string): ReactNode => {
  return <CustomTooltip data={data} />;
};

export const tooltipDateTimeRender = (date?: Date): ReactNode => {
  const data: string = date ? DateFormat.standardDateTime(new Date(date)) : "";
  return <CustomTooltip data={data} />;
};

export const tooltipDateNoTimeRender = (date?: Date): ReactNode => {
  const data: string = date ? DateFormat.standardDate(new Date(date)) : "";
  return <CustomTooltip data={data} />;
};
