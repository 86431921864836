import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import LandingPageHeader from "@components/landingpage/LandingPageHeader";
import LandingPageCommitments from "@components/landingpage/LandingPageCommitments";
import LandingPageContact from "@components/landingpage/LandingPageContact";
import LandingPageConditions from "@components/landingpage/LandingPageConditions";
import LandingPagePartners from "@components/rentreeDuChauffage/tempsFort/LandingPagePartners";

const LandingPageTempsFortScreen: FC = () => {
  return (
    <PageLayout noHeader>
      <>
        <LandingPageHeader landingPageType="tempsFort" />
        <LandingPageCommitments landingPageType="tempsFort" />
        <LandingPageContact landingPageType="tempsFort" />
        <LandingPagePartners landingPageType="tempsFort" />
        <LandingPageConditions landingPageType="tempsFort" />
      </>
    </PageLayout>
  );
};

export default LandingPageTempsFortScreen;
