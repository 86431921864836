import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import config from "@utils/Config";

interface LandingPageConfirmationHeaderProps {
  className?: string;
  landingPageType: string;
}

const LandingPageConfirmationHeader: FunctionComponent<
  LandingPageConfirmationHeaderProps
> = ({ className = "", landingPageType }) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div
      className={`app-rentree-du-chauffage-confirmation-header ${className}`}
    >
      <div className="header-container content-contained">
        <div className="header-logo">
          <img
            alt={t<string>("gazMonth.individual.home.conditions.pg.alt")}
            src="/logo-header-PG-individual.svg"
          />
        </div>
        <div className="header-content">
          <h1 className="header-content-title">
            {parse(
              t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.header.title`,
              ),
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default LandingPageConfirmationHeader;
