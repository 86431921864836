import React, { useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { MaintenanceCertificateResponseDto } from "@state/maintenancecertificate/dto/maintenance.certificate.item.response.dto";
import { MaintenanceCertificateFormScreen } from "@views/pro/gazProfessional/maitenanceCertificate/MaintenanceCertificateFormScreen";
import { useLocation, useNavigate } from "react-router-dom";
import { MaintenanceCertificateState } from "@type/maintenanceCertificate/MaintenanceCertificateState";
import {
  requestGetMaintenanceCertificate,
  requestUpdateMaintenanceCertificate,
} from "@state/maintenancecertificate/MaintenanceCertificateEffects";
import { ROUTES } from "@utils/Routes";
import PdfViewer from "@components/pdf/PdfViewer";
import { downloadFile } from "@utils/Base64File";
import BasicButton from "@components/buttons/BasicButton";
import MaintenanceCertificateHelp1Modal from "@components/modals/maintenancecertificate/MaintenanceCertificateHelp1Modal";
import { getSaveRequest } from "@utils/maintenanceCertificate-utils";
import { toastError } from "@utils/toast-helper";
import { Form } from "antd";

export const MaintenanceCertificateDetailScreen: React.FC = () => {
  const { t } = useTranslation();
  const isPublish = "PUBLISH";
  const location = useLocation();
  const [form] = Form.useForm();
  const [panelWithError, setPanelWithError] = useState<string[]>([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const maintenanceCertificateId = location.pathname.split("/").pop() ?? "";

  const [maintenanceCertificate, setMaintenanceCertificate] =
    useState<MaintenanceCertificateResponseDto>(
      (location.state as MaintenanceCertificateState)
        ?.initialMaintenanceCertificate || undefined,
    );
  const [attestationContent, setAttestationContent] = useState<string>();
  const [isModalHelpOpen1, setModalHelpOpen1] = useState<boolean>(false);
  const [maintenanceCertificateStatus, setMaintenanceCertificateStatus] =
    useState<string>(maintenanceCertificate?.status);
  const oldStatus = maintenanceCertificate?.status;

  useEffect(() => {
    if (maintenanceCertificate == undefined)
      void requestGetMaintenanceCertificate(maintenanceCertificateId);
  }, [maintenanceCertificate, maintenanceCertificateId]);

  useEffect(() => {
    if (oldStatus != maintenanceCertificateStatus) {
      void requestGetMaintenanceCertificate(maintenanceCertificateId);
    }
  }, [maintenanceCertificateId, maintenanceCertificateStatus, oldStatus]);

  useEffect(() => {
    return requestGetMaintenanceCertificate.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMaintenanceCertificate(result.data);
        setAttestationContent(
          `data:application/pdf;base64,${result.data.attestation.data}`,
        );
      }
    });
  }, []);

  const handleDownload = () => {
    downloadFile(
      maintenanceCertificate.attestation.data,
      maintenanceCertificate.attestation.mimeType ??
        maintenanceCertificate.attestation.type,
      "Application_"
        .concat(maintenanceCertificate.clientLastname)
        .concat("_", maintenanceCertificate.clientFirstname),
    );
  };

  const handleSave = () => {
    form
      .validateFields(["section1clientFirstname", "section1clientLastname"])
      .then(() => {
        const values = form.getFieldsValue();
        setIsLoading(true);
        void requestUpdateMaintenanceCertificate({
          id: maintenanceCertificate?.id,
          dto: getSaveRequest(values),
        });
      })
      .catch(() => {
        toastError(t<string>("pro.certificate.form.form.errors"));
        let sectionWithError: string[] = [];
        setPanelWithError(sectionWithError);
        form
          .getFieldsError(["section1clientFirstname", "section1clientLastname"])
          .forEach((m) => {
            if (m.errors.length != 0) {
              const section: string = m.name[0].toString().substring(0, 8);
              if (!sectionWithError.includes(section)) {
                sectionWithError = [...sectionWithError, section];
              }
            }
          });
        setPanelWithError(sectionWithError);
      });
  };

  useEffect(() => {
    return requestUpdateMaintenanceCertificate.done.watch(({ result }) => {
      if (result.ok && result.data) {
        navigate(ROUTES.professional.maintenanceCertificate.list.generate());
      } else {
        toastError(result.errorMessage);
      }
      setIsLoading(false);
    });
  });

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>("pro.certificate.form.title")}
      noSideMenu={false}
      returnRoute={ROUTES.professional.maintenanceCertificate.list.generate()}
      confirmReturnButtonRefused={() =>
        navigate(ROUTES.professional.maintenanceCertificate.list.generate())
      }
      confirmReturnRouteText={t<string>(
        "pro.certificate.form.return.updateAttestationTitle",
      )}
      confirmReturnRoute={maintenanceCertificate?.status != isPublish}
      confirmReturnButtonAccepted={handleSave}
      rightContent={
        attestationContent != undefined &&
        maintenanceCertificate?.status == isPublish ? (
          <BasicButton
            text={t<string>("pro.certificate.detail.download")}
            className="primary"
            onClick={handleDownload}
          />
        ) : (
          <BasicButton
            text={t<string>("common.help")}
            className="primary"
            onClick={() => setModalHelpOpen1(true)}
          />
        )
      }
    >
      <>
        {maintenanceCertificate && (
          <>
            {maintenanceCertificate?.status != isPublish ? (
              <>
                <MaintenanceCertificateFormScreen
                  maintenanceCertificate={maintenanceCertificate}
                  setStatus={setMaintenanceCertificateStatus}
                  form={form}
                  panelsWithError={panelWithError}
                  handleSave={handleSave}
                  isLoadingParent={isLoading}
                />
              </>
            ) : (
              <>
                {attestationContent != undefined && (
                  <>
                    <PdfViewer url={attestationContent} withData />
                  </>
                )}
              </>
            )}
          </>
        )}
        <MaintenanceCertificateHelp1Modal
          className="large-modal"
          title="Aide"
          open={isModalHelpOpen1}
          onCancel={() => setModalHelpOpen1(false)}
        />
      </>
    </PageLayout>
  );
};
