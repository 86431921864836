export const RG_REQUEST_STATUS: {
  [index: string]: RgRequestStatus;
} = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  IN_PROGRESS: {
    value: "IN_PROGRESS",
    label: "pro.qualifications.requests.status.inProgress",
  },
  PENDING: {
    value: "PENDING",
    label: "pro.qualifications.requests.status.pending",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  TO_FIX: {
    value: "TO_FIX",
    label: "pro.qualifications.requests.status.toFix",
  },
  ARCHIVED: {
    value: "ARCHIVED",
    label: "pro.qualifications.requests.status.archived",
  },
};

export interface RgRequestStatus {
  value: string;
  label: string;
}
