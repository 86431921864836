import { FunctionComponent } from "react";

interface ContactDetailItemProps {
  title: string | JSX.Element;
  description: string | JSX.Element;
  variant?: string;
  className?: string;
}

const ContactDetailItem: FunctionComponent<ContactDetailItemProps> = ({
  title,
  description,
  variant = "default",
  className = "",
}) => {
  return (
    <div className={`app-contact-detail-item ${className}`}>
      <div className={`item-title text-${variant}`}>{title}</div>
      <div className="item-description">{description}</div>
    </div>
  );
};

export default ContactDetailItem;
