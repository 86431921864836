export interface ValidationPassword {
  character: number;
  upperCase: number;
  lowerCase: number;
  number: number;
  special: number;
}

const validateCharacter = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return password?.length >= validation.character;
};

const validateUpperCase = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return password?.replace(/[^A-Z]/g, "").length >= validation.upperCase;
};

const validateLowerCase = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return password?.replace(/[^a-z]/g, "").length >= validation.lowerCase;
};

const validateNumber = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return password?.replace(/[^0-9]/g, "").length >= validation.number;
};

const validateSpecial = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return (
    password?.replace(/[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "").length >=
    validation.special
  );
};

const validate = (
  password: string,
  validation: ValidationPassword,
): boolean => {
  return (
    validateCharacter(password, validation) &&
    validateUpperCase(password, validation) &&
    validateLowerCase(password, validation) &&
    validateNumber(password, validation) &&
    validateSpecial(password, validation)
  );
};

export const passwordHelper = {
  validate,
  validateCharacter,
  validateUpperCase,
  validateLowerCase,
  validateNumber,
  validateSpecial,
};
