import { ChangeEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { passwordHelper, ValidationPassword } from "@utils/password-helper";
import InputFormField from "@components/inputs/InputFormField";
import PasswordValidationTooltip from "@components/tooltips/PasswordValidationTooltip";
import { Col } from "antd";

interface SetPasswordInputFieldsProps {
  isFromPersonalDatas?: boolean;
}

export const validationPassword: ValidationPassword = {
  character: 9,
  upperCase: 1,
  lowerCase: 1,
  number: 1,
  special: 1,
};

const SetPasswordInputFields: FunctionComponent<
  SetPasswordInputFieldsProps
> = ({ isFromPersonalDatas = false }) => {
  const { t } = useTranslation();

  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setPassword(value);
    if (value === "" || passwordHelper.validate(value, validationPassword)) {
      setPasswordValidationFailed(false);
    } else {
      setPasswordValidationFailed(true);
    }
  };

  const renderCredentialField = () => {
    return (
      <InputFormField
        onChange={onChange}
        showLabel
        module="set-password"
        type="password"
        field="credential"
        required
      />
    );
  };

  const renderConfirmCredentialField = () => {
    return (
      <InputFormField
        showLabel
        module="set-password"
        type="password"
        field="confirmCredential"
        className="mb-3"
        rules={[
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("credential") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                t<string>("set-password.form.errors.unmatch"),
              );
            },
          }),
        ]}
        required
      />
    );
  };

  return (
    <>
      {isFromPersonalDatas ? (
        <Col xs={24} lg={8}>
          {renderCredentialField()}
        </Col>
      ) : (
        renderCredentialField()
      )}

      {!isFromPersonalDatas && passwordValidationFailed && (
        <PasswordValidationTooltip password={password} />
      )}
      {isFromPersonalDatas ? (
        <Col xs={24} lg={8}>
          {renderConfirmCredentialField()}
        </Col>
      ) : (
        renderConfirmCredentialField()
      )}
    </>
  );
};

export default SetPasswordInputFields;
