import React from "react";
import { useTranslation } from "react-i18next";
import BasicCard from "@components/cards/BasicCard";
import { Steps } from "antd";

export const PacHybrideMentionInitScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BasicCard className="card-mention mb-60">
        <div className="d-flex flex-column flex-lg-row">
          <div className="card-mention-left">
            <h2 className="mb-4">
              {t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.formation.detail.title",
              )}
            </h2>
            <div className="d-flex align-items-center mb-4">
              <img
                src="/img/pro/clock.svg"
                alt={t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.hoursAlt",
                )}
              />
              <div className="card-mention-left-description ms-3">
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.hours",
                )}
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <img
                src="/img/pro/question.svg"
                alt={t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.questionsAlt",
                )}
              />
              <div className="card-mention-left-description ms-3">
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.questions",
                )}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <img
                src="/img/pro/euros.svg"
                alt={t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.priceAlt",
                )}
              />
              <div className="card-mention-left-description ms-3">
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.detail.price",
                )}
              </div>
            </div>
          </div>
          <div className="card-mention-right">
            <h2 className="mb-4">
              {t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.formation.explanation.title",
              )}
            </h2>
            <div className="mb-20">
              {t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.formation.explanation.description",
              )}
            </div>
            <ul>
              <li>
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.explanation.list1",
                )}
              </li>
              <li>
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.explanation.list2",
                )}
              </li>
              <li>
                {t<string>(
                  "pro.mentions.pacHybride.detail.noCertificate.formation.explanation.list3",
                )}
              </li>
            </ul>
          </div>
        </div>
      </BasicCard>
      <BasicCard className="bg-light-blue no-shadow-card mb-80">
        <>
          <h3 className="mb-20">
            {t<string>(
              "pro.mentions.pacHybride.detail.noCertificate.functioning.title",
            )}
          </h3>
          <Steps
            responsive
            current={3}
            direction={"horizontal"}
            labelPlacement={"vertical"}
            className="mt-30"
          >
            <Steps.Step
              className={"small-title"}
              key={0}
              title={t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.functioning.step1",
              )}
            />
            <Steps.Step
              className={"small-title"}
              key={1}
              title={t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.functioning.step2",
              )}
            />
            <Steps.Step
              className={"small-title"}
              key={2}
              title={t<string>(
                "pro.mentions.pacHybride.detail.noCertificate.functioning.step3",
              )}
            />
          </Steps>
        </>
      </BasicCard>
      <span className="functioning-details">
        {t<string>(
          "pro.mentions.pacHybride.detail.noCertificate.functioning.details",
        )}
      </span>
    </>
  );
};
