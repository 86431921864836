import { createEffect } from "effector";
import { restDetailsHandler } from "@utils/rest/RestClient";
import { AddressesApiGouvResponseDto } from "@state/address/dto/address.api.gouv.response.dto";

const baseUrl = "https://api-adresse.data.gouv.fr";

const geoApiGouvUrl = `${baseUrl}/search/?limit=15&q=`;

const geoApiType = "&type=street";

const geoApiGouvReverseUrl = `${baseUrl}/reverse/?`;

export const requestGetAddresses = createEffect({
  handler: restDetailsHandler<AddressesApiGouvResponseDto>(geoApiGouvUrl),
});

export const requestGetAddressesForLP = createEffect({
  handler: restDetailsHandler<AddressesApiGouvResponseDto>(
    geoApiGouvUrl,
    geoApiType,
  ),
});

export const requestGetAddressReverse = createEffect({
  handler:
    restDetailsHandler<AddressesApiGouvResponseDto>(geoApiGouvReverseUrl),
});
