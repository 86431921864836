import React from "react";
import { Form, InputNumber, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { formUtils } from "@utils/form-utils";

interface PropsType {
  showLabel?: boolean;
  showPlaceholder?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: boolean | string;
  readOnly?: boolean;
  noStepper?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (text: string) => void;
  onChange?: (value: string | number | null | undefined) => void;
  disabled?: boolean;
  min?: number;
  max?: number;
  precision?: number;
  step?: number;
  formatter?: (value: number | string | undefined) => string;
  parser?: (displayValue: string | undefined) => number | string;
  initialValue?: number;
}

const InputNumberFormField: React.FunctionComponent<PropsType> = (
  props: PropsType,
) => {
  const {
    showLabel,
    showPlaceholder,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    noStepper,
    labelClassName,
    fieldClassName = "",
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    disabled,
    initialValue,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  const renderedLabel = () => {
    return labelTooltip ? (
      <Tooltip title={labelTooltip}>
        <span className={labelClassName ?? ""}>
          {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
        </span>
      </Tooltip>
    ) : (
      <span className={labelClassName ?? ""}>
        {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
      </span>
    );
  };

  const renderedInput = () => {
    return (
      <InputNumber
        disabled={readOnly ?? disabled}
        className={`
          ${
            readOnly
              ? `readonly-form-field ${fieldClassName}`
              : noStepper
              ? `no-stepper ${fieldClassName}`
              : fieldClassName
          }
        `}
        placeholder={
          showPlaceholder
            ? placeholder
              ? placeholder
              : module &&
                t<string>(`${module}.form.fields.${i18nField}.placeholder`)
            : undefined
        }
        id={key}
        {...props}
        onFocus={(event) => event.target.select()}
      />
    );
  };

  return (
    <>
      <Form.Item
        name={
          typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
        }
        key={key}
        label={readOnly || showLabel ? renderedLabel() : ""}
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage
              ? emptyErrorMessage
              : t<string>("common.empty_error_field"),
          },
          ...rules,
        ]}
        className={className}
        initialValue={initialValue}
      >
        {renderedInput()}
      </Form.Item>
    </>
  );
};

export default InputNumberFormField;
