import React from "react";
import { useTranslation } from "react-i18next";

export const PacHybrideMentionPendinScreen: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="requested-mention-container d-flex flex-column flex-lg-row align-items-center align-items-lg-start mt-2">
      <div className="request-text-container mt-20">
        <h2 className="mb-2">
          {t<string>(
            "pro.mentions.pacHybride.detail.requestedCertificate.subtitle",
          )}
        </h2>
        <div>
          {t<string>(
            "pro.mentions.pacHybride.detail.requestedCertificate.description",
          )}
        </div>
      </div>
      <div className="request-img">
        <img alt={t<string>("error.img.alt")} src="/img/error404/error2.svg" />
      </div>
    </div>
  );
};
