export const QUALIFICATION_RG_STATUS: {
  [index: string]: QualificationRgStatus;
} = {
  VALID: {
    value: "VALID",
    label: "pro.qualifications.statusRG.valid",
    variant: "light-green",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EXPIRED_SOON: {
    value: "EXPIRED_SOON",
    label: "pro.qualifications.statusRG.expiredSoon",
    variant: "light-yellow",
  },
  EXPIRED: {
    value: "EXPIRED",
    label: "pro.qualifications.statusRG.expired",
    variant: "light-red",
  },
  FIRED: {
    value: "FIRED",
    label: "pro.qualifications.statusRG.fired",
    variant: "light-grey",
  },
};

export interface QualificationRgStatus {
  value: "VALID" | "EXPIRED_SOON" | "EXPIRED" | "FIRED";
  label: string;
  variant: string;
}
