import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";

interface RewardCardProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  className?: string;
}

const RewardCard: FunctionComponent<RewardCardProps> = ({
  icon,
  title,
  className = "",
}) => {
  return (
    <BasicCard className={`app-card-reward ${className}`}>
      <div className="d-flex align-items-center justify-content-center">
        <div className="card-reward-icon">{icon}</div>
        <div className="card-reward-title flex-grow-1 text-center">{title}</div>
      </div>
    </BasicCard>
  );
};

export default RewardCard;
