import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import GazEventActionTag from "@components/tags/GazEventActionTag";
import { useTranslation } from "react-i18next";
import DateFormat from "@utils/DateFormat";

interface GazEventIndividualActionCardProps {
  title: string;
  actionFrom?: string;
  actionTo?: string;
  description?: string;
  className?: string;
}

const GazEventIndividualActionCard: FunctionComponent<
  GazEventIndividualActionCardProps
> = ({ title, actionFrom, actionTo, description, className = "" }) => {
  const { t } = useTranslation();

  return (
    <BasicCard className={`app-card-individual-action-gaz-month ${className}`}>
      <>
        <div className="d-flex justify-content-end">
          <h3 className="action-title flex-grow-1">{title}</h3>
          <div className="action-type">
            <GazEventActionTag label={"Offre"} variant="black" />
          </div>
        </div>
        <div className="small mb-20">
          {actionFrom && (
            <>
              {`${t<string>(
                "gazMonth.pro.subscribed.actions.from",
              )} ${DateFormat.standardDate(new Date(actionFrom))}${
                actionTo
                  ? ` ${t<string>(
                      "gazMonth.pro.subscribed.actions.to",
                    )} ${DateFormat.standardDate(new Date(actionTo))}`
                  : ""
              }`}
            </>
          )}
        </div>
        {description && <div className="small">{description}</div>}
      </>
    </BasicCard>
  );
};

export default GazEventIndividualActionCard;
