import React, { FunctionComponent, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { Carousel } from "antd";
import BasicButton from "@components/buttons/BasicButton";
import { CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CarouselRef } from "antd/lib/carousel";
import { useTranslation } from "react-i18next";
import { useViewport } from "@utils/breakpoints-helper";

interface TutorialSlideItemProps {
  title: string;
  description: JSX.Element;
  src: string;
  alt: string;
  additionalText?: string;
}

interface TutorialModalProps {
  title: string;
  slides: TutorialSlideItemProps[];
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const TutorialModal: FunctionComponent<TutorialModalProps> = ({
  title,
  slides,
  open,
  onCancel,
  className = "",
}) => {
  const { t } = useTranslation();
  const { width } = useViewport();
  const carouselRef: React.Ref<CarouselRef> = React.createRef();
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  return (
    <BasicModal
      title={title}
      open={open}
      onCancel={onCancel}
      className={`app-modal-tutorial ${className}`}
      footer={null}
    >
      <>
        <Carousel
          dots={false}
          className="tutorial-carousel"
          ref={carouselRef}
          beforeChange={(from, to) => setCurrentSlide(to)}
        >
          {slides.map((slide, index) => (
            <div key={index} className="tutorial-slide">
              <div className="tutorial-slide-title">{slide.title}</div>
              <div className="tutorial-slide-description">
                {slide.description}
              </div>
              <div className="tutorial-slide-img d-flex justify-content-center">
                <img src={slide.src} alt={slide.alt} />
              </div>
              {slide.additionalText && (
                <div className="tutorial-slide-additional-text">
                  {slide.additionalText}
                </div>
              )}
            </div>
          ))}
        </Carousel>
        {slides.length > 1 && (
          <div className="tutorial-carousel-actions">
            <BasicButton
              variant="primary"
              className={`tutorial-carousel-button ${
                currentSlide !== 0 ? "" : "visibility-none"
              }`}
              icon={<LeftOutlined />}
              onClick={() =>
                carouselRef && carouselRef.current && carouselRef.current.prev()
              }
            />
            <div className="tutorial-carousel-dots">
              {slides.map((slide, index) => (
                <button
                  key={index}
                  className={`tutorial-carousel-dot ${
                    currentSlide === index ? "active-dot" : ""
                  }`}
                  onClick={() =>
                    carouselRef &&
                    carouselRef.current &&
                    carouselRef.current.goTo(index)
                  }
                />
              ))}
            </div>
            {currentSlide === slides.length - 1 ? (
              <div className="tutorial-carousel-button-close-container">
                <BasicButton
                  variant="primary"
                  className="tutorial-carousel-button-close"
                  text={
                    width < 576
                      ? undefined
                      : t<string>("pro.audit.list.tutorial.close")
                  }
                  icon={width < 576 ? <CloseOutlined /> : undefined}
                  onClick={onCancel}
                />
              </div>
            ) : (
              <BasicButton
                variant="primary"
                className="tutorial-carousel-button"
                icon={<RightOutlined />}
                onClick={() =>
                  carouselRef &&
                  carouselRef.current &&
                  carouselRef.current.next()
                }
              />
            )}
          </div>
        )}
      </>
    </BasicModal>
  );
};

export default TutorialModal;
