import { FunctionComponent } from "react";
import Stepper from "@components/stepper/Stepper";
import { useTranslation } from "react-i18next";
import config from "@utils/Config";

interface LandingPageStepperProps {
  landingPageType: string;
}

const LandingPageStepper: FunctionComponent<LandingPageStepperProps> = ({
  landingPageType,
}) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div className="app-rentree-du-chauffage-stepper">
      <div className="content-contained">
        <h2>
          {t<string>(
            `landingPage.${landingPageType}${
              isTempsFort ? ".".concat(gazEventType) : ""
            }.stepper.title`,
          )}
        </h2>
        <Stepper
          steps={[
            {
              title: t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.stepper.step-1`,
              ),
            },
            {
              title: t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.stepper.step-2`,
              ),
            },
            {
              title: t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.stepper.step-3`,
              ),
            },
            {
              title: t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.stepper.step-4`,
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LandingPageStepper;
