import { SectionSpeciality } from "@utils/enums/qualifications.rg-requests.section.enum";
import {
  getLabelSpeciality,
  getSection,
  getTypeButton,
} from "@utils/rgrequest-module-utils";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import UploadFormField from "@components/inputs/UploadFormField";
import { ResourceDetailsResponseDto } from "@state/resource/dto/resource.details.response.dto";
import FileDownloadModuleCMS from "@components/cms/FileDownloadModuleCMS";
import { downloadFromFile } from "@utils/Base64File";
import { mapResourceTofileType } from "@utils/upload-utils";

interface ModuleLoadProps {
  readOnly?: boolean;
  type: RGRequestType;
  speciality: SectionSpeciality;
  fileId: string;
  setFileId: React.Dispatch<React.SetStateAction<string>>;
  acceptedMentionTypes: string[];
  certificate?: ResourceDetailsResponseDto;
}

const ModuleLoad: FunctionComponent<ModuleLoadProps> = ({
  readOnly = false,
  type,
  speciality,
  fileId,
  setFileId,
  acceptedMentionTypes,
  certificate,
}) => {
  const { t } = useTranslation();
  const sectionLabel = getSection(speciality);
  const typeButton = getTypeButton(fileId);
  const uploadFormFieldLabelSpeciality = getLabelSpeciality(
    speciality,
    typeButton,
  );

  return (
    <>
      <hr />
      {type === RG_REQUEST_TYPE.ADD && (
        <div className="rg-request-form-section-title">
          {t<string>(
            `pro.qualifications.modals.general.section.${sectionLabel}`,
          )}
        </div>
      )}
      <div className="rg-request-form-section-container">
        {readOnly ? (
          certificate ? (
            <FileDownloadModuleCMS
              title={certificate.filename}
              description={`PDF - ${certificate.size}`}
              onClick={() =>
                downloadFromFile(mapResourceTofileType(certificate))
              }
              backgroundColor="white"
              buttonVariant="primary-outline"
              className="py-0"
              isInModal={true}
            />
          ) : (
            t<string>("pro.qualifications.modals.details.error.certificate")
          )
        ) : (
          <UploadFormField
            showUploadList
            className="w-100"
            name={`${
              speciality === SectionSpeciality.MAINTENANCE
                ? "maintenance"
                : "installation"
            }Certificate`}
            files={
              certificate ? [mapResourceTofileType(certificate)] : undefined
            }
            label={t(
              `${
                uploadFormFieldLabelSpeciality === "noFile"
                  ? "common.upload."
                  : "pro.qualifications.modals.general.section."
              }${uploadFormFieldLabelSpeciality}`,
            )}
            domain="RG_REQUEST_CERTIFICATE"
            saveFile
            setFileId={setFileId}
            types={acceptedMentionTypes}
            formatLabel="PDF, PNG, JPEG"
            buttonText={t<string>(`common.upload.${typeButton}`)}
            buttonVariant={typeButton === "select" ? "primary" : undefined}
          />
        )}
      </div>
    </>
  );
};

export default ModuleLoad;
