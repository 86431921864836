import { FunctionComponent, useEffect, useState } from "react";
import { StructureDto } from "@state/structure/dto/structure.dto";
import { useTranslation } from "react-i18next";

interface SummaryHeaderProps {
  category?: StructureDto;
  className?: string;
  setCategoryIds?: (id: string[]) => void;
  nbPages?: number;
}

const SummaryHeader: FunctionComponent<SummaryHeaderProps> = ({
  category,
  className = "",
  setCategoryIds,
  nbPages,
}) => {
  const { t } = useTranslation();
  const [nbArticlesInCategory, setNbArticlesInCategory] = useState<number>();
  const subCategories =
    category &&
    category.children &&
    category.children.filter((item) => item.type === "CATEGORY");
  const [title, setTitle] = useState<string>(t<string>("summary.findArticles"));

  useEffect(() => {
    setTitle(category ? category.title : t<string>("summary.findArticles"));
  }, [category, t]);

  useEffect(() => {
    setNbArticlesInCategory(nbPages);
  }, [nbPages]);

  return (
    <div className={`app-header-summary ${className}`}>
      <div className="summary-container d-flex justify-content-end flex-wrap">
        <div className="flex-grow-1">
          <h1 className="mb-10">{title}</h1>
          {title === category?.title ? (
            nbArticlesInCategory &&
            subCategories &&
            subCategories.length > 0 ? (
              <div className="summary-subtitle mb-10">{`${t<string>(
                "summary.allArticles",
              )} (${nbArticlesInCategory})`}</div>
            ) : nbArticlesInCategory && nbArticlesInCategory > 0 ? (
              <div className="summary-subtitle mb-10">{`${nbArticlesInCategory} ${
                nbArticlesInCategory === 1
                  ? t<string>("summary.articleAlone")
                  : t<string>("summary.articles")
              }`}</div>
            ) : (
              <div className="summary-subtitle mb-10">
                {t<string>("summary.noArticle")}
              </div>
            )
          ) : nbArticlesInCategory && nbArticlesInCategory > 0 ? (
            <div className="summary-subtitle mb-10">{`${nbArticlesInCategory} ${
              nbArticlesInCategory === 1
                ? t<string>("summary.articleAlone")
                : t<string>("summary.articles")
            }`}</div>
          ) : (
            <div className="summary-subtitle mb-10">
              {t<string>("summary.noArticle")}
            </div>
          )}
        </div>
        {category && subCategories && subCategories.length > 0 && (
          <div className="d-flex flex-column align-items-end ms-4 mb-10">
            <div className="summary-subtitle text-right">
              {t<string>("summary.categorySubtitle")}
            </div>
            <div className="d-flex align-center justify-content-end flex-wrap">
              <div
                className="summary-tag"
                onClick={() => {
                  setCategoryIds && setCategoryIds([category.key]);
                  setNbArticlesInCategory(
                    category.children &&
                      category.children.filter((item) => item.type === "PAGE")
                        .length,
                  );
                  setTitle(t<string>("summary.others"));
                }}
              >{`${t<string>("summary.others")} (${
                category.children.filter((item) => item.type === "PAGE").length
              })`}</div>
              {subCategories.map((subCategory) => (
                <div
                  key={subCategory.key}
                  className="summary-tag"
                  onClick={() => {
                    setCategoryIds && setCategoryIds([subCategory.key]);
                    setNbArticlesInCategory(
                      subCategory.children &&
                        subCategory.children.filter(
                          (item) => item.type === "PAGE",
                        ).length,
                    );
                    setTitle(subCategory.title);
                  }}
                >
                  {`${subCategory.title} (${
                    subCategory.children.filter((item) => item.type === "PAGE")
                      .length
                  })`}
                </div>
              ))}
            </div>
            <a
              className="summary-subtitle text-right mt-2"
              onClick={() => {
                setCategoryIds &&
                  setCategoryIds(
                    [category?.key].concat(
                      category.children
                        .filter((cat) => cat.type === "CATEGORY")
                        .map((catId) => catId.key),
                    ),
                  );
                setTitle(category?.title);
                setNbArticlesInCategory(
                  category.children &&
                    category.children.filter((item) => item.type === "PAGE")
                      .length,
                );
              }}
            >
              {t<string>("summary.resetFilter")}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryHeader;
