import { FC, useEffect, useState } from "react";
import configuration from "@utils/Config";
import { useTranslation } from "react-i18next";
import Section from "@components/section/Section";
import NewsItem, { NewsItemProps } from "@components/block/widget/NewsItem";
import { requestGetPagesList } from "@state/page/PageEffects";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { SorterResult } from "antd/es/table/interface";

const News: FC = () => {
  const { t } = useTranslation();

  const [pages, setPages] = useState<PageInfoResponseDto[]>([]);

  const authentifiedContext = useStore(authentifiedStore);

  const headlinePagesMapped: NewsItemProps[] = [];

  const [sorter] = useState<SorterResult<any>>({
    columnKey: "updatedAt",
    order: "descend",
  });

  const addHeadlinePageToArray = (page: PageInfoResponseDto) => {
    headlinePagesMapped.push({
      src: `${configuration.backendBaseUrl}/public/resources/${page.mainImageResourceId}/file`,
      title: page.title,
      description: page.readingTime ? `lecture ${page.readingTime} min.` : "",
      redirectUrl: `${page.categorySeoFriendlyTitle || ""}/${
        page.seoFriendlyTitle
      }`,
    });
  };

  useEffect(() => {
    void requestGetPagesList({
      page: 0,
      limit: 5,
      sorter,
      dto: {
        isHeadline: true,
        universe: authentifiedContext.universe.value,
      },
    });
  }, [authentifiedContext, sorter]);

  useEffect(() => {
    return requestGetPagesList.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setPages(result.data.content);
      }
    });
  });

  pages.forEach((page) => addHeadlinePageToArray(page));

  return (
    <>
      {pages.length > 0 && (
        <Section
          type="h2"
          title={t<string>("blocks.news.title")}
          className="app-actualities container my-40"
        >
          <div className="actualities">
            <div className="d-flex actualities-container mt-40">
              {headlinePagesMapped.map(
                (image: NewsItemProps, index: number) => (
                  <NewsItem
                    key={index}
                    src={image.src}
                    title={image.title}
                    description={image.description}
                    redirectUrl={image.redirectUrl}
                    className={image.className}
                  />
                ),
              )}
            </div>
          </div>
        </Section>
      )}
    </>
  );
};

export default News;
