import { FunctionComponent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddressFields } from "@components/inputs/AddressFields";
import { useNavigate } from "react-router-dom";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { requestGetAddressReverse } from "@state/address/AddressesEffects";
import { Spin } from "antd";
import TagManager from "react-gtm-module";
import { ROUTES } from "@utils/Routes";

interface HomeHeaderWithVideoProps {
  videoUrl: string;
  posterUrl?: string;
  className?: string;
}

const HomeHeaderWithVideo: FunctionComponent<HomeHeaderWithVideoProps> = ({
  videoUrl,
  posterUrl,
  className = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isCurrentLocationLoading, setCurrentLocationLoading] =
    useState<boolean>(false);

  const [selectedAddress, setSelectedAddress] = useState<
    AddressFieldsDto | undefined
  >();
  const [isSelectedAddressExists, setIsSelectedAddressExists] =
    useState<boolean>(false);

  const [isRightOpen, setIsRightOpen] = useState<boolean>(false);

  const handleSearch = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "lancer_recherche_home_part",
      },
    });
    if (selectedAddress) {
      navigate(ROUTES.individual.gazProfessionals.search.generate(), {
        state: {
          parentQueryString: selectedAddress.properties.label,
          parentCurrentPosition: {
            lat: selectedAddress.geometry.coordinates[1],
            lon: selectedAddress.geometry.coordinates[0],
          },
        },
      });
    } else {
      navigate(ROUTES.individual.gazProfessionals.search.generate());
    }
  }, [navigate, selectedAddress]);

  return (
    <div
      className={`app-home-header-with-video ${
        isRightOpen ? "with-right-open" : ""
      } ${className}`}
    >
      <div className="header-with-video-left">
        <div className="header-with-video-left-container">
          <h1 className="header-with-video-left-title">
            {t<string>("homeWithVideo.header-section.title")}
          </h1>
          <p className="header-with-video-left-description">
            {t<string>("homeWithVideo.header-section.description")}
          </p>
          <div className="header-searchbar">
            <Spin
              spinning={isCurrentLocationLoading}
              style={{ height: "60px" }}
            >
              <div
                className={"searchbar-locate-btn"}
                onClick={() => {
                  setCurrentLocationLoading(true);
                  setIsSelectedAddressExists(true);
                  if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(
                      (position: GeolocationPosition): void => {
                        void requestGetAddressReverse(
                          `lon=${position.coords.longitude}&lat=${position.coords.latitude}`,
                        );
                      },
                    );
                  }
                }}
              >
                <img src={"/img/icons/target.svg"} alt={"geoLocationIcon"} />
              </div>
            </Spin>
            <AddressFields
              setSelectedAddress={(val) => {
                setSelectedAddress(val);
                setCurrentLocationLoading(false);
              }}
              onEnterPress={handleSearch}
              className={"searchbar-addressField"}
              setIsSelectedAddressExists={setIsSelectedAddressExists}
            />
            {isSelectedAddressExists && (
              <div
                className={"searchbar-submit-btn"}
                onClick={() => handleSearch()}
              >
                <img src={"/img/icons/search.svg"} alt={"searchIcon"} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="header-with-video-right">
        <div className="header-with-video-right-container">
          <h2 className="header-with-video-right-title h1">
            {t<string>("homeWithVideo.video-section.title")}
          </h2>
          <div className="header-with-video-right-video">
            <video
              src={videoUrl}
              autoPlay={false}
              controls={true}
              poster={posterUrl}
            />
          </div>
          <p className="header-with-video-right-description">
            {t<string>("homeWithVideo.video-section.description")}
          </p>
        </div>
        <div
          className="header-with-video-right-button"
          onClick={() => setIsRightOpen(!isRightOpen)}
        >
          {isRightOpen
            ? t<string>("homeWithVideo.video-section.back")
            : t<string>("homeWithVideo.video-section.jump")}
        </div>
      </div>
    </div>
  );
};

export default HomeHeaderWithVideo;
