import i18n from "i18next";
import React, { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import TableButton from "@components/buttons/TableButton";
import {
  ClockCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { tooltipDateNoTimeRender } from "@components/tooltips/CustomTooltip";
import {
  RG_REQUEST_STATUS,
  RgRequestStatus,
} from "@utils/enums/qualifications.rg-requests.status.enum";
import { ColumnsType } from "antd/es/table";
import { QualificationsRequestItem } from "@views/pro/gazProfessional/qualifications/QualificationsScreen";
import { getNameFromFullName, Name } from "@utils/name-utils";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { RGRequestType } from "@utils/enums/qualifications.rg-requests.type.enum";

export const columns = (
  setShowRGRequestModal: (show: boolean) => void,
  setModalType: React.Dispatch<React.SetStateAction<RGRequestType | undefined>>,
  setModalMode: React.Dispatch<React.SetStateAction<ModalMode | undefined>>,
  setIsInstallation: React.Dispatch<React.SetStateAction<boolean>>,
  setNameRG: React.Dispatch<React.SetStateAction<Name>>,
  setSelectedRGRequestId: React.Dispatch<React.SetStateAction<string>>,
  fetchRGRequest: (id: string) => void,
): ColumnsType<QualificationsRequestItem> => [
  {
    title: i18n.t("pro.qualifications.requests.table.headers.createdAt"),
    dataIndex: "createdAt",
    key: "createdAt",
    className: "date",
    width: 180,
    render: tooltipDateNoTimeRender,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("pro.qualifications.requests.table.headers.type"),
    dataIndex: "typeLabel",
    key: "typeLabel",
    className: "typeLabel",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
    width: "35%",
  },
  {
    title: i18n.t("pro.qualifications.requests.table.headers.rg"),
    dataIndex: "rg",
    key: "rg",
    className: "rg",
    ellipsis: {
      showTitle: false,
    },
  },
  {
    title: i18n.t("pro.qualifications.requests.table.headers.status"),
    dataIndex: "status",
    key: "status",
    className: "status",
    ellipsis: {
      showTitle: false,
    },
    width: 160,
    render: (record: RgRequestStatus): ReactNode => {
      return (
        <div className="d-flex justify-content-start">
          {record === RG_REQUEST_STATUS.IN_PROGRESS ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-green"
              className="d-inline"
            />
          ) : record === RG_REQUEST_STATUS.PENDING ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-yellow"
              className="d-inline"
              icon={<ClockCircleOutlined />}
            />
          ) : record === RG_REQUEST_STATUS.TO_FIX ? (
            <BasicTag
              label={i18n.t(record.label)}
              variant="light-red"
              className="d-inline"
              icon={<ExclamationCircleOutlined />}
            />
          ) : (
            <b>{i18n.t(record.label)}</b>
          )}
        </div>
      );
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("pro.qualifications.requests.table.headers.actions"),
    key: "status",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 90,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: QualificationsRequestItem): JSX.Element => (
      <div className="d-flex justify-content-center">
        {record.status === RG_REQUEST_STATUS.TO_FIX ? (
          <TableButton
            icon={<EditOutlined />}
            buttonClassName="btn-transparent w-auto"
            onClick={() => {
              setNameRG(getNameFromFullName(record.rg));
              setSelectedRGRequestId(record.id);
              setModalMode(ModalMode.UPDATE);
              setModalType(record.type);
              setIsInstallation(record.isInstallation);
              setShowRGRequestModal(true);
              fetchRGRequest(record.id);
            }}
          />
        ) : (
          <TableButton
            icon={<EyeOutlined />}
            buttonClassName="btn-transparent w-auto"
            onClick={() => {
              setNameRG(getNameFromFullName(record.rg));
              setSelectedRGRequestId(record.id);
              setModalMode(ModalMode.DETAILS);
              setModalType(record.type);
              setIsInstallation(record.isInstallation);
              setShowRGRequestModal(true);
              fetchRGRequest(record.id);
            }}
          />
        )}
      </div>
    ),
  },
];
