import { FunctionComponent } from "react";
import parse from "html-react-parser";

interface RentreeDuChauffageContactItemProps {
  title: string;
  description: string;
  src: string;
  alt?: string;
  imgLeft?: boolean;
  className?: string;
}

const RentreeDuChauffageContactItem: FunctionComponent<
  RentreeDuChauffageContactItemProps
> = ({
  title,
  description,
  src,
  alt = "",
  imgLeft = false,
  className = "",
}) => {
  return (
    <div className={`app-rentree-du-chauffage-contact-item ${className}`}>
      <h2 className="contact-item-title">{title}</h2>
      <div
        className={`contact-item-content ${
          imgLeft ? "contact-item-img-left" : ""
        }`}
      >
        <div className="contact-item-description">{parse(description)}</div>
        <div className="contact-item-img">
          <img src={src} alt={alt} />
        </div>
      </div>
    </div>
  );
};

export default RentreeDuChauffageContactItem;
