import { FunctionComponent } from "react";
import Section from "@components/section/Section";
import { Col, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { useTranslation } from "react-i18next";
import BasicDivider from "@components/divider/BasicDivider";
import { PATTERNS } from "@utils/enums/pattern.enum";

const ProfessionalSection: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="px-12">
        <BasicDivider variant="primary" className="mb-40 w-100" height={1} />
      </div>
      <Section
        type="h2"
        title={t<string>("personalInfo.sectionTitle.professional")}
        subtitle={t<string>("personalInfo.notEditable")}
        titleClassName="px-12"
      >
        <div className="px-12">
          <Row gutter={16}>
            <Col xs={0} lg={1} className="mx-0 px-0">
              <img src="/img/icons/personalData/lock.svg" />
            </Col>
            <Col xs={24} lg={23}>
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <InputFormField
                    showLabel
                    module="personalInfo"
                    field="professionalName"
                    readOnly
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <InputFormField
                    showLabel
                    module="personalInfo"
                    field="professionalPhone"
                    readOnly
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} lg={12}>
                  <InputFormField
                    showLabel
                    module="personalInfo"
                    field="professionalEmail"
                    rules={[
                      {
                        pattern: new RegExp(PATTERNS.EMAIL),
                        message: "example.form.fields.email.errors.invalid",
                      },
                    ]}
                    readOnly
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Section>
    </>
  );
};

export default ProfessionalSection;
