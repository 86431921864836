import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import DateFormat from "@utils/DateFormat";

interface GazEventIndividualPromotionCardProps {
  img?: string;
  title: string;
  promotionFrom: string;
  promotionTo: string;
  description?: string;
  className?: string;
}

const GazEventIndividualPromotionCard: FunctionComponent<
  GazEventIndividualPromotionCardProps
> = ({
  img,
  title,
  promotionFrom,
  promotionTo,
  description,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <BasicCard
      className={`app-card-individual-promotion-gaz-month ${className}`}
    >
      <div className="d-flex align-items-center justify-content-center">
        <div className="flex-grow-1">
          <div className="promotion-title">{title}</div>
          <div className="promotion-date small">
            {`${t<string>(
              "gazMonth.pro.subscribed.actions.from",
            )} ${DateFormat.standardDate(new Date(promotionFrom))}${
              promotionTo
                ? ` ${t<string>(
                    "gazMonth.pro.subscribed.actions.to",
                  )} ${DateFormat.standardDate(new Date(promotionTo))}`
                : ""
            }`}
          </div>
          <div className="d-flex flex-column flex-sm-row ">
            {img && (
              <div className="promotion-img">
                <img src={img} alt="Logo" />
              </div>
            )}
            {description && <div className="small">{description}</div>}
          </div>
        </div>
      </div>
    </BasicCard>
  );
};

export default GazEventIndividualPromotionCard;
