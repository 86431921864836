import parse from "html-react-parser";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import BasicModal from "@components/modals/BasicModal";
import { requestPostWebinarRegistration } from "@state/webinar/WebinarEffects";
import { WebinarItemResponse } from "@state/webinar/dto/webinar.item.response";
import DateFormat from "@utils/DateFormat";
import { toastError } from "@utils/toast-helper";

interface WebinarRegisterProps {
  webinar: WebinarItemResponse;
  className?: string;
  title?: string;
  description?: string;
  buttonText?: string;
  modalContent: string;
}

const WebinarRegister: FC<WebinarRegisterProps> = ({
  webinar,
  className = "",
  title,
  description,
  buttonText,
  modalContent,
}) => {
  const { t } = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const startDate = webinar.startAt ? new Date(webinar.startAt) : undefined;
  const endDate = webinar.endAt ? new Date(webinar.endAt) : undefined;
  const canSubscribe = startDate && startDate > new Date();
  const translationParams = {
    date: startDate ? DateFormat.standardDate(startDate) : "",
    startTime: startDate ? DateFormat.standardTime(startDate) : "",
    endTime: endDate ? DateFormat.standardTime(endDate) : "",
  };

  useEffect(() => {
    return requestPostWebinarRegistration.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setIsModalVisible(true);
      } else if (result.responseCode === 409) {
        setIsModalVisible(true);
      } else {
        toastError(t<string>("webinar.common.registrationError"));
      }
      setIsButtonLoading(false);
    });
  }, [t]);

  const handleClickSubscribe = useCallback(() => {
    setIsButtonLoading(true);
    void requestPostWebinarRegistration({
      dto: {
        webinarId: webinar.id,
      },
    });
  }, [webinar.id]);

  return canSubscribe ? (
    <div className={`webinar-register ${className}`}>
      {title && (
        <div className="text-primary font-weight-bold">
          {t<string>(title, translationParams)}
        </div>
      )}
      {description && (
        <div className="text-primary mb-20">
          {t<string>(description, translationParams)}
        </div>
      )}
      <BasicButton
        variant="primary-outline"
        text={buttonText || t<string>("webinar.common.subscribe")}
        onClick={handleClickSubscribe}
        isLoading={isButtonLoading}
        className="mention-button"
      />
      <BasicModal
        className="webinar-register-modal"
        open={isModalVisible}
        title={t<string>("webinar.common.modal.title")}
        onCancel={() => setIsModalVisible(false)}
        footer={
          <div className="d-flex justify-content-center">
            <BasicButton
              variant="primary"
              text={t<string>("common.close")}
              onClick={() => setIsModalVisible(false)}
            />
          </div>
        }
      >
        <>
          <div>{parse(t<string>(modalContent, translationParams))}</div>
          <div className="mt-20">
            {t<string>("webinar.common.modal.complement")}
          </div>
        </>
      </BasicModal>
    </div>
  ) : (
    <></>
  );
};

export default WebinarRegister;
