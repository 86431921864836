import { FC, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicCard from "@components/cards/BasicCard";
import parse from "html-react-parser";
import { formItemBlockLayout } from "@utils/Constant";
import { Form } from "antd";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useForm } from "antd/lib/form/Form";
import BasicButton from "@components/buttons/BasicButton";
import { toastError, toastSuccess } from "@utils/toast-helper";
import configuration from "@utils/Config";
import { GazEventRegistrationSaveRequestDto } from "@state/gazMonth/pro/dto/gazEvent.save.request.dto";
import { requestCreateGazEventRegistration } from "@state/gazMonth/pro/GazEventEffects";
import { GazEventRegistrationResponseDto } from "@state/gazMonth/dto/gaz.event.registration.response.dto";
import GazEventActionScreen from "@views/pro/gazProfessional/gazEvent/GazEventActionScreen";
import {
  gazMonthEndDate,
  gazMonthStartDate,
  getCardSubtitle,
  getGazEvent,
  rentreeDuChauffageEndDate,
  rentreeDuChauffageStartDate,
} from "@utils/gazEvent-utils";

interface FormValues {
  businessName: string;
  username: string;
  email: string;
  phone: string;
  postcode: string;
  city: string;
  conditions: boolean;
}

const initialValue = (user: GazProfessionalUser): FormValues => {
  return {
    businessName: user.companyName,
    username: user.username,
    email: user.email,
    phone: user.phone1,
    postcode: user.zipcode,
    city: user.city,
    conditions: false,
  };
};

const GazEventScreen: FC = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const userPg = authentifiedContext.user as GazProfessionalUser;
  const gazEventType = configuration.gazEventType;
  const [gazEvent, setGazEvent] = useState<
    GazEventRegistrationResponseDto | undefined
  >(userPg?.gazEvent);

  const [form] = useForm();
  const today = new Date();

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isRegisteredForGazMonth, setIsRegisteredForGazMonth] =
    useState<boolean>(false);
  const [
    isRegisteredForRentreeDuChauffage,
    setIsRegisteredForRentreeDuChauffage,
  ] = useState<boolean>(false);

  useEffect(() => {
    setIsRegisteredForGazMonth(gazEvent?.gazMonth ?? false);
    setIsRegisteredForRentreeDuChauffage(gazEvent?.rentreeDuChauffage ?? false);
  }, [gazEvent]);

  const handleSubmit = (
    registrationToSave: GazEventRegistrationSaveRequestDto,
    gazEvent: string,
  ) => {
    setButtonLoading(true);
    void requestCreateGazEventRegistration({
      dto: registrationToSave,
    }).then((result) => {
      if (result.ok && result.data) {
        if (gazEvent === "gazMonth") {
          toastSuccess(
            t<string>(
              `${gazEvent}.pro.subscribed.messages.${
                isRegisteredForGazMonth ? "unsubscribe" : "subscribe"
              }`,
            ),
          );
        } else {
          toastSuccess(
            t<string>(
              `${gazEvent}.pro.subscribed.messages.${
                isRegisteredForRentreeDuChauffage ? "unsubscribe" : "subscribe"
              }`,
            ),
          );
        }
        setGazEvent(result.data);
      } else {
        toastError(result.errorMessage);
      }
      setButtonLoading(false);
    });
  };

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>(`${gazEventType}.pro.notSubscribed.title`)}
      noSideMenu={false}
    >
      <>
        {authentifiedContext.typeUser === "GazProfessionalUser" && (
          <>
            <BasicCard className="app-card-gaz-month-registration">
              <>
                <h3 className="mb-30">
                  {parse(
                    t<string>(`${gazEventType}.pro.notSubscribed.card.title`),
                  )}
                </h3>
                <div>
                  {parse(
                    t<string>(`${gazEventType}.pro.notSubscribed.card.text1`),
                  )}
                </div>
                <br />
                <div>
                  {parse(
                    t<string>(`${gazEventType}.pro.notSubscribed.card.text2`),
                  )}
                </div>
                <br />
                <div>
                  {parse(
                    t<string>(`${gazEventType}.pro.notSubscribed.card.text3`),
                  )}
                </div>
                <br />
                <div>
                  {parse(
                    t<string>(`${gazEventType}.pro.notSubscribed.card.text4`),
                  )}
                </div>
                <div className="mt-4 mb-40">
                  <h3>
                    {t<string>(
                      `${gazEventType}.pro.notSubscribed.subscription.title`,
                    )}
                  </h3>
                  <ul>
                    <li>
                      {t<string>(
                        `${gazEventType}.pro.notSubscribed.subscription.list.list1`,
                      )}
                    </li>
                    <li>
                      {t<string>(
                        `${gazEventType}.pro.notSubscribed.subscription.list.list2`,
                      )}
                    </li>
                  </ul>
                </div>
                <Form
                  form={form}
                  initialValues={initialValue(userPg)}
                  {...formItemBlockLayout}
                >
                  <div className="d-flex flex-column flex-md-row justify-content-between gap-5">
                    <BasicCard className="app-card-gaz-month-registration-suscribe">
                      <div className="d-flex flex-column ">
                        <img
                          src="/img/pro/logo-gaz-month.svg"
                          alt={t<string>(
                            "gazMonth.pro.notSubscribed.cardRegistration.gazMonth",
                          )}
                        />
                        <h2 className="text-primary">
                          {t<string>(
                            "gazMonth.pro.notSubscribed.cardRegistration.gazMonth",
                          )}
                        </h2>
                        <span>
                          {getCardSubtitle(
                            t,
                            "gazMonth",
                            gazMonthStartDate,
                            gazMonthEndDate,
                            today,
                          )}
                        </span>
                        {getGazEvent().typeEvent === "gazMonth" && (
                          <BasicButton
                            text={t<string>(
                              isRegisteredForGazMonth
                                ? `${gazEventType}.pro.notSubscribed.cardRegistration.unsuscribe`
                                : `${gazEventType}.pro.notSubscribed.cardRegistration.suscribe`,
                            )}
                            variant="primary mt-3 card-gaz-month-registration-button"
                            onClick={() =>
                              handleSubmit(
                                { gazMonth: !isRegisteredForGazMonth },
                                "gazMonth",
                              )
                            }
                            isLoading={buttonLoading}
                          />
                        )}
                      </div>
                    </BasicCard>
                    <BasicCard className="app-card-gaz-month-registration-suscribe">
                      <div className="d-flex flex-column ">
                        <img
                          src="/img/pro/logo-rentree-du-chauffage.svg"
                          alt={t<string>(
                            "rentreeDuChauffage.pro.notSubscribed.cardRegistration.rentreeDuChauffage",
                          )}
                          className="mb-2"
                        />
                        <h2 className="text-primary">
                          {t<string>(
                            "rentreeDuChauffage.pro.notSubscribed.cardRegistration.rentreeDuChauffage",
                          )}
                        </h2>
                        <span>
                          {getCardSubtitle(
                            t,
                            "rentreeDuChauffage",
                            rentreeDuChauffageStartDate,
                            rentreeDuChauffageEndDate,
                            today,
                          )}
                        </span>
                        {getGazEvent().typeEvent !== "noEvent" && (
                          <BasicButton
                            text={t<string>(
                              isRegisteredForRentreeDuChauffage
                                ? `${gazEventType}.pro.notSubscribed.cardRegistration.unsuscribe`
                                : `${gazEventType}.pro.notSubscribed.cardRegistration.suscribe`,
                            )}
                            variant="primary mt-3 card-gaz-month-registration-button"
                            onClick={() =>
                              handleSubmit(
                                {
                                  rentreeDuChauffage:
                                    !isRegisteredForRentreeDuChauffage,
                                },
                                "rentreeDuChauffage",
                              )
                            }
                            isLoading={buttonLoading}
                          />
                        )}
                      </div>
                    </BasicCard>
                  </div>
                </Form>
              </>
            </BasicCard>

            <GazEventActionScreen />
          </>
        )}
      </>
    </PageLayout>
  );
};

export default GazEventScreen;
