import configuration from "@utils/Config";
import {
  restCreationHandler,
  restGetUniqueHandler,
  restPatchHandler,
} from "@utils/rest/RestClient";
import { createEffect } from "effector";
import {
  MentionPacHybrideRenewalRequestDto,
  PacHybrideSaveRequestDto,
} from "@state/mention/pachybride/dto/pac.hybride.save.request.dto";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import { PacHybrideUpdateMarkRequestDto } from "@state/mention/pachybride/dto/pac.hybride.update.mark.request.dto";
import { PacHybrideUpdateRgeRequestDto } from "@state/mention/pachybride/dto/pac.hybride.update.rge.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/mentions/pac-hybride`;

export const requestCreateMention = createEffect({
  handler: restCreationHandler<PacHybrideSaveRequestDto, MentionResponseDto>(
    baseUrl,
  ),
});

export const requestUpdateMention = createEffect({
  handler: restPatchHandler<PacHybrideSaveRequestDto, MentionResponseDto>(
    `${baseUrl}/`,
  ),
});

export const requestGetMentionExport = createEffect({
  handler: restGetUniqueHandler<Blob>(`${baseUrl}/export`),
});

export const requestUpdateStatusELearningMention = createEffect({
  handler: restPatchHandler<undefined, void>(`${baseUrl}/status-e-learning/`),
});

export const requestUpdateMarkMention = createEffect({
  handler: restPatchHandler<PacHybrideUpdateMarkRequestDto, void>(
    `${baseUrl}/mark/`,
  ),
});
export const requestUpdateRgeMention = createEffect({
  handler: restPatchHandler<PacHybrideUpdateRgeRequestDto, void>(
    `${baseUrl}/rge/`,
  ),
});

export const requestRenewalMention = createEffect({
  handler: restPatchHandler<MentionPacHybrideRenewalRequestDto, void>(
    `${baseUrl}/renewal/`,
  ),
});
