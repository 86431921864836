import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import HomeHeader from "@components/home/HomeHeader";
import BasicButton from "@components/buttons/BasicButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

const RentreeDuChauffageMaintenanceScreen: FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <PageLayout bodyClassName={"app-home"} noProSideMenu>
      <div>
        <HomeHeader noDescription />
        <div className="content-contained">
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center my-80">
            <div className="my-20 px-4">
              <h2 className="mb-20">
                {t<string>("rentreeDuChauffage.maintenance.title")}
              </h2>
              <div>
                {t<string>("rentreeDuChauffage.maintenance.description1")}
              </div>
              <br />
              <div className="mb-60">
                {t<string>("rentreeDuChauffage.maintenance.description2")}
              </div>
              <BasicButton
                text={t<string>("rentreeDuChauffage.maintenance.goBack")}
                onClick={() => navigate(ROUTES.public.home.generate())}
              />
            </div>
            <div className="my-20 px-4">
              <img
                alt={t<string>("maintenance.img.alt")}
                src={"/img/error404/gazMonth-maintenance.png"}
                className="max-w-lg-100"
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default RentreeDuChauffageMaintenanceScreen;
