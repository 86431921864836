import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import BasicButton from "@components/buttons/BasicButton";

interface ToolsCardProps {
  title: string | JSX.Element;
  icon: JSX.Element;
  description?: string | JSX.Element;
  buttonText?: string;
  buttonHref?: string;
  buttonTarget?: string;
  onClick?: () => any;
  children?: string | JSX.Element;
  className?: string;
}

const ToolsCard: FunctionComponent<ToolsCardProps> = ({
  title,
  icon,
  description,
  buttonText,
  buttonHref,
  buttonTarget,
  onClick,
  children,
  className = "",
}) => {
  return (
    <BasicCard
      className={`app-card-tools d-flex flex-column align-items-center justify-content-center ${className}`}
    >
      <>
        <div className="card-tools-icon">{icon}</div>
        <h3 className="card-tools-title">{title}</h3>
        {description && (
          <div className="card-tools-description flex-grow-1 d-flex align-items-center justify-content-center">
            {description}
          </div>
        )}
        {buttonText && (
          <>
            {buttonHref ? (
              <a href={buttonHref} target={buttonTarget} className="w-100">
                <BasicButton
                  variant="primary"
                  size="lg"
                  text={buttonText}
                  onClick={onClick}
                  className="card-tools-button"
                />
              </a>
            ) : (
              <BasicButton
                variant="primary"
                size="lg"
                text={buttonText}
                onClick={onClick}
                className="card-tools-button"
              />
            )}
          </>
        )}
        {}
        {children}
      </>
    </BasicCard>
  );
};

export default ToolsCard;
