import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import {
  RG_REQUEST_STATUS,
  RgRequestStatus,
} from "@utils/enums/qualifications.rg-requests.status.enum";
import {
  RG_REQUEST_SPECIALITY,
  RGRequestSpeciality,
} from "@utils/enums/qualifications.rg-requests.speciality.enum";
import {
  RG_REQUEST_RAD_REASON,
  RGRequestRadReason,
} from "@utils/enums/qualifications.rg-requests.rad.reason.enum";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";

export const maptoRgRequestType = (type: string): RGRequestType => {
  switch (type) {
    case "ADD":
      return RG_REQUEST_TYPE.ADD;
    case "RAD":
      return RG_REQUEST_TYPE.RAD;
    case "UPDATE":
      return RG_REQUEST_TYPE.UPDATE;
    default:
      return RG_REQUEST_TYPE.ADD;
  }
};

export const maptoRgRequestSpeciality = (
  speciality: string,
): RGRequestSpeciality => {
  switch (speciality) {
    case "INSTALLATION":
      return RG_REQUEST_SPECIALITY.INSTALLATION;
    case "MAINTENANCE":
      return RG_REQUEST_SPECIALITY.MAINTENANCE;
    case "ALL":
      return RG_REQUEST_SPECIALITY.ALL;
    default:
      return RG_REQUEST_SPECIALITY.ALL;
  }
};

export const maptoRgRequestStatus = (status: string): RgRequestStatus => {
  switch (status) {
    case "IN_PROGRESS":
      return RG_REQUEST_STATUS.IN_PROGRESS;
    case "PENDING":
      return RG_REQUEST_STATUS.PENDING;
    case "TO_FIX":
      return RG_REQUEST_STATUS.TO_FIX;
    case "ARCHIVED":
      return RG_REQUEST_STATUS.ARCHIVED;
    default:
      return RG_REQUEST_STATUS.PENDING;
  }
};

export const maptoRgRequestRadReason = (reason: string): RGRequestRadReason => {
  switch (reason) {
    case "LEAVE":
      return RG_REQUEST_RAD_REASON.LEAVE;
    case "NO_VALIDATION":
      return RG_REQUEST_RAD_REASON.NO_VALIDATION;
    case "RETIREMENT":
      return RG_REQUEST_RAD_REASON.RETIREMENT;
    case "OTHER":
      return RG_REQUEST_RAD_REASON.OTHER;
    default:
      return RG_REQUEST_RAD_REASON.OTHER;
  }
};
export const maptoRgRequestMode = (modalMode: ModalMode): string => {
  switch (modalMode) {
    case ModalMode.CREATE:
      return "create";
    case ModalMode.UPDATE:
      return "update";
    case ModalMode.DETAILS:
      return "details";
  }
};
