import React, { useCallback, useEffect, useState } from "react";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import PageLayout from "@components/layouts/PageLayout";
import { PageContent } from "@components/page/PageContent";
import { BreadcrumbsItem } from "@components/breadcrumbs/Breadcrumbs";
import { useStructures } from "@state/structure/Structures";
import { StructureDto } from "@state/structure/dto/structure.dto";
import configuration from "@utils/Config";

export interface Props {
  page: PageInfoResponseDto | undefined;
  isLoading?: boolean;
  isFromArticle?: boolean;
}

export interface Input {
  inputType: string;
  content: string;
  properties?: string;
}

const PageComponent: React.FC<Props> = ({
  page,
  isLoading = false,
  isFromArticle = false,
}) => {
  const structure = useStructures();
  const [breadcrumb, setBreadcrumbs] = useState<BreadcrumbsItem[]>([
    {
      label: page?.shortTitle ?? page?.title,
      link: page?.seoFriendlyTitle,
      type: "PAGE",
    },
  ]);

  const updateBreadcrumbsRecursive = useCallback(
    (category: StructureDto): boolean => {
      const havePage =
        category.children?.some(updateBreadcrumbsRecursive) ||
        category.key === page?.categoryId;

      if (havePage) {
        setBreadcrumbs((breadcrumbs) => [
          {
            label: category.title,
            link: category.seoFriendlyTitle ? category.seoFriendlyTitle : "",
          },
          ...breadcrumbs,
        ]);
      }
      return havePage;
    },
    [page],
  );

  const updateBreadcrumbs = useCallback(() => {
    setBreadcrumbs([
      {
        label: page?.shortTitle ?? page?.title,
        type: "PAGE",
      },
    ]);
    structure.universe.forEach(updateBreadcrumbsRecursive);
  }, [page, structure, updateBreadcrumbsRecursive]);

  useEffect(() => {
    if (page) {
      updateBreadcrumbs();
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <PageLayout
      title={page && page.title}
      subtitle={page && page.summary}
      imageUrl={
        page &&
        page.mainImageResourceId &&
        `${configuration.backendBaseUrl}/public/resources/${page.mainImageResourceId}/file`
      }
      imageAlt={page && page.mainImageAlt}
      imageTitle={page && page.mainImageTitle}
      isLoading={isLoading}
      isFromArticle={isFromArticle}
      page={page}
      noProSideMenu
      breadcrumbs={breadcrumb}
    >
      <>
        {page && page.summary && (
          <div className="content-contained">
            <div className="content-summary">{page.summary}</div>
          </div>
        )}
        <PageContent page={page} />
      </>
    </PageLayout>
  );
};

export { PageComponent };
