import { FC, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useNavigate, useParams } from "react-router-dom";
import { requestGetGazProfessional } from "@state/gazprofessional/GazProfessionalEffects";
import ProfessionalDetails from "@components/gazprofessional/ProfessionalDetails";
import { ROUTES } from "@utils/Routes";
import { GazProfessionalUser } from "@type/auth/auth.types";

const GazProfessionalDetailsScreen: FC = () => {
  const { yoocanId } = useParams<{
    yoocanId: string;
  }>();
  const seoTitle = "pg/".concat(yoocanId ?? "0");

  const [professional, setProfessional] = useState<
    GazProfessionalUser | undefined
  >();

  const navigate = useNavigate();

  useEffect(() => {
    if (yoocanId) {
      void requestGetGazProfessional(yoocanId);
    }
  }, [yoocanId]);

  useEffect(() => {
    return requestGetGazProfessional.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setProfessional(result.data);
      } else {
        navigate(ROUTES.public.error404.generate());
      }
    });
  });

  return (
    <PageLayout seoTitle={seoTitle}>
      <ProfessionalDetails professional={professional} />
    </PageLayout>
  );
};

export default GazProfessionalDetailsScreen;
