import { FunctionComponent } from "react";
import Section from "@components/section/Section";
import { Col, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import { useTranslation } from "react-i18next";

const ProfessionalSection: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Section
      title={t<string>("personalInfo.sectionTitle.social")}
      titleClassName="text-primary px-12"
    >
      <div className="px-12">
        <Row gutter={16}>
          <Col xs={0} lg={1} className="mx-0 px-0" />
          <Col xs={24} lg={23}>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <InputFormField
                  showLabel
                  module="personalInfo"
                  field="website"
                />
              </Col>
              <Col xs={24} lg={12}>
                <InputFormField
                  showLabel
                  module="personalInfo"
                  field="facebook"
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} lg={12}>
                <InputFormField
                  showLabel
                  module="personalInfo"
                  field="twitter"
                />
              </Col>
              <Col xs={24} lg={12}>
                <InputFormField
                  showLabel
                  module="personalInfo"
                  field="linkedin"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Section>
  );
};

export default ProfessionalSection;
