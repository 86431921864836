import i18n from "i18next";
import React, { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import TableButton from "@components/buttons/TableButton";
import { InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import DateFormat from "@utils/DateFormat";
import { Dropdown, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { RG } from "@state/professional/dto/professional.qualifications.response.dto";
import { maptoQualificationRgStatus } from "@utils/rgStatusMapper";
import { getNameFromFullName, Name } from "@utils/name-utils";
import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";

export interface PGColumnParams {
  section: "PG";
  setIsInstallation: React.Dispatch<React.SetStateAction<boolean>>;
  setShowRGRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalType: React.Dispatch<React.SetStateAction<RGRequestType | undefined>>;
  setModalMode: React.Dispatch<React.SetStateAction<ModalMode | undefined>>;
  setNameRG: React.Dispatch<React.SetStateAction<Name>>;
}

export interface OPColumnsParams {
  section: "OP";
}

export const columns = (
  isInstallation: boolean,
  params: PGColumnParams | OPColumnsParams,
): ColumnsType<RG> => [
  {
    title: i18n.t("pro.qualifications.table.headers.name"),
    dataIndex: "name",
    key: "name",
    className: "name",
    ellipsis: {
      showTitle: false,
    },
    sorter: false,
  },
  {
    title: i18n.t("pro.qualifications.table.headers.qualificationDeadline"),
    key: "qualificationDeadline",
    className: "qualificationDeadline",
    ellipsis: {
      showTitle: false,
    },
    width: 160,
    render: (record: RG): ReactNode => {
      const status = maptoQualificationRgStatus(record.status);
      return (
        <div className="d-flex justify-content-end">
          <BasicTag
            label={DateFormat.standardDate(new Date(record.expirationDate))}
            variant={status.variant}
            className="d-inline"
            icon={
              <Tooltip placement="bottomRight" title={i18n.t(status.label)}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          />
        </div>
      );
    },
    sorter: false,
  },
  {
    title: "",
    key: "id",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 65,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: RG): JSX.Element => (
      <>
        {params.section === "PG" && (
          <div className="d-inline-flex">
            <Dropdown
              placement="bottomRight"
              arrow
              trigger={["click"]}
              menu={{
                items: [
                  {
                    label: `${i18n.t<string>(
                      "pro.qualifications.table.actions.rad",
                    )}`,
                    key: "rad",
                    className: "font-weight-bold",
                  },
                  {
                    label: `${i18n.t<string>(
                      "pro.qualifications.table.actions.update",
                    )}`,
                    key: "update",
                    className: "font-weight-bold",
                  },
                ],
                onClick: ({ key }) => {
                  if (key === "rad") {
                    params.setIsInstallation(isInstallation);
                    params.setNameRG(getNameFromFullName(record.name));
                    params.setModalMode(ModalMode.CREATE);
                    params.setModalType(RG_REQUEST_TYPE.RAD);
                    params.setShowRGRequestModal(true);
                  }
                  if (key === "update") {
                    params.setIsInstallation(isInstallation);
                    params.setNameRG(getNameFromFullName(record.name));
                    params.setModalMode(ModalMode.CREATE);
                    params.setModalType(RG_REQUEST_TYPE.UPDATE);
                    params.setShowRGRequestModal(true);
                  }
                },
              }}
              overlayClassName="app-dropdown-qualifications"
            >
              <TableButton
                icon={<MoreOutlined />}
                buttonClassName="btn-transparent w-auto"
              />
            </Dropdown>
          </div>
        )}
      </>
    ),
  },
];
