import { createEvent } from "effector";
import { Pageable } from "@type/pagination/pagination.types";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";

export const setMailLoading = createEvent<boolean>("SET_MAIL_LOADING");

export const setPgMails =
  createEvent<Pageable<GazProfessionalHabitAPlusMailsResponseDto>>(
    "SET_PG_MAILS",
  );

export const setOpMails =
  createEvent<Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>>(
    "SET_OP_MAILS",
  );

export const resetMailProfessionalStore = createEvent(
  "RESET_MAIL_PROFESSIONAL_STORE",
);
