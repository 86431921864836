declare global {
  interface Window {
    /* eslint-disable @typescript-eslint/naming-convention */
    REACT_APP_BACKEND_BASE_URL?: string;
    REACT_APP_FRONTEND_BASE_URL?: string;
    REACT_APP_ENVIRONMENT?: string;
    REACT_APP_GAZ_MONTH?: boolean;
    REACT_APP_GAZ_MONTH_PRO?: boolean;
    REACT_APP_EVENT_GAZ?: "gazMonth" | "rentreeDuChauffage";
    /* eslint-enable @typescript-eslint/naming-convention */
  }
}

interface ConfigInterface {
  backendBaseUrl: string;
  frontendBaseUrl: string;
  environment: string;
  isGazEvent: boolean;
  isGazEventPro: boolean;
  gazEventType: string;
}

const configuration: ConfigInterface = {
  backendBaseUrl:
    process.env.REACT_APP_BACKEND_BASE_URL ??
    window.REACT_APP_BACKEND_BASE_URL ??
    "MISSING_REACT_APP_BACKEND_BASE_URL",
  frontendBaseUrl:
    process.env.REACT_APP_FRONTEND_BASE_URL ??
    window.REACT_APP_FRONTEND_BASE_URL ??
    "MISSING_REACT_APP_FRONTEND_BASE_URL",
  environment:
    process.env.REACT_APP_ENVIRONMENT ??
    window.REACT_APP_ENVIRONMENT ??
    "MISSING_REACT_APP_ENVIRONMENT",
  isGazEvent:
    process.env.REACT_APP_GAZ_MONTH === "true" ||
    window.REACT_APP_GAZ_MONTH ||
    false,
  isGazEventPro:
    process.env.REACT_APP_GAZ_MONTH_PRO === "true" ||
    window.REACT_APP_GAZ_MONTH_PRO ||
    false,
  gazEventType:
    process.env.REACT_APP_EVENT_GAZ || window.REACT_APP_EVENT_GAZ || "gazMonth",
};

export const ENV_PRODUCTION = "production";

export default configuration;
