import { FunctionComponent, useState } from "react";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "antd";
import { FieldData, Store } from "rc-field-form/lib/interface";
import {
  requestGetOrganisationProfessionalInfo,
  requestLoginOrganisationProfessional,
} from "@state/auth/AuthEffects";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import {
  AuthResult,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import { setOrganisationProfessional } from "@state/auth/AuthEvents";
import { useTranslation } from "react-i18next";
import { formItemBlockLayout } from "@utils/Constant";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

interface LoginFormProps {
  proHome?: boolean;
}

interface FormValues {
  login: string;
  password: string;
}

const LoginOrganisationProfessionalForm: FunctionComponent<
  LoginFormProps
> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fields] = useState<FieldData[]>([]);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const InitialValues = {
    login: "",
    password: "",
  };

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    const obj = values as FormValues;

    requestLoginOrganisationProfessional({
      dto: {
        login: obj.login,
        password: obj.password,
      },
    })
      .then((authResult: ParsedResponse<AuthResult>) => {
        if (authResult.data) {
          const login = authResult.data.connectedOP?.login;
          void requestGetOrganisationProfessionalInfo({}).then((authResult) => {
            if (authResult.ok && authResult.data) {
              const result = {
                id: authResult.data.id,
                login: login,
              } as OrganisationProfessionalUser;
              setOrganisationProfessional(result);
              navigate(
                ROUTES.professional.organisationProfessional.tools.generate(),
              );
            }
          });
        } else if (authResult.errorCode === 1000) {
          toastError(
            t<string>(`login.messages.errors.${authResult.errorCode}`),
          );
        }
      })
      .catch(() => {
        toastError(t<string>("login.messages.error"));
      })
      .finally(() => setButtonLoading(false));
  };

  return (
    <Form
      className=""
      onFinish={handleSubmit}
      initialValues={InitialValues}
      fields={fields}
      {...formItemBlockLayout}
    >
      <InputFormField
        module="login.organisationProfessional"
        field="login"
        rules={[
          {
            required: true,
            message: t<string>(
              "login.organisationProfessional.form.fields.login.errors.invalid",
            ),
          },
        ]}
      />
      <InputFormField
        module="login.organisationProfessional"
        type="password"
        field="password"
        required
      />
      <>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <SubmitButton
            module="login.organisationProfessional"
            isSubmitting={buttonLoading}
            label="submit"
          />
        </div>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Link to={ROUTES.professional.auth.login.generate()}>
            <span className="btn btn-link pl-0">
              {t<string>(
                "login.organisationProfessional.form.actions.gazProfessional",
              )}
            </span>
          </Link>
        </div>
      </>
    </Form>
  );
};

export default LoginOrganisationProfessionalForm;
