import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicCard from "@components/cards/BasicCard";
import { Col, Form, Row } from "antd";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import UploadFormField from "@components/inputs/UploadFormField";
import { emailFormatRegex, formItemBlockLayout } from "@utils/Constant";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Store } from "rc-field-form/lib/interface";
import {
  requestCreateMention,
  requestUpdateMention,
} from "@state/mention/pachybride/MentionPacHybrideEffects";
import { setMentionPacHybride } from "@state/auth/AuthEvents";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { mapResourceTofileType } from "@utils/upload-utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export const PacHybrideMentionFormScreen: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const hasRequestedCertificate = !!authentifiedUser?.pacHybride;
  const rge =
    authentifiedUser?.pacHybride?.rge &&
    mapResourceTofileType(authentifiedUser?.pacHybride?.rge);

  const [rgeId, setRgeId] = useState<string | undefined>(
    authentifiedUser?.pacHybride?.rge.id,
  );

  const handleSubmit = (values: Store) => {
    if (rgeId) {
      !authentifiedUser?.pacHybride
        ? void requestCreateMention({
            dto: {
              rgeId,
              firstName: values.firstName as string,
              lastName: values.lastName as string,
              email: values.emailMention as string,
            },
          })
        : void requestUpdateMention({
            id: authentifiedUser?.pacHybride.id,
            dto: {
              rgeId,
              firstName: values.firstName as string,
              lastName: values.lastName as string,
              email: values.emailMention as string,
            },
          });
    }
  };

  useEffect(() => {
    return requestCreateMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
        navigate(ROUTES.professional.pacsHybrides.iframe.generate());
      }
    });
  });

  useEffect(() => {
    return requestUpdateMention.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setMentionPacHybride(result.data);
        navigate(ROUTES.professional.pacsHybrides.mention.generate());
      }
    });
  });

  const InitialValues = {
    email: authentifiedUser?.email,
    phone: authentifiedUser?.phone1,
    siret: authentifiedUser?.siret,
    username: authentifiedUser?.username,
    companyName: authentifiedUser?.companyName,
    firstName: authentifiedUser?.pacHybride?.firstName,
    lastName: authentifiedUser?.pacHybride?.lastName,
    emailMention: authentifiedUser?.pacHybride?.emailMention,
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.mentions.pacHybride.form.title")}
      titleCentered={false}
      subtitle={
        <span>
          {t<string>("pro.mentions.pacHybride.form.subtitle")}{" "}
          <a href="https://lesprofessionnelsdugaz.com/">
            https://lesprofessionnelsdugaz.com/
          </a>
        </span>
      }
    >
      <>
        <BasicCard className="bg-light-blue no-shadow-card mt-2 mb-4">
          <div className="d-flex flex-column flex-sm-row align-items-center">
            <div>
              <img alt="document" src="/img/pro/document.svg" />
            </div>
            <div className="text-primary font-weight-bold mt-4 mt-sm-0 ms-sm-4 ps-sm-3">
              <div>
                {t<string>("pro.mentions.pacHybride.form.card.description")}
              </div>
              <ul>
                <li>{t<string>("pro.mentions.pacHybride.form.card.list1")}</li>
              </ul>
            </div>
          </div>
        </BasicCard>
        <Form
          form={form}
          onFinish={handleSubmit}
          {...formItemBlockLayout}
          initialValues={InitialValues}
        >
          <h2 className="form-title pt-2">
            {t<string>("pro.mentions.pacHybride.form.inscription")}
          </h2>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="firstName"
                required
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="lastName"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="emailMention"
                rules={[
                  {
                    pattern: new RegExp(emailFormatRegex),
                    message: t<string>("common.email.errors.invalid"),
                  },
                ]}
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="siret"
                required
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="companyName"
                required
                readOnly
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="username"
                required
                readOnly
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="email"
                readOnly
              />
            </Col>
            <Col xs={24} sm={12}>
              <InputFormField
                showLabel
                module="pro.mentions.pacHybride"
                field="phone"
                readOnly
              />
            </Col>
          </Row>
          <h2 className="form-title">
            {t<string>("pro.mentions.pacHybride.form.documents")}
          </h2>
          <div className="mb-4">
            <UploadFormField
              name="rge"
              label={t<string>("pro.mentions.pacHybride.form.rge")}
              domain="RGE_PAC_HYBRIDE"
              saveFile
              setFileId={setRgeId}
              types={["application/pdf"]}
              formatLabel="PDF"
              files={rge && [rge]}
            />
          </div>
          <h2 className="form-title">
            {t<string>("pro.mentions.pacHybride.form.convention.title")}
          </h2>
          <div className="px-12">
            <div className="mb-2 font-weight-bold">
              {t<string>("pro.mentions.pacHybride.form.convention.description")}
            </div>
          </div>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label1"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label2"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label3"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label4"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="px-12">
            <CheckboxFormField
              className="mb-2"
              field="label5"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="px-12">
            <CheckboxFormField
              field="label6"
              module="pro.mentions.pacHybride.form.convention"
              required
              checked={hasRequestedCertificate}
            />
          </div>
          <div className="d-flex justify-content-center px-12">
            <SubmitButton
              module="pro.mentions.pacHybride"
              label={hasRequestedCertificate ? "submitInfo" : "submit"}
            />
          </div>
        </Form>
      </>
    </PageLayout>
  );
};
