import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import QualificationDetailItem from "@components/items/QualificationDetailItem";
import { useTranslation } from "react-i18next";
import DateFormat from "@utils/DateFormat";
import {
  IconQualificationDanger,
  IconQualificationStatusInProgress,
  IconQualificationStatusInvalid,
  IconQualificationStatusValid,
} from "../../static/icons";
import { Empty, Table } from "antd";
import {
  columns,
  OPColumnsParams,
  PGColumnParams,
} from "@components/lists/QualificationsColumns";
import {
  GazProfessionalStatus,
  QualificationsItemProps,
} from "@state/professional/dto/professional.qualifications.response.dto";
import { QualificationCardTypeEnum } from "@utils/enums/qualification.card.type.enum";

type QualificationsCardProps = (PGColumnParams | OPColumnsParams) & {
  title: string;
  qualification: QualificationsItemProps;
  className?: string;
  type: QualificationCardTypeEnum;
  isInstallation: boolean;
};

const QualificationsCard: FunctionComponent<QualificationsCardProps> = ({
  title,
  qualification,
  className = "",
  type,
  isInstallation,
  ...columnsParams
}) => {
  const { t } = useTranslation();

  const replacement = (value: string | number) => {
    return value ?? t("pro.qualifications.null");
  };

  const getValidRGs = (): number => {
    return qualification.rgs.filter(
      (rg) => rg.status == "VALID" || rg.status == "EXPIRED_SOON",
    ).length;
  };

  return (
    <BasicCard className={`app-card-qualifications ${className}`}>
      <>
        <div className="card-qualifications-header">
          <h2 className="card-qualifications-title">{title}</h2>
          <div className="card-qualifications-status">
            {qualification.status === GazProfessionalStatus.VALID && (
              <>
                {t("pro.qualifications.status.valid")}
                <IconQualificationStatusValid />
              </>
            )}
            {qualification.status === GazProfessionalStatus.INVALID && (
              <>
                {t("pro.qualifications.status.invalid")}
                <IconQualificationStatusInvalid />
              </>
            )}
            {qualification.status === GazProfessionalStatus.INPROGRESS && (
              <>
                {t("pro.qualifications.status.inProgress")}
                <IconQualificationStatusInProgress />
              </>
            )}
          </div>
        </div>
        <div className="card-qualifications-items">
          <QualificationDetailItem
            title={t<string>("pro.qualifications.vintage")}
            description={replacement(qualification.year)}
            icon={
              qualification.yearValid ||
              qualification.year == null ? undefined : (
                <IconQualificationDanger />
              )
            }
          />
          <QualificationDetailItem
            title={t<string>(type.label)}
            description={
              qualification.qualificationDate == null
                ? t<string>("pro.qualifications.null")
                : DateFormat.standardDate(
                    new Date(qualification.qualificationDate),
                  )
            }
          />
          {qualification.lastAuditDate && (
            <QualificationDetailItem
              title={t<string>("pro.qualifications.lastAuditDate")}
              description={
                qualification.lastAuditDate == null
                  ? t<string>("pro.qualifications.null")
                  : DateFormat.standardDate(
                      new Date(qualification.lastAuditDate),
                    )
              }
            />
          )}
          {qualification.result && (
            <QualificationDetailItem
              title={t<string>("pro.qualifications.result")}
              description={qualification.result}
            />
          )}
        </div>
        <div className="card-qualifications-infos mb-3">
          <h3 className="card-qualifications-infos-text">
            {t("pro.qualifications.authorizedOfficial", {
              count: getValidRGs(),
            })}
          </h3>
        </div>
        <Table
          locale={{
            emptyText: <Empty description={t<string>("common.noData")} />,
          }}
          columns={columns(isInstallation, columnsParams)}
          dataSource={qualification.rgs}
          size="small"
          rowKey={(record) => record.name}
          className="app-contact-pro-table"
          pagination={false}
        />
      </>
    </BasicCard>
  );
};

export default QualificationsCard;
