import { FunctionComponent, Key, ReactNode } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authentifiedStore } from "@state/auth/AuthStore";
import { useStore } from "effector-react";
import { Dropdown, MenuProps, Spin } from "antd";
import { logout } from "@state/auth/AuthEvents";
import { useStructures } from "@state/structure/Structures";
import { UNIVERSES } from "@utils/enums/universe.enum";
import { SelectFormField } from "@components/inputs/SelectFormField";
import BasicButton from "@components/buttons/BasicButton";
import {
  PageStructureDto,
  StructureDto,
} from "@state/structure/dto/structure.dto";
import TagManager from "react-gtm-module";
import { computeUserName, getConnectedHomeRoute } from "@utils/pg-user-utils";
import { ROUTES } from "@utils/Routes";

export const renderTitle = (title: string): string => {
  return title.length > 60 ? title.substring(0, 57).concat("...") : title;
};

interface HeaderProps {
  isLoading?: boolean;
  className?: string;
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: ReactNode,
  key?: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: "group",
  popupClassName?: string,
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    popupClassName,
  } as MenuItem;
}

const Header: FunctionComponent<HeaderProps> = ({
  className = "",
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const structure = useStructures();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    getItem(
      <div className="logout-user d-flex align-items-center">
        <img src="/img/pro/user.svg" alt="User" />
        <div className="logout-user-text overflow-ellipsis">
          {computeUserName(authentifiedContext)}
        </div>
      </div>,
      "user",
    ),
    getItem(
      <div className="d-flex mb-10 justify-content-center">
        <Link
          to={getConnectedHomeRoute(authentifiedContext)}
          className="user-link"
        >
          {t<string>("menu.space")}
        </Link>
      </div>,
      "home",
    ),
    getItem(
      <div className="d-flex mb-30 justify-content-center">
        <Link
          to={
            authentifiedContext.typeUser === "OrganisationProfessionalUser"
              ? ROUTES.professional.auth.loginOp.generate()
              : ROUTES.professional.auth.login.generate()
          }
          className="user-logout"
          onClick={() => logout()}
        >
          {t<string>("login.actions.logout")}
        </Link>
      </div>,
      "logout",
    ),
  ];

  const getItems = (
    categories: StructureDto[],
    pages: StructureDto[],
    category: StructureDto,
  ) => {
    const categoryItems = categories?.length
      ? categories.map((item) =>
          getItem(
            <div
              onClick={() => {
                navigate(ROUTES.public.summary.generate(item.seoFriendlyTitle));
              }}
            >
              <div className="submenu-article-number">{`${
                item.children.filter((p) => p.type === "PAGE").length
              } ${
                item.children.length === 1
                  ? t<string>("header.menus.article")
                  : t<string>("header.menus.articles")
              } ${t<string>("header.menus.category")}`}</div>
              <div className="submenu-title">
                {renderTitle(
                  (item as PageStructureDto).shortTitle
                    ? (item as PageStructureDto).shortTitle
                    : item.title,
                )}
              </div>
            </div>,
            item.key,
            null,
            item?.children?.map((subItem) =>
              subItem.type === "PAGE"
                ? getItem(
                    <Link
                      to={`${item.seoFriendlyTitle}/${
                        (subItem as PageStructureDto).seoFriendlyTitle
                      }`}
                    >
                      {renderTitle(
                        (subItem as PageStructureDto).shortTitle
                          ? (subItem as PageStructureDto).shortTitle
                          : subItem.title,
                      )}
                    </Link>,
                    subItem.key,
                  )
                : null,
            ),
            undefined,
            `header-link-sub-dropdown ${authentifiedContext.universe.value.toLowerCase()}`,
          ),
        )
      : undefined;

    const pageItems = pages?.length
      ? pages.map((item) =>
          getItem(
            <Link
              to={`${category.seoFriendlyTitle}/${
                (item as PageStructureDto).seoFriendlyTitle
              }`}
            >
              {renderTitle(
                (item as PageStructureDto).shortTitle
                  ? (item as PageStructureDto).shortTitle
                  : item.title,
              )}
            </Link>,
            item.key,
          ),
        )
      : undefined;
    if (pageItems && categoryItems) {
      const titleArticleAlone = getItem(
        <div className="menu-articles-no-category">
          {pages.length === 1
            ? t<string>("header.menus.articleAlone")
            : t<string>("header.menus.articlesAlone")}
        </div>,
        "titleArticleAlone",
      );
      return categoryItems.concat(titleArticleAlone).concat(pageItems);
    } else return categoryItems ?? pageItems;
  };

  return (
    <header
      className={`d-flex align-items-center justify-content-center ${className}`}
    >
      <div className="header-select">
        <SelectFormField
          initialValue={authentifiedContext.universe.value}
          module="folders.personalInfos"
          field="universe"
          required={true}
          options={Object.values(UNIVERSES)}
          onSelect={(value) => {
            navigate(`/${UNIVERSES[value].urlValue}`);
          }}
        />
        <div className="header-select-text">
          {t<string>("header.universe.youAre")}
        </div>
      </div>
      <div className="logo-container h-100 d-flex align-items-center">
        <Link to={`/${UNIVERSES[authentifiedContext.universe.value].urlValue}`}>
          <img
            alt={t<string>("header.logo.alt")}
            className="logo px-2"
            src="/logo-header-PG-professional.svg"
          />
        </Link>
      </div>
      <div className="links flex-grow-1 d-flex align-items-center justify-content-end">
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {structure?.universe
              .filter(
                (categorie) =>
                  categorie.type === "CATEGORY" &&
                  categorie?.children.length != 0,
              )
              .map((category) => {
                const categories = category?.children?.filter(
                  (item) => item.type === "CATEGORY",
                );
                const pages = category?.children?.filter(
                  (item) => item.type === "PAGE",
                );
                return (
                  category.type === "CATEGORY" && (
                    <Dropdown
                      key={category.key}
                      placement="bottomLeft"
                      overlayClassName="app-dropdown-header"
                      menu={{
                        items: getItems(categories, pages, category),
                        className: `header-link-dropdown ${authentifiedContext.universe.value.toLowerCase()}`,
                      }}
                    >
                      <Link
                        to={ROUTES.public.summary.generate(
                          category.seoFriendlyTitle,
                        )}
                        key={category.key}
                        className="link px-2"
                      >
                        <span>{category.title}</span>
                      </Link>
                    </Dropdown>
                  )
                );
              })}
            <Link
              to={ROUTES.public.actualites.generate(
                authentifiedContext.universe,
              )}
              className="link"
            >
              <div className="px-2">{t<string>("menu.news")}</div>
            </Link>
            {authentifiedContext.universe.value ===
              UNIVERSES.PROFESSIONAL.value && (
              <Link
                to={ROUTES.professional.contacts.publicForm.generate()}
                className="link"
              >
                <div className="px-2">{t<string>("menu.contact")}</div>
              </Link>
            )}
          </>
        )}
      </div>
      <div className="user d-flex align-items-center ms-xl-4">
        {authentifiedContext?.user &&
          authentifiedContext.universe.value ===
            UNIVERSES.PROFESSIONAL.value && (
            <>
              <Dropdown
                overlayClassName="app-dropdown-header"
                menu={{
                  items,
                  className: "dropdown-logout",
                }}
                placement="bottomRight"
                arrow
                className="cursor-pointer"
              >
                <div className="dropdown-user-container d-flex align-items-center">
                  <div className="dropdown-user-text overflow-ellipsis">
                    {computeUserName(authentifiedContext)}
                  </div>
                  <div className="dropdown-user-img">
                    <img src="/img/pro/user2.svg" alt="User" />
                  </div>
                  <img src="/img/pro/arrow.svg" alt="Arrow" />
                </div>
              </Dropdown>
            </>
          )}
        {authentifiedContext &&
          !authentifiedContext.user &&
          authentifiedContext.universe.value ===
            UNIVERSES.PROFESSIONAL.value && (
            <Link to={ROUTES.professional.auth.login.generate()}>
              <BasicButton
                text={t<string>("header.login")}
                className="btn-login ms-xl-2"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "se_connecter_menu_pro",
                    },
                  });
                }}
              />
            </Link>
          )}
        {authentifiedContext &&
          authentifiedContext.universe.value === UNIVERSES.INDIVIDUAL.value && (
            <Link to={ROUTES.individual.gazProfessionals.search.generate()}>
              <BasicButton
                text={t<string>("header.findPG")}
                className="btn-login ms-xl-2 box-shadow-none"
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "trouver_pg_part",
                    },
                  });
                }}
              />
            </Link>
          )}
      </div>
    </header>
  );
};

export default Header;
