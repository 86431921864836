import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { MoreOutlined } from "@ant-design/icons";

interface DashboardProCardProps {
  title: string | JSX.Element;
  onClick?: () => any;
  onMoreClick?: () => any;
  icon?: JSX.Element;
  description?: string | JSX.Element;
  children?: string | JSX.Element;
  className?: string;
  descriptionClassName?: string;
}

const DashboardProCard: FunctionComponent<DashboardProCardProps> = ({
  title,
  onClick,
  onMoreClick,
  icon,
  description,
  children,
  className = "",
  descriptionClassName = "",
}) => {
  return (
    <BasicCard
      className={`app-card-pro d-flex flex-column ${className}`}
      onClick={onClick}
    >
      <>
        <div className="card-pro-title d-flex align-items-center">
          <div className="flex-grow-1">{title}</div>
          {onMoreClick && (
            <div onClick={onMoreClick} className="ms-2 cursor-pointer">
              <MoreOutlined />
            </div>
          )}
        </div>
        {(icon || description) && (
          <div className="flex-grow-1 d-flex flex-lg-column">
            {icon && (
              <div className="card-pro-icon d-flex align-items-center justify-content-center">
                {icon}
              </div>
            )}
            {description && (
              <div
                className={`card-pro-description flex-grow-1 d-flex align-items-center justify-content-lg-center ${descriptionClassName}`}
              >
                {description}
              </div>
            )}
          </div>
        )}
        {children}
      </>
    </BasicCard>
  );
};

export default DashboardProCard;
