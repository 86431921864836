// eslint-disable-next-line @typescript-eslint/naming-convention
export const PAC_AMOUNT: { [index: string]: AmountType } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_OFFER: {
    value: "NO_OFFER",
    label: "Je ne propose pas d’offre sur PAC hybride ",
  },
  AMOUNT400: {
    value: "AMOUNT400",
    label: "400 €",
  },
  AMOUNT600: {
    value: "AMOUNT600",
    label: "600 €",
  },
  AMOUNT800: {
    value: "AMOUNT800",
    label: "800 €",
  },
  AMOUNT1000: {
    value: "AMOUNT1000",
    label: "1000 €",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OTHER_AMOUNT: {
    value: "OTHER_AMOUNT",
    label: "Autre montant",
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const THPE_AMOUNT: { [index: string]: AmountType } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_OFFER: {
    value: "NO_OFFER",
    label: "Je ne propose pas d’offre sur THPE",
  },
  AMOUNT200: {
    value: "AMOUNT200",
    label: "200 €",
  },
  AMOUNT300: {
    value: "AMOUNT300",
    label: "300 €",
  },
  AMOUNT500: {
    value: "AMOUNT500",
    label: "500 €",
  },
  AMOUNT700: {
    value: "AMOUNT700",
    label: "700 €",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OTHER_AMOUNT: {
    value: "OTHER_AMOUNT",
    label: "Autre montant",
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const POELE_AMOUNT: { [index: string]: AmountType } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_OFFER: {
    value: "NO_OFFER",
    label: "Je ne propose pas d’offre sur Poêle et Insert Gaz",
  },
  AMOUNT200: {
    value: "AMOUNT200",
    label: "200 €",
  },
  AMOUNT300: {
    value: "AMOUNT300",
    label: "300 €",
  },
  AMOUNT500: {
    value: "AMOUNT500",
    label: "500 €",
  },
  AMOUNT700: {
    value: "AMOUNT700",
    label: "700 €",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OTHER_AMOUNT: {
    value: "OTHER_AMOUNT",
    label: "Autre montant",
  },
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const REGULATION_AMOUNT: { [index: string]: AmountType } = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_OFFER: {
    value: "NO_OFFER",
    label: "Je ne propose pas d’offre sur Régulation & émetteurs",
  },
  AMOUNT100: {
    value: "AMOUNT100",
    label: "100 €",
  },
  AMOUNT200: {
    value: "AMOUNT200",
    label: "200 €",
  },
  AMOUNT300: {
    value: "AMOUNT300",
    label: "300 €",
  },
  AMOUNT500: {
    value: "AMOUNT500",
    label: "500 €",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  OTHER_AMOUNT: {
    value: "OTHER_AMOUNT",
    label: "Autre montant",
  },
};

export interface AmountType {
  value:
    | "NO_OFFER"
    | "AMOUNT100"
    | "AMOUNT200"
    | "AMOUNT300"
    | "AMOUNT400"
    | "AMOUNT500"
    | "AMOUNT600"
    | "AMOUNT700"
    | "AMOUNT800"
    | "AMOUNT1000"
    | "OTHER_AMOUNT";
  label: string;
}

export const PAC_HYBRIDE_PARTNER = [
  {
    value: "ARISTON/CHAFFOTEAUX",
    label: "Ariston/Chaffoteaux",
  },
  {
    value: "ATLANTIC",
    label: "Atlantic",
  },
  {
    value: "BOSCH",
    label: "Bosch",
  },
  {
    value: "BROTJE",
    label: "Brötje",
  },
  {
    value: "CHAPPEE",
    label: "Chappée",
  },
  {
    value: "DAIKIN",
    label: "Daikin",
  },
  {
    value: "DE DIETRICH",
    label: "De Dietrich",
  },
  {
    value: "DOMUSA TEKNIK",
    label: "Domusa teknik",
  },
  {
    value: "ELM LEBLANC",
    label: "Elm.leblanc",
  },
  {
    value: "FRISQUET",
    label: "Frisquet",
  },
  {
    value: "INTUIS",
    label: "Intuis",
  },
  {
    value: "OERTLI",
    label: "Oertli",
  },
  {
    value: "PERGE",
    label: "Perge",
  },
  {
    value: "SAUNIER DUVAL",
    label: "Saunier Duval",
  },
  {
    value: "VAILLANT",
    label: "Vaillant",
  },
  {
    value: "VIESSMANN",
    label: "Viessmann",
  },
  {
    value: "WEISHAUPT",
    label: "Weishaupt",
  },
] as OptionType[];
export const THPE_PARTNER = [
  {
    value: "ARISTON/CHAFFOTEAUX",
    label: "Ariston/Chaffoteaux",
  },
  {
    value: "ATLANTIC",
    label: "Atlantic",
  },
  {
    value: "BOSCH",
    label: "Bosch",
  },
  {
    value: "BROTJE",
    label: "Brötje",
  },
  {
    value: "CHAPPEE",
    label: "Chappée",
  },
  {
    value: "DAIKIN",
    label: "Daikin",
  },
  {
    value: "DE DIETRICH",
    label: "De Dietrich",
  },
  {
    value: "DOMUSA TEKNIK",
    label: "Domusa Teknik",
  },
  {
    value: "ELM LEBLANC",
    label: "Elm.leblanc",
  },
  {
    value: "FRISQUET",
    label: "Frisquet",
  },
  {
    value: "INTUIS",
    label: "Intuis",
  },
  {
    value: "OERTLI",
    label: "Oertli",
  },
  {
    value: "PERGE",
    label: "Perge",
  },
  {
    value: "SAUNIER DUVAL",
    label: "Saunier Duval",
  },
  {
    value: "VAILLANT",
    label: "Vaillant",
  },
  {
    value: "VIESSMANN",
    label: "Viessmann",
  },
  {
    value: "WEISHAUPT",
    label: "Weishaupt",
  },
] as OptionType[];

export const POELE_PARTNER = [
  {
    value: "BEST FIRES",
    label: "Best Fires",
  },
  {
    value: "CHAZELLES",
    label: "Chazelles",
  },
  {
    value: "DRU",
    label: "Dru",
  },
  {
    value: "KALFIRE",
    label: "Kalfire",
  },
  {
    value: "RUEGG",
    label: "Ruegg",
  },
] as OptionType[];

export const REGULATION_PARTNER = [
  {
    value: "DELTA DORE",
    label: "Delta Dore",
  },
  {
    value: "THERMOZYKLUS",
    label: "Thermozyklus",
  },
] as OptionType[];

export interface OptionType {
  value: string;
  label: string;
}
