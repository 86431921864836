import { KeyboardEvent, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutoComplete, Spin } from "antd";
import {
  requestGetAddresses,
  requestGetAddressesForLP,
  requestGetAddressReverse,
} from "src/state/address/AddressesEffects";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";

interface Props {
  id?: string;
  setSelectedAddress?: (selectedAddress: AddressFieldsDto) => void;
  disabled?: boolean;
  autoSelect?: boolean;
  geoLocation?: boolean;
  onChange?: (e: any) => void;
  placeholder?: string;
  value?: string;
  initialValue?: string;
  loader?: boolean;
  onEnterPress?: () => void;
  setIsSelectedAddressExists?: (isSelectedAddressExists: boolean) => void;
  isInit?: boolean;
  landingPage?: boolean;
  allowClear?: boolean;
}

const { Option } = AutoComplete;

const AddressVanillaField = ({
  id,
  setSelectedAddress,
  disabled,
  autoSelect = false,
  geoLocation = false,
  onChange,
  value,
  placeholder,
  initialValue,
  loader,
  onEnterPress,
  setIsSelectedAddressExists,
  isInit,
  landingPage,
  allowClear = false,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const [internalValue, setInternalValue] = useState<string>(
    initialValue ?? "",
  );
  const [apiGouvAddresses, setApiGouvAddresses] = useState<AddressFieldsDto[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isIncludes, setIsIncludes] = useState<boolean>(isInit ?? false);

  const [isSelectedAddressExistsInternal, setIsSelectedAddressExistsInternal] =
    useState<boolean>();

  useEffect(() => {
    if (initialValue) {
      landingPage
        ? void requestGetAddressesForLP(initialValue)
        : void requestGetAddresses(initialValue);
    }
  }, [initialValue, landingPage]);

  useEffect(() => {
    setIsIncludes(
      apiGouvAddresses
        .map((address) => address.properties.label)
        .includes(internalValue),
    );
  }, [apiGouvAddresses, internalValue]);

  useEffect(() => {
    if (apiGouvAddresses && internalValue) {
      setIsSelectedAddressExists?.(isIncludes);
      setIsSelectedAddressExistsInternal(isIncludes);
    }
  }, [setIsSelectedAddressExists, isIncludes]);

  useEffect(() => {
    if (value !== undefined && internalValue !== value) {
      setInternalValue(value);
    }
  }, [value, internalValue]);

  const triggerChange = (value?: string) => {
    onChange?.({
      target: {
        value: value,
        name: id,
      },
    });
  };

  useEffect(() => {
    return requestGetAddresses.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setApiGouvAddresses(result.data.features);

        if (result.data.features.length === 1 && autoSelect) {
          setSelectedAddress?.(result.data.features[0]);
          setInternalValue(result.data.features[0].properties.label ?? "");
          triggerChange(result.data.features[0].properties.label);
        }
      }
    });
  });

  useEffect(() => {
    return requestGetAddressesForLP.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setApiGouvAddresses(result.data.features);

        if (result.data.features.length === 1 && autoSelect) {
          setSelectedAddress?.(result.data.features[0]);
          setInternalValue(result.data.features[0].properties.label ?? "");
          triggerChange(result.data.features[0].properties.label);
        }
      }
    });
  });

  useEffect(() => {
    return requestGetAddressReverse.done.watch(({ result }) => {
      if (result.data && result.data.features.length >= 1) {
        setSelectedAddress?.(result.data.features[0]);
        setInternalValue(result.data.features[0].properties.label ?? "");
        triggerChange(result.data.features[0].properties.label);
      }
      setIsLoading(false);
    });
  });

  const handleSearch = (value: string) => {
    if (value.length > 3) {
      landingPage
        ? void requestGetAddressesForLP(value)
        : void requestGetAddresses(value);
    } else {
      setApiGouvAddresses([]);
    }
    setInternalValue(value);
    triggerChange(value);
  };

  const handleOnSelectAroundMe = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition): void => {
          setIsLoading(true);
          void requestGetAddressReverse(
            `lon=${position.coords.longitude}&lat=${position.coords.latitude}`,
          );
        },
      );
      setIsSelectedAddressExists?.(true);
      setIsSelectedAddressExistsInternal(true);
    } else {
      setIsLoading(false);
    }
  };

  const handleOnSelectAddress = (value: string) => {
    if (value) {
      const currentSelectedAddress = apiGouvAddresses.find(
        (address: AddressFieldsDto): boolean => address.properties.id === value,
      );
      if (currentSelectedAddress) {
        setSelectedAddress?.(currentSelectedAddress);
        setInternalValue(currentSelectedAddress.properties.label ?? "");
        triggerChange(currentSelectedAddress.properties.label);
      } else {
        setInternalValue(value);
        triggerChange(value);
      }
    }
  };

  const options = apiGouvAddresses.map((address: AddressFieldsDto) => (
    <Option value={address.properties.id ?? ""} key={address.properties.id}>
      {address.properties.label}
    </Option>
  ));

  const pressEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === "Enter" && isSelectedAddressExistsInternal) {
      onEnterPress?.();
    }
  };

  return (
    <Spin spinning={loader && isLoading} style={{ height: "76px" }}>
      <AutoComplete
        id={id}
        showAction={["focus", "click"]}
        disabled={disabled}
        value={internalValue}
        placeholder={internalValue ? "" : placeholder}
        defaultActiveFirstOption={false}
        filterOption={false}
        onSearch={handleSearch}
        onSelect={handleOnSelectAddress}
        onFocus={() => {
          if (internalValue === "Autour de moi") {
            triggerChange();
          }
        }}
        onChange={handleOnSelectAddress}
        notFoundContent={null}
        onKeyUp={pressEnter}
        allowClear={allowClear}
      >
        {geoLocation && (
          <Option key="geoLocation" value="" className="p-0">
            <div className="py-1 px-2" onClick={handleOnSelectAroundMe}>
              <img src={"/img/icons/target-black.svg"} alt="" />
              {t<string>("search.fields.street.geoLocation")}
            </div>
          </Option>
        )}
        {options}
      </AutoComplete>
    </Spin>
  );
};

export { AddressVanillaField };
