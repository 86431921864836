import React, { ReactElement, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import {
  LatLngBounds,
  latLngBounds,
  LatLngExpression,
  LeafletEvent,
  LeafletMouseEvent,
} from "leaflet";
import { getIcon, MarkerClustor } from "@components/map/MarkerClusterGroup";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

type MarkerType = "current" | "pg" | "pgContactList" | "pgChimney" | "position";

export interface MarkerProps {
  id: string;
  isCurrentLocation?: boolean;
  label?: ReactElement;
  position: LatLngExpression;
  type?: MarkerType;
}

export interface RegionProps {
  id: string;
  name: string;
  coords: LatLngExpression[][] | LatLngExpression[][][];
  bounds?: LatLngBounds;
}

interface MapLeafletProps {
  markers?: MarkerProps[];
  markersContactList?: MarkerProps[];
  markerPosition?: MarkerProps;
  markerCurrentLocationId?: string;
  center?: LatLngExpression;
  scrollWheelZoom?: boolean;
  autoCenter?: boolean;
  className?: string;
  onMapZoomEnd: (e: LeafletEvent) => void;
  onMapDragEnd: (e: LeafletEvent) => void;
  onMouseUp: (e: LeafletMouseEvent) => void;
  onClickMarker?: (id: string) => void;
  updateBounds?: boolean;
  zoom?: number;
}

const MapLeaflet = (props: MapLeafletProps): ReactElement => {
  const {
    markers = [],
    markersContactList = [],
    markerPosition,
    markerCurrentLocationId,
    center = [46.7111, 1.7191],
    className,
    onMapZoomEnd,
    onMapDragEnd,
    onClickMarker,
    updateBounds = false,
    zoom = 7,
  } = props;

  const [bounds, setBounds] = useState<LatLngBounds>();
  const [init, setInit] = useState<boolean>(true);

  const LocationMarker = () => {
    const map = useMapEvents({
      locationfound(e) {
        map.flyTo(e.latlng, map.getZoom());
      },
      zoomend(e) {
        onMapZoomEnd(e);
      },
      dragend(e) {
        onMapDragEnd(e);
      },
    });

    return null;
  };

  const BoundsSetter = () => {
    const map = useMap();
    bounds && map.fitBounds(bounds);
    return null;
  };

  const GestureHandlingSetter = () => {
    /* eslint-disable */
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler("gestureHandling", GestureHandling);
    setInit(false);
    /* eslint-enable */
    return null;
  };

  useEffect(() => {
    const gpMarkers = markers.filter((marker) => !marker.isCurrentLocation);
    setBounds(
      gpMarkers.length > 0
        ? latLngBounds(
            gpMarkers.map(
              (marker: MarkerProps): LatLngExpression => marker.position,
            ),
          ).pad(0.05)
        : undefined,
    );
  }, [markers]);

  const markerClusterComponent = React.useMemo(() => {
    return (
      <MarkerClustor
        markers={markers.filter((marker) => !marker.isCurrentLocation)}
        onClickMarker={onClickMarker}
        markerCurrentLocationId={markerCurrentLocationId}
      />
    );
  }, [markers, onClickMarker, markerCurrentLocationId]);

  return (
    <MapContainer
      id="map-id"
      center={center}
      zoom={zoom}
      className={className}
      scrollWheelZoom={false}
      doubleClickZoom={true}
      minZoom={2}
      preferCanvas={false}
      zoomAnimation={false}
    >
      <>
        {init && <GestureHandlingSetter />}
        <TileLayer
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
          noWrap={true}
        />
        {markerClusterComponent}
        {markersContactList.map((marker: MarkerProps, index) => (
          <Marker
            key={`marker_contact_list_${index.valueOf()}`}
            position={marker.position}
            icon={getIcon(marker.type, marker.id, markerCurrentLocationId)}
            eventHandlers={{
              click: () => {
                onClickMarker?.(marker.id);
              },
            }}
          />
        ))}
        {markerPosition && (
          <Marker
            key={"marker_position"}
            position={markerPosition.position}
            icon={getIcon("position", "marker_position", null)}
          />
        )}
        <LocationMarker />
        {updateBounds && <BoundsSetter />}
      </>
    </MapContainer>
  );
};

export default MapLeaflet;
