import { createStore } from "effector";
import { Pageable } from "@type/pagination/pagination.types";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import { emptySearchResult } from "@utils/Constant";
import {
  resetMailProfessionalStore,
  setMailLoading,
  setOpMails,
  setPgMails,
} from "@state/mailprofessional/MailProfessionalEvents";
import { OrganisationProfessionalHabitAPlusMailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.habitaplus.mails.response.dto";

export interface MailProfessionalContext {
  loading: boolean;
  pgMails: Pageable<GazProfessionalHabitAPlusMailsResponseDto>;
  opMails: Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>;
}

export const mailProfessionalStore = createStore<MailProfessionalContext>({
  loading: true,
  pgMails: emptySearchResult,
  opMails: emptySearchResult,
});

mailProfessionalStore
  .on(
    setPgMails,
    (
      state: MailProfessionalContext,
      payload: Pageable<GazProfessionalHabitAPlusMailsResponseDto>,
    ) => ({
      ...state,
      loading: false,
      pgMails: payload,
    }),
  )
  .on(
    setOpMails,
    (
      state: MailProfessionalContext,
      payload: Pageable<OrganisationProfessionalHabitAPlusMailsResponseDto>,
    ) => ({
      ...state,
      loading: false,
      opMails: payload,
    }),
  )
  .on(setMailLoading, (state: MailProfessionalContext, payload: boolean) => ({
    ...state,
    loading: payload,
  }))
  .on(resetMailProfessionalStore, () => ({
    loading: false,
    pgMails: emptySearchResult,
    opMails: emptySearchResult,
  }));
