import { FC } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import DataGroupModuleCMS from "@components/cms/DataGroupModuleCMS";
import HomeImageSection from "@components/home/HomeImageSection";
import BasicCard from "@components/cards/BasicCard";
import NumberCard from "@components/cards/NumberCard";
import ProHomeHeader from "@components/home/ProHomeHeader";
import News from "@components/block/widget/News";
import { ROUTES } from "@utils/Routes";

const ProHomeScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <PageLayout bodyClassName={"app-home"} noProSideMenu>
      <>
        <ProHomeHeader />
        <div className="my-40 pt-20 pb-1">
          <DataGroupModuleCMS
            title={t<string>("pro.home.numbers.title")}
            content={[
              {
                content: "15000",
                description: t<string>("pro.home.numbers.pgi"),
              },
              {
                content: "1000",
                description: t<string>("pro.home.numbers.pgm"),
              },
              {
                content: "330000",
                description: t<string>("pro.home.numbers.cc2"),
              },
              {
                content: "17000",
                description: t<string>("pro.home.numbers.audits"),
              },
            ]}
            dataClassName="pro-home-numbers"
            className="my-40"
          />
        </div>
        <div className="my-40 pt-20 pb-20">
          <News />
        </div>
        <HomeImageSection
          isProfessional
          text={t<string>("pro.home.join.content")}
          buttonAction={{
            buttonLabel: t<string>("pro.home.join.button.label"),
            url: ROUTES.professional.auth.registration.generate(),
            variant: "white",
            size: "lg",
          }}
          overlayClassName="pro-home-join-card"
          overlayComponent={
            <BasicCard className="shadow-card p-10">
              <>
                <div className="font-weight-bold text-primary mb-10">
                  {t<string>("pro.home.join.card.title")}
                </div>
                <NumberCard
                  icon={<img src="/img/home/contract.svg" alt="demandes" />}
                  title={t<string>("pro.home.join.card.numbers.demands")}
                  number="17"
                />
                <NumberCard
                  icon={<img src="/img/home/eye.svg" alt="views" />}
                  title={t<string>("pro.home.join.card.numbers.views")}
                  subtitle={t<string>("pro.home.join.card.numbers.clics")}
                  number="12"
                />
                <NumberCard
                  icon={<img src="/img/home/bonus.svg" alt="bonus" />}
                  title={t<string>("pro.home.join.card.numbers.bonuses")}
                  number="2"
                />
              </>
            </BasicCard>
          }
        />
        <div className="my-40 pt-20 pb-20" />
      </>
    </PageLayout>
  );
};

export default ProHomeScreen;
