import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import RadioFormField from "@components/inputs/RadioFormField";
import { Form } from "antd";
import SubmitButton from "@components/buttons/SubmitButton";
import { Store } from "rc-field-form/lib/interface";
import { requestWorkflowContact } from "@state/contact/gazprofessional/ContactGazProfessionalEffects";
import {
  ABANDON_REASONS,
  CONTACT_GAZ_PROFESSIONAL_STATUS,
} from "@utils/enums/contact.gazprofessional.status";
import { formItemBlockLayout } from "@utils/Constant";

interface UpdateStatusModalProps {
  contactId: string;
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const UpdateStatusModal: FunctionComponent<UpdateStatusModalProps> = ({
  contactId,
  open,
  onCancel,
  className = "",
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleSubmit = (values: Store) => {
    void requestWorkflowContact({
      id: contactId,
      dto: {
        abandonReason: values.reason as string,
        targetStatus: CONTACT_GAZ_PROFESSIONAL_STATUS.ABANDONED.value,
      },
    });
    onCancel();
  };

  return (
    <BasicModal
      open={open}
      onCancel={onCancel}
      className={`app-modal-update-status ${className}`}
      footer={null}
    >
      <>
        <div className="my-30">
          {t<string>("pro.contact.modals.updateStatus.description")}
        </div>
        <Form form={form} onFinish={handleSubmit} {...formItemBlockLayout}>
          <div className="d-flex justify-content-center">
            <RadioFormField
              required
              field="reason"
              direction="vertical"
              options={Object.values(ABANDON_REASONS)}
            />
          </div>
          <div className="d-flex justify-content-center">
            <SubmitButton
              module="pro.contact.modals.updateStatus"
              isSubmitting={false}
              label="submit"
              className="mb-20"
            />
          </div>
        </Form>
      </>
    </BasicModal>
  );
};

export default UpdateStatusModal;
