import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface AuditWarningModalProps {
  open: boolean;
  onCancel: () => void;
  className?: string;
  status?: string;
}

const AuditWarningModal: FunctionComponent<AuditWarningModalProps> = ({
  open,
  onCancel,
  className = "",
  status,
}) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      open={open}
      onCancel={onCancel}
      className={className}
      title={
        status === "RAD"
          ? t<string>("pro.audit.modals.radie.title")
          : t<string>("pro.audit.modals.controleSystematique.title")
      }
      footer={
        <div className="d-flex justify-content-end">
          <BasicButton
            variant="primary"
            text={t<string>("common.close")}
            onClick={onCancel}
          />
        </div>
      }
    >
      <>
        <div>
          {status === "RAD"
            ? t<string>("pro.audit.modals.radie.description")
            : t<string>("pro.audit.modals.controleSystematique.description")}
        </div>
      </>
    </BasicModal>
  );
};

export default AuditWarningModal;
