import { FC } from "react";
import AccordionsModuleCMS from "@components/cms/AccordionsModuleCMS";
import {
  BlockInput,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";

interface Props {
  block: PageBlockInfoResponseDto;
}

interface Input {
  id: string;
  value: BlockInput[];
}

const CollapseBlock: FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  const sections =
    inputs[0].content && (inputs[0].content as unknown as Input[]);

  /* eslint-disable @typescript-eslint/naming-convention */
  const articleStructuredData = sections && {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": sections.map((section) => {
      return {
        "@type": "Question",
        "name": section.value[0].content as string,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": section.value[1].content as string,
        },
      };
    }),
  };
  /* eslint-enable @typescript-eslint/naming-convention */

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(articleStructuredData)}
      </script>
      <AccordionsModuleCMS
        sections={
          sections
            ? sections.map((section) => {
                return {
                  title: (section.value[0].content as string) || "",
                  content: (section.value[1].content as string) || "",
                };
              })
            : []
        }
        bordered
        borderColor="#A5A5A5"
        headerColor="#ECECEC"
        autoCollapse
        separator
        containerClassName="py-5"
      />
    </>
  );
};

export default CollapseBlock;
