import { FC } from "react";
import {
  BlockInput,
  PageBlockInfoResponseDto,
} from "@state/page/dto/page.info.response.dto";
import { clearHtml } from "@utils/html-utils";

interface Props {
  block: PageBlockInfoResponseDto;
}

const Html: FC<Props> = ({ block }) => {
  const inputs = JSON.parse(block.inputs) as BlockInput[];
  return (
    <>
      {inputs.length > 0 && inputs[0].content && (
        <div
          className="d-flex justify-content-center my-2"
          dangerouslySetInnerHTML={{
            /* eslint-disable-next-line @typescript-eslint/naming-convention */
            __html: clearHtml(inputs[0].content as string),
          }}
        />
      )}
    </>
  );
};

export default Html;
