import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Card, Col, Divider, Row } from "antd";

interface MaintenanceCertificateModalHelp2Props {
  title?: string;
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const MaintenanceCertificateHelp2Modal: FunctionComponent<
  MaintenanceCertificateModalHelp2Props
> = ({ title, open, onCancel, className = "" }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      className={className}
      title={title}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <>
        <Card className="mb-30">
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table1.text1")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table1.value1")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table1.text2")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table1.value2")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table1.text3")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table1.value3")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table1.text4")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table1.value4")}
              </div>
            </Col>
          </Row>
        </Card>
        <Card className="mb-30">
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text1")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value1")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text2")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value2")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text3")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value3")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text4")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value4")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text5")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value5")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text6")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value6")}
              </div>
              <Divider />
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value6b")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text7")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value7")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {parse(
                  t<string>("pro.certificate.form.help.section4.table2.text8"),
                )}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value8")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text9")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value9")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text10")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value10")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text11")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value11")}
              </div>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={12}>
              <div>
                {t<string>("pro.certificate.form.help.section4.table2.text12")}
              </div>
            </Col>
            <Col span={12}>
              <div className="font-weight-bold justify-center">
                {t<string>("pro.certificate.form.help.section4.table2.value12")}
              </div>
            </Col>
          </Row>
        </Card>
      </>
    </BasicModal>
  );
};

export default MaintenanceCertificateHelp2Modal;
