export const RG_REQUEST_RAD_REASON: {
  [index: string]: RGRequestRadReason;
} = {
  LEAVE: {
    value: "LEAVE",
    label: "pro.qualifications.modals.radRG.form.fields.checkbox.leave",
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NO_VALIDATION: {
    value: "NO_VALIDATION",
    label: "pro.qualifications.modals.radRG.form.fields.checkbox.noValidation",
  },
  RETIREMENT: {
    value: "RETIREMENT",
    label: "pro.qualifications.modals.radRG.form.fields.checkbox.retirement",
  },
  OTHER: {
    value: "OTHER",
    label: "pro.qualifications.modals.radRG.form.fields.checkbox.other",
  },
};

export interface RGRequestRadReason {
  value: string;
  label: string;
}
