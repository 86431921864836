/* eslint-disable */
export const ERP: { [index: string]: SearchType } = {
  ERP1: {
    value: "ERP1",
    label: "gazprofessional.erp.erp1",
  },
  ERP2: {
    value: "ERP2",
    label: "gazprofessional.erp.erp2",
  },
  ERP3: {
    value: "ERP3",
    label: "gazprofessional.erp.erp3",
  },
  ERP4: {
    value: "ERP4",
    label: "gazprofessional.erp.erp4",
  },
  ERP5: {
    value: "ERP5",
    label: "gazprofessional.erp.erp5",
  },
};

export interface SearchType {
  value: string;
  label: string;
  shortLabel?: string;
}

/* eslint-enable */
