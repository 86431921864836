import i18n from "i18next";
import TableButton from "@components/buttons/TableButton";
import { MailOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import BasicTag from "@components/tags/BasicTag";
import { IconNotValid, IconValid, IconWaiting } from "../../static/icons";
import { OrganisationProfessionalRGItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.responsable.gaz.item.response.dto";
import { maptoQualificationRgStatus } from "@utils/rgStatusMapper";
import { OrganisationProfessionalGPItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.item.response.dto";

const getIconFromRGStatus = (status: string) => {
  switch (status) {
    case "VALID":
      return <IconValid />;
    case "EXPIRED":
      return <IconNotValid />;
    case "EXPIRED_SOON":
      return <IconWaiting />;
    case "FIRED":
      return <></>;
    default:
      return <></>;
  }
};

export const columns = [
  {
    title: i18n.t("organisationprofessional.rgs.table.headers.code"),
    dataIndex: "code",
    key: "code",
    width: 150,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.rgs.table.headers.name"),
    dataIndex: "name",
    key: "name",
    ellipsis: {
      showTitle: false,
    },
    width: 200,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.rgs.table.headers.companyName"),
    dataIndex: "companyName",
    key: "companyName",
    ellipsis: {
      showTitle: false,
    },
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("organisationprofessional.rgs.table.headers.qualifications"),
    key: "qualifications",
    width: 201,
    render: (record: OrganisationProfessionalRGItemResponseDto): ReactNode => {
      const iStatus = maptoQualificationRgStatus(record.pgiStatus);
      const mStatus = maptoQualificationRgStatus(record.pgmStatus);
      return record.pgiStatus || record.pgmStatus ? (
        <div className="app-rg-tags">
          <div className="rg-tag">
            {record.pgiStatus && (
              <BasicTag
                label={i18n.t("organisationprofessional.rgs.table.rgi")}
                icon={getIconFromRGStatus(record.pgiStatus)}
                variant={iStatus.variant}
              />
            )}
          </div>
          <div className="rg-tag">
            {record.pgmStatus && (
              <BasicTag
                label={i18n.t("organisationprofessional.rgs.table.rgm")}
                icon={getIconFromRGStatus(record.pgmStatus)}
                variant={mStatus.variant}
              />
            )}
          </div>
        </div>
      ) : (
        ""
      );
    },
  },
  {
    title: i18n.t("organisationprofessional.rgs.table.headers.contact"),
    key: "email",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 110,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (
      record: OrganisationProfessionalGPItemResponseDto,
    ): JSX.Element => (
      <div className="w-100 d-inline-flex justify-content-center">
        <TableButton
          tooltipTitle={i18n.t(
            "organisationprofessional.rgs.table.tooltip.contact",
          )}
          icon={<MailOutlined />}
          buttonClassName="btn-transparent w-auto"
          onClick={() => (window.location.href = `mailto:${record.email}`)}
        />
      </div>
    ),
  },
];
