import i18n from "i18next";
import { ReactNode } from "react";
import { WORKSITE_STATUS } from "@utils/enums/worksite.enum";

export const columns = [
  {
    title: i18n.t<string>("worksite.headers.name"),
    dataIndex: "name",
    key: "name",
    className: "name",
    ellipsis: {
      showTitle: false,
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("worksite.headers.address"),
    dataIndex: "address",
    key: "address",
    className: "address",
    ellipsis: {
      showTitle: false,
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("worksite.headers.city"),
    dataIndex: "city",
    key: "city",
    className: "city",
    ellipsis: {
      showTitle: false,
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("worksite.headers.typePipe"),
    dataIndex: "typePipe",
    key: "typePipe",
    className: "typePipe",
    ellipsis: {
      showTitle: false,
    },
    render: (typePipe: string): ReactNode => (
      <div className="status-container">
        {i18n.t<string>(`worksite.form.radioGroup.typePipe.${typePipe}`)}
      </div>
    ),
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("worksite.headers.status"),
    dataIndex: "worksiteStatus",
    key: "worksiteStatus",
    className: "worksiteStatus",
    render: (status: string): ReactNode => (
      <div className="status-container">
        {i18n.t<string>(`worksite.status.${WORKSITE_STATUS[status].value}`)}
      </div>
    ),
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t<string>("worksite.headers.date"),
    dataIndex: "createdAt",
    key: "createdAt",
    className: "createdAt",
    ellipsis: {
      showTitle: false,
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
];
