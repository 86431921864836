export function extractStringFrom(
  value: string | string[] | null | undefined,
): string | undefined {
  if (value == null) {
    return undefined;
  }

  if (value instanceof Array) {
    return value[0];
  }

  return value;
}

export function buildQuery(query: string | undefined | null): string {
  if (!query || query.length === 0) {
    return "";
  }
  return `&query=${query}`;
}

export function extractNumberFrom(
  value: string | string[] | null | undefined,
): number | undefined {
  if (!value) {
    return undefined;
  }

  if (value instanceof Array) {
    return parseInt(value[0]);
  }

  return parseInt(value) || undefined;
}
