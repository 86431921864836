import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import ImageGroupModuleCMS from "@components/cms/ImageGroupModuleCMS";

interface IndividualGazEventCommitmentsProps {
  className?: string;
}

const IndividualGazEventCommitments: FunctionComponent<
  IndividualGazEventCommitmentsProps
> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={`app-individual-gaz-month-commitments ${className}`}>
      <ImageGroupModuleCMS
        title={t<string>("gazMonth.individual.home.commitments.title")}
        titleClassName="text-center text-sm-start"
        images={[
          {
            src: "/img/home/local.svg",
            title: " ",
            description: t<string>(
              "gazMonth.individual.home.commitments.description1",
            ),
          },
          {
            src: "/img/home/quality.svg",
            title: " ",
            description: t<string>(
              "gazMonth.individual.home.commitments.description2",
            ),
          },
          {
            src: "/img/home/audit.svg",
            title: " ",
            description: t<string>(
              "gazMonth.individual.home.commitments.description3",
            ),
          },
          {
            src: "/img/gazMonth/offer.svg",
            title: " ",
            description: t<string>(
              "gazMonth.individual.home.commitments.description4",
            ),
          },
        ]}
      />
    </div>
  );
};

export default IndividualGazEventCommitments;
