/* eslint-disable */
import React from "react";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";
import {Marker} from "react-leaflet";
import {Icon} from "leaflet";

export const getIcon = (type, id, markerCurrentLocationId) => {
    if(markerCurrentLocationId && id === markerCurrentLocationId) {
        return type === "pgChimney" ? pgChimneySelected : pgContactListIcon;
    }
    switch (type) {
        case "current":
            return pgContactListIcon;
        case "pg":
            return pgIcon;
        case "pgContactList":
            return pgContactListIcon;
        case "pgChimney":
            return pgChimney;
        case "pgChimneySelected":
            return pgChimneySelected;
        case "position":
            return position;
        default:
            return currentIcon;
    }
};

const baseIcon = {
    iconSize: [41, 41],
    iconAnchor: [41, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
};

const currentIcon = new Icon({
    ...baseIcon,
    iconUrl: "/img/leaflet/marker-current.svg",
});

const pgIcon = new Icon({
    className: "marker-pg",
    ...baseIcon,
    iconUrl: "/img/leaflet/marker-pg.svg",
});

const pgContactListIcon = new Icon({
    className: "marker-pg-contact-list",
    ...baseIcon,
    iconUrl: "/img/leaflet/marker-pg-contact-list.svg",
});

const pgChimney = new Icon({
    className: "marker-pg",
    ...baseIcon,
    iconUrl: "/img/leaflet/marker-pg-chinmey.svg"
})

const pgChimneySelected = new Icon({
    className: "marker-pg",
    ...baseIcon,
    iconUrl: "/img/leaflet/marker-pg-chinmey-selected.svg"
})

const position = new Icon({
    ...baseIcon,
    iconSize: [50,50],
    iconUrl: "/img/leaflet/marker-position.svg",
})


export const MarkerClustor = ({markers, onClickMarker, markerCurrentLocationId}) => {
    const markerComponents = React.useMemo(() => {
        return markers.map((marker) => {
            return (
                <Marker
                    key={marker.id}
                    position={marker.position}
                    icon={getIcon(marker.type, marker.id, markerCurrentLocationId)}
                    eventHandlers={{
                        click: () => {
                            onClickMarker?.(marker.id);
                        },
                    }}
                />
        );
        });
    }, [markers, onClickMarker]);

    return (<MarkerClusterGroup chunkedLoading={true} maxClusterRadius={100}>
            {markerComponents}
</MarkerClusterGroup>
    )
}
