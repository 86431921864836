import BasicModal from "@components/modals/BasicModal";
import { FC, useEffect, useState } from "react";
import BaseForm from "@components/forms/BaseForm";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form } from "antd";
import { useStore } from "effector-react";
import { contactGazProfessionalStore } from "@state/contact/gazprofessional/ContactGazProfessionalStore";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import { Store } from "rc-field-form/lib/interface";
import { requestCreateCampaignContact } from "@state/campaign/CampaignEffects";
import { ROUTES } from "@utils/Routes";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import {
  CAMPAIGN_CURRENT_ENERGIES,
  CAMPAIGN_EQUIPMENT_TYPES,
  CAMPAIGN_INTERVENTION_TYPES,
} from "@utils/enums/campaign.enum";
import { emailFormatRegex, phoneNumberFormatRegex } from "@utils/Constant";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import parse from "html-react-parser";

interface RentreeDuChauffageTempsFortContactModalProps {
  open: boolean;
  onClose?: () => void;
  className?: string;
}

const RentreeDuChauffageTempsFortContactModal: FC<
  RentreeDuChauffageTempsFortContactModalProps
> = ({ className = "", open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const contactInfo = useStore(contactGazProfessionalStore);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressFieldsDto>();

  const handleSubmit = (values: Store): void => {
    const saveRequest = {
      firstname: values.firstName as string,
      lastname: values.lastName as string,
      email: values.email as string,
      phoneNumber: (values.phone as string).replace(/\s/g, ""),
      houseNumber: selectedAddress?.properties.housenumber as string,
      street: (selectedAddress?.properties.street ??
        selectedAddress?.properties.name) as string,
      city: selectedAddress?.properties.city as string,
      zipCode: selectedAddress?.properties.postcode as string,
      intervention: values.interventionType as string,
      energy: values?.currentEnergy as string,
      equipments: values?.equipmentTypes as string[],
      message: values.message as string,
      inTiers: values.callbackGrdf as boolean,
      medium: contactInfo.medium,
      url: contactInfo.url ?? undefined,
      lp: "LP TEMPS FORT",
      source: contactInfo.source ?? "autre",
      campaign: contactInfo.campaign,
      qwpId: new URLSearchParams(location.search).get("qwp_id") ?? undefined,
    };
    void requestCreateCampaignContact({
      dto: saveRequest,
    });
    setIsLoading(true);
  };

  useEffect(() => {
    return requestCreateCampaignContact.done.watch(({ result }) => {
      if (open) {
        if (result.ok) {
          navigate(
            ROUTES.individual.rentreeChauffage.tempsFortV1.confirmation.generate(),
          );
          toastSuccess(
            t<string>(
              "rentreeDuChauffage.individual.header.form.form.messages.success",
            ),
          );
        } else if (
          (result.responseCode === 400 &&
            (result?.fieldErrors?.street ||
              result?.fieldErrors?.city ||
              result?.fieldErrors?.zipCode)) ||
          result.responseCode === 500
        ) {
          toastError(
            t<string>(
              "rentreeDuChauffage.individual.header.form.form.messages.errorAddress",
            ),
          );
        } else if (result.responseCode === 400) {
          toastError(
            t<string>(
              "rentreeDuChauffage.individual.header.form.form.messages.invalid",
            ),
          );
        } else {
          toastError(
            t<string>(
              "rentreeDuChauffage.individual.header.form.form.messages.error",
            ),
          );
        }
      }
      setIsLoading(false);
    });
  });

  const FORM = {
    main: {
      key: "main",
      module: "rentreeDuChauffage.individual.header.form",
      title: "form.title",
      fields: [
        [
          {
            name: "interventionType",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_INTERVENTION_TYPES),
          },
          {
            name: "currentEnergy",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CAMPAIGN_CURRENT_ENERGIES),
            size: 12,
          },
          {
            name: "equipmentTypes",
            type: FORMS_FIELD_TYPE.MULTISELECT,
            required: true,
            options: Object.values(CAMPAIGN_EQUIPMENT_TYPES),
            size: 12,
          },
          {
            name: "lastName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "firstName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            pattern: new RegExp(emailFormatRegex),
            htmlInputType: "email",
          },
          {
            name: "phone",
            type: FORMS_FIELD_TYPE.CUSTOM,
            custom: (
              <MaskInputFormField
                emptyErrorMessage={t<string>(
                  "rentreeDuChauffage.individual.header.form.form.fields.phone.errors.required",
                )}
                type="tel"
                required
                mask="## ## ## ## ##"
                showLabel
                module="rentreeDuChauffage.individual.header.form"
                field="phone"
                rules={[
                  {
                    pattern: new RegExp(phoneNumberFormatRegex),
                    message: t<string>("forms.errors.format"),
                  },
                  {
                    min: 14,
                    message: t<string>("forms.errors.length", { size: "10" }),
                  },
                ]}
              />
            ),
            size: 12,
          },
          {
            name: "address",
            type: FORMS_FIELD_TYPE.CUSTOM,
            size: 24,
            required: true,
            custom: (
              <AddressFields
                module="rentreeDuChauffage.individual.header.form"
                field="address"
                showLabel
                setSelectedAddress={setSelectedAddress}
                emptyErrorMessage={t<string>(
                  "rentreeDuChauffage.individual.header.form.form.fields.address.errors.required",
                )}
                landingPage
              />
            ),
          },
          {
            name: "message",
            type: FORMS_FIELD_TYPE.TEXTAREA,
            size: 24,
            retractable: true,
            maxLength: 3000,
          },
          {
            name: "callbackGrdf",
            type: FORMS_FIELD_TYPE.CHECKBOX,
            required: true,
            size: 24,
            parsed: true,
          },
        ],
      ],
    },
  };

  return (
    <BasicModal
      open={open}
      onCancel={() => {
        onClose && onClose();
      }}
      footer={null}
      className={`app-rentree-du-chauffage-contact-modal ${className}`}
    >
      <div className="contact-modal-form">
        <h2 className="contact-modal-form-title">
          {t("rentreeDuChauffage.individual.header.form.title")}
        </h2>
        <div className="contact-modal-form-required">
          {t<string>("common.field.required")}
        </div>
        <BaseForm
          module="rentreeDuChauffage.individual.header.form"
          form={form}
          editMode={true}
          formConfiguration={FORM}
          hasBackButton={false}
          hasConfirmPopup={false}
          customOnSubmitCallback={handleSubmit}
          loading={isLoading}
          buttonSize="md"
          buttonCentered
          buttonVariant="secondary-green-outline"
          buttonClassName="contact-modal-form-link"
        />
        <div className="contact-modal-form-subtitle">
          {parse(t("rentreeDuChauffage.individual.header.form.subtitle"))}
        </div>
      </div>
    </BasicModal>
  );
};

export default RentreeDuChauffageTempsFortContactModal;
