import { FunctionComponent, useEffect, useState } from "react";
import parse from "html-react-parser";
import { ButtonAction } from "@type/cms/cms.types";
import ButtonModuleCMS from "@components/cms/ButtonModuleCMS";

interface VideoCarouselProps {
  videoUrls: string[];
  posterUrl?: string;
  sideContent?: string;
  sideContentVariant?: string;
  alignImgLeft?: boolean;
  className?: string;
  backgroundColor?: string;
  fillContainer?: boolean;
  verticalAlign?: "center" | "start" | "end";
  buttonAction?: ButtonAction;
  btnClassName?: string;
  videoClassName?: string;
  carded?: boolean;
  cardColor?: string;
  buttonAlignment?: "start" | "center" | "end";
  autoPlay?: boolean;
  controls?: boolean;
}

const VideoCarousel: FunctionComponent<VideoCarouselProps> = ({
  videoUrls = [],
  posterUrl = undefined,
  sideContent = undefined,
  verticalAlign = "center",
  fillContainer = false,
  alignImgLeft = false,
  className = "",
  backgroundColor = "transparent",
  buttonAction = undefined,
  btnClassName = "",
  videoClassName = "",
  carded = false,
  cardColor = "white",
  autoPlay = true,
  controls = true,
  buttonAlignment = "center",
}) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0);

  useEffect(() => {
    if (currentVideoIndex === undefined) {
      if (videoUrls.length > 0) setCurrentVideoIndex(0);
    }
  }, [currentVideoIndex, videoUrls.length]);

  const handleVideoEnded = () => {
    if (currentVideoIndex !== videoUrls.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else {
      setCurrentVideoIndex(0);
    }
  };

  return (
    <div
      className={`app-video-carousel ${className} ${
        carded ? "video-carousel-carded" : ""
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div
        className={`content-contained video-carousel-content align-items-${verticalAlign} ${
          alignImgLeft ? "flex-row-reverse" : "flex-row"
        } flex-wrap 
      ${fillContainer ? "p-0" : ""} ${
          carded && cardColor === "white" ? "bg-white" : ""
        }`}
        style={{ backgroundColor: carded ? cardColor : "transparent" }}
      >
        {(sideContent || buttonAction) && (
          <div
            className={
              "video-carousel-sidecontent flex-grow-1 text-left flex-column"
            }
          >
            {sideContent && (
              <div className={"mb-2 mx-0 mx-md-3"}>{parse(sideContent)}</div>
            )}
            {buttonAction && (
              <div className={`d-flex justify-content-${buttonAlignment}`}>
                <ButtonModuleCMS
                  text={buttonAction.buttonLabel}
                  url={buttonAction.url}
                  variant={buttonAction.variant}
                  size={buttonAction.size}
                  btnClassName={btnClassName}
                />
              </div>
            )}
          </div>
        )}
        {videoUrls[currentVideoIndex] && (
          <div
            className={`${videoClassName} mt-3 mt-md-0 ${
              fillContainer ? "video-carousel-img-cover" : ""
            }`}
          >
            <video
              src={videoUrls[currentVideoIndex]}
              autoPlay={autoPlay}
              controls={controls}
              onEnded={handleVideoEnded}
              poster={posterUrl}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCarousel;
