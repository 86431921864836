import parse from "html-react-parser";
import { TFunction } from "i18next";
import {
  ACTION_TYPE,
  ActionType,
} from "@utils/enums/gaz.month.action.type.enum";
import {
  PAC_AMOUNT,
  PAC_HYBRIDE_PARTNER,
  POELE_AMOUNT,
  POELE_PARTNER,
  REGULATION_AMOUNT,
  REGULATION_PARTNER,
  THPE_AMOUNT,
  THPE_PARTNER,
} from "@utils/enums/gaz.month.action.amount.enum";

export const getCardSubtitle = (
  t: TFunction,
  typeEvent: string,
  beginEvent: Date,
  endEvent: Date,
  today: Date,
) => {
  if (today < beginEvent) {
    return parse(
      t<string>(`${typeEvent}.pro.notSubscribed.cardRegistration.notStart`),
    );
  } else if (today >= beginEvent && today <= endEvent) {
    return parse(
      t<string>(`${typeEvent}.pro.notSubscribed.cardRegistration.inProgress`),
    );
  } else {
    return parse(
      t<string>(`${typeEvent}.pro.notSubscribed.cardRegistration.finish`),
    );
  }
};
export const today = new Date();
export const gazMonthStartDate = new Date(today.getFullYear(), 3, 1, 0, 0);
export const gazMonthEndDate = new Date(today.getFullYear(), 3, 30, 23, 59);
export const rentreeDuChauffageStartDate = new Date(
  today.getFullYear(),
  9,
  1,
  0,
  0,
);
export const rentreeDuChauffageEndDate = new Date(
  today.getFullYear(),
  10,
  30,
  23,
  59,
);

export const getGazEvent = () => {
  if (today <= gazMonthEndDate) {
    return {
      typeEvent: "gazMonth",
      start: gazMonthStartDate,
      end: gazMonthEndDate,
    };
  } else if (today > gazMonthEndDate && today <= rentreeDuChauffageEndDate) {
    return {
      typeEvent: "rentreeDuChauffage",
      start: rentreeDuChauffageStartDate,
      end: rentreeDuChauffageEndDate,
    };
  } else return { typeEvent: "noEvent", start: today, end: today };
};

export const getAmountOption = (action: ActionType) => {
  switch (action) {
    case ACTION_TYPE.PAC:
      return PAC_AMOUNT;
    case ACTION_TYPE.THPE:
      return THPE_AMOUNT;
    case ACTION_TYPE.POELE:
      return POELE_AMOUNT;
    case ACTION_TYPE.REGULATION:
      return REGULATION_AMOUNT;
    default:
      return PAC_AMOUNT;
  }
};

export const getOptionPartner = (action: ActionType) => {
  switch (action) {
    case ACTION_TYPE.PAC:
      return PAC_HYBRIDE_PARTNER;
    case ACTION_TYPE.THPE:
      return THPE_PARTNER;
    case ACTION_TYPE.POELE:
      return POELE_PARTNER;
    case ACTION_TYPE.REGULATION:
      return REGULATION_PARTNER;
    default:
      return PAC_HYBRIDE_PARTNER;
  }
};
