import { ChangeEvent, FormEvent, FunctionComponent } from "react";
import { Form, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { formUtils } from "@utils/form-utils";
import { PatternFormat } from "react-number-format";

interface PropsType {
  mask: string;
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: boolean;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onPressEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  type?: "text" | "tel" | "password" | undefined;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string };
  disabled?: boolean;
}

const MaskInputFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    mask,
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    maxLength,
    type,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  const i18nField = formUtils.geti18nFieldFromField(field);

  const key = `field_${String(field)}`.replace(",", "_");

  return (
    <>
      <Form.Item
        name={
          typeof field === "object" ? field : `${fieldPrefix}${String(field)}`
        }
        key={key}
        label={
          readOnly || showLabel ? (
            <span className={labelClassName || ""}>
              {t<string>(`${module}.form.fields.${i18nField}.label`)}&nbsp;
              {labelTooltip && (
                <Tooltip
                  className="ml-1"
                  title={t<string>(
                    `${module}.form.fields.${i18nField}.tooltip`,
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
            </span>
          ) : (
            ""
          )
        }
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage
              ? emptyErrorMessage
              : t<string>("forms.errors.mandatory"),
            whitespace: isRequired,
          },
          ...rules,
        ]}
        className={className}
        initialValue={initialValue}
      >
        <PatternFormat
          format={mask}
          className={
            readOnly
              ? "readonly-form-field ant-input"
              : `ant-input ${fieldClassName ?? ""}`
          }
          placeholder={
            placeholder
              ? placeholder
              : t<string>(`${module}.form.fields.${i18nField}.placeholder`)
          }
          id={key}
          onChange={onChange}
          maxLength={maxLength}
          type={type ? type : "text"}
          onInput={onInput}
          disabled={readOnly || disabled}
        />
      </Form.Item>
    </>
  );
};

export default MaskInputFormField;
