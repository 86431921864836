import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import config from "@utils/Config";

interface LandingPageConditionsProps {
  className?: string;
  landingPageType: string;
}

const LandingPageConditions: FunctionComponent<LandingPageConditionsProps> = ({
  className = "",
  landingPageType,
}) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div className={`app-rentree-du-chauffage-conditions ${className}`}>
      <div className="content-contained">
        <div className="rentree-du-chauffage-conditions-logos">
          <img
            alt={t<string>("gazMonth.individual.home.conditions.pg.alt")}
            src="/logo-header-PG-professional.svg"
          />
        </div>
        <div className="rentree-du-chauffage-conditions-text">
          <div>
            {parse(
              t(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.conditions.text1`,
              ),
            )}
          </div>
          <div>
            {parse(
              t(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.conditions.text2`,
              ),
            )}
          </div>
          <div>
            {parse(
              t(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.conditions.text3`,
              ),
            )}
          </div>
          {landingPageType === "tempsFort" && (
            <div>
              {parse(
                t(
                  `landingPage.${landingPageType}${
                    isTempsFort ? ".".concat(gazEventType) : ""
                  }.conditions.text4`,
                ),
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingPageConditions;
