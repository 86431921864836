export const RG_REQUEST_SPECIALITY: {
  [index: string]: RGRequestSpeciality;
} = {
  INSTALLATION: {
    value: "INSTALLATION",
    label: "pro.qualifications.requests.speciality.installation",
  },
  MAINTENANCE: {
    value: "MAINTENANCE",
    label: "pro.qualifications.requests.speciality.maintenance",
  },
  ALL: {
    value: "ALL",
    label: "pro.qualifications.requests.speciality.all",
  },
};

export interface RGRequestSpeciality {
  value: string;
  label: string;
}
