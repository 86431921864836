import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "@components/buttons/SubmitButton";
import { RGRequestType } from "@utils/enums/qualifications.rg-requests.type.enum";
import { getButtonLabel, getTypeLabel } from "@utils/rgrequest-module-utils";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import BasicButton from "@components/buttons/BasicButton";
import parse from "html-react-parser";

interface ModuleSubmissionFooterProps {
  isOPSection?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
  type: RGRequestType;
  modalMode: ModalMode;
  isLoading?: boolean;
}

const ModuleSubmissionFooter: FunctionComponent<
  ModuleSubmissionFooterProps
> = ({ isOPSection = false, onClick, type, modalMode, isLoading }) => {
  const { t } = useTranslation();
  const typeLabel = getTypeLabel(type);
  const labelButton = getButtonLabel(isOPSection, modalMode);
  const baseCondition = isOPSection
    ? "organisationprofessional.requests"
    : "pro.qualifications";

  return (
    <>
      <hr />
      <div className="rg-request-conditions">
        {parse(t(`${baseCondition}.modals.${typeLabel}.conditions`))}
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-end">
          <SubmitButton
            module={`${baseCondition}.modals.${typeLabel}`}
            label={labelButton}
            className="mt-20"
            variant="primary-outline"
            isSubmitting={isLoading}
          />
        </div>

        {isOPSection && (
          <BasicButton
            className="mt-20 mr-4 ms-4"
            text={t<string>(
              `organisationprofessional.requests.modals.${typeLabel}.validate`,
            )}
            onClick={onClick}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default ModuleSubmissionFooter;
