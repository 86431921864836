import { FunctionComponent } from "react";
import ImageModuleCMS from "@components/cms/ImageModuleCMS";
import { ButtonAction } from "@type/cms/cms.types";

interface HomeImageSectionProps {
  text: string;
  buttonAction: ButtonAction;
  isProfessional?: boolean;
  overlayComponent?: JSX.Element;
  overlayClassName?: string;
}

const HomeImageSection: FunctionComponent<HomeImageSectionProps> = ({
  text = "",
  buttonAction = undefined,
  isProfessional = false,
  overlayComponent = undefined,
  overlayClassName = "home-image-section-overlay",
}) => {
  return (
    <div
      className={`app-home-image-section ${
        isProfessional ? "professional" : ""
      }`}
      style={{
        backgroundImage: isProfessional
          ? "url('/img/home/hand-shake.svg')"
          : "url('/img/home/wrench.svg')",
      }}
    >
      <ImageModuleCMS
        src={isProfessional ? "/img/home/pro-promo.png" : "/img/home/promo.png"}
        alt={isProfessional ? "Pro-promo" : "promo"}
        sideContent={text}
        buttonAction={{
          buttonLabel: buttonAction?.buttonLabel ?? "",
          url: buttonAction?.url ?? "",
          variant: buttonAction?.variant ?? undefined,
          size: buttonAction?.size ? buttonAction?.size : undefined,
        }}
        fillContainer
      />
      {overlayComponent && (
        <div className={overlayClassName}>{overlayComponent}</div>
      )}
    </div>
  );
};

export default HomeImageSection;
