import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import {
  QualificationStatus,
  QualificationValidity,
} from "@utils/enums/qualifications.status.enum";
import { DateTime } from "luxon";

interface QualificationsRenewalModalProps {
  visible: boolean;
  onCancel: () => void;
  qualificationValidity: QualificationValidity;
}

const QualificationsRenewalModal: FunctionComponent<
  QualificationsRenewalModalProps
> = ({ visible, qualificationValidity, onCancel }) => {
  const { t } = useTranslation();

  const computeType = (status: QualificationStatus[]): string => {
    if (
      status.includes(qualificationValidity.maintenance) &&
      status.includes(qualificationValidity.installation)
    ) {
      return "PGI et PGM";
    } else if (status.includes(qualificationValidity.installation)) {
      return "PGI";
    } else if (status.includes(qualificationValidity.maintenance)) {
      return "PGM";
    }
    return "";
  };

  const computeDays = (): number => {
    const now = DateTime.now();

    let targetDate = DateTime.now().set({ month: 4, day: 30 });
    if (targetDate < now) {
      targetDate = targetDate.plus({ year: 1 });
    }

    return Math.round(targetDate.diff(now, "days").toObject().days ?? 0);
  };

  const isExpiredSoon =
    qualificationValidity.installation === QualificationStatus.expiredSoon ||
    qualificationValidity.maintenance === QualificationStatus.expiredSoon;
  const isInvalid =
    qualificationValidity.installation === QualificationStatus.invalid ||
    qualificationValidity.maintenance === QualificationStatus.invalid;
  const isFired =
    qualificationValidity.installation === QualificationStatus.fired ||
    qualificationValidity.maintenance === QualificationStatus.fired;
  const status = [];
  if (isExpiredSoon) {
    status.push(QualificationStatus.expiredSoon);
  } else if (isInvalid) {
    status.push(QualificationStatus.invalid);
  }
  const type = computeType(status);
  const days = computeDays();
  const firedType = computeType([QualificationStatus.fired]);
  return (
    <BasicModal
      open={visible}
      onCancel={onCancel}
      className="app-modal-qualifications-renewal"
      title={t<string>("pro.qualifications.modals.renewal.title")}
      footer={
        <div className="d-flex justify-content-end">
          <BasicButton
            variant="primary"
            text={t<string>("pro.qualifications.modals.renewal.button")}
            onClick={onCancel}
          />
        </div>
      }
    >
      <>
        {isExpiredSoon && (
          <div className="mb-3">
            {t("pro.qualifications.modals.renewal.expiredSoon", {
              days,
              count: type.length > 3 ? 2 : 1,
              type,
            })}
          </div>
        )}
        {isInvalid && (
          <div className="mb-3">
            {t("pro.qualifications.modals.renewal.invalid", {
              count: type.length > 3 ? 2 : 1,
              type,
            })}
          </div>
        )}
        {isFired && (
          <div>
            {t("pro.qualifications.modals.renewal.fired", {
              count: firedType.length > 3 ? 2 : 1,
              type: firedType,
            })}
          </div>
        )}
      </>
    </BasicModal>
  );
};

export default QualificationsRenewalModal;
