import {
  AuthentifiedContext,
  GazProfessionalUser,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import { ROUTES } from "@utils/Routes";

const USER_TYPES = {
  PG: "GazProfessionalUser",
  OP: "OrganisationProfessionalUser",
};

export const getConnectedHomeRoute = (
  authentifiedContext: AuthentifiedContext,
): string => {
  const typeUser = authentifiedContext.typeUser;
  if (typeUser === USER_TYPES.PG) {
    return ROUTES.professional.connectedHome.generate();
  } else if (typeUser === USER_TYPES.OP) {
    return ROUTES.professional.organisationProfessional.tools.generate();
  } else {
    return "";
  }
};

export const computeUserName = (
  authentifiedContext: AuthentifiedContext,
): string => {
  const { user, typeUser } = authentifiedContext;

  if (user) {
    if (typeUser === USER_TYPES.PG) {
      return (user as GazProfessionalUser).companyName;
    } else if (typeUser === USER_TYPES.OP) {
      return (user as OrganisationProfessionalUser).login;
    }
  }
  return "";
};
