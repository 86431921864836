import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import ImageGroupModuleCMS from "@components/cms/ImageGroupModuleCMS";
import config from "@utils/Config";

interface LandingPageCommitmentsProps {
  confirmationScreen?: boolean;
  className?: string;
  landingPageType: string;
}

const LandingPageCommitments: FunctionComponent<
  LandingPageCommitmentsProps
> = ({ className = "", confirmationScreen = false, landingPageType }) => {
  const { t } = useTranslation();
  const gazEventType = config.gazEventType;
  const isTempsFort = landingPageType === "tempsFort";

  return (
    <div
      className={`app-rentree-du-chauffage-commitments ${
        confirmationScreen ? "commitments-confirmation" : ""
      } ${className}`}
    >
      <ImageGroupModuleCMS
        title={
          confirmationScreen
            ? t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.commitments.confirmation.title`,
              )
            : t<string>(
                `landingPage.${landingPageType}${
                  isTempsFort ? ".".concat(gazEventType) : ""
                }.commitments.title`,
              )
        }
        titleClassName="h2"
        images={[
          {
            src: confirmationScreen
              ? "/img/rentreeDuChauffage/confirmation/expertise.svg"
              : "/img/rentreeDuChauffage/expertise.svg",
            title: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.expertise.title`,
            ),
            description: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.expertise.description`,
            ),
          },
          {
            src: confirmationScreen
              ? "/img/rentreeDuChauffage/confirmation/confiance.svg"
              : "/img/rentreeDuChauffage/confiance.svg",
            title: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.confiance.title`,
            ),
            description: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.confiance.description`,
            ),
          },
          {
            src: confirmationScreen
              ? "/img/rentreeDuChauffage/confirmation/proximite.svg"
              : "/img/rentreeDuChauffage/proximite.svg",
            title: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.proximite.title`,
            ),
            description: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.proximite.description`,
            ),
          },
          {
            src: confirmationScreen
              ? "/img/rentreeDuChauffage/confirmation/gazVert.svg"
              : "/img/rentreeDuChauffage/gazVert.svg",
            title: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.gazVert.title`,
            ),
            description: t<string>(
              `landingPage.${landingPageType}${
                isTempsFort ? ".".concat(gazEventType) : ""
              }.commitments.gazVert.description`,
            ),
          },
        ]}
      />
    </div>
  );
};

export default LandingPageCommitments;
