import BasicDivider from "@components/divider/BasicDivider";
import Section from "@components/section/Section";
import FileDownloadModuleCMS from "@components/cms/FileDownloadModuleCMS";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { FunctionComponent } from "react";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { downloadFromFile } from "@utils/Base64File";
import { mapResourceTofileType } from "@utils/upload-utils";
import { GazProfessionalUser } from "@type/auth/auth.types";

const DocumentsSection: FunctionComponent = () => {
  const { t } = useTranslation();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const pacHybride = authentifiedUser?.pacHybride || undefined;
  const smokeDuct = authentifiedUser?.smokeDuct || undefined;

  return (
    <>
      {(pacHybride || smokeDuct) && (
        <>
          <BasicDivider variant="primary" className="mb-40 w-100" height={1} />
          {pacHybride && (
            <Section
              type="h2"
              title={t<string>("personalInfo.sectionTitle.rgePAC")}
              titleClassName="text-primary"
              className="mb-40"
            >
              <div className="d-flex flex-wrap">
                <Row gutter={16} className="w-100 mx-0">
                  <Col xs={0} lg={1} className="mx-0 px-0">
                    <img src="/img/icons/personalData/download.svg" />
                  </Col>
                  {pacHybride.rge && (
                    <Col xs={24} lg={23} className="px-0">
                      <FileDownloadModuleCMS
                        title={pacHybride.rge.filename}
                        description={`PDF - ${pacHybride.rge.size}.Ko`}
                        buttonLabel={t<string>(
                          "personalInfo.form.actions.download",
                        )}
                        onClick={() =>
                          downloadFromFile(
                            mapResourceTofileType(pacHybride.rge),
                          )
                        }
                        backgroundColor="white"
                        className="py-0"
                      />
                    </Col>
                  )}
                </Row>
              </div>
            </Section>
          )}
        </>
      )}
    </>
  );
};

export default DocumentsSection;
