const addOrRemoveUniqueItem = (array: string[], item: string) => {
  if (array.includes(item)) {
    return array.filter((a) => a !== item);
  } else {
    return [...array, item];
  }
};

export const arrayUtils = {
  addOrRemoveUniqueItem,
};
