import React from "react";
import { List } from "antd";
import { SearchResultDto } from "@type/list/search-result-dto-interface";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import ProfessionalItemResult from "@components/gazprofessional/ProfessionalItemResult";

interface PropsType {
  item: SearchResultDto;
  onToggleContact?: (item: GazProfessionalItemResponseDto) => void;
  inContactList?: boolean;
  setModalVisible?: (item: boolean) => void;
  setSelectedGazProfessional?: (item: GazProfessionalItemResponseDto) => void;
  mapContact?: GazProfessionalItemResponseDto;
}

export const GazProfessionalSearchResult: React.FC<PropsType> = (
  props: PropsType,
) => {
  const {
    item,
    onToggleContact,
    inContactList,
    setModalVisible,
    setSelectedGazProfessional,
    mapContact,
  } = props;

  return (
    <List.Item>
      <ProfessionalItemResult
        professional={item as GazProfessionalItemResponseDto}
        onToggleContact={onToggleContact}
        setSelectedGazProfessional={setSelectedGazProfessional}
        inContactList={inContactList}
        setModalVisible={setModalVisible}
        mapContact={mapContact}
      />
    </List.Item>
  );
};
