import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { setContactGazProfessional } from "@state/contact/gazprofessional/ContactGazProfessionalEvents";
import { SearchEngine } from "@utils/enums/contact.enum";
import { useStore } from "effector-react";
import { contactGazProfessionalStore } from "@state/contact/gazprofessional/ContactGazProfessionalStore";

export const ContactsContext: React.FC = () => {
  const location = useLocation();
  const contactInfo = useStore(contactGazProfessionalStore);
  const referrer = document.referrer;
  const utmSource = new URLSearchParams(location.search).get("utm_source");
  const utmMedium = new URLSearchParams(location.search).get("utm_medium");
  const utmCampaign = new URLSearchParams(location.search).get("utm_campaign");
  const gclid = new URLSearchParams(location.search).get("gclid");
  const year = new Date().getFullYear().toString().substring(2);
  const url = window.location.search;

  const getContext = useCallback(() => {
    if (utmSource || utmMedium || utmCampaign) {
      setContactGazProfessional({
        source: utmSource ?? "",
        medium: utmMedium ?? "",
        campaign: utmCampaign ?? "",
        url: url,
        firstConnect: false,
      });
    } else if (gclid) {
      setContactGazProfessional({
        source: "google",
        medium: "sea",
        campaign: "D0CA-" + year + "-INS-991-007",
        firstConnect: false,
      });
    } else if (referrer != null) {
      if (
        Object.values(SearchEngine).filter((s) => s.urlValue == referrer)
          .length != 0
      ) {
        setContactGazProfessional({
          source: referrer,
          medium: "seo",
          campaign: "D0CA-" + year + "-INS-991-001",
          firstConnect: false,
        });
      } else if (referrer == "") {
        setContactGazProfessional({
          source: "direct",
          medium: "direct",
          campaign: "D0CA-" + year + "-INS-991-003",
          firstConnect: false,
        });
      } else {
        setContactGazProfessional({
          source: "autre",
          medium: "autre",
          campaign: undefined,
          firstConnect: false,
        });
      }
    } else {
      setContactGazProfessional({
        source: "autre",
        medium: "autre",
        campaign: undefined,
        firstConnect: false,
      });
    }
  }, [gclid, referrer, url, utmCampaign, utmMedium, utmSource, year]);

  useEffect(() => {
    if (referrer != "" || contactInfo?.firstConnect) {
      getContext();
    }
  }, [contactInfo?.firstConnect, getContext, referrer]);

  return <></>;
};
