import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { columns } from "@components/lists/MailsColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";
import MailDetailModal from "@components/modals/MailDetailModal";
import SearchFormField from "@components/inputs/SearchFormField";
import { GazProfessionalHabitAPlusMailsResponseDto } from "@state/mailprofessional/dto/gaz.professional.habitaplus.mails.response.dto";
import toastAlert from "@components/alerts/ToastAlert";
import configuration from "@utils/Config";
import { Loader } from "@components/loader/Loader";
import { requestGetGazProfessionalHabitAPlusMailsByOrganisationProfessional } from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { useLocation, useParams } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

export const MailsProfessionalGazScreen: React.FC = () => {
  const { t } = useTranslation();

  const [mails, setMails] =
    useState<Pageable<GazProfessionalHabitAPlusMailsResponseDto>>(
      emptySearchResult,
    );

  const { id: pgId } = useParams<{ id: string }>();
  const query = new URLSearchParams(useLocation().search);

  const searchTimeout = useRef<number>();
  const [localQuery, setLocalQuery] = useState<string>("");

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [sorter, setSorter] = useState<
    SorterResult<GazProfessionalHabitAPlusMailsResponseDto>
  >({
    columnKey: "date",
    order: "descend",
  });

  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [detailModalTitle, setDetailModalTitle] = useState<string>("");
  const [detailModalUrl, setDetailModalUrl] = useState<string>();

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const onRowClick = (
    record: GazProfessionalHabitAPlusMailsResponseDto,
  ): void => {
    setDetailModalTitle(record.title);
    setDetailModalUrl(pgId && getPDFLink(record.id, pgId));
    if (record.title) {
      setShowDetailModal(true);
    }
  };

  const fetchMails = useCallback(() => {
    setDataHasLoaded(false);
    requestGetGazProfessionalHabitAPlusMailsByOrganisationProfessional({
      page: pageNumber - 1,
      limit,
      sorter,
      id: pgId,
      dto: { query: localQuery },
    }).catch(() => {
      toastAlert(t<string>("pro.mail.error"));
    });
  }, [pageNumber, limit, sorter, pgId, localQuery, t]);

  useEffect(() => {
    fetchMails();
  }, [fetchMails]);

  useEffect(() => {
    return requestGetGazProfessionalHabitAPlusMailsByOrganisationProfessional.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setMails(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = window.setTimeout(() => setLocalQuery(value), 800);
  };

  const getPDFLink = (mailId: string, pgId: string) => {
    return `${configuration.backendBaseUrl}/organisation-professionals/pgs/${pgId}/mails/${mailId}/file`;
  };

  return (
    <PageLayout
      noProSideMenu
      organisationProSideMenu
      container
      title={t<string>("organisationprofessional.mails.gp.title", {
        pgName: query.get("pgName"),
      })}
      titleCentered={false}
      returnText={t<string>("organisationprofessional.mails.gp.return")}
      returnRoute={
        pgId &&
        ROUTES.professional.organisationProfessional.adherentsPg.detail.generate(
          pgId,
        )
      }
    >
      {mails == emptySearchResult ? (
        <Loader text={t<string>("pro.loader")} />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-end flex-wrap my-3 gap-3">
            <SearchFormField
              module="pro.mail"
              field="search"
              onChange={onChange}
              onSearch={(value: string) => setLocalQuery(value)}
              value={localQuery}
            />
          </div>
          {pgId && (
            <Table
              locale={{
                emptyText: <Empty description={t<string>("common.noData")} />,
              }}
              columns={columns(onRowClick, {
                type: "OP",
                getPDFLink,
                pgId,
              })}
              dataSource={mails.content}
              loading={{
                spinning: !dataHasLoaded,
                indicator: <Loader />,
              }}
              size="small"
              rowKey={(record) => record.id}
              className="app-contact-pro-table pro-table-large-font"
              pagination={{
                total: mails.totalElements,
                pageSize: mails.size,
                current: mails.number + 1,
                onChange: setPageNumber,
                onShowSizeChange: onChangeLimit,
                showSizeChanger: true,
              }}
              onChange={(pagination, filters, sorter) =>
                setSorter(sorter as SorterResult<any>)
              }
              onRow={(record) => {
                return {
                  onClick: () => onRowClick(record),
                };
              }}
            />
          )}
          <MailDetailModal
            visible={showDetailModal}
            onCancel={() => setShowDetailModal(false)}
            title={detailModalTitle}
            url={detailModalUrl}
          />
        </>
      )}
    </PageLayout>
  );
};
