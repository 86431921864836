import { OptionType } from "@type/form/field.types";

export const PO_FILTERS_RENEWAL_STATE = [
  {
    value: "ALL",
    label: "organisationprofessional.pgSubscribers.filters.renewalState.all",
  },
  {
    value: "VALIDATED",
    label:
      "organisationprofessional.pgSubscribers.filters.renewalState.validated",
  },
  {
    value: "IN_PROGRESS",
    label:
      "organisationprofessional.pgSubscribers.filters.renewalState.inProgress",
  },
  {
    value: "INCOMPLETE",
    label:
      "organisationprofessional.pgSubscribers.filters.renewalState.incomplete",
  },
] as OptionType[];

export const PO_FILTERS_TITLE_PG = [
  {
    value: "ALL",
    label: "organisationprofessional.pgSubscribers.filters.title.all",
  },
  {
    value: "PGIPGM",
    label: "organisationprofessional.pgSubscribers.filters.title.pgipgm",
  },
  {
    value: "PGI",
    label: "organisationprofessional.pgSubscribers.filters.title.pgi",
  },
  {
    value: "PGM",
    label: "organisationprofessional.pgSubscribers.filters.title.pgm",
  },
] as OptionType[];

export const PO_FILTERS_QUALIFICATION_STATE = [
  {
    value: "ALL",
    label: "organisationprofessional.rgs.filters.qualificationState.all",
  },
  {
    value: "AUTHORISED",
    label: "organisationprofessional.rgs.filters.qualificationState.authorised",
  },
  {
    value: "RENEW",
    label: "organisationprofessional.rgs.filters.qualificationState.renew",
  },
  {
    value: "RAD",
    label: "organisationprofessional.rgs.filters.qualificationState.rad",
  },
] as OptionType[];

export const PO_FILTERS_TITLE_RG = [
  {
    value: "ALL",
    label: "organisationprofessional.rgs.filters.title.all",
  },
  {
    value: "RGI",
    label: "organisationprofessional.rgs.filters.title.rgi",
  },
  {
    value: "RGM",
    label: "organisationprofessional.rgs.filters.title.rgm",
  },
] as OptionType[];
