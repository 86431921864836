import React, { useState } from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import BasicButton from "src/components/buttons/BasicButton";
import { requestForgotPassword } from "@state/auth/AuthEffects";
import PageLayout from "@components/layouts/PageLayout";
import InputFormField from "@components/inputs/InputFormField";
import SubmitButton from "@components/buttons/SubmitButton";
import { FieldData, Store } from "rc-field-form/lib/interface";
import { formItemBlockLayout } from "@utils/Constant";
import { hideEmail } from "@utils/email-utils";
import { formHelper } from "@utils/form-helper";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

const ForgotPasswordScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const InitialValues = {
    email: "",
  };
  const [fields, setFields] = useState<FieldData[]>([]);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(
    undefined,
  );

  const handleSubmit = (values: Store) => {
    requestForgotPassword({ dto: { username: values.username as string } })
      .then((result) => {
        if (result.ok && result.data)
          setSuccessMessage(
            t<string>("forgot-password.messages.success", {
              email: hideEmail(result.data.email),
            }),
          );
        else if (result.errorCode === 1006) {
          navigate(ROUTES.professional.errors.wrongId.generate());
        } else if (result.fieldErrors) {
          setFields(formHelper.addErrorsToFieldsData([], result.fieldErrors));
        }
      })
      .catch((error: Error) => toastError(error.message));
  };

  return (
    <PageLayout
      container
      contentInCard
      bodyClassName="login-body"
      title={t<string>("forgot-password.title")}
      subtitle={
        (!successMessage && t<string>("forgot-password.subtitle")) || undefined
      }
      mainClassName="bg-light-blue"
    >
      <>
        {successMessage && (
          <>
            <div className="d-flex align-items-center justify-content-center mb-3">
              {successMessage}
            </div>
            <div className="d-flex align-items-center justify-content-center mb-3 text-danger">
              {t<string>("common.spam")}
            </div>
          </>
        )}
        {!successMessage && (
          <Form
            onFinish={handleSubmit}
            initialValues={InitialValues}
            fields={fields}
            {...formItemBlockLayout}
          >
            <InputFormField
              showLabel
              module="forgot-password"
              field="username"
              type="username"
              className="mb-3"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(/^[A-Z]{2}\d{4}/i),
                  message: t<string>(
                    "login.form.fields.username.errors.invalid",
                  ),
                },
              ]}
              required
            />
            <div className="d-flex align-items-center justify-content-center mt-2">
              <Link to={ROUTES.professional.auth.login.generate()}>
                <BasicButton
                  text={t<string>("buttons.back")}
                  variant="secondary"
                  className="mt-2 me-2"
                />
              </Link>
              <div className="form-group mb-0">
                <SubmitButton
                  module="forgot-password"
                  isSubmitting={false}
                  label="reset"
                  className="mt-2"
                />
              </div>
            </div>
          </Form>
        )}
      </>
    </PageLayout>
  );
};

export default ForgotPasswordScreen;
