import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Form, Table } from "antd";
import { emptySearchResult, formItemBlockLayout } from "@utils/Constant";
import BasicDivider from "@components/divider/BasicDivider";
import RegisterInformationSection from "@views/pro/gazProfessional/account/section/RegisterInformationSection";
import QualificationsCard from "@components/cards/QualificationsCard";
import BasicButton from "@components/buttons/BasicButton";
import { MailOutlined } from "@ant-design/icons";
import { QUALIFICATION_CARD_TYPE } from "@utils/enums/qualification.card.type.enum";
import { GPQualificationsResponseDto } from "@state/professional/dto/professional.qualifications.response.dto";
import toastAlert from "@components/alerts/ToastAlert";
import {
  requestGetOrganisationProfessionalQualificationsGazProfessional,
  requestGetOrganisationProfessionalRGRequest,
  requestGetOrganisationProfessionalRGRequests,
  requestUpdateOrganisationProfessionalRGRequest,
} from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { OrganisationProfessionalGPPreviewResponseDto } from "@state/organisationprofessional/dto/organisation.professional.gaz.professional.preview.response.dto";
import { requestGetOrganisationProfessionalPreviewGazProfessional } from "@state/gazprofessional/GazProfessionalEffects";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import { Loader } from "@components/loader/Loader";
import Section from "@components/section/Section";
import { columns } from "@components/lists/OPRGRequestsColumns";
import { RGRequestType } from "@utils/enums/qualifications.rg-requests.type.enum";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { Name } from "@utils/name-utils";
import RGRequestModalSwitch from "@components/switch/RGRequestModalSwitch";
import { Pageable } from "@type/pagination/pagination.types";
import { OrganisationProfessionalRGRequestItemResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.item.response.dto";
import {
  maptoRgRequestSpeciality,
  maptoRgRequestStatus,
  maptoRgRequestType,
} from "@utils/rgRequestMappers";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/qualifications.rg-requests.speciality.enum";

interface FormValues {
  id: string;
  siret: string;
  businessName: string;
  username: string;
  phone: string;
  email: string;
  address: string;
  postcode: string;
  city: string;
  installation: boolean;
  maintenance: boolean;
  pac: boolean;
  smokeDuct: boolean;
}

const initialValues = (
  preview: OrganisationProfessionalGPPreviewResponseDto,
): FormValues => {
  return {
    id: preview.id,
    siret: preview.siret,
    businessName: preview.businessName,
    username: preview.username,
    phone: preview.phone,
    email: preview.email,
    address: preview.address,
    postcode: preview.postcode,
    city: preview.city,
    installation: preview.installation,
    maintenance: preview.maintenance,
    pac: preview.pac,
    smokeDuct: preview.smokeDuct,
  };
};
export const DetailsPGSubscribersOrganisationProfessionalScreen: React.FC =
  () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { id: pgId } = useParams<{ id: string }>();

    const [qualifications, setQualifications] =
      useState<GPQualificationsResponseDto>();
    const [gpPreview, setGpPreview] =
      useState<OrganisationProfessionalGPPreviewResponseDto>();

    const [hasLoaded, sethasLoaded] = useState<boolean>(false);
    const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

    const [showRGRequestModal, setShowRGRequestModal] =
      useState<boolean>(false);
    const [modalType, setModalType] = useState<RGRequestType>();
    const [modalMode, setModalMode] = useState<ModalMode>();

    const [selectedRGRequestId, setSelectedRGRequestId] = useState<string>("");
    const [isInstallation, setIsInstallation] = useState<boolean>(false);
    const [nameRG, setNameRG] = useState<Name>({
      firstName: "",
      lastName: "",
    });

    const [rgRequests, setRgRequests] =
      useState<Pageable<OrganisationProfessionalRGRequestItemResponseDto>>(
        emptySearchResult,
      );

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [limit, setLimit] = useState<number>(10);

    const fetchRGRequests = useCallback(() => {
      setDataHasLoaded(false);
      requestGetOrganisationProfessionalRGRequests({
        page: pageNumber - 1,
        limit,
        sorter: {
          columnKey: "creationDate",
          order: "descend",
        },
        dto: {
          pgIds: [pgId],
        },
      }).catch(() => {
        toastAlert(t<string>("pro.qualifications.error"));
      });
    }, [pageNumber, limit, t]);

    useEffect(() => {
      fetchRGRequests();
    }, [fetchRGRequests]);

    const fecthQualifications = useCallback(() => {
      if (pgId) {
        sethasLoaded(false);
        requestGetOrganisationProfessionalQualificationsGazProfessional(
          pgId,
        ).catch(() => {
          toastAlert(t<string>("pro.qualifications.error"));
        });
      }
    }, [pgId, t]);

    useEffect(() => {
      fecthQualifications();
    }, [fecthQualifications]);

    useEffect(() => {
      return requestUpdateOrganisationProfessionalRGRequest.done.watch(
        ({ result: { data, ok } }) => {
          if (ok && data) {
            setShowRGRequestModal(false);
            fetchRGRequests();
          }
        },
      );
    });

    useEffect(() => {
      return requestGetOrganisationProfessionalQualificationsGazProfessional.done.watch(
        ({ result: { data, ok } }) => {
          if (ok && data) {
            setQualifications(data);
            sethasLoaded(true);
          }
        },
      );
    });

    useEffect(() => {
      return requestGetOrganisationProfessionalRGRequests.done.watch(
        ({ result: { data, ok } }) => {
          if (ok && data) {
            setRgRequests(data);
            setDataHasLoaded(true);
          }
        },
      );
    });

    const fecthGpPreview = useCallback(() => {
      if (pgId) {
        sethasLoaded(false);
        requestGetOrganisationProfessionalPreviewGazProfessional(pgId).catch(
          () => {
            toastAlert(t<string>("pro.qualifications.error"));
          },
        );
      }
    }, [pgId, t]);

    useEffect(() => {
      fecthGpPreview();
    }, [fecthGpPreview]);

    useEffect(() => {
      return requestGetOrganisationProfessionalPreviewGazProfessional.done.watch(
        ({ result: { data, ok } }) => {
          if (ok && data) {
            setGpPreview(data);
            sethasLoaded(true);
          }
        },
      );
    });

    const fetchRGRequest = (id: string) => {
      requestGetOrganisationProfessionalRGRequest(id).catch(() => {
        toastAlert(t<string>("pro.qualifications.modals.details.error.toast"));
      });
    };

    function mapToRequest(
      rgRequest: OrganisationProfessionalRGRequestItemResponseDto,
    ) {
      const type = maptoRgRequestType(rgRequest.type);
      const speciality = maptoRgRequestSpeciality(rgRequest.speciality);
      const status = maptoRgRequestStatus(rgRequest.status);
      return {
        id: rgRequest.id,
        pgId: rgRequest.pgId,
        creationDate: rgRequest.creationDate,
        reference: rgRequest.reference,
        typeLabel: `${t(type.label)} ${t(speciality.label)}`,
        rg: `${rgRequest.lastName} ${rgRequest.firstName}`,
        status,
        type,
        isInstallation: speciality === RG_REQUEST_SPECIALITY.INSTALLATION,
      };
    }

    function buildRequests() {
      return rgRequests.content.map((rgRequest) => mapToRequest(rgRequest));
    }

    const onChangeLimit = (current: number, newLimit: number): void => {
      setLimit(newLimit);
    };

    return (
      <PageLayout
        noProSideMenu
        organisationProSideMenu
        container
        titleCentered={false}
        title={gpPreview?.businessName}
        returnRoute={
          location.pathname.startsWith(
            ROUTES.professional.organisationProfessional.rg.home.generate(),
          )
            ? ROUTES.professional.organisationProfessional.rg.home.generate()
            : ROUTES.professional.organisationProfessional.adherentsPg.home.generate()
        }
        returnText={t<string>("worksite.detail.return")}
        additionalButtons={
          <BasicButton
            size="sm"
            variant="primary"
            text={t<string>("organisationprofessional.details.seeMails")}
            icon={<MailOutlined />}
            iconRight
            onClick={() =>
              pgId &&
              navigate(
                ROUTES.professional.organisationProfessional.adherentsPg.mail.generate(
                  pgId,
                  gpPreview?.businessName,
                ),
              )
            }
          />
        }
      >
        <>
          {gpPreview && qualifications ? (
            <div>
              {gpPreview && (
                <Form
                  initialValues={initialValues(gpPreview)}
                  {...formItemBlockLayout}
                >
                  <RegisterInformationSection
                    noDivider
                    title={t<string>(
                      "organisationprofessional.details.infos.title",
                    )}
                    titleClassName="text-primary"
                    noEditableLabel
                    subtitle={t<string>(
                      "organisationprofessional.details.infos.subtitle",
                    )}
                    noIcon
                  />
                </Form>
              )}
              <BasicDivider
                variant="primary"
                className="mt-0 mb-4 w-100"
                height={1}
              />
              {qualifications && (
                <div>
                  <h2 className="text-primary mb-4">
                    {t("organisationprofessional.details.qualifications.title")}
                  </h2>
                  <div className="qualifications-cards">
                    <QualificationsCard
                      title={t<string>("pro.qualifications.installation.title")}
                      className="flex-grow-1"
                      qualification={qualifications.installation}
                      type={QUALIFICATION_CARD_TYPE.INSTALLATION}
                      isInstallation
                      section="OP"
                    />
                    <QualificationsCard
                      title={t<string>("pro.qualifications.maintenance.title")}
                      className="flex-grow-1"
                      qualification={qualifications.maintenance}
                      type={QUALIFICATION_CARD_TYPE.MAINTENANCE}
                      isInstallation={false}
                      section="OP"
                    />
                  </div>
                </div>
              )}

              <Section
                title={t<string>("organisationprofessional.details.requests")}
                titleClassName="text-primary"
                className="mt-40"
              >
                <Table
                  locale={{
                    emptyText: (
                      <Empty description={t<string>("common.noData")} />
                    ),
                  }}
                  columns={columns(
                    setShowRGRequestModal,
                    setModalType,
                    setModalMode,
                    setIsInstallation,
                    setNameRG,
                    setSelectedRGRequestId,
                    fetchRGRequest,
                  )}
                  dataSource={buildRequests()}
                  loading={{
                    spinning: !dataHasLoaded,
                    indicator: <Loader />,
                  }}
                  size="small"
                  className="app-contact-pro-table pro-table-cursor-default"
                  pagination={{
                    total: rgRequests.totalElements,
                    pageSize: rgRequests.size,
                    current: rgRequests.number + 1,
                    onChange: setPageNumber,
                    onShowSizeChange: onChangeLimit,
                    showSizeChanger: true,
                  }}
                />
              </Section>
            </div>
          ) : hasLoaded ? (
            <div className="pt-4">
              <p>{t<string>("organisationprofessional.details.error")}</p>
            </div>
          ) : (
            <Loader />
          )}
          {!!modalMode && (
            <RGRequestModalSwitch
              visible={showRGRequestModal}
              onCancel={() => setShowRGRequestModal(false)}
              isOPSection
              modalType={modalType}
              modalMode={modalMode}
              rgRequestId={selectedRGRequestId}
              isInstallationSection={isInstallation}
              name={nameRG}
            />
          )}
        </>
      </PageLayout>
    );
  };
