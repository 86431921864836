import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import { ReactElement } from "react";
import CheckboxFormField from "@components/inputs/CheckboxFormField";
import { pgsTest } from "@utils/Constant";

interface Props {
  yoocanId?: number;
}
const SettingsSection = ({ yoocanId }: Props): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="px-12 mb-4">
      <Row gutter={32}>
        <Col xs={24} lg={12} className="mb-3">
          {t<string>("personalInfo.sectionSubtitle.settings")}
        </Col>
        <Col xs={24} lg={12} className="mb-3">
          {yoocanId && !(pgsTest.indexOf(yoocanId.toString()) > -1) && (
            <CheckboxFormField
              module="personalInfo"
              field="visible"
              className="mb-1"
            />
          )}
          <CheckboxFormField module="personalInfo" field="alert" />
        </Col>
      </Row>
    </div>
  );
};

export default SettingsSection;
