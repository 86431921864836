import { Universe } from "@utils/enums/universe.enum";

export enum RoutePrefixes {
  INDIVIDUAL = "/particulier",
  PROFESSIONAL = "/professionnel",
  CONNECTED = "/mon-compte",
}

export const SUMMARY_ROUTE = "/sommaire";

export const ROUTES = {
  public: {
    home: {
      generate: (): string => "/",
    },
    universe: {
      generate: (universe: Universe): string => `/${universe.urlValue}`,
    },
    summary: {
      generate: (seoFriendlyTitle: string): string => {
        return `${seoFriendlyTitle}${SUMMARY_ROUTE}`;
      },
    },

    aides: {
      generate: (): string => {
        return `${RoutePrefixes.INDIVIDUAL}/aides${SUMMARY_ROUTE}`;
      },
    },
    gazProfessionals: {
      details: {
        generate: (yoocanId: string | number): string => {
          return `/pg/${yoocanId}`;
        },
      },
    },
    rentreeChauffage: {
      maintenance: {
        generate: (): string => "/maintenance-rentree-du-chauffage",
      },
    },
    gazMonth: {
      maintenance: {
        generate: (): string => "/maintenance-mois-chauffage",
      },
    },
    maintenance: {
      generate: (): string => "/maintenance",
    },
    error404: {
      generate: (): string => "/404",
    },
    preview: {
      generate: () => "/preview/*",
    },
    actualites: {
      generate: (universe: Universe): string =>
        `/${universe.urlValue}/actualites`,
    },
    pageIndividual: {
      generate: (): string => `${RoutePrefixes.INDIVIDUAL}/*`,
    },
    pagePro: {
      generate: (): string => `${RoutePrefixes.PROFESSIONAL}/*`,
    },
    pageFooter: {
      generate: (): string => "/footer/*",
    },
  },
  individual: {
    gazProfessionals: {
      search: {
        generate: (): string =>
          `${RoutePrefixes.INDIVIDUAL}/les-professionnels-du-gaz/recherche`,
      },
    },
    mentionGreenGaz: {
      generate: (): string =>
        `${RoutePrefixes.INDIVIDUAL}/une-mention-gaz-vert-pour-une-meilleure-connaissance-du-biogaz`,
    },
    publicHome: {
      generate: (): string => RoutePrefixes.INDIVIDUAL,
    },
    gazMonth: {
      landingPage: {
        generate: (): string => `${RoutePrefixes.INDIVIDUAL}/mois-du-chauffage`,
      },
      confirmation: {
        generate: (): string =>
          `${RoutePrefixes.INDIVIDUAL}/mois-du-chauffage/confirmation`,
      },
    },
    rentreeChauffage: {
      filRouge: {
        landingPage: {
          generate: (): string => "/formulaire-filrouge",
        },
        confirmation: {
          generate: (): string => "/formulaire-filrouge/confirmation",
        },
      },
      tempsFortV1: {
        landingPage: {
          generate: (): string => "/formulaire-tempsfort1",
        },
        confirmation: {
          generate: (): string => "/formulaire-tempsfort1/confirmation",
        },
      },
      tempsFort: {
        landingPage: {
          generate: (): string => "/formulaire-tempsfort",
        },
        confirmation: {
          generate: (): string => "/formulaire-tempsfort/confirmation",
        },
      },
    },
  },
  professional: {
    auth: {
      login: {
        generate: (options?: { callback: string }): string =>
          `${RoutePrefixes.PROFESSIONAL}/login${
            options && options.callback ? "?c=" + options.callback : ""
          }`,
      },
      loginOp: {
        generate: (): string => `${RoutePrefixes.PROFESSIONAL}/login-op`,
      },
      forgotPassword: {
        generate: (): string => `${RoutePrefixes.PROFESSIONAL}/forgot-password`,
      },
      initPassword: {
        generate: (): string => `${RoutePrefixes.PROFESSIONAL}/init-password`,
      },
      recoverPassword: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}/recover-password`,
      },
      registration: {
        generate: (): string => `${RoutePrefixes.PROFESSIONAL}/registration`,
      },
    },
    publicHome: {
      generate: (): string => RoutePrefixes.PROFESSIONAL,
    },
    connectedHome: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/accueil`,
    },
    errors: {
      wrongId: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}/identifiant-inconnu`,
      },
    },
    contacts: {
      publicForm: {
        generate: (): string => "/contact",
      },
      list: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/contacts`,
      },
      details: {
        generate: (id: string): string =>
          `${ROUTES.professional.contacts.list.generate()}/${id}`,
      },
    },
    audits: {
      list: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/audits`,
      },
      details: {
        generate: (id: string): string =>
          `${ROUTES.professional.audits.list.generate()}/${id}`,
      },
    },
    maintenanceCertificate: {
      list: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/attestations-entretien`,
      },
      details: {
        generate: (id: string): string =>
          `${ROUTES.professional.maintenanceCertificate.list.generate()}/${id}`,
      },
      add: {
        generate: (): string =>
          `${ROUTES.professional.maintenanceCertificate.list.generate()}/ajout`,
      },
    },
    pacsHybrides: {
      mention: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/pac-hybride`,
      },
      form: {
        generate: (): string =>
          `${ROUTES.professional.pacsHybrides.mention.generate()}/certification`,
      },
      iframe: {
        generate: (): string =>
          `${ROUTES.professional.pacsHybrides.mention.generate()}/iframe`,
      },
      renewal: {
        generate: (): string =>
          `${ROUTES.professional.pacsHybrides.mention.generate()}/renouvellement`,
      },
    },
    smokeDucts: {
      mention: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/conduit-de-fumee`,
      },
      form: {
        generate: (): string =>
          `${ROUTES.professional.smokeDucts.mention.generate()}/certification`,
      },
      iframe: {
        generate: (): string =>
          `${ROUTES.professional.smokeDucts.mention.generate()}/iframe`,
      },
    },
    worksites: {
      details: {
        generate: (id: string): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/conduit-de-fumee/chantier/${id}`,
      },
      form: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/conduit-de-fumee/chantier/form`,
      },
    },
    gazMonth: {
      details: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/temps-forts`,
      },
    },
    tools: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/outils`,
    },
    greenGas: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/gaz-vert`,
    },
    personalData: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/donnees-personnelles`,
    },
    mailOP: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/courriers-pg`,
    },
    qualifications: {
      generate: (): string =>
        `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/qualifications`,
    },
    organisationProfessional: {
      tools: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/outils-op`,
      },
      adherentsPg: {
        home: {
          generate: (): string =>
            `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/adherents-pg`,
        },
        detail: {
          generate: (id: string): string =>
            `${ROUTES.professional.organisationProfessional.adherentsPg.home.generate()}/${id}`,
        },
        mail: {
          generate: (id: string, pgName?: string): string =>
            `${ROUTES.professional.organisationProfessional.adherentsPg.home.generate()}/${id}/mails${
              pgName ? `?pgName=${pgName}` : ""
            }`,
        },
      },
      rg: {
        home: {
          generate: (): string =>
            `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/rg`,
        },
        detail: {
          generate: (id: string): string =>
            `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/rg/${id}`,
        },
      },
      mailOp: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/courriers-op`,
      },
      requests: {
        generate: (): string =>
          `${RoutePrefixes.PROFESSIONAL}${RoutePrefixes.CONNECTED}/demandes`,
      },
    },
  },
};
