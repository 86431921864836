import React from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import BasicButton from "@components/buttons/BasicButton";
import { arrayUtils } from "@utils/array-utils";
import TertiaryCard from "@components/cards/TertiaryCard";

interface TertiaryModalFirstStepProps {
  setStep: (step: number) => void;
  habitats: string[];
  boilerPower: string[];
  setHabitats: (habitat: string[]) => void;
  setBoilerPower: (boilerPower: string[]) => void;
}

export const TertiaryModalFirstStep: React.FC<TertiaryModalFirstStepProps> = ({
  setStep,
  setHabitats,
  habitats,
  setBoilerPower,
  boilerPower,
}) => {
  const { t } = useTranslation();

  const handleChangeHabitat = (habitat: string) => {
    setHabitats(arrayUtils.addOrRemoveUniqueItem(habitats, habitat));
  };

  return (
    <div>
      <div className="d-flex justify-content-center">
        <h3 className="text-primary">
          {parse(t<string>("pro.tertiary.modal2.step"))}
        </h3>
      </div>
      <div className="mt-30">
        <h3 className="text-primary">
          {parse(t<string>("pro.tertiary.modal2.section1.title"))}
        </h3>
        <div className="d-flex flex-column flex-xl-row gap-3">
          <TertiaryCard
            label={t<string>("pro.tertiary.modal2.section1.pavillon")}
            active={habitats.includes("pavillon")}
            onClick={() => handleChangeHabitat("pavillon")}
            icon={
              <img
                src="/img/pro/tertiary/house.svg"
                alt={t<string>("pro.tertiary.modal2.section1.pavillon")}
              />
            }
          />
          <TertiaryCard
            label={t<string>("pro.tertiary.modal2.section1.appartment")}
            active={habitats.includes("appartment")}
            onClick={() => handleChangeHabitat("appartment")}
            icon={
              <img
                src="/img/pro/tertiary/appartment.svg"
                alt={t<string>("pro.tertiary.modal2.section1.appartment")}
              />
            }
          />
          <TertiaryCard
            label={t<string>("pro.tertiary.modal2.section1.immeuble")}
            active={habitats.includes("immeuble")}
            onClick={() => handleChangeHabitat("immeuble")}
            icon={
              <img
                src="/img/pro/tertiary/appartment.svg"
                alt={t<string>("pro.tertiary.modal2.section1.immeuble")}
              />
            }
          />
        </div>
        {habitats.includes("immeuble") && (
          <div>
            <span>
              {parse(t<string>("pro.tertiary.modal2.section2.detail"))}
            </span>
            <div className="d-flex flex-column flex-xl-row gap-3  mt-10">
              <TertiaryCard
                label={t<string>("pro.tertiary.modal2.section2.boiler1")}
                active={boilerPower?.includes("less300") ?? false}
                onClick={() =>
                  setBoilerPower(
                    arrayUtils.addOrRemoveUniqueItem(boilerPower, "less300"),
                  )
                }
                icon={
                  <img
                    src="/img/pro/tertiary/boiler.svg"
                    alt={t<string>("pro.tertiary.modal2.section2.boiler1")}
                  />
                }
              />
              <TertiaryCard
                label={t<string>("pro.tertiary.modal2.section2.boiler2")}
                active={boilerPower?.includes("more300") ?? false}
                onClick={() =>
                  setBoilerPower(
                    arrayUtils.addOrRemoveUniqueItem(boilerPower, "more300"),
                  )
                }
                icon={
                  <img
                    src="/img/pro/tertiary/boiler.svg"
                    alt={t<string>("pro.tertiary.modal2.section2.boiler2")}
                  />
                }
              />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center mt-30 ">
          <BasicButton
            text={t<string>("pro.tertiary.modal2.section2.nextStep")}
            onClick={() => setStep(2)}
            disabled={
              habitats.length === 0 ||
              (boilerPower.length === 0 && habitats.includes("immeuble"))
            }
          />
        </div>
      </div>
    </div>
  );
};
