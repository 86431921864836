import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import parse from "html-react-parser";
import { AccordionSection } from "@type/cms/cms.types";

import { RightOutlined } from "@ant-design/icons";

interface AccordionsCMSProps {
  sections: AccordionSection[];
  containerClassName?: string;
  accordionClassName?: string;
  accordionHeaderClassName?: string;
  customIcon?: ReactNode;
  headerColor?: string;
  selectedHeaderColor?: string;
  backgroundColor?: string;
  contentColor?: string;
  separator?: boolean;
  bordered?: boolean;
  borderColor?: string;
  autoCollapse?: boolean;
  disabled?: boolean;
  defaultSelection?: number[];
  fillContainer?: boolean;
}

const AccordionsModuleCMS: FunctionComponent<AccordionsCMSProps> = ({
  sections = [],
  containerClassName = "",
  accordionClassName = "",
  accordionHeaderClassName = "",
  customIcon = <RightOutlined />,
  separator = false,
  bordered = false,
  borderColor = "black",
  contentColor = "transparent",
  headerColor = "transparent",
  selectedHeaderColor = undefined,
  backgroundColor = "transparent",
  autoCollapse = false,
  disabled = false,
  defaultSelection = [],
  fillContainer = false,
}) => {
  const [selectedSections, setSelectedSections] = useState<number[]>([]);

  useEffect(() => {
    if (defaultSelection.length) setSelectedSections(defaultSelection);
  }, [defaultSelection]);

  const toggleSelection = (index: number) => {
    if (!disabled) {
      if (selectedSections.includes(index)) {
        if (autoCollapse) setSelectedSections([]);
        else {
          const newArray = selectedSections.filter((item) => item !== index);
          setSelectedSections(newArray);
        }
      } else {
        if (autoCollapse) setSelectedSections([index]);
        else setSelectedSections([...selectedSections, index]);
      }
    }
  };

  return (
    <div
      className={`app-accordion-group ${containerClassName} ${
        fillContainer ? "accordions-fill" : ""
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={fillContainer ? "" : "content-contained"}>
        {sections.map((section: AccordionSection, index: number) => (
          <div
            key={index}
            className={`app-accordion ${accordionClassName} ${
              bordered ? "accordion-border" : ""
            } ${separator ? "accordion-separator" : ""}`}
            style={{ backgroundColor: backgroundColor }}
          >
            <div
              className={`accordion-header ${accordionHeaderClassName} ${
                disabled ? "pe-none" : ""
              }`}
              aria-expanded={!selectedSections.includes(index)}
              onClick={() => {
                toggleSelection(index);
              }}
              style={{
                backgroundColor: selectedSections.includes(index)
                  ? selectedHeaderColor ?? headerColor
                  : headerColor,
              }}
            >
              {section.title}
              <div
                className={"accordion-header-icon"}
                aria-expanded={selectedSections.includes(index)}
              >
                {customIcon}
              </div>
            </div>
            <div
              className={"accordion-content"}
              aria-expanded={!selectedSections.includes(index)}
              style={{
                borderColor: borderColor,
                backgroundColor: contentColor,
              }}
            >
              <div className={"accordion-inner-container"}>
                {parse(section.content)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionsModuleCMS;
