import BasicModal from "@components/modals/BasicModal";
import { FC, ReactElement, useCallback, useEffect, useRef } from "react";
import ReactPlayer from "react-player";

interface EmbedVideoModalProps {
  title?: string;
  open: boolean;
  onClose?: () => void;
  className?: string;
  videoUrl: string;
  children?: ReactElement;
}

interface InternalVideoPlayer {
  // youtube api
  playVideo?: () => void;
  pauseVideo?: () => void;
  // vimeo api
  play?: () => void;
  pause?: () => void;
}

const EmbedVideoModal: FC<EmbedVideoModalProps> = ({
  title,
  className = "",
  open,
  videoUrl,
  onClose,
  children,
}) => {
  const player = useRef<ReactPlayer | null>(null);

  const playVideo = useCallback(() => {
    const internalPlayer =
      player.current?.getInternalPlayer() as InternalVideoPlayer;
    if (internalPlayer?.play) {
      internalPlayer.play();
    } else if (internalPlayer?.playVideo) {
      internalPlayer.playVideo();
    }
  }, []);

  const pauseVideo = useCallback(() => {
    const internalPlayer =
      player.current?.getInternalPlayer() as InternalVideoPlayer;
    if (internalPlayer?.pause) {
      internalPlayer.pause();
    } else if (internalPlayer?.pauseVideo) {
      internalPlayer.pauseVideo();
    }
  }, []);

  useEffect(() => {
    if (open) {
      playVideo();
    } else {
      pauseVideo();
    }
  }, [open, playVideo, pauseVideo]);

  return (
    <BasicModal
      open={open}
      onCancel={() => {
        onClose && onClose();
      }}
      footer={null}
      className={`modal-embed-video ${className}`}
      title={title}
    >
      <>
        <div className="video-container">
          <ReactPlayer
            ref={player}
            className="react-player"
            url={videoUrl}
            width="100%"
            height="100%"
            controls
            playing
          />
        </div>
        {children}
      </>
    </BasicModal>
  );
};

export default EmbedVideoModal;
