import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import { GazProfessionalUser } from "@type/auth/auth.types";

export const getAddressWithZipCodeAndCity = (
  gp: GazProfessionalItemResponseDto | GazProfessionalUser,
): string => {
  return [
    gp.editableAddress || getAddress(gp),
    gp.editableZipCode || (gp.zipcode ?? gp.postalCode),
    gp.editableCity || gp.city,
  ].join(" ");
};

export const getAddress = (
  gp: GazProfessionalItemResponseDto | GazProfessionalUser,
) => {
  let address = `<div>${gp.address ?? ""}`;
  if ((gp.address2?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(gp.address2 ?? "");
  }
  if ((gp.address3?.length ?? 0) > 0) {
    address = address.concat("</br>").concat(gp.address3 ?? "");
  }
  return address.concat("</div>");
};
