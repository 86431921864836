import { FunctionComponent, ChangeEvent, FormEvent } from "react";
import { Form, Input, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { InternalNamePath } from "rc-field-form/lib/interface";
import { Rule } from "antd/es/form";

interface PropsType {
  showLabel?: boolean;
  module: string;
  field: string | InternalNamePath;
  required?: boolean;
  emptyErrorMessage?: string;
  placeholder?: string;
  labelTooltip?: string | null;
  readOnly?: boolean;
  labelClassName?: string;
  fieldClassName?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  addonAfter?: string;
  addonBefore?: string;
  textArea?: boolean;
  className?: string;
  fieldPrefix?: string;
  rules?: Rule[];
  onInput?: (e: FormEvent<HTMLInputElement>) => void;
  initialValue?: { [key in string]: string };
  disabled?: boolean;
  autocomplete?: string;
}

const InputFormField: FunctionComponent<PropsType> = (props: PropsType) => {
  const {
    showLabel,
    labelTooltip,
    module,
    field,
    required,
    emptyErrorMessage,
    readOnly,
    labelClassName,
    fieldClassName,
    onChange,
    maxLength,
    type,
    addonAfter,
    addonBefore,
    placeholder,
    className = "",
    fieldPrefix = "",
    rules = [],
    onInput,
    initialValue,
    disabled,
    autocomplete,
  } = props;
  const { t } = useTranslation();

  const isRequired: boolean = !!readOnly ? false : !!required; // !(!var) => return false if var = false | null | undefined

  let i18nField;
  if (typeof field === "string") {
    i18nField = field.split(".")[field.split(".").length - 1];
  } else {
    i18nField = Array.isArray(field)
      ? (field[field.length - 1] as string).replace(/\d/g, "")
      : "";
  }

  const key = `field_${String(field)}`.replace(",", "_");

  const getLabel = (i18nField: string) => {
    return labelTooltip ? (
      <Tooltip title={labelTooltip}>
        <span
          className={`${labelClassName ?? ""} ${
            readOnly ? "label-readonly" : ""
          }`}
        >
          {t<string>(`${module}.form.fields.${i18nField}.label`)}
        </span>
      </Tooltip>
    ) : (
      <span
        className={`${labelClassName ?? ""} ${
          readOnly ? "label-readonly" : ""
        }`}
      >
        {t<string>(`${module}.form.fields.${i18nField}.label`)}
      </span>
    );
  };

  return (
    <>
      <Form.Item
        name={`${fieldPrefix}${String(field)}`}
        key={key}
        label={showLabel ? getLabel(i18nField) : ""}
        rules={[
          {
            required: isRequired,
            message: emptyErrorMessage ?? t<string>("forms.errors.mandatory"),
            whitespace: isRequired,
          },
          ...rules,
        ]}
        className={className}
        initialValue={initialValue}
      >
        <Input
          disabled={readOnly ?? disabled}
          className={readOnly ? " readonly-form-field" : fieldClassName}
          placeholder={
            placeholder ??
            t<string>(`${module}.form.fields.${i18nField}.placeholder`)
          }
          id={key}
          onChange={onChange}
          maxLength={maxLength}
          type={type ?? "text"}
          addonAfter={addonAfter ?? ""}
          addonBefore={addonBefore ?? ""}
          onInput={onInput}
          autoComplete={autocomplete ?? undefined}
        />
      </Form.Item>
    </>
  );
};

export default InputFormField;
