import { FunctionComponent } from "react";
import BasicCard from "@components/cards/BasicCard";
import { useTranslation } from "react-i18next";
import DateFormat from "@utils/DateFormat";

interface CertificateCardProps {
  date: string;
  expiratedDate?: string;
  title: string | JSX.Element;
  onClick?: () => any;
  className?: string;
  type: "pacHybride" | "evapdc" | "smokeDuct";
}

const CertificateCard: FunctionComponent<CertificateCardProps> = ({
  date,
  expiratedDate,
  title,
  onClick,
  className = "",
  type,
}) => {
  const { t } = useTranslation();

  return (
    <BasicCard
      className={`app-card-certificate bg-light-blue no-shadow-card ${className}`}
    >
      <div className="d-flex justify-content-between ">
        <div className="d-flex flex-column flex-sm-row align-items-center">
          <div className="card-certificate-img">
            <img alt="document" src="/img/pro/document.svg" />
          </div>
          <div className="text-primary mt-4 mt-sm-0 ms-sm-3">
            <div className="mb-1">
              <small>{DateFormat.standardDate(new Date(date))}</small>
            </div>
            <div className="font-weight-bold mb-1">{title}</div>
            <div
              onClick={onClick}
              className="text-decoration-underline cursor-pointer"
            >
              {t<string>(
                `pro.mentions.${type}.detail.certificate.downloadCertificate`,
              )}
            </div>
          </div>
        </div>
        {expiratedDate && (
          <div className="text-primary mt-4 mt-sm-0 ms-sm-3 align-items-start">
            <div className="mb-1">
              <small>
                {t<string>(
                  `pro.mentions.${type}.detail.certificate.expiredAt`,
                  {
                    date: DateFormat.standardDate(new Date(expiratedDate)),
                  },
                )}
              </small>
            </div>
          </div>
        )}
      </div>
    </BasicCard>
  );
};

export default CertificateCard;
