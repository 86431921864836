import configuration from "@utils/Config";
import { restCreationHandler } from "@utils/rest/RestClient";
import { createEffect } from "effector";
import { ContactSaveRequestDto } from "@state/contact/backoffice/dto/contact.save.request.dto";

const baseUrl = `${configuration.backendBaseUrl}/contacts`;

export const requestCreateContact = createEffect({
  handler: restCreationHandler<ContactSaveRequestDto, void>(baseUrl),
});
