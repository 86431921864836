import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

export interface Props {
  module: string;
  field: string;
  showLabel?: boolean;
  required?: boolean;
  readOnly?: boolean;
  children?: string | JSX.Element;
  fieldPrefix?: string;
  emptyErrorMessage?: string;
  className?: string;
}

const ChildrenFormField = ({
  module,
  field,
  showLabel,
  required,
  readOnly,
  children,
  fieldPrefix = "",
  className = "",
  emptyErrorMessage,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const rules =
    !readOnly && required
      ? [
          {
            required: true,
            message: emptyErrorMessage ?? t<string>("common.empty_error_field"),
            whitespace: true,
          },
        ]
      : [];

  return (
    <Form.Item
      name={fieldPrefix + field}
      key={fieldPrefix + field}
      label={
        showLabel ? (
          <span className={readOnly ? "label-readonly" : ""}>
            {t<string>(`${module}.form.fields.${field}.label`)}&nbsp;
          </span>
        ) : (
          ""
        )
      }
      rules={rules}
      className={className}
    >
      {children}
    </Form.Item>
  );
};

export { ChildrenFormField };
