import { Spin } from "antd";
import { FunctionComponent } from "react";

interface LoaderProps {
  text?: string;
}

export const Loader: FunctionComponent<LoaderProps> = ({ text }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <Spin tip={text ? text : ""} size="large" />
    </div>
  );
};
