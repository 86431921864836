import { ROUTES } from "@utils/Routes";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { Fragment, useEffect, useMemo, useState } from "react";
import { requestGetOrganisationProfessionalInfo } from "@state/auth/AuthEffects";
import { setOrganisationProfessional } from "@state/auth/AuthEvents";
import { OrganisationProfessionalUser } from "@type/auth/auth.types";

const AuthOPLayout = (): JSX.Element => {
  const navigate = useNavigate();
  const isAuthentified = useStore(authentifiedStore);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loginRedirect = useMemo(
    () => ROUTES.professional.auth.loginOp.generate(),
    [],
  );

  useEffect(() => {
    void requestGetOrganisationProfessionalInfo({}).then((ar) => {
      if (ar.ok && ar.data) {
        const result = {
          id: ar.data.id,
          login: (isAuthentified.user as OrganisationProfessionalUser)?.login,
        } as OrganisationProfessionalUser;
        setOrganisationProfessional(result);
      } else {
        navigate(loginRedirect);
      }
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, loginRedirect]);

  if (!isLoading) {
    if (!isAuthentified?.user) {
      return (
        <Routes>
          <Route path="/*" element={<Navigate to={loginRedirect} />} />
        </Routes>
      );
    }
    return <Outlet />;
  }
  return <Fragment />;
};

export default AuthOPLayout;
