import { FunctionComponent, MouseEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";

interface SubmitButtonProps {
  module: string;
  isSubmitting?: boolean;
  label?: string;
  className?: string;
  variant?: string;
  size?: "sm" | "md" | "lg";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => any;
  disabled?: boolean;
}

const SubmitButton: FunctionComponent<SubmitButtonProps> = ({
  module,
  label,
  isSubmitting = false,
  size = "md",
  className = "",
  variant,
  onClick,
  disabled,
}: SubmitButtonProps): ReactElement => {
  const { t } = useTranslation();
  const submitButtonLabel = label
    ? `${module}.form.actions.${label}`
    : "forms.buttons.submit.defaultLabel";

  return (
    <BasicButton
      type="submit"
      isLoading={isSubmitting}
      text={t<string>(submitButtonLabel)}
      size={size}
      className={`${className} it-button`}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default SubmitButton;
