import { DateTime } from "luxon";

class DateFormat {
  standardDateTime(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy à HH'h'mm");
  }
  standardDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("dd/MM/yyyy");
  }

  articleDate(date: Date): string {
    return DateTime.fromJSDate(date).setLocale("fr").toFormat("dd MMMM yyyy");
  }

  standardTime(date: Date): string {
    const datetime = DateTime.fromJSDate(date);
    const hour = datetime.toFormat("HH'h'");
    const minute = datetime.toFormat("mm");

    return minute === "00" ? hour : `${hour}${minute}`;
  }

  formatUsDateToFrDate(date: string): string {
    return this.standardDate(new Date(date));
  }

  getNumberOfDaysBeforeTheDeadline(date: string) {
    let difference;
    if (date) {
      difference = Math.ceil(
        (new Date(date).getTime() - new Date(Date.now()).getTime()) /
          (1000 * 3600 * 24),
      );
    } else difference = 0;
    return difference;
  }
  standardDateIso(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  }
}

export default new DateFormat();
