import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import BasicAlert from "@components/alerts/BasicAlert";
import QualificationsRenewalModal from "@components/modals/QualificationsRenewalModal";
import QualificationsCard from "@components/cards/QualificationsCard";
import {
  requestCreateGazProfessionalRGRequest,
  requestGetGazProfessionalQualifications,
  requestGetGazProfessionalRGRequest,
  requestGetGazProfessionalRGRequests,
  requestUpdateGazProfessionalRGRequest,
} from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import {
  GazProfessionalStatus,
  GPQualificationsResponseDto,
} from "@state/professional/dto/professional.qualifications.response.dto";
import { QUALIFICATION_CARD_TYPE } from "@utils/enums/qualification.card.type.enum";
import { Loader } from "@components/loader/Loader";
import BasicButton from "@components/buttons/BasicButton";
import { columns } from "@components/lists/QualificationsRequestsColumns";
import Section from "@components/section/Section";
import { Empty, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GazProfessionalRGRequestItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.item.response.dto";
import {
  maptoRgRequestSpeciality,
  maptoRgRequestStatus,
  maptoRgRequestType,
} from "@utils/rgRequestMappers";
import { RgRequestStatus } from "@utils/enums/qualifications.rg-requests.status.enum";
import { Name } from "@utils/name-utils";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import RGRequestModalSwitch from "@components/switch/RGRequestModalSwitch";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/qualifications.rg-requests.speciality.enum";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";
import { DateTime } from "luxon";
import {
  QualificationStatus,
  QualificationValidity,
} from "@utils/enums/qualifications.status.enum";
import { computeQualificationStatus } from "@utils/qualification-utils";

export interface QualificationsRequestItem {
  id: string;
  createdAt: string;
  typeLabel: string;
  rg: string;
  status: RgRequestStatus;
  type: RGRequestType;
  isInstallation: boolean;
}

export const QualificationsScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [showRenewalModal, setShowRenewalModal] = useState<boolean>(false);
  const [qualifications, setQualifications] =
    useState<GPQualificationsResponseDto>();
  const [isCompletelyValid, setIsCompletelyValid] = useState<boolean>(true);

  const [showRGRequestModal, setShowRGRequestModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<RGRequestType>();
  const [modalMode, setModalMode] = useState<ModalMode>();

  const [selectedRGRequestId, setSelectedRGRequestId] = useState<string>("");
  const [isInstallation, setIsInstallation] = useState<boolean>(false);
  const [nameRG, setNameRG] = useState<Name>({
    firstName: "",
    lastName: "",
  });

  const [rgRequests, setRgRequests] =
    useState<GazProfessionalRGRequestItemResponseDto[]>();

  const [qualificationValidity, setQualificationValidity] =
    useState<QualificationValidity>({
      installation: QualificationStatus.valid,
      maintenance: QualificationStatus.valid,
    });

  useEffect(() => {
    const installationYear = qualifications?.installation.year;
    const maintenanceYear = qualifications?.maintenance.year;

    const now = DateTime.now();
    const currentYear = now.year;
    const currentMonth = now.month;
    const isRenewalPeriod = [12, 1, 2, 3, 4].includes(currentMonth);
    const validYear = currentMonth === 12 ? currentYear + 1 : currentYear;

    setQualificationValidity({
      installation: computeQualificationStatus(
        validYear,
        isRenewalPeriod,
        installationYear,
      ),
      maintenance: computeQualificationStatus(
        validYear,
        isRenewalPeriod,
        maintenanceYear,
      ),
    });
  }, [qualifications]);

  useEffect(() => {
    if (
      qualificationValidity.installation !== QualificationStatus.valid ||
      qualificationValidity.maintenance !== QualificationStatus.valid
    ) {
      setShowRenewalModal(true);
    }
  }, [qualificationValidity]);

  const fetchQualifications = useCallback(() => {
    requestGetGazProfessionalQualifications({}).catch(() => {
      toastAlert(t<string>("pro.qualifications.error"));
    });
  }, [t]);

  useEffect(() => {
    fetchQualifications();
  }, [fetchQualifications]);

  useEffect(() => {
    return requestGetGazProfessionalQualifications.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setQualifications(data);
          setIsCompletelyValid(
            data.installation.status === GazProfessionalStatus.VALID ||
              data.maintenance.status === GazProfessionalStatus.VALID,
          );
        }
      },
    );
  });

  const fetchRGRequests = useCallback(() => {
    setDataHasLoaded(false);
    requestGetGazProfessionalRGRequests({}).catch(() => {
      toastAlert(t<string>("pro.qualifications.error"));
    });
  }, [t]);

  useEffect(() => {
    fetchRGRequests();
  }, [fetchRGRequests]);

  useEffect(() => {
    return requestGetGazProfessionalRGRequests.done.watch(
      ({ result: { data, ok } }) => {
        if (ok && data) {
          setRgRequests(data);
          setDataHasLoaded(true);
        }
      },
    );
  });

  function mapToRequest(rgRequest: GazProfessionalRGRequestItemResponseDto) {
    const type = maptoRgRequestType(rgRequest.type);
    const speciality = maptoRgRequestSpeciality(rgRequest.speciality);
    const status = maptoRgRequestStatus(rgRequest.status);
    return {
      id: rgRequest.id,
      createdAt: rgRequest.creationDate,
      typeLabel: `${t(type.label)} ${t(speciality.label)}`,
      rg: `${rgRequest.lastName} ${rgRequest.firstName}`,
      status,
      type,
      isInstallation: speciality === RG_REQUEST_SPECIALITY.INSTALLATION,
    };
  }

  function buildRequests() {
    return rgRequests?.map((rgRequest) => mapToRequest(rgRequest));
  }

  useEffect(() => {
    return requestCreateGazProfessionalRGRequest.done.watch(
      ({ result: { ok } }) => {
        if (ok) {
          setShowRGRequestModal(false);
          fetchRGRequests();
        }
      },
    );
  });

  useEffect(() => {
    return requestUpdateGazProfessionalRGRequest.done.watch(
      ({ result: { ok } }) => {
        if (ok) {
          setShowRGRequestModal(false);
          fetchRGRequests();
        }
      },
    );
  });

  const fetchRGRequest = (id: string) => {
    requestGetGazProfessionalRGRequest(id).catch(() => {
      toastAlert(t<string>("pro.qualifications.modals.details.error.toast"));
    });
  };

  return (
    <PageLayout
      container
      title={t<string>("pro.qualifications.title")}
      titleCentered={false}
      subtitle={
        <p>
          {parse(t<string>("pro.qualifications.subtitle"))}
          <a
            href="#"
            onClick={() => navigate(ROUTES.professional.tools.generate())}
          >
            {t<string>("pro.tools.title")}
          </a>
          )
        </p>
      }
    >
      <>
        {!isCompletelyValid && (
          <>
            {/*TODO !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/}
            <BasicAlert
              text={
                <>
                  <div>{t<string>("pro.qualifications.alert1")}</div>
                  <div>{t<string>("pro.qualifications.alert2")}</div>
                </>
              }
              variant="danger"
              className="mb-4"
            />
          </>
        )}
        {qualifications == null ? (
          <Loader text={t<string>("pro.loader")} />
        ) : (
          <div className="qualifications-cards">
            <QualificationsCard
              title={t<string>("pro.qualifications.installation.title")}
              className="flex-grow-1"
              qualification={qualifications.installation}
              type={QUALIFICATION_CARD_TYPE.INSTALLATION}
              isInstallation
              section="PG"
              setIsInstallation={setIsInstallation}
              setShowRGRequestModal={setShowRGRequestModal}
              setModalMode={setModalMode}
              setModalType={setModalType}
              setNameRG={setNameRG}
            />
            <QualificationsCard
              title={t<string>("pro.qualifications.maintenance.title")}
              className="flex-grow-1"
              qualification={qualifications.maintenance}
              type={QUALIFICATION_CARD_TYPE.MAINTENANCE}
              isInstallation={false}
              section="PG"
              setIsInstallation={setIsInstallation}
              setShowRGRequestModal={setShowRGRequestModal}
              setModalMode={setModalMode}
              setModalType={setModalType}
              setNameRG={setNameRG}
            />
          </div>
        )}

        <Section
          title={t<string>("pro.qualifications.requests.title")}
          titleClassName="text-primary"
          rightContent={
            <BasicButton
              icon={<PlusOutlined />}
              iconRight
              text={t<string>("pro.qualifications.requests.addRG")}
              onClick={() => {
                setModalMode(ModalMode.CREATE);
                setModalType(RG_REQUEST_TYPE.ADD);
                setShowRGRequestModal(true);
                setSelectedRGRequestId("");
              }}
            />
          }
          className="mt-40"
        >
          <Table
            locale={{
              emptyText: <Empty description={t<string>("common.noData")} />,
            }}
            columns={columns(
              setShowRGRequestModal,
              setModalType,
              setModalMode,
              setIsInstallation,
              setNameRG,
              setSelectedRGRequestId,
              fetchRGRequest,
            )}
            dataSource={buildRequests()}
            loading={{
              spinning: !dataHasLoaded,
              indicator: <Loader />,
            }}
            size="small"
            className="app-contact-pro-table pro-table-cursor-default"
            pagination={false}
          />
        </Section>

        {modalMode != undefined && (
          <RGRequestModalSwitch
            visible={showRGRequestModal}
            onCancel={() => setShowRGRequestModal(false)}
            modalType={modalType}
            modalMode={modalMode}
            rgRequestId={selectedRGRequestId}
            isInstallationSection={isInstallation}
            name={nameRG}
          />
        )}
        <QualificationsRenewalModal
          visible={showRenewalModal}
          qualificationValidity={qualificationValidity}
          onCancel={() => setShowRenewalModal(false)}
        />
      </>
    </PageLayout>
  );
};
