import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

interface IndividualGazEventConditionsProps {
  className?: string;
}

const IndividualGazEventConditions: FunctionComponent<
  IndividualGazEventConditionsProps
> = ({ className = "" }) => {
  const { t } = useTranslation();

  return (
    <div className={`app-individual-gaz-month-conditions ${className}`}>
      <div className="content-contained">
        <div className="individual-gaz-month-conditions-logos">
          <img
            alt={t<string>("gazMonth.individual.home.conditions.pg.alt")}
            src="/logo-header-PG-professional.svg"
          />
        </div>
        <div className="individual-gaz-month-conditions-text">
          {parse(t("gazMonth.individual.home.conditions.text"))}
        </div>
      </div>
    </div>
  );
};

export default IndividualGazEventConditions;
