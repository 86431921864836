import { QualificationStatus } from "./enums/qualifications.status.enum";

export const computeQualificationStatus = (
  validYear: number,
  isRenewalPeriod: boolean,
  year?: number | string | null,
): QualificationStatus => {
  if (!year) {
    return QualificationStatus.valid;
  }
  const delta = validYear - parseInt(year.toString());
  if (delta === 0) {
    return QualificationStatus.valid;
  } else if (delta === 1) {
    return isRenewalPeriod
      ? QualificationStatus.expiredSoon
      : QualificationStatus.invalid;
  }
  return QualificationStatus.fired;
};
