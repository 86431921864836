export interface GPQualificationsResponseDto {
  installation: QualificationsItemProps;
  maintenance: QualificationsItemProps;
}
export interface QualificationsItemProps {
  year: number | string;
  yearValid: boolean;
  status: GazProfessionalStatus;
  qualificationDate: string;
  lastAuditDate?: string;
  result?: string;
  rgs: RG[];
}

export interface RG {
  name: string;
  expirationDate: string;
  status: string;
}

export enum GazProfessionalStatus {
  VALID = "VALID",
  INPROGRESS = "IN_PROGRESS",
  INVALID = "INVALID",
}
