import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@utils/Routes";

interface IndividualGazEventConfirmationSectionProps {
  className?: string;
}

const IndividualGazEventConfirmationSection: FunctionComponent<
  IndividualGazEventConfirmationSectionProps
> = ({ className = "" }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={`app-individual-gaz-month-confirmation ${className}`}>
      <div className="individual-gaz-month-confirmation-container content-contained">
        <div className="individual-gaz-month-confirmation-left">
          <h2 className="individual-gaz-month-confirmation-title">
            {t<string>("gazMonth.individual.confirmation.title")}
          </h2>
          <div className="individual-gaz-month-confirmation-description">
            {t<string>("gazMonth.individual.confirmation.description")}
          </div>
          <BasicButton
            text={t<string>("gazMonth.individual.confirmation.button")}
            onClick={() => navigate(ROUTES.individual.publicHome.generate())}
          />
        </div>
        <div className="individual-gaz-month-confirmation-right">
          <img
            alt={t<string>("gazMonth.individual.confirmation.alt")}
            src={"/img/gazMonth/confirmation.svg"}
          />
        </div>
      </div>
    </div>
  );
};

export default IndividualGazEventConfirmationSection;
