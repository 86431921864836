import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import configuration from "@utils/Config";
import { IconSend } from "../../static/icons";
import { getAddressWithZipCodeAndCity } from "@utils/map-utils";
import parse from "html-react-parser";

interface ProfessionalItemProps {
  professional: GazProfessionalItemResponseDto;
  className?: string;
  inContactList?: boolean;
  onToggleContact?: (item: GazProfessionalItemResponseDto) => void;
  setModalVisible?: (item: boolean) => void;
  setSelectedGazProfessional?: (item: GazProfessionalItemResponseDto) => void;
  mapContact?: GazProfessionalItemResponseDto;
}

const ProfessionalItemResult: FunctionComponent<ProfessionalItemProps> = ({
  professional,
  className = "",
  inContactList = false,
  onToggleContact,
  setSelectedGazProfessional,
  setModalVisible,
  mapContact,
}) => {
  const { t } = useTranslation();

  const isGazEvent = configuration.isGazEvent;

  const getMention = () => {
    const mention = [
      professional?.pacHybride ? "PAC hybride" : null,
      professional?.smokeDuct ? "Conduit de fumée" : null,
      professional?.greenGaz ? "Gaz vert" : null,
      professional?.erp ? "ERP" : null,
    ];
    return mention.filter((m) => m != null).join(" • ");
  };

  return (
    <div
      className={`app-professional-item ${
        inContactList || mapContact?.id === professional.id
          ? "item-selected"
          : ""
      } ${className}`}
      onClick={
        onToggleContact && !professional.chimneyProfessional
          ? () => onToggleContact(professional)
          : undefined
      }
    >
      {professional && (
        <>
          <div className="professional-item-info">
            <div className="professional-item-name">
              <span>{professional.companyName}</span>
              {professional.amountOfTheHighestOffer != 0 &&
                !professional.chimneyProfessional &&
                isGazEvent &&
                professional.hasAction && (
                  <div className="professional-item-offer-number">
                    {t<string>("gazprofessional.amountOffer", {
                      amount: professional.amountOfTheHighestOffer,
                    })}
                  </div>
                )}
            </div>
            {(professional.greenGaz ||
              professional.pacHybride ||
              professional.smokeDuct ||
              professional.erp) && (
              <div className="professional-item-mention">{getMention()}</div>
            )}
            <div className="professional-item-address">
              {parse(getAddressWithZipCodeAndCity(professional))}
            </div>
            {!professional.chimneyProfessional && (
              <div className="professional-item-details">
                {t<string>("gazprofessional.seeDetails")}
              </div>
            )}
          </div>
          {!professional.chimneyProfessional && (
            <div
              className="professional-item-contact"
              onClick={(e) => {
                e.stopPropagation();
                !inContactList && setModalVisible?.(true);
                setSelectedGazProfessional?.(professional);
              }}
            >
              <div
                className={`${
                  inContactList
                    ? "professional-item-contacted-icon"
                    : "professional-item-contact-icon"
                }`}
              >
                <IconSend />
              </div>
              <span className={`${inContactList ? "contacted" : ""}`}>
                {t<string>(
                  `${
                    inContactList
                      ? "gazprofessional.contacted"
                      : "gazprofessional.contact"
                  }`,
                )}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProfessionalItemResult;
