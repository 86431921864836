import { FunctionComponent, useEffect, useState } from "react";
import { Form } from "antd";
import BaseForm from "@components/forms/BaseForm";
import { FORMS_FIELD_TYPE } from "@type/form/field.types";
import {
  CURRENT_ENERGIES,
  EQUIPMENT_TYPES,
  INTERVENTION_TYPES,
} from "@utils/enums/search.enum";
import { Store } from "rc-field-form/lib/interface";
import { requestCreateContacts } from "@state/contact/gazprofessional/ContactGazProfessionalEffects";
import { useTranslation } from "react-i18next";
import { emailFormatRegex, phoneNumberFormatRegex } from "@utils/Constant";
import TagManager from "react-gtm-module";
import MaskInputFormField from "@components/inputs/MaskInputFormField";
import { AddressFields } from "@components/inputs/AddressFields";
import { useStore } from "effector-react";
import { contactGazProfessionalStore } from "@state/contact/gazprofessional/ContactGazProfessionalStore";
import { toastError, toastSuccess } from "@utils/toast-helper";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  interventionType: string;
  currentEnergy: string;
  equipmentTypes: string[];
  message: string;
  callbackGrdf: boolean;
  form: string;
  source: string;
  medium: string;
  campaign: string;
  url?: string;
}

interface BasicCardProps {
  className?: string;
  gazProfessional: GazProfessionalItemResponseDto;
  setContactModalVisible: (isContactModalVisible: boolean) => void;
  initSearchOption: {
    selectedAddressLabel?: string;
    selectedInterventionType?: string;
  };
  originForm?: string;
  setContactList?: (item: GazProfessionalItemResponseDto[]) => void;
  contactList?: GazProfessionalItemResponseDto[];
}

const ProfessionalContactForm: FunctionComponent<BasicCardProps> = ({
  originForm,
  gazProfessional,
  setContactModalVisible,
  initSearchOption,
  contactList,
  setContactList,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const contactInfo = useStore(contactGazProfessionalStore);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  initSearchOption.selectedAddressLabel &&
    form.setFields([
      { name: "address", value: initSearchOption.selectedAddressLabel },
    ]);
  initSearchOption.selectedInterventionType &&
    form.setFields([
      {
        name: "interventionType",
        value: initSearchOption.selectedInterventionType,
      },
    ]);

  const handleSubmit = (values: Store): void => {
    TagManager.dataLayer({
      dataLayer: {
        event: "contacter_formulaire_carte",
      },
    });
    const obj = values as FormValues;
    obj.form = originForm ?? "";
    obj.source = contactInfo.source ?? "";
    obj.medium = contactInfo.medium ?? "";
    obj.campaign = contactInfo.campaign ?? "";
    obj.url = contactInfo.url ?? "";

    void requestCreateContacts({
      dto: {
        ...obj,
        contactType: "GAZ_PROFESSIONAL",
        gazProfessionalIds: [gazProfessional.id],
      },
    });
    setIsLoading(true);
  };

  useEffect(() => {
    return requestCreateContacts.done.watch(({ result }) => {
      if (result.ok && result.data) {
        toastSuccess(t<string>("search.contacts.success"));
        setContactModalVisible(false);
        setContactList?.(contactList ? [gazProfessional, ...contactList] : []);
      } else {
        toastError(result.errorMessage);
      }
      setIsLoading(false);
    });
  });

  const FORM = {
    main: {
      key: "main",
      module: "search.contacts",
      title: "form.title",
      fields: [
        [
          {
            name: "lastName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "firstName",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            minLength: 2,
          },
          {
            name: "email",
            type: FORMS_FIELD_TYPE.TEXT,
            size: 12,
            required: true,
            pattern: new RegExp(emailFormatRegex),
            htmlInputType: "email",
          },
          {
            name: "phone",
            type: FORMS_FIELD_TYPE.CUSTOM,
            custom: (
              <MaskInputFormField
                type="tel"
                required
                mask="## ## ## ## ##"
                showLabel
                module="search.contacts"
                field="phone"
                rules={[
                  {
                    pattern: new RegExp(phoneNumberFormatRegex),
                    message: t<string>("forms.errors.format"),
                  },
                  {
                    min: 10,
                    message: t<string>("forms.errors.length", { size: "10" }),
                  },
                ]}
                emptyErrorMessage={t<string>(
                  "search.contacts.form.fields.phone.errors.required",
                )}
              />
            ),
            size: 12,
          },
          {
            name: "address",
            type: FORMS_FIELD_TYPE.CUSTOM,
            size: 24,
            required: true,
            custom: (
              <AddressFields
                module="search.contacts"
                field="address"
                showLabel
                emptyErrorMessage={t<string>(
                  "search.contacts.form.fields.address.errors.required",
                )}
              />
            ),
          },
          {
            name: "interventionType",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(INTERVENTION_TYPES),
            size: 12,
          },
          {
            name: "currentEnergy",
            type: FORMS_FIELD_TYPE.SELECT,
            required: true,
            options: Object.values(CURRENT_ENERGIES),
            size: 12,
          },
          {
            name: "equipmentTypes",
            type: FORMS_FIELD_TYPE.MULTISELECT,
            required: true,
            options: Object.values(EQUIPMENT_TYPES),
          },
          {
            name: "message",
            type: FORMS_FIELD_TYPE.TEXTAREA,
            size: 24,
          },
          {
            name: "callbackGrdf",
            type: FORMS_FIELD_TYPE.CHECKBOX,
            size: 24,
            required: true,
          },
        ],
      ],
    },
  };

  return (
    <BaseForm
      module="search.contacts"
      form={form}
      editMode={true}
      formConfiguration={FORM}
      hasBackButton={false}
      hasConfirmPopup={false}
      customOnSubmitCallback={handleSubmit}
      loading={isLoading}
    />
  );
};

export default ProfessionalContactForm;
