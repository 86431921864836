import { createStore } from "effector";
import {
  addGazEventAction,
  authentifiedContextEvent,
  changeUniverse,
  clearUser,
  deleteGazEventAction,
  logout,
  setGazEventRegistration,
  setMentionPacHybride,
  setMentionSmokeDuct,
  setOrganisationProfessional,
  setShowGazEventAlert,
  setUser,
  updateGazEventAction,
} from "@state/auth/AuthEvents";
import {
  AuthentifiedContext,
  GazProfessionalUser,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import logger from "@utils/logger";
import { Universe, UNIVERSES } from "@utils/enums/universe.enum";
import { MentionResponseDto } from "@state/mention/dto/mention.response.dto";
import {
  GazEventActionResponseDto,
  GazEventRegistrationResponseDto,
} from "@state/gazMonth/dto/gaz.event.registration.response.dto";
import { resetMailProfessionalStore } from "@state/mailprofessional/MailProfessionalEvents";
import { requestLogout } from "@state/auth/AuthEffects";

export interface TablePageSizePayload {
  value: number;
  property: string;
}

export const authentifiedStore = createStore<AuthentifiedContext>({
  user: undefined,
  universe: UNIVERSES["INDIVIDUAL"],
  typeUser: "",
});

authentifiedStore
  .on(authentifiedContextEvent, (state, payload) => {
    return payload === undefined
      ? {
          ...state,
          user: undefined,
        }
      : {
          ...state,
          ...payload,
        };
  })
  .on(changeUniverse, (state, payload: Universe): AuthentifiedContext => {
    return {
      ...state,
      universe: payload,
    };
  })
  .on<GazProfessionalUser>(
    setUser,
    (state, payload: GazProfessionalUser): AuthentifiedContext => {
      return {
        ...state,
        user: { ...payload, showGazEventAlert: state.user?.showGazEventAlert },
        typeUser: "GazProfessionalUser",
      };
    },
  )
  .on<OrganisationProfessionalUser>(
    setOrganisationProfessional,
    (state, payload: OrganisationProfessionalUser): AuthentifiedContext => {
      return {
        ...state,
        user: { ...payload },
        typeUser: "OrganisationProfessionalUser",
      };
    },
  )
  .on(clearUser, (state) => ({
    ...state,
    user: undefined,
    typeUser: "",
  }))
  .on(setMentionPacHybride, (state, payload: MentionResponseDto) => {
    return {
      ...state,
      user: state.user && {
        ...state.user,
        pacHybride: payload,
      },
    };
  })
  .on(setMentionSmokeDuct, (state, payload: MentionResponseDto) => {
    return {
      ...state,
      user: state.user && {
        ...state.user,
        smokeDuct: payload,
      },
    };
  })
  .on(setShowGazEventAlert, (state, payload: boolean) => {
    return {
      ...state,
      user: state.user && {
        ...state.user,
        showGazEventAlert: payload,
      },
    };
  })
  .on(
    setGazEventRegistration,
    (state, payload: GazEventRegistrationResponseDto) => {
      return {
        ...state,
        user: state.user && {
          ...state.user,
          gazEvent: payload,
        },
      };
    },
  )
  .on<GazEventActionResponseDto>(
    addGazEventAction,
    (state, payload: GazEventActionResponseDto) => {
      const userPg = state.user as GazProfessionalUser;
      return {
        ...state,
        user: userPg && {
          ...userPg,
          gazEvent: userPg.gazEvent && {
            ...userPg.gazEvent,
            actions: [...userPg.gazEvent?.actions, payload],
          },
        },
      };
    },
  )
  .on<string>(deleteGazEventAction, (state, payload: string) => {
    const userPg = state.user as GazProfessionalUser;
    return {
      ...state,
      user: userPg && {
        ...userPg,
        gazEvent: userPg.gazEvent && {
          ...userPg.gazEvent,
          actions: [
            ...userPg.gazEvent.actions.filter((action) => action.id != payload),
          ],
        },
      },
    };
  })
  .on<GazEventActionResponseDto>(
    updateGazEventAction,
    (state, payload: GazEventActionResponseDto) => {
      const userPg = state.user as GazProfessionalUser;
      return {
        ...state,
        user: userPg && {
          ...userPg,
          gazEvent: userPg.gazEvent && {
            ...userPg.gazEvent,
            actions: [
              ...userPg.gazEvent.actions.filter(
                (action) => action.id != payload.id,
              ),
              payload,
            ],
          },
        },
      };
    },
  );

logout.watch(() => {
  logger.debug("Clear storage");
  localStorage.clear();
  sessionStorage.clear();
  clearUser();
  resetMailProfessionalStore();
  void requestLogout({ dto: undefined });
});
