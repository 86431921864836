import { FunctionComponent } from "react";
import { DataBlockItem } from "@type/cms/cms.types";
import Section from "@components/section/Section";
import DataBlock from "@components/content/DataBlock";

interface DataGroupProps {
  title?: string;
  content: DataBlockItem[];
  backgroundColor?: string;
  packed?: boolean;
  className?: string;
  carded?: boolean;
  cardColor?: string;
  dataClassName?: string;
}

const DataGroupModuleCMS: FunctionComponent<DataGroupProps> = ({
  title = "",
  content = [],
  className = "",
  backgroundColor = "transparent",
  packed = false,
  carded = false,
  cardColor = "white",
  dataClassName = "",
}) => {
  const renderImageGroup = () => {
    return (
      <div
        className={`content-group-content ${
          packed ? "justify-content-center" : "justify-content-around"
        }`}
        style={{ backgroundColor: carded ? cardColor : "transparent" }}
      >
        {content.map((data: DataBlockItem, index: number) => (
          <DataBlock
            key={index}
            description={data?.description ?? ""}
            content={data?.content}
            title={data?.title}
            compact={packed}
            className={dataClassName}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={`app-content-group ${className} ${
        carded ? "content-group-carded" : ""
      }`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="content-contained">
        {title ? (
          <Section title={title} className={"content-group-section"}>
            {renderImageGroup()}
          </Section>
        ) : (
          renderImageGroup()
        )}
      </div>
    </div>
  );
};

export default DataGroupModuleCMS;
