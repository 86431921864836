import i18n from "i18next";
import { tooltipDateTimeRender } from "@components/tooltips/CustomTooltip";
import { ReactNode } from "react";
import { MaintenanceCertificateItemResponseDto } from "@state/maintenancecertificate/dto/maintenance.certificate.item.response.dto";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import TableButton from "@components/buttons/TableButton";

export const columns = (
  onRowClick: (record: string) => void,
  onDelete: (record: MaintenanceCertificateItemResponseDto) => void,
  onDownload: (record: string) => void,
) => [
  {
    title: i18n.t<string>("pro.certificate.table.headers.createdAt"),
    dataIndex: "createdAt",
    key: "createdAt",
    className: "reception",
    width: 170,
    render: tooltipDateTimeRender,
    sorter: true,
  },
  {
    title: i18n.t<string>("pro.certificate.table.headers.name"),
    key: "lastName",
    className: "name",
    ellipsis: {
      showTitle: false,
    },
    render: (record: MaintenanceCertificateItemResponseDto): ReactNode => {
      const name = `${record.clientFirstname} ${record.clientLastname}`;
      return (
        <div className="d-flex align-items-center gap-3">
          {name}
          {record.status == "INIT" && (
            <span>{i18n.t<string>("pro.certificate.table.headers.draft")}</span>
          )}
        </div>
      );
    },
    width: 185,
    sorter: true,
    showSorterTooltip: false,
  },
  {
    title: i18n.t("pro.certificate.table.headers.actions"),
    key: "id",
    className: "actions",
    ellipsis: {
      showTitle: false,
    },
    width: 105,
    onCell: (): any => {
      return {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
          e.stopPropagation(),
      };
    },
    render: (record: MaintenanceCertificateItemResponseDto): JSX.Element => (
      <div className="d-inline-flex gap-3">
        {record.status == "INIT" ? (
          <TableButton
            tooltipTitle={i18n.t("pro.certificate.table.tooltip.edit")}
            icon={<EditOutlined />}
            buttonClassName="btn-transparent w-auto ms-3"
            onClick={() => {
              onRowClick(record.id);
            }}
          />
        ) : (
          <TableButton
            tooltipTitle={i18n.t("pro.certificate.table.tooltip.download")}
            icon={<DownloadOutlined />}
            buttonClassName="btn-transparent w-auto ms-3"
            onClick={() => {
              onDownload(record.attestationId);
            }}
          />
        )}
        <TableButton
          tooltipTitle={i18n.t("pro.certificate.table.tooltip.delete")}
          icon={<DeleteOutlined />}
          buttonClassName="btn-transparent w-auto"
          onClick={() => {
            onDelete(record);
          }}
        />
      </div>
    ),
  },
];
