import { createEffect } from "effector";
import { restGetUniqueHandler, restPostHandler } from "@utils/rest/RestClient";
import {
  AuthResult,
  GazProfessionalUser,
  OrganisationProfessionalUser,
} from "@type/auth/auth.types";
import configuration from "src/utils/Config";
import { ResetPasswordRequestDto } from "@state/auth/dto/reset.password.request.dto";
import { ValidateTokenRequestDto } from "@state/auth/dto/validate.token.request.dto";
import { ForgotPasswordRequestDto } from "@state/auth/dto/forgot.password.request.dto";
import { LoginRequestDto } from "@state/auth/dto/login.request.dto";
import { RegistrationRequestDto } from "@state/auth/dto/registration.request.dto";
import { GazProfessionalItemResponseDto } from "@state/gazprofessional/dto/gaz.professional.item.response.dto";
import { UpdatePasswordRequestDto } from "@state/auth/dto/update.password.request.dto";
import { ForgotPasswordResponseDto } from "@state/auth/response/forgot.password.response.dto";
import { LoginOrganisationProfessionalRequestDto } from "@state/auth/dto/login.op.request.dto";

const userInfoUrl = "/gaz-professionals/me";
const organisationProfessionalsInfoUrl = "/organisation-professionals/me";

export const requestGetUserInfo = createEffect({
  handler: restGetUniqueHandler<GazProfessionalUser>(
    configuration.backendBaseUrl + userInfoUrl,
  ),
});

export const requestGetOrganisationProfessionalInfo = createEffect({
  handler: restGetUniqueHandler<OrganisationProfessionalUser>(
    configuration.backendBaseUrl + organisationProfessionalsInfoUrl,
  ),
});
export const requestForgotPassword = createEffect({
  handler: restPostHandler<ForgotPasswordRequestDto, ForgotPasswordResponseDto>(
    configuration.backendBaseUrl + "/gaz-professionals/forgot-password",
  ),
});
export const requestResetPassword = createEffect({
  handler: restPostHandler<ResetPasswordRequestDto, void>(
    configuration.backendBaseUrl + "/gaz-professionals/reset-password",
  ),
});

export const requestUpdatePassword = createEffect({
  handler: restPostHandler<UpdatePasswordRequestDto, void>(
    configuration.backendBaseUrl + "/gaz-professionals/reset-password/me",
  ),
});

export const requestValidateResetToken = createEffect({
  handler: restPostHandler<ValidateTokenRequestDto, void>(
    configuration.backendBaseUrl + "/gaz-professionals/validate-reset-token",
  ),
});

export const requestLogin = createEffect({
  handler: restPostHandler<LoginRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/gaz-professionals/login",
  ),
});

export const requestLogout = createEffect({
  handler: restPostHandler<void, void>(
    configuration.backendBaseUrl + "/gaz-professionals/logout",
  ),
});

export const requestLoginOrganisationProfessional = createEffect({
  handler: restPostHandler<LoginOrganisationProfessionalRequestDto, AuthResult>(
    configuration.backendBaseUrl + "/organisation-professionals/login",
  ),
});

export const requestRegistration = createEffect({
  handler: restPostHandler<
    RegistrationRequestDto,
    GazProfessionalItemResponseDto
  >(`${configuration.backendBaseUrl}/gaz-professionals/registration`),
});
