import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "@components/layouts/PageLayout";
import { Empty, Table } from "antd";
import { columns } from "@components/lists/ContactGazProfessionalColumns";
import { Pageable } from "@type/pagination/pagination.types";
import { emptySearchResult } from "@utils/Constant";
import { useNavigate } from "react-router-dom";
import { requestGetContacts } from "@state/contact/gazprofessional/ContactGazProfessionalEffects";
import { ContactItemResponseDto } from "@state/contact/gazprofessional/dto/contacts.gazprofessional.item.response.dto";
import { SorterResult } from "antd/es/table/interface";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";

export const ContactsListScreen: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const [contacts, setContacts] =
    useState<Pageable<ContactItemResponseDto>>(emptySearchResult);

  const [dataHasLoaded, setDataHasLoaded] = useState<boolean>(true);

  const [sorter, setSorter] = useState<SorterResult<any>>({
    columnKey: "status",
    order: "ascend",
  });

  const onChangeLimit = (current: number, newLimit: number): void => {
    setLimit(newLimit);
  };

  const fetchPages = useCallback(() => {
    setDataHasLoaded(false);
    requestGetContacts({
      page: pageNumber - 1,
      limit,
      sorter,
      dto: {},
    })
      .catch(() => {
        toastError(t<string>("notification.void messages.error"));
      })
      .finally(() => {
        setDataHasLoaded(true);
      });
  }, [pageNumber, limit, t, sorter]);

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  useEffect(() => {
    return requestGetContacts.done.watch(({ result }) => {
      if (result.ok && result.data) {
        setContacts(result.data);
      }
    });
  });

  return (
    <PageLayout
      container
      title={t<string>("pro.contact.list.title")}
      titleCentered={false}
      subtitle={t<string>("pro.contact.list.subtitle")}
    >
      <Table
        locale={{
          emptyText: <Empty description={t<string>("common.noData")} />,
        }}
        columns={columns}
        dataSource={contacts.content}
        loading={!dataHasLoaded}
        size="small"
        rowClassName={(record: ContactItemResponseDto) =>
          record.status.toLowerCase()
        }
        className="app-contact-pro-table"
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(
                ROUTES.professional.contacts.details.generate(record.id),
                {
                  state: {
                    initialContact: record,
                  },
                },
              );
            },
          };
        }}
        pagination={{
          total: contacts.totalElements,
          pageSize: contacts.size,
          current: contacts.number + 1,
          onChange: setPageNumber,
          onShowSizeChange: onChangeLimit,
          showSizeChanger: true,
        }}
        onChange={(pagination, filters, sorter) =>
          setSorter(sorter as SorterResult<any>)
        }
      />
    </PageLayout>
  );
};
