import {
  RG_REQUEST_TYPE,
  RGRequestType,
} from "@utils/enums/qualifications.rg-requests.type.enum";
import { Modal } from "antd";
import { RESOURCE_TYPE } from "@utils/enums/resource.type.enum";
import { SectionSpeciality } from "@utils/enums/qualifications.rg-requests.section.enum";
import { TFunction } from "i18next";
import { RG_REQUEST_RAD_REASON } from "@utils/enums/qualifications.rg-requests.rad.reason.enum";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { requestUpdateOrganisationProfessionalRGRequest } from "@state/organisationprofessional/OrganisationProfessionalEffects";
import { OrganisationProfessionalRGRequestSaveRequestDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.save.request.dto";

const { confirm } = Modal;

export const RG_REQUEST_CERTIFICATE_ACCEPTED_MENTION_TYPES = [
  RESOURCE_TYPE.PDF.applicationType,
  RESOURCE_TYPE.PNG.applicationType,
  RESOURCE_TYPE.JPEG.applicationType,
  RESOURCE_TYPE.JPG.applicationType,
];

export const options = (
  t: TFunction,
): { value: string | number; label: string }[] => [
  {
    value: RG_REQUEST_RAD_REASON.LEAVE.value,
    label: t(RG_REQUEST_RAD_REASON.LEAVE.label),
  },
  {
    value: RG_REQUEST_RAD_REASON.NO_VALIDATION.value,
    label: t(RG_REQUEST_RAD_REASON.NO_VALIDATION.label),
  },
  {
    value: RG_REQUEST_RAD_REASON.RETIREMENT.value,
    label: t(RG_REQUEST_RAD_REASON.RETIREMENT.label),
  },
];

export const getTypeButton = (fileId: string): "select" | "replace" => {
  return fileId === "" ? "select" : "replace";
};

export const getLabelSpeciality = (
  speciality: SectionSpeciality,
  typeButton: string,
): "installation" | "maintenance" | "noFile" => {
  if (typeButton === "select") {
    return "noFile";
  } else {
    return getSection(speciality);
  }
};

export const getSection = (
  speciality: SectionSpeciality,
): "installation" | "maintenance" => {
  return speciality === SectionSpeciality.INSTALLATION
    ? "installation"
    : "maintenance";
};

export const confirmationPopup = async (
  isOPSection: boolean,
  type: RGRequestType,
  t: TFunction,
): Promise<boolean> => {
  const typeLabel = getTypeLabel(type);
  const baseCondition = isOPSection
    ? "organisationprofessional.requests"
    : "pro.qualifications";
  return new Promise<boolean>((resolve) => {
    confirm({
      title: t("common.confirm"),
      content: t(`${baseCondition}.modals.${typeLabel}.popup`),
      okText: t("common.yes"),
      cancelText: t("common.no"),
      centered: true,
      className: "app-modal professional",
      onOk() {
        resolve(true);
      },
      onCancel() {
        resolve(false);
      },
    });
  });
};

export const getTypeLabel = (
  type: RGRequestType,
): "addRG" | "radRG" | "updateCertificate" => {
  switch (type) {
    case RG_REQUEST_TYPE.ADD:
      return "addRG";
    case RG_REQUEST_TYPE.RAD:
      return "radRG";
    case RG_REQUEST_TYPE.UPDATE:
      return "updateCertificate";
    default:
      return "addRG";
  }
};

export const getSpeciality = (
  installation: boolean,
  maintenance: boolean,
): "ALL" | "MAINTENANCE" | "INSTALLATION" | undefined => {
  if (maintenance && installation) {
    return "ALL";
  } else if (maintenance) {
    return "MAINTENANCE";
  } else if (installation) {
    return "INSTALLATION";
  } else {
    return undefined;
  }
};

export const getButtonLabel = (
  isOPSection: boolean,
  modalMode: ModalMode,
): "submit" | "resubmit" => {
  if (isOPSection) {
    return "submit";
  } else {
    return modalMode === ModalMode.CREATE ? "submit" : "resubmit";
  }
};

export const isReadOnly = (mode: ModalMode, isOPSection: boolean): boolean =>
  isOPSection || mode === ModalMode.DETAILS;

export const requestRGRequestOPProceed = (
  isValidated: boolean,
  rgRequestId: string,
  comment: string | undefined,
): void => {
  const rgRequestToSave: OrganisationProfessionalRGRequestSaveRequestDto = {
    comment: isValidated ? "" : comment,
  };
  void requestUpdateOrganisationProfessionalRGRequest({
    id: rgRequestId,
    dto: rgRequestToSave,
  });
};
