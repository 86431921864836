import { FunctionComponent } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { Card, Divider } from "antd";

interface MaintenanceCertificateModalHelp4Props {
  open: boolean;
  onCancel: () => void;
  className?: string;
}

const MaintenanceCertificateHelp4Modal: FunctionComponent<
  MaintenanceCertificateModalHelp4Props
> = ({ open, onCancel, className = "" }) => {
  const { t } = useTranslation();

  return (
    <BasicModal
      className={className}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <>
        <h2 className="mb-30 mt-30">
          {t<string>("pro.certificate.form.help.global.subtitle1")}
        </h2>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.wood")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.boilerLog")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.c")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.wood")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.boilerPellet")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.a")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.electricity")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.pacWaterWater")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.a++")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.electricity")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.pacAirWater")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.a+")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.gaz")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>(
                "pro.certificate.form.help.global.card.boilerCondensing",
              )}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.a")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.gaz")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.boilerB1")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.c")}
            </div>
          </div>
        </Card>
        <Card className="mb-30">
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.energy")}
            </div>
            <div className="font-weight-bold ">
              {t<string>("pro.certificate.form.help.global.card.fuel")}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>("pro.certificate.form.help.global.card.system")}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>(
                "pro.certificate.form.help.global.card.boilerCondensing",
              )}
            </div>
          </div>
          <Divider />
          <div className="d-flex justify-content-between">
            <div className="">
              {t<string>(
                "pro.certificate.form.help.global.card.classEnergetic",
              )}
            </div>
            <div className="font-weight-bold ">
              {" "}
              {t<string>("pro.certificate.form.help.global.card.ab")}
            </div>
          </div>
        </Card>
      </>
    </BasicModal>
  );
};

export default MaintenanceCertificateHelp4Modal;
