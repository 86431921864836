export interface Name {
  firstName: string;
  lastName: string;
}
export const getNameFromFullName = (fullName: string): Name => {
  const name = fullName.split(" ");
  return {
    firstName: name[name.length - 1],
    lastName: getAllNames(name.splice(-name.length, name.length - 1)),
  };
};

const getAllNames = (name: string[]): string => {
  return name.reduce((prev, curr) => `${prev}${!prev ? "" : " "}${curr}`, "");
};
