import { FunctionComponent, useEffect, useState } from "react";
import BasicModal from "@components/modals/BasicModal";
import { useTranslation } from "react-i18next";
import { formItemBlockLayout } from "@utils/Constant";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import { GazProfessionalRGRequestSaveRequestDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.save.request.dto";
import { RG_REQUEST_TYPE } from "@utils/enums/qualifications.rg-requests.type.enum";
import {
  requestCreateGazProfessionalRGRequest,
  requestUpdateGazProfessionalRGRequest,
} from "@state/gazprofessional/GazProfessionalEffects";
import toastAlert from "@components/alerts/ToastAlert";
import { RG_REQUEST_SPECIALITY } from "@utils/enums/qualifications.rg-requests.speciality.enum";
import ModuleFeedBack from "@components/rgRequestModules/ModuleFeedBack";
import ModuleLoad from "@components/rgRequestModules/ModuleLoad";
import { SectionSpeciality } from "@utils/enums/qualifications.rg-requests.section.enum";
import {
  confirmationPopup,
  isReadOnly,
  requestRGRequestOPProceed,
  RG_REQUEST_CERTIFICATE_ACCEPTED_MENTION_TYPES,
} from "@utils/rgrequest-module-utils";
import ModuleSubmissionFooter from "@components/rgRequestModules/ModuleSubmissionFooter";
import { ModalMode } from "@utils/enums/qualifications.rg-requests.mode.enum";
import { GazProfessionalRGRequestDetailsResponseDto } from "@state/gazprofessional/dto/gaz.professional.rgrequest.details.response.dto";
import { OrganisationProfessionalRGRequestDetailsResponseDto } from "@state/organisationprofessional/dto/organisation.professional.rgrequest.details.response.dto";
import ModuleCompanyName from "@components/rgRequestModules/ModuleCompanyName";
import { RG_REQUEST_STATUS } from "@utils/enums/qualifications.rg-requests.status.enum";
import { maptoRgRequestMode } from "@utils/rgRequestMappers";
import ModuleSubtitle from "@components/rgRequestModules/ModuleSubtitle";

interface UpdateCertificateRGFormValues {
  comment?: string;
}

interface QualificationsUpdateCertificateModalProps {
  visible: boolean;
  onCancel: () => void;
  className?: string;
  isOPSection?: boolean;
  modalMode: ModalMode;
  rgRequest?:
    | GazProfessionalRGRequestDetailsResponseDto
    | OrganisationProfessionalRGRequestDetailsResponseDto;
  isInstallationSection?: boolean;
  name: {
    firstName: string;
    lastName: string;
  };
}

const UpdateCertificateModal: FunctionComponent<
  QualificationsUpdateCertificateModalProps
> = ({
  visible,
  onCancel,
  className = "",
  isOPSection = false,
  modalMode,
  rgRequest,
  isInstallationSection = rgRequest?.speciality ===
    RG_REQUEST_SPECIALITY.INSTALLATION.value,
  name,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [maintenanceFileId, setMaintenanceFileIid] = useState("");
  const [installationFileId, setInstallationFileIid] = useState("");
  const [acceptedMentionTypes] = useState<string[]>(
    RG_REQUEST_CERTIFICATE_ACCEPTED_MENTION_TYPES,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInstallation, setIsInstallation] = useState(isInstallationSection);

  const readOnly = isReadOnly(modalMode, isOPSection);

  useEffect(() => {
    if (isInstallationSection) {
      setIsInstallation(isInstallationSection);
    } else {
      setIsInstallation(
        rgRequest?.speciality === RG_REQUEST_SPECIALITY.INSTALLATION.value,
      );
    }
  }, [isInstallationSection, rgRequest]);

  useEffect(() => {
    if (rgRequest) {
      if (rgRequest.installationCertificate) {
        setInstallationFileIid(rgRequest.installationCertificate.id);
      } else {
        setInstallationFileIid("");
      }
      if (rgRequest.maintenanceCertificate) {
        setMaintenanceFileIid(rgRequest.maintenanceCertificate.id);
      } else {
        setMaintenanceFileIid("");
      }

      form.setFields([
        {
          name: ["installationCertificate"],
          value: rgRequest.installationCertificate,
        },
        {
          name: ["maintenanceCertificate"],
          value: rgRequest.maintenanceCertificate,
        },
        {
          name: ["comment"],
          value: rgRequest.comment,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [rgRequest, form]);

  const handleSubmit = (values: UpdateCertificateRGFormValues) => {
    void confirmationPopup(isOPSection, RG_REQUEST_TYPE.UPDATE, t).then(
      (confirmed: boolean) => {
        if (confirmed) {
          setIsLoading(true);
          if (isOPSection) {
            if (rgRequest) {
              requestRGRequestOPProceed(false, rgRequest.id, values.comment);
            }
          } else {
            const speciality = isInstallationSection
              ? RG_REQUEST_SPECIALITY.INSTALLATION.value
              : RG_REQUEST_SPECIALITY.MAINTENANCE.value;
            const rgRequestToSave: GazProfessionalRGRequestSaveRequestDto = {
              type: RG_REQUEST_TYPE.UPDATE.value,
              speciality: speciality,
              firstName: name.firstName,
              lastName: name.lastName,
              installationFileId: installationFileId,
              maintenanceFileId: maintenanceFileId,
            };
            if (rgRequest) {
              void requestUpdateGazProfessionalRGRequest({
                id: rgRequest.id,
                dto: rgRequestToSave,
              });
            } else {
              void requestCreateGazProfessionalRGRequest({
                dto: rgRequestToSave,
              });
            }
          }
        }
      },
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  const validation = () => {
    if (rgRequest) {
      requestRGRequestOPProceed(true, rgRequest.id, undefined);
    }
  };

  const onFinishFailed = () => {
    toastAlert(t<string>("pro.qualifications.modals.updateCertificate.toast"));
  };

  return (
    <BasicModal
      open={visible}
      onCancel={onCancel}
      className={`app-modal-qualifications-add-rg ${className}`}
      title={t<string>(
        `pro.qualifications.modals.updateCertificate.title.${maptoRgRequestMode(
          modalMode,
        )}`,
      )}
      footer={null}
    >
      <>
        {isOPSection ? (
          <ModuleCompanyName companyName={rgRequest?.companyName} />
        ) : (
          <>
            <ModuleSubtitle status={rgRequest?.status} />
            {modalMode === ModalMode.CREATE && (
              <div className="mb-20">
                {t<string>(
                  "pro.qualifications.modals.updateCertificate.description",
                )}
              </div>
            )}
            {modalMode === ModalMode.UPDATE && (
              <ModuleFeedBack
                refusalDate={rgRequest?.refusalDate}
                comment={rgRequest?.comment}
              />
            )}
          </>
        )}
        <div className="mb-20 fw-bold">
          {t<string>("pro.qualifications.modals.updateCertificate.subtitle", {
            name: `${name.firstName} ${name.lastName}`,
          })}
        </div>
        <div className="rg-request-form-section-title">
          {t<string>(
            `pro.qualifications.modals.general.section.${
              isInstallation ? "installation" : "maintenance"
            }`,
          )}
        </div>
        <Form
          form={form}
          {...formItemBlockLayout}
          onFinishFailed={onFinishFailed}
          onFinish={handleSubmit}
        >
          {isInstallation ? (
            <ModuleLoad
              readOnly={readOnly}
              type={RG_REQUEST_TYPE.UPDATE}
              speciality={SectionSpeciality.INSTALLATION}
              fileId={installationFileId}
              setFileId={setInstallationFileIid}
              acceptedMentionTypes={acceptedMentionTypes}
              certificate={rgRequest?.installationCertificate}
            />
          ) : (
            <ModuleLoad
              readOnly={readOnly}
              type={RG_REQUEST_TYPE.UPDATE}
              speciality={SectionSpeciality.MAINTENANCE}
              fileId={maintenanceFileId}
              setFileId={setMaintenanceFileIid}
              acceptedMentionTypes={acceptedMentionTypes}
              certificate={rgRequest?.maintenanceCertificate}
            />
          )}
          {isOPSection &&
            !(rgRequest?.status === RG_REQUEST_STATUS.IN_PROGRESS.value) && (
              <ModuleFeedBack isOPSection modalMode={modalMode} />
            )}
          {modalMode !== ModalMode.DETAILS && (
            <ModuleSubmissionFooter
              isOPSection={isOPSection}
              onClick={validation}
              type={RG_REQUEST_TYPE.UPDATE}
              modalMode={modalMode}
              isLoading={isLoading}
            />
          )}
        </Form>
      </>
    </BasicModal>
  );
};

export default UpdateCertificateModal;
