import common from "./common.json";
import block from "./block.json";
import login from "./login.json";
import dashboard from "./dashboard.json";
import menu from "./menu.json";
import header from "./header.json";
import footer from "./footer.json";
import buttons from "./buttons.json";
import forms from "./forms.json";
import tasks from "./tasks.json";
import endpoints from "./endpoints.json";
import search from "./search.json";
import gazprofessional from "./gazprofessional.json";
import home from "./home.json";
import pro from "./pro.json";
import summary from "./summary.json";
import personalInfo from "./personalInfo.json";
import error from "./error.json";
import mentions from "./mentions.json";
import confidentiality from "./confidentiality.json";
import gazMonth from "./gazMonth.json";
import organisationprofessional from "./organisationprofessional.json";
import webinar from "./webinar.json";
import worksite from "./worksite.json";
import rentreeDuChauffage from "./rentreeDuChauffage.json";
import landingpage from "./landingpage.json";
import homeWithVideo from "./homeWithVideo.json";

const translationFR = {
  ...block,
  ...common,
  ...login,
  ...dashboard,
  ...header,
  ...footer,
  ...home,
  ...menu,
  ...buttons,
  ...forms,
  ...tasks,
  ...endpoints,
  ...search,
  ...gazprofessional,
  ...pro,
  ...summary,
  ...personalInfo,
  ...error,
  ...mentions,
  ...confidentiality,
  ...gazMonth,
  ...organisationprofessional,
  ...webinar,
  ...worksite,
  ...rentreeDuChauffage,
  ...landingpage,
  ...homeWithVideo,
};

export default translationFR;
