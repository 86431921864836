import { createEffect } from "effector";
import "whatwg-fetch";
import configuration from "src/utils/Config";
import {
  makeRestCall,
  restDetailsHandler,
  restListHandler,
  restListHandlerPagination,
} from "@utils/rest/RestClient";
import { PageInfoResponseDto } from "@state/page/dto/page.info.response.dto";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import { Pageable } from "@type/pagination/pagination.types";
import { PageSearchRequestDto } from "@state/page/dto/page.search.request.dto";

const baseUrl = configuration.backendBaseUrl;
const pageUrl = baseUrl + "/pages/";

export const requestGetPagesList = createEffect({
  handler: restListHandlerPagination<Pageable<PageInfoResponseDto>>(
    `${pageUrl}details`,
  ),
});
export const requestGetPage = createEffect({
  handler: restDetailsHandler<PageInfoResponseDto>(`${pageUrl}`),
});
export const requestGetPages = createEffect({
  handler: restListHandler<PageInfoResponseDto, PageSearchRequestDto>(
    `${pageUrl}all`,
  ),
});

export const requestGetPagePreview = createEffect({
  handler: restDetailsHandler<PageInfoResponseDto>(`${pageUrl}id/`),
});

export const restArticleDetailsHandler = <T>(
  endpointUrl: string,
): ((data: { dto: { pageTitle: string } }) => Promise<ParsedResponse<T>>) => {
  return ({ dto }: { dto: { pageTitle: string } }) =>
    makeRestCall<void, T>(endpointUrl + dto.pageTitle, "GET");
};

export const requestGetArticle = createEffect({
  handler: restArticleDetailsHandler<PageInfoResponseDto>(
    `${pageUrl}articles/`,
  ),
});
