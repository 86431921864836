import { FC } from "react";
import { Helmet } from "react-helmet";
import configuration from "@utils/Config";
import { pgsTest } from "@utils/Constant";

interface PropsType {
  title?: string;
  description?: string;
  url: string;
  mainImageResourceId?: string;
  shareImageResourceId?: string;
}

const Seo: FC<PropsType> = ({
  title,
  description,
  url,
  mainImageResourceId,
  shareImageResourceId,
}) => {
  const isAPg = title?.split("/")[0] === "pg";
  let renderedTitle;
  if (title) {
    renderedTitle = isAPg ? "Les professionnels du gaz" : title;
  } else renderedTitle = "Les professionnels du gaz";
  const pgsTestYoocanId =
    pgsTest.indexOf("1") > -1 ? pgsTest.concat("1") : pgsTest;
  const isPgTest =
    title?.split("/")[1] && pgsTestYoocanId.indexOf(title?.split("/")[1]) > -1;
  const frontendBaseUrl = configuration.frontendBaseUrl;
  const backendBaseUrl = configuration.backendBaseUrl;

  const getImageContent = () => {
    if (shareImageResourceId) {
      return `${backendBaseUrl}/public/resources/${shareImageResourceId}/file`;
    } else if (mainImageResourceId) {
      return `${backendBaseUrl}/public/resources/${mainImageResourceId}/file`;
    } else return `${frontendBaseUrl}/logo-header-PG-og.png`;
  };

  return (
    <div className="application">
      <Helmet>
        <title>{renderedTitle}</title>
        <meta property="og:title" content={renderedTitle} />
        {description && (
          <meta property="og:description" content={description} />
        )}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={getImageContent()} />
        <meta property="og:image:alt" content="Logo PG" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@ProsduGaz" />
        <meta name="twitter:creator" content="@ProsduGaz" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={getImageContent()} />
        {isPgTest && <meta name="robots" content="noindex, nofollow" />}
        <meta name="twitter:domain" content={url} />
        <link rel="canonical" href={url} />
      </Helmet>
    </div>
  );
};

export default Seo;
