import { FC, useEffect, useState } from "react";
import PageLayout from "@components/layouts/PageLayout";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { Col, Form, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import SubmitButton from "@components/buttons/SubmitButton";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { Store } from "rc-field-form/lib/interface";
import {
  requestUpdateCoordinateGazProfessional,
  requestUpdateGazProfessional,
  requestUpdateTertiaryInformation,
} from "@state/gazprofessional/GazProfessionalEffects";
import Section from "@components/section/Section";
import TextAreaFormField from "@components/inputs/TextAreaFormField";
import { formItemBlockLayout } from "@utils/Constant";
import { setUser } from "@state/auth/AuthEvents";
import BasicModal from "@components/modals/BasicModal";
import MapLeaflet from "@components/map/MapLeaflet";
import parse from "html-react-parser";
import { AddressFieldsDto } from "@state/address/dto/address.api.gouv.response.dto";
import PicturesUpload from "@components/uploads/PicturesUpload";
import { toastSuccess } from "@utils/toast-helper";
import MapSection from "@views/pro/gazProfessional/account/section/MapSection";
import SocialSection from "@views/pro/gazProfessional/account/section/SocialSection";
import CoordinatesSection from "@views/pro/gazProfessional/account/section/CoordinatesSection";
import DocumentsSection from "@views/pro/gazProfessional/account/section/DocumentsSection";
import PasswordSection from "@views/pro/gazProfessional/account/section/PasswordSection";
import RegisterInformationSection from "@views/pro/gazProfessional/account/section/RegisterInformationSection";
import ProfessionalSection from "@views/pro/gazProfessional/account/section/ProfessionalSection";
import { SelectFormField } from "@components/inputs/SelectFormField";

interface FormValues {
  siret: string;
  businessName: string;
  username: string;
  phone: string;
  email: string;
  address: string;
  postcode: string;
  city: string;
  installation: boolean;
  maintenance: boolean;
  thpe: boolean;
  pac: boolean;
  smokeDuct: boolean;

  professionalName: string;
  professionalPhone: string;
  professionalEmail: string;

  editableEmail: string;
  editableAddress: string;
  editableZipCode: string;
  editableCity: string;
  editablePhone1?: string;
  editablePhone2?: string;

  latitude: number;
  longitude: number;

  website: string;
  facebook: string;
  twitter: string;
  linkedin: string;

  description: string;

  visible: boolean;
  alert: boolean;

  pictureListId: string[];

  participatesErp?: string;
  erp?: string[];
}

const initialValue = (user: GazProfessionalUser): FormValues => {
  return {
    siret: user.siret,
    businessName: user.companyName,
    username: user.username,
    phone: user.phone1,
    email: user.email,
    address: user.address1,
    postcode: user.zipcode,
    city: user.city,
    installation: user.installation !== undefined,
    maintenance: user.maintenance !== undefined,
    thpe: user.thpeEvapdc,
    pac: user.pacHybride != undefined,
    smokeDuct: user.smokeDuct != undefined,
    professionalName: user?.professionalOrganization,
    professionalPhone: user?.phone2,
    professionalEmail: user?.emailOptional,
    editableEmail: user?.editableEmail,
    editableAddress: user?.editableAddress,
    editableZipCode: user?.editableZipCode,
    editableCity: user.editableCity,
    editablePhone1: user.editablePhone1,
    editablePhone2: user.editablePhone2,
    latitude: user.latitude,
    longitude: user.longitude,
    website: user.website,
    facebook: user.facebook,
    twitter: user.twitter,
    linkedin: user.linkedin,
    description: user.description,
    visible: user.visible,
    alert: user.alert,
    pictureListId: user.pictureListId,
    participatesErp: user.gazProfessionalTertiaryDetail?.participatesErp
      ? "yes"
      : "no",
    erp: user.gazProfessionalTertiaryDetail?.erp ?? [],
  };
};

const PersonalDataScreen: FC = () => {
  const { t } = useTranslation();
  const [form] = useForm();
  const authentifiedContext = useStore(authentifiedStore);
  const userPg = authentifiedContext.user as GazProfessionalUser;

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pictureListId, setPictureListId] = useState<string[] | undefined>(
    userPg.pictureListId,
  );
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isParticipatedErp, setIsParticipatedErp] = useState<boolean>(
    userPg?.gazProfessionalTertiaryDetail?.participatesErp ?? false,
  );
  const [tempUser, setTempUser] = useState<GazProfessionalUser>();
  const [selectedAddress, setSelectedAddress] = useState<AddressFieldsDto>();
  const [isSelectedAddressExists, setIsSelectedAddressExists] =
    useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{
    longitude: number;
    latitude: number;
  }>();

  const initAddress =
    userPg.editableAddress &&
    userPg.editableZipCode &&
    userPg.editableCity &&
    userPg.editableAddress
      .concat(" ", userPg.editableZipCode)
      .concat(" ", userPg.editableCity);

  const handleSave = (values: Store) => {
    const obj = values as FormValues;
    obj.editableAddress =
      selectedAddress?.properties.name ?? userPg.editableAddress;
    obj.editableZipCode =
      selectedAddress?.properties.postcode ?? userPg.editableZipCode;
    obj.editableCity = selectedAddress?.properties.city ?? userPg.editableCity;
    void requestUpdateGazProfessional({
      dto: {
        ...obj,
        pictureListId: pictureListId,
      },
      id: "",
    });
    if (obj.participatesErp ?? obj.erp) {
      void requestUpdateTertiaryInformation({
        dto: {
          participatesErp: obj.participatesErp === "yes",
          erp: obj.participatesErp === "yes" ? obj.erp : undefined,
        },
        id: "",
      });
    }
  };

  const handleSubmit = (values: Store) => {
    setButtonLoading(true);
    const obj = values as FormValues;
    obj.editableAddress =
      selectedAddress?.properties.name ?? userPg.editableAddress;
    obj.editableZipCode =
      selectedAddress?.properties.postcode ?? userPg.editableZipCode;
    obj.editableCity = selectedAddress?.properties.city ?? userPg.editableCity;
    if (
      obj.editableAddress != userPg.editableAddress ||
      obj.editableCity != userPg.editableCity ||
      obj.editableZipCode != userPg.editableZipCode
    ) {
      void requestUpdateCoordinateGazProfessional({
        dto: {
          ...obj,
        },
        id: "",
      });
    } else {
      handleSave(values);
    }
  };

  useEffect(() => {
    return requestUpdateCoordinateGazProfessional.done.watch(({ result }) => {
      if (result.data && result.ok) {
        setTempUser(result.data);
        setIsModalVisible(true);
      }
      setButtonLoading(false);
    });
  });

  useEffect(() => {
    if (tempUser) {
      setCoordinates({
        latitude: tempUser.latitude,
        longitude: tempUser.longitude,
      });
    }
  }, [tempUser]);

  useEffect(() => {
    return requestUpdateGazProfessional.done.watch(({ result }) => {
      if (result.data && result.ok) {
        setUser(result.data);
        toastSuccess(t<string>("personalInfo.messages.success"));
      }
      setButtonLoading(false);
    });
  });

  useEffect(() => {
    return requestUpdateTertiaryInformation.done.watch(({ result }) => {
      if (result.data && result.ok) {
        setUser(result.data);
      }
      setButtonLoading(false);
    });
  });

  return (
    <PageLayout
      container
      titleCentered={false}
      title={t<string>("personalInfo.title")}
      subtitle={t<string>("personalInfo.subtitle")}
      noSideMenu={false}
    >
      <>
        {userPg && (
          <>
            <Form
              onFinish={handleSubmit}
              form={form}
              initialValues={initialValue(userPg)}
              {...formItemBlockLayout}
            >
              <MapSection
                setSelectedAddress={setSelectedAddress}
                initAddress={initAddress}
                setIsSelectedAddressExists={setIsSelectedAddressExists}
                userPg={userPg}
              />
              <SocialSection />
              <CoordinatesSection />

              <PicturesUpload
                pictureListId={pictureListId}
                setPicturesListId={setPictureListId}
              />

              <Section
                title={t<string>("personalInfo.sectionTitle.description")}
                titleClassName="text-primary px-12"
              >
                <div className="px-12">
                  <Row gutter={16}>
                    <Col xs={0} lg={1} className="mx-0 px-0" />
                    <Col xs={24} lg={23}>
                      <TextAreaFormField
                        showLabel
                        module="personalInfo"
                        field="description"
                      />
                    </Col>
                  </Row>
                </div>
              </Section>
              <Section
                title={t<string>("personalInfo.sectionTitle.tertiary")}
                titleClassName="text-primary px-12"
              >
                <div className="px-12">
                  <Row gutter={16}>
                    <Col xs={0} lg={1} className="mx-0 px-0" />
                    <Col xs={24} lg={12}>
                      <SelectFormField
                        showLabel
                        module="personalInfo"
                        field="participatesErp"
                        options={[
                          {
                            value: "yes",
                            label: t("common.yes"),
                          },
                          {
                            value: "no",
                            label: t("common.no"),
                          },
                        ]}
                        onSelect={(value: string) =>
                          setIsParticipatedErp(value === "yes")
                        }
                        initialValue={isParticipatedErp ? "yes" : "no"}
                      ></SelectFormField>
                    </Col>
                    {isParticipatedErp && (
                      <Col xs={24} lg={11}>
                        <SelectFormField
                          mode="multiple"
                          initialValue={
                            userPg?.gazProfessionalTertiaryDetail?.erp
                          }
                          showLabel
                          module="personalInfo"
                          field="erp"
                          options={[
                            {
                              value: "erp5",
                              label: t("personalInfo.form.fields.erp.erp5"),
                            },
                            {
                              value: "erp4",
                              label: t("personalInfo.form.fields.erp.erp4"),
                            },
                            {
                              value: "erp3",
                              label: t("personalInfo.form.fields.erp.erp3"),
                            },
                            {
                              value: "erp2",
                              label: t("personalInfo.form.fields.erp.erp2"),
                            },
                            {
                              value: "erp1",
                              label: t("personalInfo.form.fields.erp.erp1"),
                            },
                          ]}
                        ></SelectFormField>
                      </Col>
                    )}
                  </Row>
                </div>
              </Section>
              <div className="mb-40 px-12">
                <div className="d-flex align-items-center justify-content-end flex-wrap">
                  {/*<Link*/}
                  {/*  to={ROUTES.public.gazProfessionals.details.generate(*/}
                  {/*    userPg.yoocanId,*/}
                  {/*  )}*/}
                  {/*>*/}
                  {/*  <BasicButton*/}
                  {/*    variant="primary-outline"*/}
                  {/*    text={t<string>("personalInfo.form.actions.fiche")}*/}
                  {/*    className="ms-3 mt-3"*/}
                  {/*  />*/}
                  {/*</Link>*/}
                  <SubmitButton
                    disabled={
                      selectedAddress?.properties.label
                        ? !isSelectedAddressExists
                        : false
                    }
                    module="personalInfo"
                    isSubmitting={buttonLoading}
                    label="submit"
                    className="ms-3 mt-3"
                  />
                </div>
              </div>
            </Form>

            <DocumentsSection />
            <PasswordSection />

            <Form initialValues={initialValue(userPg)} {...formItemBlockLayout}>
              <RegisterInformationSection />
              <ProfessionalSection />
            </Form>
          </>
        )}
        <BasicModal
          className="large-modal"
          open={isModalVisible}
          title={t<string>("personalInfo.modal.save.title")}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <>
            <span>{parse(t<string>("personalInfo.modal.save.message"))}</span>
            <div className="app-professional-details">
              <div className="professional-details-map">
                {coordinates && tempUser && (
                  <div className="map flex-grow-1 d-flex flex-column">
                    <MapLeaflet
                      center={[coordinates.latitude, coordinates.longitude]}
                      markers={[
                        {
                          id: "marker",
                          position: [
                            coordinates.latitude,
                            coordinates.longitude,
                          ],
                        },
                      ]}
                      scrollWheelZoom
                      onMapZoomEnd={() => void 0}
                      onMapDragEnd={() => void 0}
                      onMouseUp={() => void 0}
                      zoom={14}
                    />
                    <div className="d-flex align-items-center justify-content-end flex-wrap">
                      <BasicButton
                        variant="primary-outline"
                        text={t<string>("personalInfo.modal.save.buttonSave")}
                        className="ms-3 mt-3"
                        onClick={() => {
                          setIsModalVisible(false);
                          handleSave(tempUser);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        </BasicModal>
      </>
    </PageLayout>
  );
};

export default PersonalDataScreen;
