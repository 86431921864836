import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BasicButton from "@components/buttons/BasicButton";
import { useNavigate } from "react-router-dom";
import CertificateCard from "@components/cards/CertificateCard";
import { useStore } from "effector-react";
import { authentifiedStore } from "@state/auth/AuthStore";
import { requestGetMentionExport } from "@state/mention/pachybride/MentionPacHybrideEffects";
import { Spin } from "antd";
import FileSaver from "file-saver";
import { ParsedResponse } from "@utils/rest/ServerResponseParse";
import Section from "@components/section/Section";
import FilesCard from "@components/files/FilesCard";
import { File } from "@type/resource-type-interface";
import { GazProfessionalUser } from "@type/auth/auth.types";
import { toastError } from "@utils/toast-helper";
import { ROUTES } from "@utils/Routes";
import { requestGetResources } from "@state/resource/ResourceEffects";
import { mapResourceTofileType } from "@utils/upload-utils";

export const PacHybrideMentionAcceptedScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authentifiedContext = useStore(authentifiedStore);
  const authentifiedUser = authentifiedContext.user as GazProfessionalUser;
  const pacHybride = authentifiedUser?.pacHybride;

  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pacHybrideFiles, setPacHybrideFiles] = useState<File[]>([]);

  const today = new Date();
  const expiredAt = pacHybride?.expiredAt ?? "";
  const dateExpiredAt = new Date(expiredAt);
  const dateExpiredAtMoreThreeMonth = new Date(
    dateExpiredAt.setMonth(dateExpiredAt.getMonth() - 3),
  );

  const [showRenewalButton] = useState<boolean>(
    today > dateExpiredAtMoreThreeMonth,
  );

  const handleDownload = () => {
    setButtonLoading(true);
    void requestGetMentionExport({
      dto: {},
    });
  };

  const needToPassElearning =
    pacHybride?.numberPacHybride === "no" &&
    pacHybride?.technicalTraining === "no" &&
    pacHybride?.commercialTraining === "no" &&
    pacHybride.elearningStatus === "IN_PROGRESS";

  const isRenewalIsPending =
    pacHybride?.statusRge === "PENDING" ||
    pacHybride?.statusRge === "REFUSED" ||
    pacHybride?.elearningStatus === "REFUSED" ||
    pacHybride?.elearningStatus === "IN_PROGRESS";

  useEffect(() => {
    return requestGetMentionExport.done.watch(({ result }) => {
      afterWs(result);
    });
  });

  useEffect(() => {
    setIsLoading(true);
    void requestGetResources({
      dto: {
        domain: ["PAC_HYBRIDE"],
        ids: [],
      },
    });
  }, []);

  useEffect(() => {
    return requestGetResources.done.watch(({ result }) => {
      if (result.ok && result.data && result.data.length > 0) {
        setPacHybrideFiles(
          result.data
            .filter((file) => file.domain === "PAC_HYBRIDE")
            .map((resource) => {
              return mapResourceTofileType(resource);
            }),
        );
      }
      setIsLoading(false);
    });
  });

  const afterWs = (result: ParsedResponse<Blob>) => {
    setButtonLoading(false);
    if (result.ok && result.data && result.fileName) {
      const data = new Blob([result.data], {
        type: result.data.type,
      });
      FileSaver.saveAs(data, result.fileName);
    } else {
      toastError(t<string>("error"));
    }
  };
  const isRefused =
    pacHybride?.statusRge === "REFUSED" ||
    pacHybride?.elearningStatus === "REFUSED";

  const getButtonRenewal = () => {
    if (needToPassElearning) {
      return t<string>(
        "pro.mentions.pacHybride.detail.certificate.renewal.elearningButton",
      );
    } else {
      return t<string>(
        isRenewalIsPending
          ? "pro.mentions.pacHybride.detail.certificate.renewal.resumeButton"
          : "pro.mentions.pacHybride.detail.certificate.renewal.button",
      );
    }
  };

  return (
    <Spin spinning={buttonLoading}>
      <Section
        type="h2"
        title={t<string>(
          "pro.mentions.pacHybride.detail.certificate.titleSection1",
        )}
        titleClassName="text-primary px-12"
      >
        <>
          {isRefused && (
            <div style={{ color: "red" }} className="mb-3">
              {t(
                "pro.mentions.pacHybride.detail.certificate.renewal.documentRefused",
              )}
            </div>
          )}
          <CertificateCard
            type="pacHybride"
            date={pacHybride?.updatedAt || ""}
            expiratedDate={pacHybride?.expiredAt || ""}
            title={t<string>(
              "pro.mentions.pacHybride.detail.certificate.titleCertificate",
            )}
            onClick={handleDownload}
            className="mb-3 "
          />
          {showRenewalButton && (
            <div className="d-flex justify-content-center">
              <BasicButton
                variant="primary"
                text={getButtonRenewal()}
                onClick={() =>
                  needToPassElearning
                    ? navigate(
                        ROUTES.professional.pacsHybrides.iframe.generate(),
                      )
                    : navigate(
                        ROUTES.professional.pacsHybrides.renewal.generate(),
                      )
                }
                className="mx-auto"
              />
            </div>
          )}
        </>
      </Section>
      <Section
        type="h2"
        title={t<string>(
          "pro.mentions.pacHybride.detail.certificate.titleSection2",
        )}
        titleClassName="text-primary px-12 my-5"
      >
        {!isLoading ? (
          <div className="d-flex justify-content-center flex-wrap">
            <FilesCard files={pacHybrideFiles} />
          </div>
        ) : (
          <div className="anticon-resource">
            <Spin size="large" />
          </div>
        )}
      </Section>
      <Section
        type="h2"
        title={t<string>(
          "pro.mentions.pacHybride.detail.certificate.titleSection3",
        )}
        titleClassName="text-primary px-12 my-5"
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <BasicButton
            variant="primary-outline"
            text={t<string>(
              "pro.mentions.pacHybride.detail.certificate.linksUtil1",
            )}
            onClick={() =>
              window.open(
                "https://www.grdf.fr/installateurs/equipements-innovations-gaz/simulateur-aides-renovation-gaz-naturel",
              )
            }
            className="m-3"
          />
          <BasicButton
            variant="primary-outline"
            text={t<string>(
              "pro.mentions.pacHybride.detail.certificate.linksUtil2",
            )}
            onClick={() =>
              window.open(
                "https://www.grdf.fr/installateurs/equipements-innovations-gaz/panorama-pac-hybride-marche-renovation ",
              )
            }
            className="m-3"
          />
        </div>
      </Section>
    </Spin>
  );
};
